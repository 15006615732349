/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import { Card, Col, Form, Row, Space, Typography, theme } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import UserResult from './UserResponse/UsersResult';
import UserSearchForm from './UserSearchForm';
import { useTranslation } from 'react-i18next';
import { breakpoints } from '../../../globalVar/theme';
import { useSearchParams } from 'react-router-dom';

const { Text } = Typography;
/**
 * UserSearch
 * @return {JSX.Element}
 */
function UserSearch({ inDeliveryCreation, formStep1 }) {
  const { token } = theme.useToken();
  const { t } = useTranslation();
  const [userform] = Form.useForm();
  const [searchValue, setSearchValue] = useState();
  const [searchParams] = useSearchParams();

  return (
    searchParams.get('name') || searchParams.get('telephone') || searchParams.get('email') ?
      <UserResult
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        inDeliveryCreation={inDeliveryCreation}
        formStep1={formStep1}
      /> :
      <Row justify='center' css={{
        display: 'grid',
        height: inDeliveryCreation ? '400' : 'auto',
        minHeight: inDeliveryCreation ? '364px' : 'calc(100vh - 64px - 20px)',
        marginTop: inDeliveryCreation ? '30px' : '0px',
        [breakpoints.md]: {
          marginTop: inDeliveryCreation ? 'calc(64px + 40px)' : '0px',
        },
      }}>
        <Card css={{
          maxWidth: 620,
          alignSelf: 'center',
          transform: inDeliveryCreation && 'translateY(-20px)',
        }}>
          <Space direction='vertical' size={20} css={{ width: '100%' }}>
            <Row justify='center' align='middle' gutter={15} >
              <Col>
                <SearchOutlined css={{ fontSize: 24, color: token.colorPrimary }} />
              </Col>
              <Col>
                <Text strong css={{ fontSize: 16, color: token.colorPrimary }}>{inDeliveryCreation ? t('SEARCH_CUSTOMER') : t('SEARCH_USER')}</Text>
              </Col>
            </Row>
            <Row justify='center' align='middle'>
              <Text css={{ textAlign: 'center' }}>{t('COMPLETE_FIELD_FOR_SEARCH')}</Text>
            </Row>
            <UserSearchForm
              form={userform}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
            />
          </Space>
        </Card>
      </Row>
  );
}

export default UserSearch;
