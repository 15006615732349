/**
 * addPropertyDeactivationType
 * Add property deactivationType to array
 * @param {Array} array
 * @param {String} deactivationTypeName
 * @return {Array}
 */
function addPropertyDeactivationType(array, deactivationTypeName) {
  return array.map((item) => ({
    ...item,
    deactivationType: deactivationTypeName,
  }));
}
export default addPropertyDeactivationType;
