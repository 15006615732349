import { theme } from 'antd';
import { WarningFilled } from '@ant-design/icons';

/**
 * Renders an icon for a delivery incident.
 *
 * @param {boolean} incident - If true, the icon will be rendered. Otherwise, null will be returned.
 *
 * @return {JSX.Element|null} - The rendered icon component or null if the incident is false.
 */
function DeliveryIncidentIconListItem({ incident }) {
  const { token } = theme.useToken();
  return incident ? <WarningFilled style={{ color: token.colorError, fontSize: '15px' }} /> : null;
}

export default DeliveryIncidentIconListItem;
