import React from 'react';
import Error500 from '../Errors/Error500';
import Error504 from '../Errors/Error504';
import PageNotFound from '../PageNotFound/PageNotFound';

/**
 * StatusErrorWrapper
 * @param {number} status
 * @return {JSX}
 */
function StatusErrorWrapper({ statusError, refreshLink, children }) {
  switch (statusError) {
  case 500:
    return (
      <Error500 />
    );
  case 504:
    return (
      <Error504 refreshLink={refreshLink}/>
    );
  case 400:
    return (
      <PageNotFound />
    );
  case 200:
  case 202:
    return children;
  default:
    return (
      <Error500 />
    );
  }
}

export default StatusErrorWrapper;
