import { Tooltip, Typography, theme } from 'antd';
import { ReactNode } from 'react';

const { Text } = Typography;

interface DetailWithPopoverTupleProps {
  primaryInfo?: string;
  hoverInfo?: string;
}

function DetailWithPopoverTuple({ primaryInfo, hoverInfo }: DetailWithPopoverTupleProps): ReactNode {
  const {
    token: { colorPrimaryText },
  } = theme.useToken();

  if (!primaryInfo?.trim()) {
    return null;
  }

  return hoverInfo?.trim() ? (
    <Tooltip title={hoverInfo} placement="bottom" color={colorPrimaryText}>
      <Text>{primaryInfo}</Text>
    </Tooltip>
  ) : (
    <Text>{primaryInfo}</Text>
  );
}

export default DetailWithPopoverTuple;
