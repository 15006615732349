import Icon, { ArrowLeftOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Breadcrumb, BreadcrumbProps, theme, Typography } from 'antd';
import { ItemType } from 'antd/es/breadcrumb/Breadcrumb';
import { CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import WavyUnderline from '../iconComponents/WavyUnderline';

const previousLinkStyle: CSSProperties = {
  display: 'flex',
  border: '1px solid',
  borderRadius: '50%',
  padding: 0,
  width: 32,
  height: 32,
  alignItems: 'center',
  justifyContent: 'center',
};

const linkStyle: CSSProperties = {
  display: 'grid',
  height: 'auto',
};

const itemRender = (arrowColor: string, { href, title }: ItemType, routes: ItemType[]) => {
  const isLast = title === routes.at(-1)?.title;
  const to = href ?? '#';
  const { Paragraph } = Typography;

  return isLast ? (
    <Link to={to} style={linkStyle} className="breadcrumb-text">
      <Paragraph ellipsis strong style={{ fontSize: 16, margin: 0 }}>
        {title}
      </Paragraph>
      <Icon component={WavyUnderline} />
    </Link>
  ) : (
    <Link to={to} style={{ ...previousLinkStyle, color: arrowColor }}>
      <ArrowLeftOutlined />
    </Link>
  );
};

function Breadcrumbs({ items, ...props }: Omit<BreadcrumbProps, 'itemRender'>) {
  const {
    token: { colorPrimaryActive },
  } = theme.useToken();
  const renderedItems = (items ?? []).slice(-2);

  return <Breadcrumb {...props} separator={' '} items={renderedItems} itemRender={(route, _, routes) => itemRender(colorPrimaryActive, route, routes)} />;
}

export default styled((props: Omit<BreadcrumbProps, 'itemRender'>) => <Breadcrumbs {...props} />)`
    > ol {
      flex-wrap: nowrap;
    }
    .breadcrumb-text:hover {
      background-color: initial;
    }
`;
