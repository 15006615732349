/**
 * @param {number} idIncident from table errand_status
 * @return {String} is email is good format or no
 */
export function findDetailsDeliveryIncidents(idIncident) {
  switch (idIncident) {
  case 'CLIENT':
    return 'CLIENT_INCIDENT';
  case 'ORDER_UNAVAILABLE':
    return 'ORDER_UNAVAILABLE_INCIDENT';
  case 'OTHER':
    return 'OTHER_INCIDENT';
  default:
    return null;
  }
}
