import { EmptyData } from './EmptyData';

/**
 * @name EmptyModerationDrawer
 * @return {JSX.Element} EmptyModerationDrawer
 */
const EmptyModerationDrawer = () => {
  return (
    <EmptyData
      dataString='NO_MODERATION_REPORT'
      style={{
        height: 'calc(100vh - 256px)'/** retrait du header, du footer et des tabs du drawer  */,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    />
  );
};

export default EmptyModerationDrawer;
