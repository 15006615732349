import React from 'react';
import { Button, Popconfirm, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { removeObjectInArrayByKey } from '../../../utils/manipulateArray/removeObjectInArrayByKey';
import { deleteAddress } from '../../../services/apiEndPoints/addresses/deleteAddress';

/**
 * ArchiveAddressButtonDrawer
 * @param {Object} props
 * @param {Object} props.address - The address to archive.
 * @param {Function} props.setAddresses - The function to update the addresses.
 * @param {Function} props.setDrawerOpened - The function to update the drawer state.
 * @param {number} props.indexValueSelected - The index of the selected address.
 * @param {Function} props.setIndexValueSelected - The function to update the selected index.
 * @param {number} props.index - The index of the address.
 * @return {JSX.Element} Return the JSX element.
 */
function ArchiveAddressButtonDrawer({ address, setAddresses, setDrawerOpened, indexValueSelected, setIndexValueSelected, index }) {
  const { t } = useTranslation();

  const handleArchiveAndClose = () => {
    setDrawerOpened(false);
    deleteAddress({ addressId: address.id, deleted_date: new Date() })
      .then((response)=> {
        if (response.type === 'success') setAddresses((addresses) => removeObjectInArrayByKey({ array: addresses, key: 'id', value: address.id }));
        notification[response.type]({ message: t(response.message) });
        if (indexValueSelected === index) setIndexValueSelected(0);
      });
  };

  return (
    <Popconfirm
      title={t('CONFIRM_ARCHIV_ADRESS')}
      icon= {<ExclamationCircleFilled css={{ color: 'var(--terracotta)' }} />}
      onConfirm={() => handleArchiveAndClose()}
    >
      <Button
        danger
        type='primary'
      >
        {t('ARCHIVE')}
      </Button>
    </Popconfirm>
  );
}

export default ArchiveAddressButtonDrawer;
