import React from 'react';
import { useTranslation } from 'react-i18next';
import useCustomHtml from '../../../../../hooks/useCustomHtml';

/**
 * PushedHistoryNode
 * @param {Object} props
 * @param {Object} props.user
 * @param {string} props.user.firstName
 * @param {string} props.user.lastName
 * @return {JSX.Element}
 */
function PushedHistoryNode({ user }) {
  const { t } = useTranslation();

  const deliveryPushedBy = useCustomHtml({
    keyValues: [
      { key: '[SHOPOPOP_NAME]', value: `${user?.firstName} ${user?.lastName}`, bold: true },
    ],
    inputString: t('DELIVERY_PUSHED_BY'),
  });

  return <div>{deliveryPushedBy}</div>;
}

export default PushedHistoryNode;
