import { QuestionCircleTwoTone } from '@ant-design/icons';
import { FormatedAPIErrorsProps, useValidationStatus } from '@shopopop/react-hooks';
import { Card, Col, Form, Input, InputNumber, Row, Tooltip, theme } from 'antd';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { InputCurrency } from '../../index.ts';

// TODO: use regex created in an other ticket with API call (cf. Zod)

interface OrderInfoCardProps {
  apiErrors?: FormatedAPIErrorsProps | undefined;
}

function OrderInfoCard({ apiErrors }: OrderInfoCardProps): ReactNode {
  const { t } = useTranslation();
  const { token } = theme.useToken();

  // Handle email API error on validation
  const { validateStatus: amountValidateStatus, help: helpAmountMessage } = useValidationStatus(apiErrors, 'amount');

  return (
    <Card title={t('ORDER_INFO_TITLE')}>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            label={t('ORDER_REFERENCE')}
            name="reference"
            rules={[
              { required: true, message: t('ORDER_REF_REQUIRED') },
              { max: 50, message: t('ORDER_REF_LIMIT_SIZE') },
              {
                pattern: /^[a-zA-Z0-9# ]+$/,
                message: t('INVALID_VALUE'),
              },
            ]}
          >
            <Input data-testid="order-ref-input" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            label={
              <span>
                {t('PACK_NB')}
                <Tooltip title={t('TOOLTIP_PACKS')}>
                  <QuestionCircleTwoTone twoToneColor={token.colorPrimary} style={{ marginLeft: 4 }} />
                </Tooltip>
              </span>
            }
            name="pack"
            rules={[
              { required: true, message: t('PACKS_NBR_REQUIRED') },
              {
                type: 'number',
                min: 0,
                max: 40,
                message: t('PACKS_LIMIT_NBR'),
              },
            ]}
          >
            <InputNumber data-testid="packs-input" style={{ width: '100%' }} min={0} step={1} precision={0} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <InputCurrency
            label={t('ORDER_VALUE')}
            name="amount"
            rules={[{ required: true, message: t('ORDER_VALUE_REQUIRED') }]}
            options={{
              validateStatus: amountValidateStatus,
              help: t(helpAmountMessage),
            }}
          />
        </Col>
      </Row>
    </Card>
  );
}

export default OrderInfoCard;
