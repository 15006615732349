/** @jsxImportSource @emotion/react */
import { Button, Col, Collapse, Row, Typography, Space, Pagination } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import SkeletonMobile from '../../../SkeletonLoaders/SkeletonMobile';
import { colors } from '../../../../globalVar/theme';
import UserSearchSubListMobile from './UserSearchSubListMobile';
import useCustomHtml from '../../../../hooks/useCustomHtml';
import UserListTag from '../../../Tag/UserListTag';

const { Panel } = Collapse;
const { Text } = Typography;

/**
 * UsersListMobile
* @param {array} usersResult
 * @param {boolean} customizeEmpty
 * @param {function} updateUserSearch function to open modal with search form
 * @param {boolean} loading
 * @return {JSX.Element}
 */
function UsersListMobile({ customizeEmpty, updateUserSearch, usersResult, loading }) {
  const { t } = useTranslation();
  const [collapseActiveKey, setCollapseActiveKey] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const page = parseInt(searchParams.get('page')) || 1;
  const limit = parseInt(searchParams.get('limit')) || 10;
  const maxResultMessage = useCustomHtml({
    inputString: t('MAX_RESULTS_MSG'),
  });

  // Calculer les index de début et de fin pour la pagination
  const startIndex = (page - 1) * limit;
  const endIndex = startIndex + limit;

  // Obtenir les résultats de la pagination en utilisant la méthode slice()
  const paginatedUsersResult = usersResult.slice(startIndex, endIndex);

  return (
    <Col span={24}>
      <SkeletonMobile
        loading={loading}
        emptyState={usersResult.length === 0}
        emptyError={customizeEmpty}
        updateUserSearch={updateUserSearch}
      >
        <Collapse
          accordion
          activeKey={collapseActiveKey}
          onChange={(key)=>setCollapseActiveKey(key)}
        >
          {paginatedUsersResult?.map((user, index) =>
            <Panel
              key={index}
              header={
                <Space css={{ display: 'flex', justifyContent: 'space-between' }}>
                  {user.first_name + ' ' + user.last_name}
                  {<UserListTag user={user} />}
                </Space>
              }
            >
              <Row gutter={[0, 10]}>
                <Col span={8}>
                  <Text strong css={{ color: `${colors.primary}`, fontSize: 12 }}>
                    {t('PHONE')}
                  </Text>
                </Col>
                <Col span={16}>
                  <Text css={{ fontSize: 12 }}>{user.telephone}</Text>
                </Col>
                <Col span={8}>
                  <Text strong css={{ color: `${colors.primary}`, fontSize: 12 }}>
                    {t('EMAIL')}
                  </Text>
                </Col>
                <Col span={16}>
                  <Text css={{ fontSize: 12 }}>{user.email}</Text>
                </Col>
                <Col span={24}>
                  <Row justify='center'>
                    <Space direction='vertical'>
                      <Link to={`/users/${user.id}`}>
                        <Button type='primary' >{t('VIEW_PROFILE')}</Button>
                      </Link>
                    </Space>
                  </Row>
                </Col>
              </Row>
              <UserSearchSubListMobile userId={user.id}/>
            </Panel>,
          )}
        </Collapse>
        <Row justify='center'>
          <Pagination
            simple
            total={usersResult.length}
            current={page}
            defaultCurrent={page}
            defaultPageSize={limit}
            pageSize={limit}
            onChange={
              (pagination) => {
                const newSearchParams = new URLSearchParams(searchParams);
                if (pagination.current !== page) {
                  newSearchParams.set('page', pagination);
                }
                setSearchParams(newSearchParams.toString());
              }
            }
            style={{
              margin: '20px auto',
            }}
          />
        </Row>
      </SkeletonMobile>
      {usersResult.length === 30 &&
        <Text css={{ textAlign: 'center', padding: '0px 20px 0px 0px' }}>
          {maxResultMessage}
        </Text>
      }
    </Col>
  );
}

export default UsersListMobile;
