/**
 * @deprecated use the distanceParser in our utils packages instead
 * @param {number} distance of deliverie in meter
 * @return {object} distance in Meter or kilometer
 */
export function distanceParser(distance) {
  const dist = (distance / 1000).toFixed(1);
  if (dist > 0) {
    return { value: dist, unit: 'km' };
  } else {
    return { value: distance.toString(), unit: 'm' };
  }
}
