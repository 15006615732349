/** @jsxImportSource @emotion/react */
import { ConfigProvider, Table, Button } from 'antd';
import SkeletonTableLoader from '../../SkeletonLoaders/SkeletonTableLoader';
import { useEffect, useState } from 'react';
import ShopTableRow from '../../Deliveries/ShopTableRow';
import { toLocaleString } from '../../../utils/toLocaleString';
import { toLocaleStringHourandMinutes } from '../../../utils/toLocaleStringHourandMinutes';
import { useTranslation } from 'react-i18next';
import { customizeRenderEmpty } from '../../../services/customizeRenderEmpty';
import { readCotransportorCanceledDeliveries } from '../../../services/apiEndPoints/user/readUser';
import { Link } from 'react-router-dom';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useRouter } from '../../../hooks/useRouter';

/**
 * Represents the list of canceled deliveries for a shopper.
 *
 * @param {Object} count - The count of canceled deliveries.
 * @param {Function} setCount - The function to set the count of canceled deliveries.
 *
 * @return {Element} - Returns the element representing the list of canceled deliveries.
 */
function ShopperCanceledDeliveriesList({ count, setCount }) {
  const router = useRouter();
  const shopperId = router.query.id;

  const [deliveries, setDeliveries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const { t } = useTranslation();

  const columns = [
    {
      title: t('DELIVERY_TABLE_TRADE'),
      dataIndex: 'trade',
      render: (trade) => {
        return (
          <ShopTableRow
            logoSrc={process.env.REACT_APP_STATIC_BUCKET + trade.logo + '.webp'}
            shopName={trade.name}
            shopCity={trade.addressZipCode + ' ' + trade.addressCity}
          />
        );
      },
    },
    {
      title: t('DELIVERY'),
      dataIndex: 'delivery',
      render: (date) => {
        return date ? (
          <div>
            <div>{toLocaleString({ date: date.deliveryStart, fullDatetime: true })}</div>
            <span>
              {toLocaleStringHourandMinutes(date.deliveryStart)} - {toLocaleStringHourandMinutes(date.deliveryEnd)}
            </span>
          </div>
        ) : null;
      },
    },
    {
      title: t('CANCELATION'),
      dataIndex: 'cancelation',
      render: (date) => {
        return date ? (
          <div>
            <div>{toLocaleString({ date: date.cancelledDate, fullDatetime: true })}</div>
            <span>
              {toLocaleStringHourandMinutes(date.cancelledDate)}
            </span>
          </div>
        ) : null;
      },
    },
    {
      key: 'buttonLink',
      dataIndex: 'buttonLink',
      width: 60,
      render: ({ deliveryId }) => (
        <Link to={`/delivery/${deliveryId}`}>
          <Button type='text' shape="circle" icon={<ArrowRightOutlined />}/>
        </Link>
      ),
    },
  ];

  useEffect(() => {
    getCotransportorCanceledDeliveries();
  }, [currentPage]);

  const getCotransportorCanceledDeliveries = async() => {
    setLoading(true);
    readCotransportorCanceledDeliveries({
      userId: shopperId,
      page: currentPage,
    }).then((response) => {
      setLoading(false);
      setCount(response.data.count);
      setDeliveries(response.data.list);
    });
  };

  return (
    <SkeletonTableLoader loading={loading} columns={columns}>
      <ConfigProvider renderEmpty={() => customizeRenderEmpty({
        refreshLink: () => getCotransportorCanceledDeliveries(),
      })}>
        <Table
          columns={columns}
          dataSource={deliveries?.map((delivery, index) => {
            return {
              key: index,
              trade: {
                logo: delivery.tradeId,
                name: delivery.driveName,
                addressZipCode: delivery.addressZipCode,
                addressCity: delivery.addressCity,
              },
              delivery: { deliveryEnd: delivery.deliveryEnd, deliveryStart: delivery.deliveryStart },
              cancelation: { cancelledDate: delivery.cancelledDate },
              buttonLink: { deliveryId: delivery.deliveryId },
            };
          })}
          pagination={{
            total: count,
            showLessItems: true,
            defaultCurrent: currentPage,
            showSizeChanger: false,
            defaultPageSize: 5,
            size: 'small',
          }}
          onChange={(pagination) => {
            setCurrentPage(pagination.current);
          }}
        />
      </ConfigProvider>
    </SkeletonTableLoader>
  );
}

export default ShopperCanceledDeliveriesList;
