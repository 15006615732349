import { Timeline, Space, Typography } from 'antd';
import dayjs from 'dayjs';
import SkeletonNodes from '../../Delivery/Detail/History/SkeletonNode';
import UserRatingHistoryNode from './ModerationHistoryNodes/user-rating-history-node';
import { colors } from '../../../globalVar/theme';
import { useEffect, useState } from 'react';
import { useRouter } from '../../../hooks/useRouter';
import EmptyModerationDrawer from '../../EmptyComponents/EmptyModerationDrawer';
import { readRatesUserHistory } from '../../../services/apiEndPoints/users/moderation';
import { toLocaleString } from '../../../utils/toLocaleString';

const { Text } = Typography;

/**
 * @name UserRatingHistory
 * @return {JSX.Element}
 */
function UserRatingHistory() {
  const router = useRouter();
  const [loading, setLoading] = useState(true);
  const [userHistoryRatingData, setUserHistoryRatingData] = useState([]);

  useEffect(() => {
    readRatesUserHistory(router.query.id).then((data) => {
      setUserHistoryRatingData(data.filter((item) => item?.createdAt !== null));
      setLoading(false);
    });
  }, []);

  return loading ? <SkeletonNodes count={3} noElementsAtLeft /> : <Content userHistoryRatingData={userHistoryRatingData}/>;
}

/**
 * @name Content
 * @param {Array} userHistoryRatingData
 * @return {JSX.Element}
*/
function Content({ userHistoryRatingData }) {
  const sorted = userHistoryRatingData.sort((firstItem, secondItem) => {
    return dayjs(secondItem.createdAt) - dayjs(firstItem.createdAt);
  });
  const items = sorted.map((item) => {
    return {
      color: colors.lightBlue,
      children: (
        <>
          <Space direction='vertical' style={{ rowGap: 0, fontWeight: 700, marginBottom: 5 }}>
            <Text style={{ textTransform: 'capitalize' }}>
              {toLocaleString({ date: dayjs(item.createdAt) })}
            </Text>
            <Text style={{ fontSize: 12 }}>
              {dayjs(item.createdAt).format('LT')}
            </Text>
          </Space>
          <UserRatingHistoryNode
            user={{ firstName: item.raterFirstName, lastName: item.raterLastName, id: item.raterId }}
            errandId={item.errandId}
            comment={item.comment}
            notation={{ attitude: item.attitude, doorPickup: item.doorPickup, punctuality: item.punctuality }}
          />
        </>
      ) };
  }
  );

  return (
    userHistoryRatingData.length > 0 ? (
      <Timeline mode={'left'}
        items={items}
        style={{ marginLeft: '10px' }}
      />
    ) : <EmptyModerationDrawer />
  );
}

export default UserRatingHistory;
