/** @jsxImportSource @emotion/react */
import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch } from 'use-http';
import { Typography, Button, Row, Col, Input } from 'antd';
import { NotificationCenterContext } from '../../../../context/NotificationCenterContext';

const { Text } = Typography;
const { TextArea } = Input;

/**
 * AddComment
 * @param {number} deliveryId - The delivery id
 * @param {Function} addCommentToList - The function to add the comment to the list
 * @return {JSX.Element}
 */
function AddComment({ deliveryId, addCommentToList }) {
  const { t } = useTranslation();
  const [comment, setComment] = useState();
  const [onError, setOnError] = useState(false);
  const notification = useContext(NotificationCenterContext);
  const { post, loading, response } = useFetch(process.env.REACT_APP_API_BO, {
    cachePolicy: 'no-cache',
  });

  /**
   * updateComment - Update the comment
   * @param {Object} e - The event
   * @return {void}
   */
  const updateComment = (e) => {
    setComment(e.target.value);
  };

  /**
   * sendComment - Send the comment to the API
   * @param {string} comment - The comment to send
   * @return {Promise<void>}
   */
  const sendComment = async() => {
    const body = {
      comment: comment?.trim().replace(/(\r\n|\n|\r)/gm, '<br>'),
    };
    await post(`/deliveries/comments?deliveryId=${deliveryId}`, body);
    if (response.ok) {
      setComment('');
      setOnError(false);
      addCommentToList(response.data);
      return notification.push('success', t('COMMENT_SUCCESSFULLY_ADDED'));
    } else {
      console.error('response KO:', response);
      return setOnError(true);
    }
  };

  return (
    <div className='delivery-timeline-history-comment'>
      <span>{t('TITLE_TEXTAREA_COMMENT')}</span>
      <TextArea
        showCount
        maxLength={255}
        onChange={updateComment}
        placeholder={t('PLACEHOLDER_COMMENT')}
        status={onError ? 'error' : 'default'}
        value={comment}
        css={{
          marginTop: 5,
          height: 78,
        }}
      />
      <Row>
        {onError &&
        <Col span={24}>
          <Text type='danger'>
            {t('INVALID_VALUE')}
          </Text>
        </Col>}
        <Col span={24}>
          <Button
            id='button-comment'
            type='primary'
            loading={loading}
            disabled={!comment?.trim()}
            onClick={sendComment}
            css={{
              marginTop: 10,
            }}
          >
            {t('SEND')}
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default AddComment;
