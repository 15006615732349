import mapI18N from '../i18n/i18nAntD';

/**
 * @param {string} locals from localStorage
 * @return {object} translation for i18n
 */
export const getI18nFile = (locals) => {
  let file = mapI18N.filter((lang) => lang.keys.includes(locals[0]))[0]?.file;
  if (!file) {
    file = mapI18N.filter((lang) => lang.keys.includes(locals[2]))[0]?.file;
    if (file === undefined) {
      file = mapI18N.filter((lang) => lang.keys.includes('en'))[0]?.file;
    }
  }
  return file;
};
