import styled from '@emotion/styled';
import { DeliveryStatusEnum } from '@shopopop/react-hooks';
import { Tag } from 'antd';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

const DeliveryStatusI18nKeys: Record<DeliveryStatusEnum, string> = {
  [DeliveryStatusEnum.Available]: 'DELIVERY_STATUT_1',
  [DeliveryStatusEnum.Reserved]: 'DELIVERY_STATUT_2',
  [DeliveryStatusEnum.ToPickUpPoint]: 'DELIVERY_STATUT_3',
  [DeliveryStatusEnum.AtPickUpPoint]: 'DELIVERY_STATUT_4',
  [DeliveryStatusEnum.ToRecipient]: 'DELIVERY_STATUT_5',
  [DeliveryStatusEnum.DeliveredAndFinished]: 'DELIVERY_STATUT_6',
};

interface DeliveryStatusTagProps {
  status: DeliveryStatusEnum;
  isArchived?: boolean;
}
const getStatusColors = (status: DeliveryStatusEnum, isArchived: boolean): [string, string] => {
  if (isArchived) return ['var(--color-archived)', 'var(--bg-color-archived)'];

  switch (status) {
    case DeliveryStatusEnum.Available:
      return ['var(--color-available)', 'var(--bg-color-available)'];
    case DeliveryStatusEnum.Reserved:
      return ['var(--color-reserved)', 'var(--bg-color-reserved)'];
    case DeliveryStatusEnum.ToPickUpPoint:
      return ['var(--color-in-progress)', 'var(--bg-color-in-progress)'];
    case DeliveryStatusEnum.AtPickUpPoint:
      return ['var(--color-to-pickup-point)', 'var(--bg-color-to-pickup-point)'];
    case DeliveryStatusEnum.ToRecipient:
      return ['var(--color-to-recipient)', 'var(--bg-color-to-recipient)'];
    case DeliveryStatusEnum.DeliveredAndFinished:
      return ['var(--color-delivered-and-finished)', 'var(--bg-color-delivered-and-finished)'];
    default:
      return ['var(--color-default)', 'var(--bg-color-default)'];
  }
};

const StyledTag = styled(Tag) <{ statuscolor: string; bgcolor: string }>`
  margin-right: 0 !important;
  color: ${(props) => props.statuscolor};
  border-color: ${(props) => (props.statuscolor === '#2F3960' ? '#EFEFF2' : props.statuscolor)};
  background-color: ${(props) => props.bgcolor};
`;

function DeliveryStatusTag({ isArchived = false, status }: DeliveryStatusTagProps): ReactNode {
  const { t } = useTranslation();
  const statusText = isArchived ? t('DELETED_TAG') : t(DeliveryStatusI18nKeys[status]);
  const [color, bgcolor] = getStatusColors(status, isArchived);

  return (
    <StyledTag statuscolor={color} bgcolor={bgcolor}>
      {statusText}
    </StyledTag>
  );
}

export default DeliveryStatusTag;
