/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useFetch from 'use-http';
import { Descriptions, Tag, Card, Pagination, Collapse, Button, Row, Typography, Badge, Space, theme } from 'antd';
import { WarningFilled } from '@ant-design/icons';
import { toLocaleString } from '../../../utils/toLocaleString';
import { findStatusDeliveries } from '../../../utils/findStatusDeliveries';
import SkeletonMobile from '../../SkeletonLoaders/SkeletonMobile';
import { useRouter } from '../../../hooks/useRouter';
import { toLocaleStringHourandMinutes } from '../../../utils/toLocaleStringHourandMinutes';
import { colors } from '../../../globalVar/theme';
import { Link } from 'react-router-dom';

const { Panel } = Collapse;
const { Item } = Descriptions;
const { Text } = Typography;

/**
 * UserDeliveriesListMobile
 * @return {string}
 */
function UserDeliveriesListMobile({ statusError }) {
  const { token } = theme.useToken();
  const { t } = useTranslation();
  const router = useRouter();
  const deliveriesListUsersFetch = useFetch(process.env.REACT_APP_API_BO);
  const [deliveries, setDeliveries] = useState([]);
  const [deliveriesCount, setDeliveriesCount] = useState('');
  const [customizeEmpty, setCustomizeEmpty] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (statusError === 200) {
      getDeliveriesRecipient();
    }
  }, [currentPage, statusError]);

  const getDeliveriesRecipient = async() => {
    const recipientId = router.query.id;
    await deliveriesListUsersFetch.get(`users/${recipientId}/deliveries?is=recipient&page=${currentPage}&sort=delivery_start&order=DESC`);
    if (deliveriesListUsersFetch.response.status === 200) {
      setDeliveriesCount(deliveriesListUsersFetch.response.data.count);
      setDeliveries(deliveriesListUsersFetch.response.data.list);
      setCustomizeEmpty(false);
    } else setCustomizeEmpty(true);
  };

  return (
    <>
      <Card
        title={(
          <Space css={{ width: '100%', justifyContent: 'space-between' }}>
            {t('DELIVERIES')}
            <Badge
              count={deliveriesCount}
              style={{ backgroundColor: colors.primary }}
            />
          </Space>
        )}
        bordered={false}
      >
        <SkeletonMobile
          loading={deliveriesListUsersFetch.loading}
          emptyState={(deliveriesCount || deliveries.length) === 0}
          emptyError={customizeEmpty}
          refreshLink={() => getDeliveriesRecipient()}
        >
          <Collapse accordion>
            {deliveries?.map((delivery, index) =>
              <Panel
                key={index}
                header={toLocaleString({ date: delivery?.delivery_end, fullDatetime: true })}
                extra={
                  <Space>
                    {delivery?.incident && <WarningFilled style={{ color: token.colorError, fontSize: '15px' }} />}
                    <Tag style={findStatusDeliveries(delivery?.status).style} >{t(findStatusDeliveries(delivery?.status).message)}</Tag>
                  </Space>
                }
                className={(delivery.validated === 0 && deliveries.status !== 7) ? 'validated' : ''}
                css={{
                  '&.validated': {
                    '.ant-collapse-header': {
                      borderLeft: `3px solid ${token.colorWarningBorder}`,
                    },
                  },
                }}
              >
                <Descriptions
                  size='small'
                  bordered
                  css={{ marginBottom: 20 }}
                  labelStyle={{ fontWeight: 600, color: '#1B3F92' }}
                >
                  <Item label= {t('DELIVERY_TABLE_TRADE')} >
                    {(delivery?.trade.name)}
                    <br />
                    <Text type='secondary'>
                      {delivery?.trade.address.zip_code + ' ' + delivery?.trade.address.city}
                    </Text>
                  </Item>
                  <Item label= {t('DELIVERY_TABLE_DATE_AND_HOUR')} >
                    {
                      toLocaleString({
                        date: delivery?.delivery_end,
                        fullDatetime: true,
                      })
                    } / {
                      toLocaleStringHourandMinutes(delivery?.delivery_start)
                    } - {
                      toLocaleStringHourandMinutes(delivery?.delivery_end)
                    }
                  </Item>
                  <Item label={t('DELIVERY_TABLE_SHOPPER')}>
                    {delivery?.shopper &&
                    `${delivery?.shopper?.first_name} ${delivery?.shopper?.last_name}`
                    }
                  </Item>
                  <Item label = {t('TIP')} >
                    {delivery.tips}€
                  </Item>
                </Descriptions>
                <Row justify='center'>
                  <Link to={`/delivery/${delivery.id}`}>
                    <Button type='primary' id='users-recipient-button-details-deliveries'>{t('DELIVERY_DETAILS')}</Button>
                  </Link>
                </Row>
              </Panel>,
            )}
          </Collapse>
        </SkeletonMobile>
        {deliveriesCount !== 0 ?
          <Pagination
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '20px',
            }}
            simple
            pageSize={5}
            defaultCurrent={1}
            current={currentPage}
            total={deliveriesCount}
            onChange={(page) => {
              setCurrentPage(page);
            }}
          /> :
          null}
      </Card>
    </>
  );
}

export default UserDeliveriesListMobile;

