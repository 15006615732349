/** @jsxImportSource @emotion/react */
import { Typography, Descriptions, Tag, Flex } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { colors } from '../../../../globalVar/theme';
import { toLocaleString } from '../../../../utils/toLocaleString';
import { toLocaleStringHourandMinutes } from '../../../../utils/toLocaleStringHourandMinutes';
import { findStatusDeliveries } from '../../../../utils/findStatusDeliveries';
import { useFetch } from 'use-http';
import StatusErrorWrapper from '../../../Wrapper/StatusErrorWrapper';
import DeliveryIncidentIconListItem from '../../../DeliveryList/DeliveryListItems/DeliveryIncidentIconListItem';

const { Text } = Typography;
const { Item } = Descriptions;
/**
 * UsersSearchSubListMobile
 * @param {number} userId
 * @return {JSX.Element}
 */
function UserSearchSubListMobile({ userId }) {
  const { t } = useTranslation();
  const [deliveriesSubList, setDeliveriesSublist] = useState([]);
  const [statusError, setStatusError] = useState(200);
  const usersDeliveriesListFetch = useFetch(process.env.REACT_APP_API_BO, {
    cachePolicy: 'no-cache',
  });

  useEffect(()=>{
    getDeliveriesListUser();
  }, []);

  const getDeliveriesListUser = async() => {
    const result = await usersDeliveriesListFetch.get(`users/${userId}/deliveries?is=either&limit=2&sort=delivery_start&order=DESC`);
    if (usersDeliveriesListFetch.response.status === 200) {
      setDeliveriesSublist(result.list);
    } else {
      setStatusError(usersDeliveriesListFetch.response.status);
    }
  };

  return (
    <>
      <StatusErrorWrapper statusError={statusError}>
        {deliveriesSubList?.map((delivery) =>
          <>
            <Descriptions
              className={delivery.validated === 0 ? 'validated' : ''}
              size='small'
              bordered
              css={{
                'marginBottom': 20,
                'marginTop': 20,
                '&.validated': {
                  '.ant-descriptions-view': {
                    borderLeft: '3px solid #FAAD14',
                  },
                },
              }}
              labelStyle={{
                fontSize: '12px',
                color: `${colors.primary}`,
                padding: '8px',
              }}
              contentStyle={{ fontSize: '12px' }}
            >
              <Item label={t('DELIVERY_TABLE_TRADE')}>
                {(delivery?.trade.name)}
                <br />
                <span css={{ color: `${colors.blueMiddle}` }}>{delivery?.trade.address.zip_code + ' ' + delivery?.trade.address.city}</span>
              </Item>
              <Item label={t('DELIVERY_DATE')}>
                {toLocaleString({ date: delivery?.delivery_end, fullDatetime: true })}
              </Item>
              <Item label={t('DELIVERY_SLOTS_HOUR')}>
                {toLocaleStringHourandMinutes(delivery?.delivery_start)} - {toLocaleStringHourandMinutes(delivery?.delivery_end)}
              </Item>
              <Item label={t('DELIVERY_TABLE_STATE')}>
                <Flex gap="medium">
                  <Tag style={findStatusDeliveries(delivery?.status).style}>
                    {t(findStatusDeliveries(delivery?.status).message)}
                  </Tag>
                  <DeliveryIncidentIconListItem incident={delivery?.incident} />
                </Flex>
              </Item>
            </Descriptions>
            <Link to={`../delivery/${delivery.id}`} css={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Text underline css={{ color: `${colors.primary}` }}>{t('VIEW_DELIVERY')}</Text>
            </Link>
          </>,
        )}
      </StatusErrorWrapper>
    </>
  );
}

export default UserSearchSubListMobile;
