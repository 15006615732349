import { apiEndPointsOptions } from '../apiEndPointsOptions';

/**
 * Update delivery detail
 * @param {number} addressId
 * @param {Object} data
 * @return {Promise<any>}
 */
export async function deleteAddress({ addressId, data }) {
  const apiUrl = `${process.env.REACT_APP_API_BO}/addresses/${addressId}`;
  const method = 'DELETE';
  const body = data;

  try {
    const response = await fetch(
      apiUrl,
      await apiEndPointsOptions(method, body),
    );

    if (response.ok) {
      return {
        type: 'success',
        message: 'ARCHIVE_ADRESS',
      };
    }
    return {
      type: 'error',
      message: `ARCHIVE_ADDRESS_ERROR`,
    };
  } catch (error) {
    throw new Error(`Error in updateAddress service: ${error.message}`);
  }
}
