/** @jsxImportSource @emotion/react */
import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { theme, Row, Col, Input, Form, Button, Typography } from 'antd';
import { CheckOutlined, EditFilled } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { NotificationCenterContext } from '../../../context/NotificationCenterContext';
import BreadcrumbContext from '../../../context/BreadcrumbContext';
import { colors } from '../../../globalVar/theme';
import { updateUserName } from '../../../services/apiEndPoints/user/updateUser';

const { Text } = Typography;

/**
 * InputNameUser
 * @param {object} user
 * @param {function} setUser
 * @param {[object]} kycListDatabase
 * @return {string}
 */
function InputNameUser({ user, setUser, kycListDatabase }) {
  const { token } = theme.useToken();
  const { id } = useParams();
  const { t } = useTranslation();
  const [formIdentity] = Form.useForm();
  const [activeInput, setActiveInput] = useState(true);
  const [documentModificationAuthorized, setDocumentModificationAuthorized] = useState(false);
  const notification = useContext(NotificationCenterContext);
  const contextBreadcrumb = useContext(BreadcrumbContext);
  const [loading, setLoading] = useState(false);
  const watchFirstNameValue = Form.useWatch('firstName', formIdentity);
  const watchLastNameValue = Form.useWatch('lastName', formIdentity);
  const [submitDisabled, setSubmitDisabled] = useState(true);

  useEffect(() => {
    const idCardShopopop = kycListDatabase?.find((item) => item.type === 'id_card_shopopop')?.status;
    if (idCardShopopop) {
      setDocumentModificationAuthorized(
        ['NOT_SENT', 'REFUSED'].includes(idCardShopopop)
      );
    }
  }, [kycListDatabase]);

  useEffect(() => {
    setSubmitDisabled(
      watchFirstNameValue === user?.first_name &&
      watchLastNameValue === user?.last_name
    );
  }, [user, watchFirstNameValue, watchLastNameValue]);

  const changeUserName = async() => {
    setLoading(true);
    updateUserName({
      id,
      firstName: formIdentity.getFieldValue('firstName'),
      lastName: formIdentity.getFieldValue('lastName'),
    }).then((response) => {
      setLoading(false);
      if (response.data) {
        setUser({ ...user, ...response.data });
        contextBreadcrumb.setInfoBreadcrumb(response.data);
      }
      setDocumentModificationAuthorized(!documentModificationAuthorized);
      setActiveInput(!activeInput);
      notification.push(response.type, t(response.message));
    });
  };

  const modificationNameView = () => {
    setActiveInput(!activeInput);
    setDocumentModificationAuthorized(!documentModificationAuthorized);
  };

  return (
    <div>
      {activeInput === true ? (
        <Text
          css={{
            textTransform: 'capitalize',
            fontFamily: token.fontFamily,
          }}
        >
          {user?.first_name} {user?.last_name}
        </Text>
      ) : (
        <Form
          onFinish={changeUserName}
          form={formIdentity}
        >
          <Row gutter={[10, 10]}>
            <Col span={10}>
              <Form.Item
                name='firstName'
                initialValue={user?.first_name}
                rules={[{
                  required: true,
                  message: t('FIRST_NAME_REQUIRED'),
                }, {
                  pattern: /^[^\d]*$/g,
                  message: t('NUMBER_NOT_ALLOWED'),
                }, {
                  pattern: /^(?=.{1,100}$).*/g,
                  message: t('FIRST_NAME_LIMITE_LENGTH'),
                }]}
              ><Input id='user-general-information-first-name' autoComplete="off" />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                name='lastName'
                initialValue={user?.last_name}
                rules={[{
                  required: true,
                  message: t('NAME_REQUIRED'),
                }, {
                  pattern: /^[^\d]*$/g,
                  message: t('NUMBER_NOT_ALLOWED'),
                }, {
                  pattern: /^(?=.{1,100}$).*/g,
                  message: t('LAST_NAME_LIMITE_LENGTH'),
                }]}
              ><Input id='user-general-information-last-name' autoComplete="off" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Button
                id='user-general-information-button-modify-user'
                htmlType="submit"
                disabled={submitDisabled}
                loading={loading}
                css={{ borderRadius: token.borderRadiusSM }}
                icon={<CheckOutlined />}
              />
            </Col>
          </Row>
        </Form>
      )}
      {documentModificationAuthorized === true ? (
        <EditFilled
          onClick={modificationNameView}
          style={{
            color: colors.primary,
            paddingLeft: '5px',
          }}
        />
      ) : null}
    </div>
  );
}

export default InputNameUser;

