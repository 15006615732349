import { ArrowRightOutlined, CalendarOutlined, ClockCircleTwoTone } from '@ant-design/icons';
import styled from '@emotion/styled';
import { getFormattedDateOrOtherDate } from '@shopopop/backoffice-frontend-utils';
import { useDeliveryDateTime, useScreenSize } from '@shopopop/react-hooks';
import { Alert, Card, DatePicker, Flex, Form, FormInstance, Input, Radio, Space, TimePicker, Typography, theme } from 'antd';
import dayjs from 'dayjs';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { boDateFromString, getDateReferences } from '../../../../utils/src/date/date';
import DateSelector from '../DateSelector/DateSelector';

const { Text } = Typography;

function DeliveryDateTime({ form }: Readonly<{ form: FormInstance }>): ReactNode {
  const { t } = useTranslation();
  const {
    token: { screenMD, screenLG, colorPrimary },
  } = theme.useToken();
  const { width, mobileMode } = useScreenSize();
  const [selectedDate, setSelectedDate] = useState(dayjs().startOf('day').toISOString());

  const { startDate, endDate, validateTimeRange, handleStartTimeChange, handleEndTimeChange, handleDateChange } = useDeliveryDateTime(form);

  const handleDatePickerChange = (date: dayjs.Dayjs | null) => {
    if (date) {
      const value = date.startOf('day').toISOString();
      setSelectedDate(value);
      form.setFieldsValue({ deliveryDate: value });
      handleDateChange(value);

      // Mettre à jour la valeur du groupe de boutons radio
      const radioValue = dateOptions.find((option) => option.value === value)?.value ?? value;
      form.setFieldsValue({ deliveryDate: radioValue });
    } else {
      form.setFieldsValue({ deliveryDate: null });
    }
  };

  useEffect(() => {
    const initialDate = dayjs().startOf('day').toISOString();
    setSelectedDate(initialDate);
    form.setFieldsValue({
      deliveryDate: initialDate,
      startDate: startDate,
      endDate: endDate,
    });
    handleDateChange(initialDate);
  }, []);

  const dateOptions = Object.entries(getDateReferences(['TODAY', 'TOMORROW', 'AFTER_TOMORROW'])).map(([key, value]) => {
    return {
      value: value,
      labelKey: key,
      date: boDateFromString(value),
    };
  });

  const renderDatePicker = () => (
    <DatePicker
      value={dayjs(form.getFieldValue('deliveryDate'))}
      onChange={(value) => handleDatePickerChange(value)}
      format={'dddd D MMMM'}
      disabledDate={(current) => current?.isBefore(dayjs().startOf('day'))}
      allowClear={false}
      style={mobileMode ? { width: '100%' } : {}}
      inputReadOnly
      suffixIcon={<CalendarOutlined style={{ color: colorPrimary }} />}
    />
  );

  const getDateText = (date: dayjs.Dayjs, width: number, screenLG: number): string => {
    const afterTomorrow = dayjs().add(2, 'day').endOf('day');
    if (date.isAfter(afterTomorrow)) {
      return date.format(width > screenLG ? 'dddd D MMMM' : 'DD/MM/YY');
    } else {
      return 'OTHER_DATE';
    }
  };

  const extraOption = width > screenMD && (
    <StyledRadioButton value={getFormattedDateOrOtherDate(form.getFieldValue('deliveryDate'))} colorPrimary={colorPrimary}>
      <Flex vertical align={'center'}>
        <Text className="hide-mobile">
          <CalendarOutlined />
        </Text>
        <Text>{t(getDateText(startDate, width, screenLG))}</Text>
      </Flex>
      <StyledDatePickerRadioButton>{renderDatePicker()}</StyledDatePickerRadioButton>
    </StyledRadioButton>
  );

  const disableTimePickerArray = (start: number, end: number): number[] => {
    const result: number[] = [];
    for (let i = start; i <= end; i++) {
      result.push(i);
    }
    return result;
  };

  return (
    <Card title={t('DATE_TIMESLOT_TITLE')}>
      <Form.Item name="startDate" style={{ display: 'none', visibility: 'hidden' }}>
        <Input />
      </Form.Item>
      <Form.Item name="endDate" style={{ display: 'none', visibility: 'hidden' }}>
        <Input />
      </Form.Item>

      <Flex vertical gap={24}>
        <Space direction={'vertical'}>
          <Form.Item label={t('DELIVERY_DATE')} name="deliveryDate" rules={[{ required: true, message: t('DELIVERY_DATE_REQUIRED_ERROR') }]} style={{ marginBottom: 0 }}>
            <DateSelector dateOptions={dateOptions} extraOption={extraOption} handleDateChange={handleDateChange} initialValue={selectedDate} />
          </Form.Item>
          {mobileMode && renderDatePicker()}
        </Space>

        <Form.Item
          label={t('DELIVERY_TIME_SLOT')}
          name="timeRange"
          rules={[{ required: true, validator: validateTimeRange }]}
          validateTrigger={['onBlur', 'onChange']}
          style={{ width: width > screenMD ? 'auto' : '100%' }}
        >
          <Flex align={'center'} gap={width > screenMD ? 24 : 12} vertical={width <= screenMD}>
            <TimePicker
              format="HH:mm"
              value={startDate}
              minuteStep={15}
              disabledTime={() => ({
                disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 21, 22, 23],
                disabledMinutes: (selectedHour) => (selectedHour === 20 ? disableTimePickerArray(1, 59) : []),
              })}
              hideDisabledOptions
              needConfirm={false}
              use12Hours={false}
              onChange={handleStartTimeChange}
              allowClear={false}
              style={{
                width: width > screenMD ? 'auto' : '100%',
                minWidth: 205,
              }}
              suffixIcon={<ClockCircleTwoTone twoToneColor={colorPrimary} />}
            />
            {width > screenMD && <ArrowRightOutlined style={{ color: colorPrimary }} />}
            <TimePicker
              format="HH:mm"
              value={endDate}
              minuteStep={15}
              disabledTime={() => ({
                disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 8, 22, 23],
                disabledMinutes: (selectedHour) => (selectedHour === 21 ? disableTimePickerArray(1, 59) : []),
              })}
              hideDisabledOptions
              needConfirm={false}
              use12Hours={false}
              onChange={handleEndTimeChange}
              allowClear={false}
              style={{
                width: width > screenMD ? 'auto' : '100%',
                minWidth: 205,
              }}
              suffixIcon={<ClockCircleTwoTone twoToneColor={colorPrimary} />}
            />
          </Flex>
        </Form.Item>
      </Flex>

      <Alert message={t('DELIVERY_TIMESLOT_INSTRUCTION')} type="info" showIcon />
    </Card>
  );
}

const StyledRadioButton = styled(Radio.Button)<{ colorPrimary: string }>`
    height: auto;
    padding: 5px 16px;
    text-align: center;
    outline: 0 !important;

    @media (max-width: 992px) {
        width: calc(100% / 4);

        .ant-typography {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .hide-mobile {
            display: none;
        }
    }

    @media (max-width: 576px) {
        width: calc(100% / 3);
        .ant-picker {
            height: auto !important;
        }
    }

    &.ant-radio-button-wrapper-checked .ant-typography {
      color: ${({ colorPrimary }) => colorPrimary};
    }

    .anticon {
        color: ${({ colorPrimary }) => colorPrimary};
    }
`;

const StyledDatePickerRadioButton = styled.div`
    margin-bottom: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer !important;

    .anticon {
        display: none;
    }

    .ant-picker {
        width: 100%;
        height: 56px;
        border-radius: 0;
        border: 0;
        padding: 0;

        .ant-picker-input {
            height: 100%;
            border-radius: 0;

            input {
                height: 100%;
                border-radius: 0;
                cursor: pointer;
            }

            .ant-picker-suffix {
                display: none;
            }
        }
    }
`;

export default DeliveryDateTime;
