/** @jsxImportSource @emotion/react */
import React, { useState, useContext } from 'react';
import { Card, Modal, Typography, Space, Divider, Button } from 'antd';
import { colors } from '../../../../../globalVar/theme';
import useFetch from 'use-http';
import { useTranslation } from 'react-i18next';
import { CloseOutlined } from '@ant-design/icons';
import { NotificationCenterContext } from '../../../../../context/NotificationCenterContext';
import useCustomHtml from '../../../../../hooks/useCustomHtml';


const { Text } = Typography;
/**
 * CommentHistoryNode
 * @param {Object} user
 * @param {string} user.firstName
 * @param {string} user.lastName
 * @param {string} comment
 * @return {JSX.Element}
 */
function CommentHistoryNode({ user, comment, commentId, deleteCommentFromList, userLoggedInId, createCommmentUser }) {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const notification = useContext(NotificationCenterContext);
  const editUserRight = userLoggedInId === createCommmentUser;

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const commentFetch = useFetch(process.env.REACT_APP_API_BO, {
    cachePolicy: 'no-cache',
  });

  /**
 * handleOk - Delete the comment - deleteCommentFromList - close the modal
 * @return {void}
 */
  const handleOk = async() => {
    await commentFetch.delete(`/deliveries/${commentId}/comments`);
    if (commentFetch.response.ok) {
      notification.push('success', t('COMMENT_DELETED'));
      deleteCommentFromList(commentId);
    } else {
      notification.push('error', t('INTERNAL_ERROR'));
    }
    setIsModalOpen(false);
  };
  const title = (
    <div css={{ display: 'flex' }}>
      <span>{`${user.firstName} ${user.lastName}`}</span>
      {editUserRight && <CloseOutlined onClick={showModal} css={{ marginLeft: 'auto' }}/>}
    </div>
  );
  return (
    <>
      <Card
        title={title}
        headStyle={{
          border: `1px solid ${colors.primary}`,
          borderBottom: 0,
          padding: '15px 15px 5px',
          backgroundColor: editUserRight ? `${colors.lightBlue}` : '',
        }}
        bodyStyle={{
          padding: '0 15px 15px',
          border: `1px solid ${colors.primary}`,
          borderTop: 0,
          backgroundColor: editUserRight ? `${colors.lightBlue}` : '',
          // rajouter une ternaire lorsque nous aurons l'id de l'utilisateur connecté
        }}
      >
        {useCustomHtml({ inputString: comment })}
      </Card>
      <Modal
        title={t('DELETE_COMMENT')}
        open={isModalOpen}
        footer={[
          <Button key="back" onClick={handleCancel}>
            {t('CANCEL')}
          </Button>,
          <Button key="submit" type="primary" loading={commentFetch.loading} onClick={handleOk}>
            {t('DELETED')}
          </Button>,
        ]}
      >
        <Divider />
        <Space direction='vertical' css={{ padding: 0 }}>
          <Text type='secondary'>{t('DELETE_COMMENT_TEXT')}</Text>
        </Space>
      </Modal>
    </>
  );
}

export default CommentHistoryNode;
