import dayjs from 'dayjs';
/**
 * createHistoryObjectOldBo
 * @param {Object} userDeactivationReasons
 * @return {Object}
 */
export function createHistoryObjectOldBo(userDeactivationReasons) {
  let historyObjectOldBo = {};
  if (userDeactivationReasons?.drive_id === null && userDeactivationReasons?.drive_name === null && userDeactivationReasons?.banned_date !== null) {
    historyObjectOldBo = {
      coTransporterId: userDeactivationReasons?.user_id,
      createdAt: dayjs(userDeactivationReasons?.banned_date.slice(0, -1)).toString('YYYY-MM-DD HH:mm:ss'),
      reason: userDeactivationReasons?.banned_reason,
      driveName: null,
      description: userDeactivationReasons?.banned_comment,
      deactivationType: 'TOTAL_DEACTIVATION',
      fromOldBo: true,
    };
    return historyObjectOldBo;
  } else if (userDeactivationReasons?.drive_id !== null && userDeactivationReasons?.drive_name !== null && userDeactivationReasons?.banned_date !== null ) {
    historyObjectOldBo = {
      coTransporterId: userDeactivationReasons?.user_id,
      createdAt: dayjs(userDeactivationReasons?.banned_date.slice(0, -1)).toString('YYYY-MM-DD HH:mm:ss'),
      reason: userDeactivationReasons?.banned_reason,
      driveName: userDeactivationReasons?.drive_name,
      description: userDeactivationReasons?.banned_comment,
      deactivationType: 'DRIVE_DEACTIVATION',
      driveId: userDeactivationReasons?.drive_id,
      fromOldBo: true,
    };
    if (userDeactivationReasons === null) {
      return null;
    }
    return historyObjectOldBo;
  }
}
