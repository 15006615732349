import { initialCaptcha } from './captchaStore';

/**
 * @param {Object} state
 * @param {Object} action
 * @return {Object}
 */
export default function captchaReducer(state, action = { type: '', payload: {}}) {
  switch (action.type) {
  case 'INIT_CAPTCHA':
    return {
      ...state,
      captcha: {
        ...initialCaptcha,
        id: action.payload,
      },
    };
  case 'SET_CAPTCHA':
    localStorage.setItem('captcha', action.payload);
    return { ...state, captcha: { ...state.captcha, value: action.payload }};
  case 'REMOVE_CAPTCHA':
    localStorage.removeItem('captcha');
    window.turnstile?.remove(state.id);
    return { ...state, captcha: initialCaptcha };
  case 'RELOAD_CAPTCHA':
    window.turnstile?.reset(state.id);
    return state;
  case 'CAPTCHA_ERROR':
    return { ...state, captcha: { ...state.captcha, error: action.payload }};
  default:
    return state;
  }
}
