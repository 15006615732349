/** @jsxImportSource @emotion/react */
import { Collapse, Descriptions, Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import SkeletonMobile from '../../../SkeletonLoaders/SkeletonMobile';
import DeliveryStatusListItem from '../../DeliveryListItems/DeliveryStatusListItem';
import DeliveryTradeItemColumn from '../../DeliveryListItems/DeliveryTradeItemColumn';
import DeliveryTimeSlotRow from '../../DeliveryListItems/DeliveryTimeSlotRow';
import { useState } from 'react';
import DeliveryIncidentIconListItem from '../../DeliveryListItems/DeliveryIncidentIconListItem';

const { Panel } = Collapse;
const { Item } = Descriptions;

/**
 * Returns a JSX element representing the mobile inactive list.
 *
 * @return {JSX.Element} - The mobile inactive list JSX element.
 */
function MobileInactiveList({ deliveries, loading, tableParams }) {
  const { t } = useTranslation();
  const [activeKey, setActiveKey] = useState(0);

  return (
    <SkeletonMobile
      loading={loading}
      emptyState={tableParams.pagination.total === 0}
    >
      <Collapse accordion activeKey={activeKey} onChange={(key) => setActiveKey(Number(key))}>
        {deliveries?.map((delivery, index) => {
          return (
            <Panel
              key={index}
              header={`${delivery?.client?.first_name} ${delivery?.client?.last_name}`}
              extra={(
                <Flex gap="small">
                  <DeliveryIncidentIconListItem incident={delivery?.incident} />
                  <DeliveryStatusListItem status={delivery.status} />
                </Flex>
              )}
              className={(!delivery.validated && deliveries.status !== 7) ? 'validated' : ''}
              css={{
                '&.validated': {
                  '.ant-collapse-header': {
                    borderLeft: '3px solid #FAAD14',
                  },
                },
              }}
            >
              <Descriptions
                size='small'
                bordered
                css={{ marginBottom: 20 }}
                labelStyle={{ fontWeight: 600, color: '#1B3F92', padding: 8, fontSize: 12 }}
              >
                <Item label={t('DELIVERY_TABLE_TRADE')}>
                  <DeliveryTradeItemColumn trade={delivery.trade} />
                </Item>
                <Item label={t('DELIVERY_TABLE_DATE_AND_HOUR')}>
                  <DeliveryTimeSlotRow timeSlot={delivery.time_slot} />
                </Item>
                {
                  (delivery?.shopper?.first_name && delivery?.shopper?.last_name) &&
                  <Item label={t('DELIVERY_TABLE_SHOPPER')}>
                    {`${delivery?.shopper?.first_name} ${delivery?.shopper?.last_name}`}
                  </Item>
                }
              </Descriptions>
            </Panel>
          );
        })}
      </Collapse>
    </SkeletonMobile>
  );
}

export default MobileInactiveList;
