/**
 * @name getDeliveryIncidentWarnMessage
 * @description See (and maintain) getDeliveryIncidentWarnMessage.cy.js test file
 * @param {string} category
 * @param {boolean} isDeliveryClosed
 * @return {string}
 */
export function getDeliveryIncidentWarnMessage({ category, isDeliveryClosed }) {
  // CAT1: PICKUP_RETURN
  if (category === 'PICKUP_RETURN') {
    return 'TIP_INCREASE_AND_DELIVERY_COMPLETED';
  }
  // CAT2: ADDITIONAL_JOURNEY
  if (category === 'ADDITIONAL_JOURNEY') {
    return 'TIP_REEVALUATED_ACCORDING_TO_EXTRA_KILOMETERS';
  }
  // CAT3: PICKUP_PROBLEM
  if (category === 'PICKUP_PROBLEM') {
    if (isDeliveryClosed) return 'TIP_DECREASE_AND_DELIVERY_COMPLETED';
    return 'TIP_NOT_MODIFIED';
  }
  // CAT4: COTRANSPORTER_PROBLEM
  if (category === 'COTRANSPORTER_PROBLEM') {
    if (isDeliveryClosed) return 'NO_COMPENSATION_AND_DELIVERY_COMPLETED';
    return 'TIP_NOT_MODIFIED';
  }
}
