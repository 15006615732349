/** @jsxImportSource @emotion/react */
import { React, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AutoComplete, Input, Row, Col, Card, Space, Typography } from 'antd';
import Profile from '../../../Profile/Profile';
import { useFetch } from 'use-http';
import HighlightText from '../../../HighlightText/HighlightText';
import useDebounce from '../../../../hooks/useDebounce';
import AddShopperModal from '../../../Modal/AddShopperModal';
import ModalContext from '../../../../context/ModalContext';


const { Text } = Typography;

/**
 * UpdateShopper Form
 * @param {object} delivery
 * @param {function} setDelivery function to update delivery
 * @param {object} searchUser
 * @param {function} setSearchUser function to update shopper search
 * @return {String}
 */
function UpdateShopper({ delivery, setDelivery, searchUser, setSearchUser }) {
  const { t } = useTranslation();
  const shopperFetch = useFetch(process.env.REACT_APP_API_BO);
  const debouncedSearchTerm = useDebounce(searchUser, 500);
  const [options, setOptions] = useState([]);
  const [selectedShopper, setSelectedShopper] = useState();
  const setOpenModalShopper = useContext(ModalContext)['setOpenModalShopper'];

  const searchResult = ({ users, search }) => {
    return users.map((user) => {
      return {
        value: JSON.stringify(user),
        label: (
          <div>
            <HighlightText
              text={`${user.first_name} ${user.last_name} - ${user.telephone} - ${user.email}`}
              highlightTag={search}
            />
          </div>
        ),
      };
    });
  };

  const getShopper = async() => {
    await shopperFetch.get(`/users/shopper/eligibleForDelivery?q=${debouncedSearchTerm}`);
    if (shopperFetch.response.ok) {
      setOptions(shopperFetch.response.data.length > 0 ? searchResult({ users: shopperFetch.response.data, search: debouncedSearchTerm }) : []);
    }
    if (shopperFetch.response.data.errors) {
      console.error(shopperFetch.response.data.errors);
    }
  };

  useEffect(() => {
    if (delivery.status === 1 && delivery.shopper !== null) {
      setDelivery({
        ...delivery,
        shopper: null,
      });
    }
  }, [delivery]);

  const onSearchShopper = (value) => {
    if (value.length === 0) {
      setOptions([]);
    }
  };

  useEffect(()=> {
    if (debouncedSearchTerm && debouncedSearchTerm?.length >= 3) {
      getShopper();
    }
  }, [debouncedSearchTerm]);

  const onChangeShopper = (data) => {
    setSearchUser(data);
  };

  const onSelectShopper = (value) => {
    const user = JSON.parse(value);
    setSelectedShopper(user);
    setSearchUser(`${user.first_name} ${user.last_name} - ${user.telephone} - ${user.email}`);
    setOpenModalShopper(true);
  };

  return (
    <Card
      title={t('SHOPPER')}
      extra={
        delivery?.shopper &&
          <Link
            to={`/users/${delivery.shopper.id}?tab=deliverer`}
            target='_blank'
          >
            <Profile />
          </Link>
      }>
      {(!delivery?.shopper) &&
        <AutoComplete
          value={searchUser}
          options={options}
          onSearch={onSearchShopper}
          onSelect={onSelectShopper}
          onChange={onChangeShopper}
          notFoundContent={shopperFetch.response.ok && t('NO_RESULT')}
          id='search-shopper-delivery-detail-delivery'
          style={{ width: '100%' }}
          placement="topLeft"
        >
          <Input.Search
            placeholder={t('SEARCH_SHOPPER_NAME')}
            loading={shopperFetch.loading}
          />
        </AutoComplete>
      }

      {(delivery?.shopper) &&
        <Row gutter={[0, 16]}>
          <Col xs={24} sm={8}>
            <Space.Compact direction='vertical'>
              <Text type='secondary'>{t('FIRST_NAME')}</Text>
              <Text>{delivery.shopper.first_name}</Text>
            </Space.Compact>
          </Col>
          <Col xs={24} sm={12}>
            <Space.Compact direction='vertical'>
              <Text type='secondary'>{t('NAME')}</Text>
              <Text>{delivery.shopper.last_name}</Text>
            </Space.Compact>
          </Col>
          <Col xs={24} sm={8}>
            <Space.Compact direction='vertical'>
              <Text type='secondary'>{t('PHONE_NUMBER')}</Text>
              <Text>{delivery.shopper.phone || delivery.shopper.telephone}</Text>
            </Space.Compact>
          </Col>
          <Col xs={24} sm={12}>
            <Space.Compact direction='vertical'>
              <Text type='secondary'>{t('EMAIL')}</Text>
              <Text>{delivery.shopper.email}</Text>
            </Space.Compact>
          </Col>
        </Row>
      }
      <AddShopperModal
        setSearchUser={setSearchUser}
        delivery={delivery}
        setDelivery={setDelivery}
        selectedShopper={selectedShopper}
      />
    </Card>
  );
}

export default UpdateShopper;
