/**
 * findKycStatus
 * @param {string} state
 * @return {object}
 */
export function findKycStatus(state) {
  const colors = {
    DEFAULT: '#8C8C8C',
    NOT_SENT: '#8C8C8C',
    VALIDATED: '#52C41A',
    PENDING: '#FAAD14',
    UNVALIDATED: '#FAAD14',
    REFUSED: '#DF4C4C',
  };

  switch (state) {
  case 'NOT_SENT':
    return { color: colors[state] };
  case 'VALIDATED':
    return { color: colors[state] };
  case 'PENDING':
    return { color: colors[state] };
  case 'UNVALIDATED':
    return { color: colors[state] };
  case 'REFUSED':
    return { color: colors[state] };
  default:
    return { color: colors['DEFAULT'] };
  }
}

