import React, { useContext, useState, useEffect } from 'react';
import { Breadcrumb, Layout } from 'antd';
import { useRouter } from '../../hooks/useRouter';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, matchRoutes } from 'react-router-dom';
import BreadcrumbContext from '../../context/BreadcrumbContext';
import TouchableView from '../DeviceDetect/TouchableView';
import UntouchableView from '../DeviceDetect/UntouchableView';
import routes from '../../routes/routes';
import ZigwigwiSeparator from '../Separators/ZigwigwiSeparator';
import { AppContext } from '../../context/AppContext';

/**
 * Breadcrumb
 * @return {JSX.Element}
 */
function BreadcrumbWrapper() {
  const [store] = useContext(AppContext);
  const { t } = useTranslation();
  const router = useRouter();
  const location = useLocation();
  // We need to do this to get the current path of route because v6 of react-router-dom doesn't have this feature
  // When you give location and routes to matchRoutes, it returns the route that matches the location whith lots of information
  const [{ route }] = matchRoutes(routes, location);
  const { infoBreadcrumb } = useContext(BreadcrumbContext);
  const [correctUrl, setCorrectUrl] = useState(null);
  const [arianeItem, setArianeItem] = useState({
    firstItem: null,
    secondItem: null,
    urlItem: null,
  });

  /**
   * buildBreadcrumb
   *
   * @description - Cette fonction définit le contenu des éléments du fil d'Ariane en fonction de l'URL actuelle.
   * @return {void}
   */
  async function handleCorrectUrl() {
    return new Promise((resolve) => {
      if (infoBreadcrumb) {
        if (Object.keys(infoBreadcrumb).length === 0) {
          resolve(false);
        } else {
          resolve(true);
        }
      }
    });
  }
  /**
   * buildBreadcrumb
   */
  function buildBreadcrumb() {
    switch (route.path) {
    case '/':
      setArianeItem(
        {
          firstItem: null,
          urlItem: '/',
        });
      break;
    case '/delivery':
      setArianeItem(
        {
          firstItem: t('CREATION_DELIVERY'),
          urlItem: '/delivery',
        });
      break;
    case '/deliveries':
      setArianeItem(
        {
          firstItem: t('DELIVERIES_LIST'),
          urlItem: `/deliveries${store.deliveryList.ongoing ?? '?label=ongoing'}`,
        });
      break;
    case '/delivery/:id':
      setArianeItem(
        {
          firstItem: t('DELIVERIES_LIST'),
          secondItem: `${t('DELIVERY')} : ${infoBreadcrumb.internal_reference}`,
          urlItem: `/deliveries${store.deliveryList.ongoing ?? '?label=ongoing'}`,
        });
      break;
    case '/users':
      setArianeItem(
        {
          firstItem: t('SEARCH_USER'),
          urlItem: '/users',
        });

      break;
    case '/users/:id':
      setArianeItem(
        {
          firstItem: t('SEARCH_USER'),
          secondItem: `${infoBreadcrumb.first_name} ${infoBreadcrumb.last_name}`,
          urlItem: '/users',
        });
      break;
    case '/settings':
      setArianeItem(
        {
          firstItem: t('SETTINGS'),
          urlItem: '/settings',
        });
      break;
    default:
      break;
    }
  }

  useEffect(() => {
    buildBreadcrumb();
    handleCorrectUrl().then((result) => setCorrectUrl(result));
  }, [location, infoBreadcrumb]);

  const breadcrumbMobileMenu = [
    {
      key: '1',
      label: (
        <Link to={arianeItem.urlItem}>
          {arianeItem.firstItem}
        </Link>
      ),
    },
  ];

  const breadcrumbItemsDesktop = [
    arianeItem.firstItem &&
    {
      title: (
        <Link to={arianeItem.urlItem}>
          {arianeItem.firstItem}
        </Link>
      ),
    },
    (arianeItem.secondItem && correctUrl) &&
    {
      title: (
        <Link to={router.location.pathname}>
          {arianeItem.secondItem}
        </Link>
      ),
    },
  ].filter(Boolean);

  const breadcrumbItemsMobile = [
    (arianeItem.firstItem && arianeItem.secondItem) ?
      {
        title: <span>...</span>,
        menu: { items: breadcrumbMobileMenu },
      } :
      {
        title: (
          <Link to={arianeItem.urlItem}>
            {arianeItem.firstItem}
          </Link>
        ),
      },
    (arianeItem.secondItem && correctUrl) &&
    {
      title: (
        <Link to={router.location.pathname}>
          {arianeItem.secondItem}
        </Link>
      ),
    },
  ].filter(Boolean);

  return (
    <Layout style={{ fontWeight: 700 }}>
      <UntouchableView>
        <Breadcrumb items={breadcrumbItemsDesktop} />
      </UntouchableView>
      <TouchableView>
        <Breadcrumb items={breadcrumbItemsMobile} />
      </TouchableView>
      <Layout style={{ width: 58 }}>
        <ZigwigwiSeparator />
      </Layout>
    </Layout>
  );
}

export default BreadcrumbWrapper;
