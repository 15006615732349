export const initialDelivery = {
  customer: {
    firstName: '',
    lastName: '',
    telephone: '',
    email: '',
    generateMail: false,
    isExisting: false,
    addresses: [],
    address: {},
    createNewAddress: false,
    userId: null,
  },
  drive: {
    defaultTips: 0,
    driveName: '',
    driveId: 0,
    currency: '€',
    tradeId: null,
  },
  date: {
    end: {},
    start: {},
  },
  reference: '',
  orderAmount: null,
  articlesAmount: null,
  storeComment: '',
  abTest: null,
  tips: 0,
  distance: 0,
  size: '',
  canceled: 0,
};
