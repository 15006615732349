/**
 * Intercept APP-scale http errors for when calling APIS.
 * @param {Object} response - http response passed by useFetch provider.
 */
export default function interceptHttpErrors(response) {
  if (!response.ok) {
    switch (response.status) {
    /*
      Error 403 should trigger if user doesn't have access to the asked ressource.
    */
    case 403:
      throw new Error('ACCESS_FORBIDDEN');
    /*
      Error 401 will trigger if user auth is invalid, inexistant or expired.
      In this case user should be logged off if he is connected and a message should notify the user.
    */
    case 401:
      // Only treat 401 errors if it is not part of an api auth resonse.
      if (new URL(response.url).pathname.split('/')[1] !== 'auth') {
        throw new Error('UNAUTHORIZED');
      }
      break;
    default:
      break;
    }
  }
}
