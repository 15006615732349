import { isMobile, isTablet } from 'react-device-detect';

export const isTouchableView = () => (isMobile || isTablet);

/**
 * TouchableView
 * @param {React.ReactElement} children
 * @return {TouchableView}
 */
function TouchableView({ children }) {
  return isTouchableView() ? children : null;
}


export default TouchableView;
