/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Result, Row } from 'antd';
import { AppContext } from '../../context/AppContext';

/**
 * PageNotFound
 * @return {JSX.Element}
 */
function PageNotFound() {
  const [store] = useContext(AppContext);
  const { t } = useTranslation();

  return (
    <Row align='middle' justify='center' css={{ height: '100%' }}>
      <Result
        icon={<img src={process.env.PUBLIC_URL + '/assets/404.svg'} width='120px' alt='BO_404' />}
        subTitle={t('PAGE_NOT_FOUND_MESSAGE')}
        extra={
          <Link to={`/deliveries${store.deliveryList.ongoing ?? '?label=ongoing'}`}>
            <Button type='primary'>
              {t('BACK_TO_HOME')}
            </Button>
          </Link>
        }
      />
    </Row>
  );
}

export default PageNotFound;
