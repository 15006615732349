/** @jsxImportSource @emotion/react */
import { Avatar, Space, Typography } from 'antd';

const { Text } = Typography;

/**
 * Creates a column element for displaying a delivery trade item.
 *
 * @param {object} trade - The trade object representing the delivery item.
 * @param {string} trade.trade_id - The unique identifier of the trade.
 * @param {string} trade.name - The name of the trade.
 * @param {object} trade.address - The address object associated with the trade.
 * @param {string} trade.address.zip_code - The zip code of the trade's address.
 * @param {string} trade.address.city - The city of the trade's address.
 * @return {Element} - The column element representing the delivery trade item.
 */
function DeliveryTradeItemColumn({ trade }) {
  return (
    <Space>
      <Avatar
        size={25}
        src={`${process.env.REACT_APP_STATIC_BUCKET}${trade.trade_id}.webp`}
      />
      <div>
        <div>{trade.name}</div>
        <Text
          type='secondary'
          css={{ fontSize: 12 }}
        >
          {`${trade.address?.zip_code} ${trade.address?.city}`}
        </Text>
      </div>
    </Space>
  );
}

export default DeliveryTradeItemColumn;
