import { apiEndPointsOptions } from '../../apiEndPointsOptions';

/**
 * Update delivery detail
 * @param {Number} userId
 * @return {Promise<any>}
 */
export async function readUserAddresses({ userId }) {
  const apiUrl = `${process.env.REACT_APP_API_BO}/users/${userId}/addresses`;
  const method = 'GET';

  try {
    const response = await fetch(
      apiUrl,
      await apiEndPointsOptions(method),
    );

    const result = await response.json();

    if (response.ok) {
      return {
        type: 'success',
        data: result,
      };
    }

    if (response.status === 404) {
      return {
        type: 'not_found',
        data: [],
      };
    }
    return {
      type: 'error',
      message: 'INTERNAL_ERROR',
    };
  } catch (error) {
    throw new Error(`Error in updateAddress service: ${error.message}`);
  }
}
