/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from '../../../hooks/useRouter';
import { readCotransportorCanceledDeliveries } from '../../../services/apiEndPoints/user/readUser';
import { theme, Button, Card, Collapse, Descriptions, Pagination, Row, Typography } from 'antd';
import { toLocaleString } from '../../../utils/toLocaleString';
import { toLocaleStringHourandMinutes } from '../../../utils/toLocaleStringHourandMinutes';
import { Link } from 'react-router-dom';
import SkeletonMobile from '../../SkeletonLoaders/SkeletonMobile';

const { Panel } = Collapse;
const { Item } = Descriptions;
const { Text } = Typography;

/**
 * Retrieves the list of deliveries for a shopper on a mobile device.
 *
 * @param {number} statusError - The status error code.
 * @return {JSX.Element}
 */
function ShopperDeliveriesListMobile({ count, setCount, statusError }) {
  const { token } = theme.useToken();
  const { t } = useTranslation();
  const router = useRouter();
  const shopperId = router.query.id;
  const [deliveries, setDeliveries] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (statusError === 200) {
      getCotransportorCanceledDeliveries();
    }
  }, [currentPage, statusError]);

  const getCotransportorCanceledDeliveries = async() => {
    setLoading(true);
    readCotransportorCanceledDeliveries({
      userId: shopperId,
      page: currentPage,
      sort: 'delivery_start',
      order: 'DESC',
    }).then((response) => {
      setLoading(false);
      setCount(response.data.count);
      setDeliveries(response.data.list);
    });
  };

  return (
    <Card bordered={false}>
      <SkeletonMobile
        loading={loading}
        emptyState={(count || deliveries.length) === 0}
        refreshLink={() => getCotransportorCanceledDeliveries()}
      >
        <Collapse accordion>
          {deliveries?.map((delivery, index)=>
            <Panel
              key={index}
              header={
                toLocaleString({
                  date: delivery?.deliveryEnd,
                  fullDatetime: true,
                })
              }
              className={(delivery.validated === 0 && deliveries.status !== 7) ? 'validated' : ''}
              css={{
                '&.validated': {
                  '.ant-collapse-header': {
                    borderLeft: `3px solid ${token.colorWarningBorder}`,
                  },
                },
              }}
            >
              <Descriptions
                size='small'
                bordered
                css={{ marginBottom: 20 }}
                labelStyle={{ fontWeight: 600, color: '#1B3F92' }}
              >
                <Item label={t('DELIVERY_TABLE_TRADE')}>
                  <p style={{ marginBottom: '0px' }}>{(delivery?.driveName)} <br />
                    <Text type='secondary'>
                      {delivery?.addressZipCode + ' ' + delivery?.addressCity}
                    </Text>
                  </p>
                </Item>
                <Item label = {t('DELIVERY')} >
                  {
                    toLocaleString({
                      date: delivery?.deliveryEnd,
                      fullDatetime: true,
                    })
                  } / {
                    toLocaleStringHourandMinutes(delivery?.deliveryStart)
                  } - {
                    toLocaleStringHourandMinutes(delivery?.deliveryEnd)
                  }
                </Item>
                <Item label = {t('CANCELATION')} >
                  {
                    toLocaleString({
                      date: delivery?.deliveryStart,
                      fullDatetime: true,
                    })
                  } / {
                    toLocaleStringHourandMinutes(delivery?.deliveryStart)
                  }
                </Item>
              </Descriptions>
              <Row justify='center' >
                <Link to={`/delivery/${delivery.deliveryId}`}>
                  <Button type='primary'>{t('DELIVERY_DETAILS')}</Button>
                </Link>
              </Row>
            </Panel>,
          )}
        </Collapse>
      </SkeletonMobile>
      {count !== 0 ?
        <Pagination
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20px',
          }}
          simple
          pageSize={5}
          defaultCurrent={1}
          current={currentPage}
          total={count}
          onChange={(page) => {
            setCurrentPage(page);
          }}
        /> :
        null}
    </Card>
  );
}

export default ShopperDeliveriesListMobile;
