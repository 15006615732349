import { Alert, Button, Drawer, Form, Space } from 'antd';
import { EditFilled } from '@ant-design/icons';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isTouchableView } from '../../../DeviceDetect/TouchableView';
import DrawForm from '../../../../middlewares/DrawForm';
import editDeliveryItems from './editDeliveryItems.json';
import SubmitButton from '../../../Buttons/SubmitButton';
import { NotificationCenterContext } from '../../../../context/NotificationCenterContext';
import { updateDeliveryDetail } from '../../../../services/apiEndPoints/delivery/detail/updateDeliveryDetail';

/**
 * EditDelivery
 * @param {Object} delivery
 * @param {function} setDelivery
 * @return {JSX.Element}
 */
function EditDelivery({ delivery, setDelivery }) {
  const { t } = useTranslation();
  const [EditDeliveryFormInstance] = Form.useForm();
  const notification = useContext(NotificationCenterContext);
  const [openEditDeliveryDrawer, setOpenEditDeliveryDrawer] = useState(false);
  const [loading, setLoading] = useState(false);
  const formItems = editDeliveryItems;

  const updateValidatedDelivery = (values) => {
    setLoading(true);
    updateDeliveryDetail({
      deliveryId: delivery.id,
      tips: values.tips,
      nbr_article: values.articlesAmount,
    }).then((response) => {
      if (response.data) {
        setDelivery({
          ...delivery,
          tips: response.data.tips,
          details: {
            ...delivery.details,
            nbr_article: response.data.nbr_article,
          },
        });
      }
      setLoading(false);
      setOpenEditDeliveryDrawer(false);
      notification.push(response.type, t(response.message));
    });
  };

  return (
    <>
      <Button type='primary' icon={<EditFilled />} onClick={() => setOpenEditDeliveryDrawer(true)}>
        {t('EDIT')}
      </Button>
      <Drawer
        title={t('EDIT_DELIVERY')}
        placement='right'
        width={isTouchableView() ? '100%' : 600}
        open={openEditDeliveryDrawer}
        onClose={() => setOpenEditDeliveryDrawer(false)}
        footer={(
          <SubmitButton
            form={EditDeliveryFormInstance}
            onClick={() => EditDeliveryFormInstance.submit()}
            loading={loading}
          >
            {t('TO_SAVE')}
          </SubmitButton>
        )}
      >
        <Space direction='vertical' size={10}>
          <Alert
            type="warning"
            message={t('EDIT_DELIVERY_WARNING')}
            showIcon
          />
          <DrawForm
            form={EditDeliveryFormInstance}
            formName='EditDeliveryForm'
            onFinish={updateValidatedDelivery}
            formItems={formItems}
            initialValues={{
              tips: delivery.tips,
              articlesAmount: delivery.details.nbr_article,
            }}
          />
        </Space>
      </Drawer>
    </>
  );
}

export default EditDelivery;
