/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useContext } from 'react';
import { theme, Input, InputNumber, Col, Form, Row, AutoComplete, Avatar, Card, Typography, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import useFetch from 'use-http';
import useDebounce from '../../../../hooks/useDebounce';
import HighlightText from '../../../HighlightText/HighlightText';
import { NotificationCenterContext } from '../../../../context/NotificationCenterContext';
import { AppContext } from '../../../../context/AppContext';
import { formatDistance } from '../../../../utils/formatDistance';

const { TextArea } = Input;
const { Text } = Typography;

/**
 * PersonalInformationsFieldset
 * @param {object} props
 * @return {fieldset}
 */
function DriveFieldset({ form }) {
  const { token } = theme.useToken();
  const { t } = useTranslation();
  const [store, dispatch] = useContext(AppContext);
  const [optionsListDrive, setOptionsListDrive] = useState([]);
  const [searchDriveInput, setSearchDriveInput] = useState(null);
  const driveFetch = useFetch(process.env.REACT_APP_API_BO, {
    cacheLife: parseInt(process.env.REACT_APP_LOCAL_CACHING_DATA, 10),
  });
  const distanceFetch = useFetch(process.env.REACT_APP_API_BO, {
    cacheLife: parseInt(process.env.REACT_APP_LOCAL_CACHING_DATA, 10),
  });
  const debouncedSearchTerm = useDebounce(searchDriveInput, 500);
  const notification = useContext(NotificationCenterContext);
  const colorError = token.colorError;

  const launchDriveSearch = async() => {
    if (debouncedSearchTerm && debouncedSearchTerm.length >= 3) {
      const results = await driveFetch.get(`/drives?q=${debouncedSearchTerm}`);
      if (driveFetch.response.ok) {
        setOptionsListDrive(results.length > 0 ? searchResult({ drives: results, search: debouncedSearchTerm }) : []);
      } else {
        if (driveFetch.response.status === 404) {
          setOptionsListDrive([]);
        } else {
          notification.push('error', t('INTERNAL_ERROR'));
        }
      }
    }
  };

  useEffect(() => {
    launchDriveSearch();
  }, [debouncedSearchTerm, driveFetch.get]);

  const handleSearch = (value) => {
    setSearchDriveInput(value);
  };

  const searchResult = ({ drives, search }) => {
    return drives.map((drive) => {
      return {
        drive: drive,
        value: drive.name,
        key: drive.id,
        label: (
          <div>
            <Avatar src={process.env.REACT_APP_STATIC_BUCKET + drive.id + '.webp'} size={22} style={{ marginRight: 5 }} />
            <HighlightText text={`${drive.name}`} highlightTag={search} />
          </div>
        ),
      };
    });
  };

  const onDriveSelection = async(value, option) => {
    form.setFieldsValue({ driveSearch: option });
    setSearchDriveInput(option.drive.name);
    dispatch({
      type: 'CREATE_DRIVE_INFO', payload: {
        defaultTips: option?.drive.default_tips,
        driveName: option?.drive.name,
        driveId: option?.drive.id,
        addressId: option?.drive.address_id,
        tradeId: option?.drive.trade_id,
      },
    });

    await distanceFetch.get(`/addresses/distance?originId=${store.delivery.customer.address.id}&destinationId=${option.drive.address_id}&tradeId=${option.drive.trade_id}`);
    if (distanceFetch.response.ok) {
      dispatch({
        type: 'SET_ORDER_DISTANCE_INFOS', payload: {
          distance: distanceFetch.response.data.distance,
          isValidatedDistance: distanceFetch.response.data.isValidatedDistance,
        },
      });
    }
  };

  const checkDriveInList = (_, value) => {
    if (!((typeof form.getFieldValue('driveSearch') === 'object') || value === store.delivery.drive.driveName) && (value?.length > 3 || value.drive)) {
      return Promise.reject(new Error(t('SELECT_DRIVE_FROM_LIST')));
    } else {
      return Promise.resolve();
    }
  };

  const resetDrive = () => {
    dispatch({
      type: 'CREATE_DRIVE_INFO', payload: {
        defaultTips: null,
        driveName: null,
        driveId: null,
        addressId: null,
        tradeId: null,
      },
    });
    dispatch({
      type: 'SET_ORDER_DISTANCE_INFOS', payload: {
        distance: null,
        isValidatedDistance: false,
      },
    });
  };

  /**
   * Render a tooltip if the distance is too long
   * And just the distance infos if distance is correct
   * @return {JSX.Element}
   */
  const RenderDistance = () => {
    if (!store.delivery.distance) return <></>;
    if (!store.delivery.isValidatedDistance) {
      return (
        <Tooltip title={t('DELIVERY_DISTANCE_TOO_LONG')} color={colorError}>
          <Text type='danger'>
            {formatDistance(store.delivery.distance)}
          </Text>
        </Tooltip>
      );
    } else {
      return (
        <Text type='secondary'>
          {formatDistance(store.delivery.distance)}
        </Text>
      );
    }
  };

  return (
    <Card title={t('THE_COMMAND')}>
      <Row gutter={{ md: 46 }}>
        <Col xs={24} md={12}>
          <Form.Item
            name='driveSearch'
            label={t('SEARCH_DRIVE')}
            validateFirst={true}
            rules={[{
              required: true,
              message: t('DRIVE_REQUIRED'),
            }, {
              validator: checkDriveInList,
            }]}
            initialValue={searchDriveInput ? searchDriveInput : store.delivery.drive.driveName}
          >
            <AutoComplete
              name='driveSearchOptions'
              options={optionsListDrive}
              onSelect={onDriveSelection}
              onSearch={handleSearch}
              onChange={resetDrive}
              notFoundContent={(driveFetch.response.status === 200 && searchDriveInput.length > 2) && t('NO_RESULT')}
            >
              <Input.Search
                id='drive-search-create-step2'
                placeholder={t('SEARCH_YOUR_DRIVE_PLACEHOLDER')}
                loading={driveFetch.loading}
                suffix={<RenderDistance />}
              />
            </AutoComplete>
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name='reference'
            label={t('ORDER_REFERENCE')}
            rules={[{
              required: true,
              message: t('ORDER_REFERENCE_REQUIRED'),
            }]}
            initialValue={store.delivery.reference}
          >
            <Input id='reference-create-step2' name='reference' />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name='orderAmount'
            label={t('ORDER_AMOUNT')}
            rules={[{
              required: true,
              message: t('ORDER_AMOUNT_REQUIRED'),
            }]}
            initialValue={store.delivery.orderAmount}
          >
            <InputNumber
              id='order-amount-create-step2'
              controls={true}
              min={0}
              style={{ width: '100%' }}
              addonAfter="€" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name='articlesAmount'
            label={t('ARTICLES_AMOUNT')}
            rules={[{
              required: true,
              message: t('ARTICLES_AMOUNT_REQUIRED'),
            }]}
            initialValue={store.delivery.articlesAmount}
          >
            <InputNumber id='articles-amount-create-step2'
              controls={true}
              min={0}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name='storeComment'
            label={t('STORE_COMMENT')}
            initialValue={store.delivery.storeComment}
          >
            <TextArea id='store-comment-create-step2' rows={4} showCount maxLength={255} />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
}

export default DriveFieldset;
