/** @jsxImportSource @emotion/react */
import React from 'react';
import { Avatar, Space, Typography } from 'antd';

const { Text } = Typography;

/**
 * ShopTableRow
 * @param {String} logoSrc
 * @param {String} shopName
 * @param {String} shopCity
 * @return {ShopTableRow}
 */
function ShopTableRow({ logoSrc, shopName, shopCity }) {
  return (
    <Space>
      <Avatar size={25} src={logoSrc} />
      <div>
        <div>{shopName}</div>
        <Text type='secondary' css={{ fontSize: 12 }}>{shopCity}</Text>
      </div>
    </Space>
  );
}

export default ShopTableRow;
