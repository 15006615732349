import { apiEndPointsOptions } from '../../../apiEndPointsOptions';

export const writeUserBan = async(userId, driveOrAllDrive) => {
  const apiUrl = `${process.env.REACT_APP_API_BO}/bans/deactivated_users/${userId}`;
  const method = 'POST';

  try {
    const response = await fetch(apiUrl, await apiEndPointsOptions(method, driveOrAllDrive));
    const result = await response.json();

    if (response.status === 200) {
      return {
        type: 'success',
        data: result,
        message: 'DEACTIVATION_SUCCESS',
      };
    }

    return {
      type: 'error',
      message: 'DEACTIVATION_ERROR',
    };
  } catch (error) {
    throw new Error(`Error in writeUserBan service: ${error.message}`);
  }
};
