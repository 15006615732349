import React from 'react';

/**
 *
 * @param {Object} person
 * @return {React.JSXElement}
 */
function PersonDataRow({ person }) {
  return (
    <>
      <div>{person?.first_name}</div>
      <div>{person?.last_name}</div>
    </>
  );
}

export default PersonDataRow;
