import dayjs from 'dayjs';
import { getHourRoundByQuarter } from '../../utils/getHourRoundByQuarter';

/**
 * Define initial dates for the delivery time
 *
 * Rules :
 * - 00h00 - 08:00 : Today at 10:00 - 11:00
 * - 08:00 - 20:00 : Today at now + 30min - now + 90min
 * - 20:00 - 00:00 : Tomorrow at 10:00 - 11:00
 *
 * @param {Object} now - dayjs object
 * @return {Object} start and end dates
 */
export const defineInitialDates = (now) => {
  const nowPlus30 = getHourRoundByQuarter(now.add(30, 'minute')).second(0);
  const nowPlus90 = getHourRoundByQuarter(now.add(90, 'minute')).second(0);
  const today10 = dayjs().hour(10).minute(0).second(0);
  const today11 = dayjs().hour(11).minute(0).second(0);
  const tomorrow10 = dayjs().add(1, 'day').hour(10).minute(0).second(0);
  const tomorrow11 = dayjs().add(1, 'day').hour(11).minute(0).second(0);

  const case1 = now.isBetween(
    now.hour(0).minute(0).second(0),
    now.hour(8).minute(0).second(0),
    'minutes',
    '[)', // '[)' includes start date but excludes end date
  );

  const case2 = now.isBetween(
    now.hour(8).minute(0).second(0),
    now.hour(20).minute(0).second(0),
    'minutes',
    '[]', // '[]' includes start and end date
  );

  const case3 = now.isBetween(
    now.hour(20).minute(0).second(0),
    now.hour(23).minute(59).second(59),
    'minutes',
    '(]', // '(]' excludes start date but includes end date
  );


  if (case1) {
    return {
      start: today10,
      end: today11,
    };
  }

  if (case2) {
    return {
      start: nowPlus30,
      end: nowPlus90,
    };
  }

  if (case3) {
    return {
      start: tomorrow10,
      end: tomorrow11,
    };
  }
};
