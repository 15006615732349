/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';
import { Button, Col, Form, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import DriveFieldset from './DriveFieldset';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { StepContext } from '../CreateDeliveryForm';
import { AppContext } from '../../../../context/AppContext';
import DeliverySizeFieldset from './DeliverySizeFieldset';
import { NotificationCenterContext } from '../../../../context/NotificationCenterContext';
import useDocumentTitle from '../../../../hooks/useDocumentTitle';

/**
 * CreateDeliveryForm
 * @return {Form}
 */
function Step2() {
  const { t } = useTranslation();
  useDocumentTitle(t('URL_DELIVERY_CREATION_ORDER'));
  const { setCurrentStep, setStepsValidated } = useContext(StepContext);
  const [store, dispatch] = useContext(AppContext);
  const notification = useContext(NotificationCenterContext);

  const setStep2 = () => {
    dispatch({ type: 'CREATE_ORDERED_INFOS_DRIVE', payload: {
      reference: form.getFieldValue('reference'),
      orderAmount: form.getFieldValue('orderAmount'),
      articlesAmount: form.getFieldValue('articlesAmount'),
      storeComment: form.getFieldValue('storeComment'),
      size: form.getFieldValue('deliverySize'),
    }});
  };

  const onFinish = () => {
    // show an alert of ant-design to ask the user to validate the distance
    if (!store.delivery.isValidatedDistance) {
      return notification.push('error', t('DELIVERY_DISTANCE_TOO_LONG'));
    }
    // submit the form because all it's ok
    setStep2();
    setCurrentStep(2);
    setStepsValidated([true, true, false]);
  };

  const prevButton = () => {
    setStep2();
    setCurrentStep(0);
  };

  const [form] = Form.useForm();
  return (
    <Form
      name="CreateDriveForm"
      onFinish={onFinish}
      form={form}
      layout="vertical"
    >
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <DriveFieldset form={form}/>
        </Col>
        <Col span={24}>
          <DeliverySizeFieldset />
        </Col>
      </Row>

      <div css={{ display: 'flex', justifyContent: 'center', padding: '25px 0', gap: 100 }}>
        <Button icon={<ArrowLeftOutlined />} id='button-prev-create-step2'onClick={() => prevButton()} type="primary" htmlType="button" name="prev">
          {t('PREVIEWS')}
        </Button>
        <Button id='button-next-create-step2' type="primary" htmlType="submit" name="next">
          {t('NEXT')}
          <ArrowRightOutlined/>
        </Button>
      </div>

    </Form>
  );
}

export default Step2;
