import { isMobile, isTablet } from 'react-device-detect';

/**
 * UntouchableView
 * @param {React.ReactElement} children
 * @return {UntouchableView}
 */
function UntouchableView({ children }) {
  return (!isMobile && !isTablet) ? children : null;
}

export default UntouchableView;
