import { useState, useEffect } from 'react';
import { Form, Button, Popconfirm } from 'antd';

/**
 * SubmitButtonComponent
 * @param {object} children
 * @param {object} form
 * @param {function} onClick
 * @param {object} popconfirm
 * @param {boolean} loading
 * @return {JSX.Element}
 */
function SubmitButtonComponent({ children, form, onClick, popconfirm, loading }) {
  const [submittable, setSubmittable] = useState(false);
  const values = Form.useWatch([], form);

  const fitContent = {
    width: 'fit-content',
  };

  useEffect(() => {
    if (values && Object.keys(values).length > 1) {
      form.validateFields({
        validateOnly: true,
      }).then(
        () => setSubmittable(true),
        () => setSubmittable(false),
      );
    }
  }, [values]);

  return popconfirm ? (
    <Popconfirm
      title={popconfirm.title}
      description={popconfirm.description}
      okText={popconfirm.okText}
      cancelText={popconfirm.cancelText}
      onConfirm={onClick}
      disabled={!submittable}
    >
      <Button style={fitContent} type="primary" htmlType="button" disabled={!submittable}>
        {children}
      </Button>
    </Popconfirm>
  ) : (
    <Button style={fitContent} type="primary" htmlType="submit" onClick={onClick} disabled={!submittable} loading={loading}>
      {children}
    </Button>
  );
}

/**
 * SubmitButton component
 * @param {object} form
 * @param {function} onClick
 * @param {object} popconfirm
 * @param {boolean} loading
 * @return {JSX.Element}
 */
function SubmitButton({ children, form, onClick, popconfirm, loading }) {
  return form ? (
    <SubmitButtonComponent form={form} onClick={onClick} popconfirm={popconfirm} loading={loading}>
      {children}
    </SubmitButtonComponent>
  ) : null;
}

export default SubmitButton;
