import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * PaidHistoryNode
 * @return {JSX.Element}
 */
function PaidHistoryNode() {
  const { t } = useTranslation();

  return (
    <div>
      {t('DELIVERY_PAID')}
    </div>
  );
}

export default PaidHistoryNode;
