import { isUrgentDelivery } from '../isUrgentDelivery';

/**
 * Formats the columns of the given deliveries.
 *
 * @param {Array} deliveries - The deliveries to format.
 * @return {Array} - The formatted deliveries.
 */
function formatDeliveriesColumns(deliveries) {
  return deliveries?.map((delivery) => {
    return {
      ...delivery,
      distance_drive_dest: delivery.distance,
      button_link: {
        deliveryId: delivery.id,
        deliveryType: delivery.delivery_type,
      },
      time_slot: {
        start: delivery.delivery_start,
        end: delivery.delivery_end,
        isUrgent: isUrgentDelivery({
          date: delivery.delivery_end,
          status: delivery.status,
        }),
      },
      shifted_withdrawal: {
        start: delivery.deliveryStart || delivery.delivery_start,
        end: delivery.deliveryEnd || delivery.delivery_end,
        nb: delivery.nbShiftedTime,
      },
      delivery_end: delivery.deliveryEnd || delivery.delivery_end,
      shifted_date: {
        date: delivery.deliveryEnd || delivery.delivery_end,
        nb: delivery.nbShiftedDays,
      },
      increase_tips: {
        initial_tips: delivery.initial_tips || delivery.initialTips,
        tips: delivery.tips,
      },
      client: {
        ...delivery.client,
        first_name: delivery.client?.first_name || delivery.client?.firstName,
        last_name: delivery.client?.last_name || delivery.client?.lastName,
      },
    };
  });
}

export default formatDeliveriesColumns;
