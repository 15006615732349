/** @jsxImportSource @emotion/react */
import { Space, Typography } from 'antd';
import { formatCurrency } from '../../../utils/formatCurrency';

const { Text } = Typography;

/**
 * Displays the delivery tip amount in a formatted column.
 *
 * @param {object} increaseTips
 * @return {JSX.Element} - The formatted column displaying the delivery tip amount.
 */
function DeliveryTipItemColumnProcessed({ increaseTips }) {
  return (
    <Space align='center'>
      {formatCurrency(increaseTips?.tips)}
      <Text type="danger" css={{ fontSize: 12 }}>
        {increaseTips.tips > increaseTips.initial_tips && `+${formatCurrency(increaseTips.tips - increaseTips.initial_tips)}`}
      </Text>
    </Space>);
}

export default DeliveryTipItemColumnProcessed;
