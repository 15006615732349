import { EmptyData } from '../components/EmptyComponents/EmptyData';
import { EmptyDeliveries } from '../components/EmptyComponents/EmptyDeliveries';
import { EmptyError } from '../components/EmptyComponents/EmptyError';
import { EmptyNobody } from '../components/EmptyComponents/EmptyNobody';

/**
 * customizeRenderEmpty
 * @param {boolean} customizeEmpty
 * @param {function} refreshLink
 * @param {string} dataString
 * @param {function} updateUserSearch function to open modal with search form
 * @return {JSX.Element}
 */
export function customizeRenderEmpty({ customizeEmpty, refreshLink, dataString, updateUserSearch }) {
  if (customizeEmpty) {
    if (refreshLink) {
      return <EmptyError refreshLink={refreshLink} />;
    } else {
      return <EmptyNobody updateUserSearch={updateUserSearch} />;
    }
  } if (dataString) {
    return <EmptyData dataString={dataString}/>;
  } else {
    return <EmptyDeliveries />;
  }
}

