/** @jsxImportSource @emotion/react */
import { Button, Col, Form, Input, Row } from 'antd';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch } from 'use-http';
import Telephone from '../../Telephone/Telephone';
import { parsePhoneNumber } from 'libphonenumber-js';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { useSearchParams } from 'react-router-dom';
import ModalContext from '../../../context/ModalContext';

/**
 * UserSearchForm
 * @param {object} form
 * @param {function} setSearchValue function to update value search
 * @param {boolean} modalForm
 * @param {boolean} modalOpenAnimationEnded
 * @return {JSX.Element}
 */
function UserSearchForm({ form, modalForm, modalOpenAnimationEnded }) {
  const { t } = useTranslation();
  const fullNameRef = useRef(null);
  const telephoneRef = useRef(null);
  const emailRef = useRef(null);
  useDocumentTitle(t('URL_USER_RESEARCH'));
  const usersFetch = useFetch(process.env.REACT_APP_API_BO, {
    cachePolicy: 'no-cache',
  });
  const setOpenModalSearch = useContext(ModalContext)['setOpenModalSearch'];
  const [searchParams, setSearchParams] = useSearchParams();
  const [disabled, setDisabled] = useState({
    resetDisabled: true,
    searchDisabled: true,
  });
  const valuesForm = Form.useWatch([], form);

  useEffect(() => {
    setDisabled((prevState) => ({
      ...prevState,
      resetDisabled: valuesForm && Object.values(valuesForm).every((val) => val === null || val === undefined),
    }));
  }, [valuesForm]);

  useEffect(()=> {
    if (searchParams) {
      form.setFieldsValue({
        fullName: searchParams.get('name'),
        email: searchParams.get('email'),
        telephone: searchParams.get('telephone'),
      });
      setDisabled((prevState) => ({ ...prevState, searchDisabled: form.getFieldsValue() }));
    }
  }, [searchParams]);

  useEffect(() => {
    const inputFocus = getFirstNonEmptyFieldName(searchParams);
    if (inputFocus) {
      switch (inputFocus) {
      case 'fullName':
        fullNameRef.current.focus();
        break;
      case 'telephone':
        telephoneRef.current.focus();
        break;
      case 'email':
        emailRef.current.focus();
        break;
      default:
        fullNameRef.current.focus();
        break;
      }
    }
  }, [modalOpenAnimationEnded]);

  const getFirstNonEmptyFieldName = (params) => {
    if (params.get('name')) {
      return 'fullName';
    } else if (params.get('telephone')) {
      return 'telephone';
    } else if (params.get('email')) {
      return 'email';
    }
    return null;
  };

  const changeSearchDisabledState = (isDisabled) => {
    setDisabled((prevState) => ({
      ...prevState,
      searchDisabled: isDisabled,
    }));
  };

  const handleFormChange = () => {
    const formValues = form.getFieldsValue();
    const formIsEmpty = !Object.values(formValues).some((value) => value !== null && value !== '');
    const formHasNoErrors = form.getFieldsError().filter(({ errors }) => errors.length).length === 0;

    if (formIsEmpty) {
      return changeSearchDisabledState(true);
    }

    if (formHasNoErrors || !formIsEmpty) {
      const isAnyFieldValid = Object.values(formValues).some((value) => value?.trim()?.length >= 1);
      isAnyFieldValid ? changeSearchDisabledState(false) : changeSearchDisabledState(true);
    } else {
      changeSearchDisabledState(true);
    }
  };

  const resetFields = () => {
    form.resetFields();
    setDisabled((prevState) => ({ ...prevState, searchDisabled: true }));
  };

  const onFinish = async(values) => {
    let formParams = [];
    if (values.fullName) formParams = [...formParams, `name=${values.fullName}`];
    if (values.telephone) formParams = [...formParams, `telephone=${encodeURIComponent(parsePhoneNumber(values.telephone).number)}`];
    if (values.email) formParams = [...formParams, `email=${encodeURIComponent(values.email)}`];
    formParams = [...formParams, `page=1`];
    formParams = searchParams.get('limit') ? [...formParams, `limit=${searchParams.get('limit')}`] : [...formParams, `limit=10`];

    setSearchParams(formParams.join('&'));
    setOpenModalSearch(false);
  };

  // TODO: Refactoriser le code en utilisant le "DrawForm"
  return (
    <Form
      form={form}
      onFieldsChange={handleFormChange}
      onFinish={onFinish}
      layout='vertical'
    >
      <Row gutter={20} css={{ margin: modalForm ? '0 auto' : '20px auto 40px' }}>
        <Col xs={24} md={24}>
          <Form.Item
            name='fullName'
            defaultValue={searchParams.get('name')}
            label={t('FIRST_NAME_LAST_NAME')}
            rules={[{
              pattern: /^[^\d]*$/g,
              message: t('NUMBER_NOT_ALLOWED'),
            }, {
              pattern: /^(?=.{1,100}$).*/g,
              message: t('FULL_NAME_LIMITE_LENGTH'),
            }, {
              pattern: /^[^£¥€$©®¿?¡!`,;:.:/÷+=%)(““§&#@<>_°*}\]{[«»≠•—≤]*$/g,
              message: t('INVALID_VALUE'),
            }]}
          >
            <Input id='name-userSearch' name='fullName' ref={fullNameRef} />
          </Form.Item>
        </Col>
        <Telephone form={form} defaultValue={searchParams.get('telephone')} disabled={false} required={false} telephoneRef={telephoneRef}/>
        <Col xs={24} md={12}>
          <Form.Item
            name='email'
            label={t('EMAIL')}
            rules={[{
              type: 'email',
              message: t('WRONG_FORMAT_EMAIL'),
            }]}
            defaultValue={searchParams.get('email')}
          >
            <Input id='email-userSearch' name='email' ref={emailRef}/>
          </Form.Item>
        </Col>
      </Row>
      <Row justify={modalForm ? 'end' : 'center'} align='middle' gutter={modalForm ? 33 : 40}>
        <Col>
          <Button type='link' onClick={resetFields} disabled={disabled.resetDisabled}>{t('RESET')}</Button>
        </Col>
        <Col>
          <Button htmlType='submit' type='primary' disabled={disabled.searchDisabled} loading={usersFetch.loading}>{t('SEARCH')}</Button>
        </Col>
      </Row>
    </Form>
  );
}

export default UserSearchForm;
