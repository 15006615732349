/**
 * @param {string} state
 * @param {action} action
 * @return {void}
 */
export default function deliveriesReducer(state, action = {}) {
  switch (action.type) {
  case 'UPDATE_DELIVERIES_ONGOING': // TODO it going to be edited after technical conception
    return { ...state, deliveries: { ...state.deliveries,
      ongoing: { ...state.deliveries.ongoing,
        date: new Date().getTime(),
        data: action.payload.ongoing,
      }},
    };
  case 'UPDATE_DELIVERIES_URGENT': // TODO it going to be edited after technical conception
    return { ...state, deliveries: { ...state.deliveries,
      urgent: { ...state.deliveries.urgent,
        date: new Date().getTime(),
        data: action.payload.urgent,
      }},
    };
  case 'UPDATE_DELIVERIES_INACTIVE': // TODO it going to be edited after technical conception
    return { ...state, deliveries: { ...state.deliveries,
      inactive: { ...state.deliveries.inactive,
        date: new Date().getTime(),
        data: action.payload.inactive,
      }},
    };
  default:
    return state;
  }
}
