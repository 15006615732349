/** @jsxImportSource @emotion/react */
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import { Space } from 'antd';
import {
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { Provider } from 'use-http';
import { AppContext } from '../context/AppContext';
import { useTranslation } from 'react-i18next';
import { NotificationCenterContext } from '../context/NotificationCenterContext';
import { Player } from '@lottiefiles/react-lottie-player';
import loader from '../assets/loader.json';
import { DEFAULT_ROUTE } from '../routes/routes';
import interceptHttpErrors from '../utils/InterceptHttpErrors';
import AuthService from '../services/AuthService';

/**
 * AuthProvider
 * @param {*} children
 * @return {JSX.Element}
 */
function AuthProvider({ children }) {
  const { t } = useTranslation();
  const [waitForLoggedUser, setWaitForLoggedUser] = useState(true);
  const [, dispatch] = useContext(AppContext);
  const logger = useContext(NotificationCenterContext);
  const options = {
    interceptors: {
      request: async({ options }) => {
        if (AuthService.isLoggedIn()) {
          try {
            const updateToken = await AuthService.updateToken();
            options.headers.Authorization = `Bearer ${updateToken}`;
          } catch (e) {
            console.error('Failed to update token', e);
          }
        }
        return {
          ...options,
          headers: {
            ...options.headers,
            'x-shopopop-backoffice-user-countries': localStorage.getItem('CountriesFilter') || '',
            'x-shopopop-captcha': localStorage.getItem('captcha') || '',
            'Authorization': AuthService.isLoggedIn() && `Bearer ${AuthService.getToken()}`,
          },
          credentials: 'include', // If this is not set globally, the navigator will never share its secure cookies.
        };
      },
      response: async({ response }) => {
        try {
          interceptHttpErrors(response);
          return response;
        } catch (e) {
          console.error('Failed to intercept response', e);
          // Called when error occur.
          logger.push('error', t(e.message), null);
        }
      },
    },
  };

  const waitForLoaderAnimation = (callback) => {
    setTimeout(() => {
      setWaitForLoggedUser(false);
      if (callback) callback();
    }, 666);
  };

  const navigate = useNavigate();
  const location = useLocation();

  const redirectToOrigin = () => {
    const { pathname, search } = location.state?.from || {};
    navigate(pathname ? `${pathname}${search}` : DEFAULT_ROUTE);
  };

  useEffect(() => {
    if (AuthService.isLoggedIn()) {
      dispatch({ type: 'LOGIN', payload: AuthService.getTokenParsed() });
      waitForLoaderAnimation();
    } else {
      waitForLoaderAnimation(redirectToOrigin);
    }
  }, []);

  const value = {
    waitForLoggedUser,
  };

  return waitForLoggedUser ? <Loader /> : (
    <Provider url={process.env.REACT_APP_API_BO} options={options}>
      <AuthContext.Provider value={value}>
        {children}
      </AuthContext.Provider>
    </Provider>
  );
}

/**
 * Loader
 * @return {JSX.Element}
*/
function Loader() {
  return (
    <Space align='center' css={{
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      height: '100vh',
    }}>
      <Player
        autoplay
        loop
        src={loader}
      />
    </Space>
  );
}

export default AuthProvider;
