import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import BigEmpty from '../../assets/shopopopEmpty.svg';

export const EmptyDeliveries = () => {
  const { t } = useTranslation();

  return (
    <Empty
      image={BigEmpty}
      description={t('NO_DELIVERIES')}
    />
  );
};
