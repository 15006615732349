import { getThrowErrorMessage } from '../../messages';
import fetchData from '../fetchData';

export const configurePassword = async(email) => {
  const apiUrl = `${process.env.REACT_APP_API_BO}/retry-send-welcome-mail`;
  const method = 'POST';

  try {
    return fetchData({ apiUrl, method, data: { email }});
  } catch (error) {
    throw new Error(getThrowErrorMessage({ functionName: 'configurePassword', error }));
  }
};
