/** @jsxImportSource @emotion/react */
import React from 'react';
import { Space } from 'antd';
// Hooks
import useCustomHtml from '../../../../hooks/useCustomHtml';
import { useTranslation } from 'react-i18next';
import CommentCardModerationHistory from '../../../TimelineUtilsComponents/CommentCardModerationHistory';
import NotationTag from '../Items/NotationTag';


/**
 * UserRatingHistoryNode
 * @return {JSX.Element}
*/
function UserRatingHistoryNode({ user, errandId, comment, notation }) {
  const { t } = useTranslation();

  const ratingNode = useCustomHtml({
    keyValues: [
      { key: '[RECIPIENT_NAME]', value: `${user.firstName} ${user.lastName}`, url: `/users/${user.id}?tab=recipient`, openNewTab: true },
      { key: '[DELIVERY_ID]', value: errandId, url: `/delivery/${errandId}`, openNewTab: true },
    ],
    inputString: t('RECIPIENT_RATING_HISTORY'),
  });

  return (
    <div>
      {ratingNode}
      <Space size={5} wrap='true' css={{ margin: '10px 0px' }}>
        <NotationTag
          value={notation?.attitude}
          goodTagText={'GOOD_BEHAVIOUR'}
          badTagText={'BAD_BEHAVIOUR'}
        />
        <NotationTag
          value={notation?.doorPickup}
          goodTagText={'AT_THE_DOOR'}
          badTagText={'NOT_AT_THE_DOOR'}
        />
        <NotationTag
          value={notation?.punctuality}
          goodTagText={'ON_TIME'}
          badTagText={'NOT_ON_TIME'}
        />
      </Space>
      {comment && <CommentCardModerationHistory comment={comment}/>}
    </div>
  );
}

export default UserRatingHistoryNode;
