/* eslint-disable max-len */

export default function WavyUnderline() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="58" height="6" fill="none">
      <path
        fill="currentColor"
        fill-rule="evenodd"
        d="M0 1.012a.5.5 0 0 1 .5-.5c1.57 0 2.357 1.21 2.966 2.146l.045.069c.68 1.044 1.19 1.761 2.173 1.761.984 0 1.491-.717 2.17-1.76l.045-.07C8.506 1.722 9.292.512 10.862.512s2.357 1.211 2.966 2.148l.044.067c.68 1.045 1.187 1.761 2.167 1.761.98 0 1.49-.717 2.171-1.761l.044-.068c.61-.936 1.4-2.147 2.97-2.147 1.57 0 2.357 1.211 2.966 2.148l.044.067c.68 1.045 1.187 1.761 2.167 1.761.98 0 1.49-.717 2.17-1.761l.045-.07c.61-.935 1.397-2.145 2.963-2.145 1.565 0 2.352 1.208 2.962 2.144l.046.07c.681 1.044 1.192 1.762 2.176 1.762.984 0 1.493-.717 2.173-1.761l.045-.07c.61-.935 1.397-2.145 2.966-2.145 1.57 0 2.357 1.21 2.966 2.146l.045.069c.68 1.044 1.19 1.761 2.173 1.761.984 0 1.493-.717 2.174-1.761l.045-.07c.609-.935 1.396-2.145 2.966-2.145 1.569 0 2.356 1.21 2.966 2.146l.045.069c.68 1.044 1.189 1.761 2.173 1.761a.5.5 0 0 1 0 1c-1.57 0-2.357-1.21-2.966-2.146l-.045-.069c-.68-1.044-1.19-1.761-2.173-1.761-.984 0-1.493.717-2.174 1.761l-.045.07c-.609.935-1.396 2.145-2.966 2.145-1.569 0-2.356-1.21-2.966-2.146l-.045-.069c-.68-1.044-1.189-1.761-2.173-1.761s-1.493.717-2.173 1.761l-.045.07c-.61.935-1.397 2.145-2.966 2.145-1.57 0-2.36-1.21-2.97-2.147l-.044-.068c-.681-1.044-1.19-1.761-2.17-1.761s-1.49.717-2.17 1.761l-.046.07c-.608.935-1.396 2.145-2.962 2.145-1.565 0-2.351-1.209-2.959-2.144l-.046-.071c-.68-1.044-1.189-1.761-2.172-1.761-.985 0-1.495.718-2.176 1.761L19 3.344c-.61.936-1.397 2.144-2.962 2.144-1.565 0-2.351-1.209-2.959-2.144l-.046-.071c-.68-1.044-1.188-1.761-2.172-1.761-.984 0-1.491.717-2.17 1.76l-.045.07c-.607.936-1.393 2.146-2.963 2.146-1.569 0-2.357-1.21-2.966-2.146l-.045-.069C1.993 2.229 1.484 1.512.5 1.512a.5.5 0 0 1-.5-.5Z"
        clip-rule="evenodd"
      />
    </svg>
  );
}
