import { Skeleton, Table } from 'antd';

/**
 * SkeletonTableLoader
 * @param {boolean} loading
 * @param {boolean} active
 * @param {number} rowCount
 * @param {Array} columns
 * @param {string} children
 * @param {JSX.Element} title Table title renderer
 *
 * @return {SkeletonTableLoader}
 */
function SkeletonTableLoader({ loading = false, active = true, rowCount = 5, columns = [], children, title }) {
  return loading ? (
    <Table
      title={title}
      rowKey="key"
      size={'middle'}
      pagination={false}
      dataSource={[...Array(rowCount)].map((_, index) => ({
        key: `key${index}`,
      }))}
      columns={columns.map((column, index) => {
        return {
          ...column,
          render: function renderPlaceholder() {
            return (
              <Skeleton
                key={index}
                active={active}
                paragraph={false}
              />
            );
          },
        };
      })}
    />
  ) : (
    <>{children}</>
  );
}

export default SkeletonTableLoader;
