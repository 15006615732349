export const CheckWaitCircle = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1_2)">
      <path d="M12.8932 1.02871C12.8932 1.53333 13.2719 1.9118 13.7767 2.03795C18.5729 3.0472 22.233 7.46265
      21.8544 12.635C21.4757 17.555 17.3107 21.7183 12.3884 21.9706C7.33984 22.2229 3.04859 18.8167 2.03889
      14.0227C1.91267 13.5182 1.53403 13.1396 1.02918 13.1396C0.398116 13.1396 -0.106745 13.7704 0.0194701
      14.4012C1.28161 20.0783 6.45635 24.2414 12.6407 23.9891C18.699 23.6106 24 17.9336 24 12.0042C24 6.07494
      19.835 1.15486 14.2816 0.0194609C13.5242 -0.106695 12.8932 0.397928 12.8932 1.02871Z"
      fill="#55AD4B"/>
      <ellipse opacity="0.1" cx="11.8594" cy="11.9141" rx="10.0156" ry="10.1016" fill="#55AD4B"/>
      <path d="M10.8789 2.0625C11.429 2.0625 11.875 1.60604 11.875 1.04297C11.875 0.479907 11.429 0.0234375
      10.8789 0.0234375C10.3288 0.0234375 9.88281 0.479907 9.88281 1.04297C9.88281 1.60604 10.3288 2.0625 10.8789 2.0625Z"
      fill="#55AD4B"/>
      <path d="M7.83203 2.76563C8.38212 2.76563 8.82813 2.30917 8.82813 1.74609C8.82813 1.18303 8.38212 0.726562
      7.83203 0.726562C7.2819 0.726562 6.83594 1.18303 6.83594 1.74609C6.83594 2.30917 7.2819 2.76563 7.83203 2.76563Z"
      fill="#55AD4B"/>
      <path d="M5.13672 4.33594C5.68681 4.33594 6.13281 3.87948 6.13281 3.31641C6.13281 2.75334 5.68681 2.29688 5.13672
      2.29688C4.58659 2.29688 4.14062 2.75334 4.14062 3.31641C4.14062 3.87948 4.58659 4.33594 5.13672 4.33594Z"
      fill="#55AD4B"/>
      <path d="M3.00391 6.57813C3.554 6.57813 4 6.12167 4 5.55859C4 4.99553 3.554 4.53906 3.00391 4.53906C2.45378 4.53906
      2.00781 4.99553 2.00781 5.55859C2.00781 6.12167 2.45378 6.57813 3.00391 6.57813Z"
      fill="#55AD4B"/>
      <path d="M1.58984 9.16406C2.13994 9.16406 2.58594 8.70761 2.58594 8.14453C2.58594 7.58147 2.13994 7.125 1.58984
      7.125C1.03971 7.125 0.59375 7.58147 0.59375 8.14453C0.59375 8.70761 1.03971 9.16406 1.58984 9.16406Z"
      fill="#55AD4B"/>
      <path d="M1.01953 12.1328C1.56962 12.1328 2.01563 11.6764 2.01563 11.1133C2.01563 10.5502 1.56962 10.0938 1.01953
      10.0938C0.4694 10.0938 0.0234375 10.5502 0.0234375 11.1133C0.0234375 11.6764 0.4694 12.1328 1.01953 12.1328Z"
      fill="#55AD4B"/>
      <path d="M17.1139 7.84164H15.8314C15.5525 7.84164 15.2873 7.96979 15.1232 8.18949L10.8247 13.8912L8.87786
      11.3072C8.71379 11.0901 8.45129 10.9593 8.16965 10.9593H6.88722C6.70949 10.9593 6.60558 11.1528 6.70948
      11.2915L10.1165 15.811C10.197 15.9185 10.3031 16.0061 10.4261 16.0665C10.549 16.1268 10.6852 16.1584 10.8234
      16.1584C10.9615 16.1584 11.0978 16.1268 11.2206 16.0665C11.3436 16.0061 11.4497 15.9185 11.5302 15.811L17.2889
      8.1738C17.3954 8.03519 17.2916 7.84164 17.1139 7.84164Z"
      fill="#55AD4B"/>
    </g>
    <defs>
      <clipPath id="clip0_1_2">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>

);
