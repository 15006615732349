import { Space } from 'antd';
import { toLocaleString } from '../../../utils/toLocaleString';
import { toLocaleStringHourandMinutes } from '../../../utils/toLocaleStringHourandMinutes';

/**
 * Represents a time slot in the delivery list.
 *
 * @param {Object} timeSlot - The time slot object.
 * @param {Date} timeSlot.start - The start time of the time slot.
 * @param {Date} timeSlot.end - The end time of the time slot.
 * @param {boolean} timeSlot.isUrgent - Indicates if the time slot is urgent.
 *
 * @return {JSX.Element} - The rendered React element representing the time slot.
 */
function DeliveryTimeSlotRow({ timeSlot }) {
  return (
    <Space align='center'>
      <div>
        <div>{toLocaleString({ date: timeSlot.start, fullDatetime: true })}</div>
        <div>{toLocaleStringHourandMinutes(timeSlot.start)} - {toLocaleStringHourandMinutes(timeSlot.end)}</div>
      </div>
    </Space>
  );
}

export default DeliveryTimeSlotRow;
