/** @jsxImportSource @emotion/react */
// Hooks
import { useTranslation } from 'react-i18next';
import { colors } from '../../../../globalVar/theme';
import findColorTagsComment from '../../../../utils/findColorTagsComment';
import { Card, Typography, Tag, Space } from 'antd';
import useCustomHtml from '../../../../hooks/useCustomHtml';

const { Text } = Typography;
/**
 * UserInternCommentHistoryNode
 * @param {Object} props
 * @param {Object} props.user
 * @param {string} props.description
 * @param {Array} props.userFeedback
 * @return {JSX.Element}
 */
function UserInternCommentHistoryNode({ user, description, userFeedback }) {
  const { t } = useTranslation();
  return (
    <div>
      <Text> {t('INTERN_COMMENT_HISTORY')} <Text className='user-name'>{user.firstName} {user.lastName}</Text></Text>
      <br />
      <Space size={5} wrap='true' css={{ margin: '10px 0px' }}>
        {userFeedback.map((feedback) => (
          <Tag key={feedback + description} style={findColorTagsComment(feedback)} css={{ fontWeight: '400 !important' }}>{t(`${feedback}`)}</Tag>
        ))}
      </Space>
      <Card
        bodyStyle={{
          padding: '15px 15px 15px',
          border: `1px solid ${colors.blueMiddle}`,
          borderTop: `1px solid ${colors.blueMiddle}`,
          borderRadius: '5px 5px 5px 5px',
        }}>
        <Text>{useCustomHtml({ inputString: description })}</Text>
      </Card>
    </div>
  );
}

export default UserInternCommentHistoryNode;
