import { Card, ConfigProvider, List, Radio, Skeleton, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import OpenCreateAddressDrawer from './CTA/OpenCreateAddressDrawer';
import LoadMoreAddresses from './CTA/LoadMoreAddresses';
import OpenEditAddressDrawer from './CTA/OpenEditAddressDrawer';
import { useTranslation } from 'react-i18next';
import addressFormatter from '../../utils/addressFormatter';
import { customizeRenderEmpty } from '../../services/customizeRenderEmpty';

const { Text } = Typography;

/**
 * Renders the AdressesListWrapper component.
 * @param {Object} props - The props of the component.
 * @param {string} props.title - The title of the card.
 * @param {Array} props.addresses - The addresses to display.
 * @param {Function} props.setAddresses - The function to update the addresses.
 * @param {number} props.userId - The user id.
 * @param {number} props.maxRows - The maximum number of rows to display.
 * @param {Object} props.actions - The actions to display.
 * @param {boolean} props.addressesLoading - The loading state of the addresses.
 * @param {Function} props.selectOtherAddress - The function to select an address.
 * @param {Object} props.addressErrorsInfos - The address errors infos.
 * @return {JSX.Element} Return the JSX element.
 */
function AddressesListCard({
  title, maxRows, actions, userId, addresses, setAddresses,
  addressesLoading, selectOtherAddress, addressErrorsInfos,
}) {
  const { t } = useTranslation();
  const [numberOfRowsView, setNumberOfRowsView] = useState(maxRows);
  const [indexValueSelected, setIndexValueSelected] = useState(0);

  const onChangeRadioValue = (index) => {
    setIndexValueSelected(index);
    selectOtherAddress(addresses[index]);
  };

  useEffect(() => {
    setIndexValueSelected(0);
  }, [addresses]);

  const addressError = (addressId) => {
    return addressErrorsInfos?.message && addressErrorsInfos.address_id === addressId;
  };

  return (
    <Card
      title={t(title)}
      extra={actions.create ?
        <OpenCreateAddressDrawer
          userId={userId}
          setAddresses={setAddresses}
          setIndexValueSelected={setIndexValueSelected}
        /> : null}
      bodyStyle={{ padding: !addressesLoading && 0 }}
    >
      {addressesLoading ?
        < Skeleton active /> :
        <ConfigProvider renderEmpty={() => customizeRenderEmpty({
          dataString: 'DELIVERY_NEW_USER_NO_ADDRESS',
        })}>
          <List
            dataSource={addresses.slice(0, numberOfRowsView)}
            loadMore={
              <LoadMoreAddresses
                setNumberOfRowsView={setNumberOfRowsView}
                addressesview={addresses.length - numberOfRowsView}
              />
            }
            size='small'
            renderItem={(item, index) => {
              return (
                <List.Item extra={actions.update ?
                  <OpenEditAddressDrawer
                    address={item}
                    setAddresses={setAddresses}
                    indexValueSelected={indexValueSelected}
                    setIndexValueSelected={setIndexValueSelected}
                    index={index}
                  /> : null}>
                  {
                    actions.select ?
                      <Radio.Group value={indexValueSelected} onChange={() => onChangeRadioValue(index)}>
                        <Radio value={index} />
                      </Radio.Group> : null
                  }
                  <List.Item.Meta
                    title={<Text type={addressError(item.id) && 'danger'}>{addressFormatter(item)}</Text>}
                    description={addressError(item.id) ? <Text type='danger'>{t(addressErrorsInfos?.message)}</Text> : null}
                  />
                </List.Item>
              );
            }}
          />
        </ConfigProvider>
      }
    </Card>
  );
}

export default AddressesListCard;
