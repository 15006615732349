import React from 'react';
import Icon, { EuroTwoTone, ClockCircleTwoTone, CheckCircleTwoTone, StopTwoTone, FrownTwoTone, ExclamationCircleTwoTone, EditTwoTone, ShopTwoTone } from '@ant-design/icons';
import { CheckWaitCircle } from './CheckWaitCircle';

const iconsAttributes = (color) => {
  return {
    twoToneColor: color,
    style: { fontSize: 24 },
  };
};

const EuroDot = ({ color }) => (
  <EuroTwoTone {...iconsAttributes(color)} />
);

const ClockDot = ({ color }) => (
  <ClockCircleTwoTone {...iconsAttributes(color)} />
);

const CheckDot = ({ color }) => (
  <CheckCircleTwoTone {...iconsAttributes(color)} />
);

const CancelDot = ({ color }) => (
  <FrownTwoTone {...iconsAttributes(color)} />
);

const RemovedDot = ({ color }) => (
  <StopTwoTone {...iconsAttributes(color)} />
);

const IncidentDot = ({ color }) => (
  <ExclamationCircleTwoTone {...iconsAttributes(color)} />
);
const DriveCommentDot = ({ color }) => (
  <ShopTwoTone {...iconsAttributes(color)} />
);

const CommentDot = ({ color }) => (
  <EditTwoTone {...iconsAttributes(color)}/>
);

const CheckWaitCircleDot = ({ color }) => (
  <Icon component={CheckWaitCircle} {...iconsAttributes(color)} />
);

export {
  EuroDot,
  ClockDot,
  CheckDot,
  CancelDot,
  RemovedDot,
  IncidentDot,
  DriveCommentDot,
  CommentDot,
  CheckWaitCircleDot,
};
