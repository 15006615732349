import { ArrowRightOutlined, WarningFilled } from '@ant-design/icons';
import styled from '@emotion/styled';
import { DeliveryListTabNames, DeliveryStatusEnum } from '@shopopop/react-hooks';
import { Button, Card, Flex, Space, theme, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { generateMessages } from '../../../../utils/src/utils/generateMessageDeliveryStatus.ts';
import DeliveryPickupTuple from '../Tables/Tuples/DeliveryPickupTuple.tsx';
import DeliveryTimeslotTuple from '../Tables/Tuples/DeliveryTimeslotTuple.tsx';
import DeliveryStatusTag from '../Tags/DeliveryStatusTag.tsx';

const { Text } = Typography;

interface DeliveryCardListItemProps {
  deliveryId: number;
  recipient: {
    firstName: string;
    lastName: string;
  };
  pickupTime: {
    start: string; // ISO8601 string
    end: string; // ISO8601 string
  };
  deliveryTime: {
    start: string; // ISO8601 string
    end: string; // ISO8601 string
  };
  status: DeliveryStatusEnum;
  tabName: DeliveryListTabNames;
  shiftedDate: boolean;
  shiftedTime: boolean;
  incident: boolean | null;
  isArchived: boolean;
  drive: {
    name: string;
    zipCode: string;
    city: string;
  };
}

function DeliveryCardListItem({ deliveryId, recipient, pickupTime, deliveryTime, status, tabName, shiftedDate, shiftedTime, incident, isArchived, drive }: DeliveryCardListItemProps) {
  const { t } = useTranslation();
  const {
    token: { colorFillQuaternary },
  } = theme.useToken();
  const navigate = useNavigate();
  const { alertMessage } = generateMessages(shiftedDate, shiftedTime, incident);

  const StyledCard = styled(Card)`
      .ant-card-head {
          background-color: ${colorFillQuaternary};
      }
  `;

  return (
    <StyledCard
      title={`${recipient.firstName} ${recipient.lastName}`}
      size={'small'}
      extra={<Button shape="circle" icon={<ArrowRightOutlined />} onClick={() => navigate(`/delivery/${deliveryId}`)} />}
    >
      <Space direction={'vertical'} size={10}>
        <DeliveryPickupTuple pickupPoint={drive.name} zipCode={drive.zipCode} city={drive.city} />
        <DeliveryTimeslotTuple pickupTime={pickupTime} deliveryTime={deliveryTime} status={status} deliveriesListTab={tabName} />
        <Space align="start">
          <DeliveryStatusTag status={status} isArchived={isArchived} />
          <Flex vertical={true}>
            {alertMessage && (
              <Text type="danger">
                <WarningFilled style={{ marginRight: 5 }} />
                {t(alertMessage)}
              </Text>
            )}
            {/* {infoMessage && (
              <Text type="danger">
                <InfoCircleOutlined style={{ marginRight: 5 }} />
                {t(infoMessage)}
              </Text>
            )} */}
          </Flex>
        </Space>
      </Space>
    </StyledCard>
  );
}

export default DeliveryCardListItem;
