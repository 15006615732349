import React from 'react';
import { useTranslation } from 'react-i18next';
import useCustomHtml from '../../../../../hooks/useCustomHtml';

/**
 * StatusHistoryNode
 * @param {Object} props
 * @param {string} props.status
 * @param {Object} props.user
 * @param {string} props.user.firstName
 * @param {string} props.user.lastName
 * @return {JSX.Element}
 */
function StatusHistoryNode({ status, user }) {
  const { t } = useTranslation();
  const isAdmin = user?.roles.includes('ADMIN');

  const statusChangeByShopopop = useCustomHtml({
    keyValues: [
      { key: '[DELIVERY_STATUS]', value: t(status) },
      { key: '[NAME]', value: `${user.firstName} ${user.lastName}`, bold: true },
    ],
    inputString: t('STATUS_UPDATE_BY_NAME'),
  });
  const statusChangeByDeliveryMan = useCustomHtml({
    keyValues: [
      { key: '[DELIVERY_STATUS]', value: t(status) },
      { key: '[NAME]', value: `${user.firstName} ${user.lastName}`, url: `/users/${user.id}` },
    ],
    inputString: t('STATUS_UPDATE_BY_NAME'),
  });
  return (
    isAdmin ? (
      <div>{statusChangeByShopopop}</div>
    ) : (
      <div>{statusChangeByDeliveryMan}</div>
    )
  );
}

export default StatusHistoryNode;
