/** @jsxImportSource @emotion/react */
import { Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

/**
 * Returns a JSX element displaying the parsed delivery distance.
 *
 * @param {string} tensionZone - The distance to be parsed.
 * @return {JSX.Element} - A JSX element displaying the parsed distance value and unit.
 */
function DeliveryTensionZoneItemColumn({ tensionZone }) {
  const { t } = useTranslation();
  return (
    <Text type={tensionZone === 'high' && 'danger'} css={{ fontSize: 12 }}>{t(`TENSION_ZONE_${tensionZone?.toUpperCase()}`)}</Text>
  );
}

export default DeliveryTensionZoneItemColumn;
