import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Flex } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import LinkOldBoModal from '../../Modal/LinkOldBoModal';
import { sizes } from '../../../globalVar/theme';
import React from 'react';
import { useWindowSize } from '../../../hooks/useWindowSize';

/**
 * Renders a link to a delivery based on the delivery ID and delivery type.
 *
 * @param {number} deliveryId - The ID of the delivery.
 * @param {string} deliveryType - The type of the delivery.
 *
 * @return {JSX.Element|null} The JSX element representing the link to the delivery, or null if the deliveryType is not supported.
 */
function DeliveryListLink({ deliveryId, deliveryType }) {
  const { t } = useTranslation();
  const windowSize = useWindowSize();

  const textB2B = {
    label: t('DELIVERY_DETAILS'),
    modalTitle: t('OLD_BO_ACCESS_B2B_DELIVERY'),
    modalContent1: t('OLD_BO_ACCESS_B2B_DELIVERY_SENTENCE_1'),
    modalContent2: t('OLD_BO_ACCESS_B2B_DELIVERY_SENTENCE_2'),
    modalLink: `${process.env.REACT_APP_OLD_BO_URL}/deliveries/b2b/${deliveryId}`,
  };

  if (deliveryType === 'B2B') {
    return (
      <Flex justify="center">
        <LinkOldBoModal isDeliveriesList={true} textContent={textB2B} />
      </Flex>
    );
  } else {
    return windowSize.width > sizes.sm ? (
      <Link to={`../delivery/${deliveryId}`}>
        <Button type='text' shape="circle" icon={<ArrowRightOutlined />}/>
      </Link>
    ) : (
      <Flex justify="center">
        <Link to={`../delivery/${deliveryId}`}>
          <Button type='primary'>
            {t('DELIVERY_DETAILS')}
          </Button>
        </Link>
      </Flex>
    );
  }
}

export default DeliveryListLink;
