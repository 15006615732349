import { useState } from 'react';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';

const { Search } = Input;

/**
 * This method returns a JSX.Element representing a search input for delivery search.
 * @param {function} props.launchSearch - The function to execute when launching a search.
 * @param {object} props.style - The style object.
 * @param {string} props.searchValueParam - The search value parameter.
 * @return {JSX.Element} - The search input JSX.Element.
 */
function DeliverySearchInput({ launchSearch, style, searchValueParam }) {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState(searchValueParam);

  return (
    <Search
      name='inputSearch'
      value={searchValue}
      onChange={(e) => setSearchValue(e.target.value)}
      onSearch={launchSearch}
      placeholder={t('TYPE_YOUR_SEARCH')}
      allowClear
      style={style}
    />
  );
}

export default DeliverySearchInput;
