import { Navigate } from 'react-router-dom';

/**
 * Home component
 * @module Home
 * @return {JSX.Element}
 */
function Home() {
  return (
    <>
      <Navigate to="/deliveries" replace={true} />
    </>
  );
}

export default Home;
