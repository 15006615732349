import { Table } from 'antd';
import SkeletonTableLoader from '../../../SkeletonLoaders/SkeletonTableLoader';

/**
 * DesktopProcessedList - Returns a JSX element representing the desktop processed list.
 *
 * @return {JSX.Element} The desktop processed list element.
 */
function DesktopProcessedList({ columns, deliveries, loading, tableParams, handleTableChanges }) {
  return (
    <SkeletonTableLoader
      loading={loading}
      active={true}
      rowCount={tableParams.pagination.pageSize}
      columns={columns}
    >
      <Table
        rowKey="id"
        columns={columns}
        dataSource={deliveries}
        pagination={tableParams.pagination}
        onChange={(pagination, filters, sorter) => {
          handleTableChanges({ label: 'action-needed', pagination, filters, sorter });
        }}
      />
    </SkeletonTableLoader>
  );
}

export default DesktopProcessedList;
