import { isValidNumberForRegion, parsePhoneNumber } from 'libphonenumber-js';
/**
 * @param {String} phone phone number in string
 * @param {String} codeCountryAlpha2 is the code off the phone number to get the intenational number
 * @return {string} the number format international
 */
export function toInternationalFormatPhoneNumber(phone, codeCountryAlpha2) {
  if (isValidNumberForRegion(phone, codeCountryAlpha2) && phone.length > 3 && typeof phone === 'string') {
    const number = parsePhoneNumber(`${phone}`, codeCountryAlpha2);
    phone = number.formatInternational();
  }
  return phone;
}
