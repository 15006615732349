import React, { useState, useEffect } from 'react';
import { Input, Col, Form, Avatar, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { countries } from 'country-flag-icons';
import Flags from 'country-flag-icons/react/1x1';
import { getCountryCallingCode, isSupportedCountry, isValidNumberForRegion, parsePhoneNumber } from 'libphonenumber-js';
import { toInternationalFormatPhoneNumber } from '../../utils/toInternationalFormatPhoneNumber.js';
const { Option } = Select;

/**
 * Telephone
  * @param {object} form
  * @param {string} initialValue
  * @param {boolean} disabled
  * @param {boolean} required
  * @param {object} telephoneRef
  * @return {JSX.Element}
 */
function Telephone({ form, initialValue, disabled, required, telephoneRef }) {
  const { t } = useTranslation();
  const [selectedCountry, setSelectedCountry] = useState('');
  useEffect(()=>{
    try {
      setSelectedCountry(parsePhoneNumber(initialValue).country);
    } catch (error) {
      setSelectedCountry(localStorage.getItem('i18nextLng').slice(-2).toUpperCase());
    }
  }, [initialValue]);

  const getCountryDialOptions = () => {
    const options = [];
    const availableCountries = countries.filter((value) => isSupportedCountry(value)).sort((a, b) => getCountryCallingCode(a) - getCountryCallingCode(b));
    for (const country of availableCountries) {
      const Flag = Flags[country];
      options.push((
        <Option value={country} key={country}>
          <Avatar size={15} icon={<Flag/>}/>&nbsp;
          <span style={{ verticalAlign: 'middle', marginLeft: 5 }}>+{getCountryCallingCode(country)}</span>
        </Option>
      ));
    }
    return options;
  };

  // Event to trigger when a search filter is called
  const filterCountrySearch = (value, option) => {
    const countryCallingCode = `+${getCountryCallingCode(option.value)}`;
    return option?.value?.includes(value?.toUpperCase()) || countryCallingCode?.includes(value);
  };

  // validator Rules
  const checkValidNumber = (_, value) => {
    if (!disabled) {
      if (value?.length > 0 && !isValidNumberForRegion(value, selectedCountry)) {
        return Promise.reject(new Error(t('WRONG_FORMAT_PHONE_NUMBER')));
      }
      if (value?.length === 0) {
        if (required) {
          return Promise.reject(new Error(t('PHONE_NUMBER_REQUIRED')));
        }
      }
    }
    return Promise.resolve();
  };

  const countrySelect = (
    <Select showSearch
      style={{ width: 100 }}
      onSelect={(value) => {
        setSelectedCountry(value);
      }}
      filterOption={filterCountrySearch}
      disabled={disabled}
      value = {selectedCountry}
    >
      {getCountryDialOptions()}
    </Select>
  );

  useEffect(() => {
    if (form.getFieldValue('telephone')) {
      form.validateFields(['telephone']);
    }
  }, [selectedCountry]);

  return (
    <Col xs={24} md={12}>
      <Form.Item
        colon
        label={t('PHONE_NUMBER')}
        validateTrigger={['onChange']}
        initialValue={initialValue}
        name='telephone'
        rules={[() => ({
          validator: checkValidNumber,
          required: required,
        })]}
        normalize={(value)=>{
          return toInternationalFormatPhoneNumber(value, selectedCountry);
        }}
      >
        <Input
          addonBefore={countrySelect}
          type={'tel'}
          disabled={disabled}
          ref={telephoneRef}
        />
      </Form.Item>
    </Col>
  );
}
export default Telephone;
