import { Table } from 'antd';
import SkeletonTableLoader from '../../../SkeletonLoaders/SkeletonTableLoader';

/**
 * DesktopOnGoingList - Returns a JSX element representing the desktop on going list.
 *
 * @return {JSX.Element} The desktop on going list element.
 */
function DesktopOnGoingList({ columns, deliveries, loading, tableParams, handleTableChanges }) {
  return (
    <SkeletonTableLoader
      active={true}
      loading={loading}
      columns={columns}
      rowCount={tableParams.pagination.pageSize}
    >
      <Table
        rowKey="id"
        columns={columns}
        dataSource={deliveries}
        pagination={tableParams.pagination}
        onChange={(pagination, filters, sorter) => {
          handleTableChanges({ label: 'ongoing', pagination, filters, sorter });
        }}
      />
    </SkeletonTableLoader>
  );
}

export default DesktopOnGoingList;
