/** @jsxImportSource @emotion/react */
import { theme, Button, Card, Collapse, Pagination, Tag, Descriptions, Row, Typography, Space } from 'antd';
import { WarningFilled } from '@ant-design/icons';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { findStatusDeliveries } from '../../../utils/findStatusDeliveries';
import { toLocaleString } from '../../../utils/toLocaleString';
import { toLocaleStringHourandMinutes } from '../../../utils/toLocaleStringHourandMinutes';
import SkeletonMobile from '../../SkeletonLoaders/SkeletonMobile';
import { useRouter } from '../../../hooks/useRouter';
import { Link } from 'react-router-dom';
import { readCotransportorDeliveries } from '../../../services/apiEndPoints/user/readUser';

const { Panel } = Collapse;
const { Item } = Descriptions;
const { Text } = Typography;

/**
 * Returns a list of deliveries for a shopper on the mobile view.
 *
 * @param {Object} options - The options for the method.
 * @param {number} options.count - The count of deliveries.
 * @param {function} options.setCount - The function to set the count of deliveries.
 * @param {number} options.statusError - The status error of the deliveries.
 * @return {Element} - The shopper deliveries list on the mobile view.
 */
function ShopperDeliveriesListMobile({ count, setCount, statusError }) {
  const { token } = theme.useToken();
  const { t } = useTranslation();
  const router = useRouter();
  const shopperId = router.query.id;
  const [deliveries, setDeliveries] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (statusError === 200) {
      getCotransportorDeliveries();
    }
  }, [currentPage, statusError]);

  const getCotransportorDeliveries = async() => {
    setLoading(true);
    readCotransportorDeliveries({
      userId: shopperId,
      page: currentPage,
      sort: 'delivery_start',
      order: 'DESC',
    }).then((response) => {
      setLoading(false);
      setCount(response.data.count);
      setDeliveries(response.data.list);
    });
  };

  return (
    <>
      <Card bordered={false}>
        <SkeletonMobile
          loading={loading}
          emptyState={(count || deliveries.length) === 0}
          refreshLink={() => getCotransportorDeliveries()}
        >
          <Collapse accordion>
            {deliveries?.map((delivery, index)=>
              <Panel
                key={index}
                header={`${delivery?.client.first_name} ${delivery?.client.last_name}`}
                extra={
                  <Space>
                    {delivery?.incident && <WarningFilled style={{ color: token.colorError, fontSize: '15px' }} />}
                    <Tag style={findStatusDeliveries(delivery?.status).style} >{t(findStatusDeliveries(delivery?.status).message)}</Tag>
                  </Space>
                }
                className={(delivery.validated === 0 && deliveries.status !== 7) ? 'validated' : ''}
                css={{
                  '&.validated': {
                    '.ant-collapse-header': {
                      borderLeft: `3px solid ${token.colorWarningBorder}`,
                    },
                  },
                }}
              >
                <Descriptions
                  size='small'
                  bordered
                  css={{ marginBottom: 20 }}
                  labelStyle={{ fontWeight: 600, color: '#1B3F92' }}
                >
                  <Item label={t('DELIVERY_TABLE_TRADE')}>
                    <p style={{ marginBottom: '0px' }}>{(delivery?.trade.name)} <br />
                      <Text type='secondary'>
                        {delivery?.trade.address.zip_code + ' ' + delivery?.trade.address.city}
                      </Text>
                    </p>
                  </Item>
                  <Item label = {t('DELIVERY')} >
                    {
                      toLocaleString({
                        date: delivery?.delivery_end,
                        fullDatetime: true,
                      })
                    } / {
                      toLocaleStringHourandMinutes(delivery?.delivery_start)
                    } - {
                      toLocaleStringHourandMinutes(delivery?.delivery_end)
                    }
                  </Item>
                  <Item label={t('TIP')}>
                    {delivery.tips}€
                  </Item>
                </Descriptions>
                <Row justify='center' >
                  <Link to={`/delivery/${delivery.id}`}>
                    <Button type='primary'>{t('DELIVERY_DETAILS')}</Button>
                  </Link>
                </Row>
              </Panel>,
            )}
          </Collapse>
        </SkeletonMobile>
        {count !== 0 ?
          <Pagination
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '20px',
            }}
            simple
            pageSize={5}
            defaultCurrent={1}
            current={currentPage}
            total={count}
            onChange={(page) => {
              setCurrentPage(page);
            }}
          /> :
          null}
      </Card>
    </>
  );
}

export default ShopperDeliveriesListMobile;
