import { Collapse, Descriptions, Flex } from 'antd';
import DeliveryStatusListItem from '../../DeliveryListItems/DeliveryStatusListItem';
import { useTranslation } from 'react-i18next';
import DeliveryTradeItemColumn from '../../DeliveryListItems/DeliveryTradeItemColumn';
import DeliveryTimeSlotRow from '../../DeliveryListItems/DeliveryTimeSlotRow';
import DeliveryDistanceItemColumn from '../../DeliveryListItems/DeliveryDistanceItemColumn';
import DeliveryTipItemColumn from '../../DeliveryListItems/DeliveryTipItemColumn';
import SkeletonMobile from '../../../SkeletonLoaders/SkeletonMobile';
import DeliveryListLink from '../../DeliveryListItems/DeliveryListLink';
import { useState } from 'react';
import DeliveryIncidentIconListItem from '../../DeliveryListItems/DeliveryIncidentIconListItem';

const { Panel } = Collapse;
const { Item } = Descriptions;

/**
 * Returns a JSX element representing the mobile on going list.
 *
 * @return {JSX.Element} - The mobile on going list JSX element.
 */
function MobileOnGoingList({ deliveries, loading, tableParams }) {
  const { t } = useTranslation();
  const [activeKey, setActiveKey] = useState(0);

  return (
    <SkeletonMobile
      loading={loading}
      emptyState={tableParams.pagination.total === 0}
    >
      <Collapse accordion activeKey={activeKey} onChange={(key) => setActiveKey(Number(key))}>
        {deliveries?.map((delivery, index) => {
          return (
            <Panel
              key={index}
              header={`${delivery?.client?.first_name} ${delivery?.client?.last_name}`}
              extra={(
                <Flex gap="small">
                  <DeliveryIncidentIconListItem incident={delivery?.incident} />
                  <DeliveryStatusListItem status={delivery.status} />
                </Flex>
              )}
            >
              <Flex vertical gap="middle">
                <Descriptions
                  size='small'
                  bordered
                  labelStyle={{ fontWeight: 600, color: '#1B3F92', padding: 8, fontSize: 12 }}
                >
                  <Item label={t('DELIVERY_TABLE_TRADE')}>
                    <DeliveryTradeItemColumn trade={delivery.trade} />
                  </Item>
                  <Item label={t('DELIVERY_TABLE_DATE_AND_HOUR')}>
                    <DeliveryTimeSlotRow timeSlot={delivery.time_slot} />
                  </Item>
                  {
                    (delivery?.shopper?.first_name && delivery?.shopper?.last_name) &&
                    <Item label={t('DELIVERY_TABLE_SHOPPER')}>
                      {`${delivery?.shopper?.first_name} ${delivery?.shopper?.last_name}`}
                    </Item>
                  }
                  <Item label={t('DELIVERY_TABLE_DISTANCE')}>
                    <DeliveryDistanceItemColumn distance={delivery.distance} />
                  </Item>
                  <Item label={t('DELIVERY_TABLE_TIPS')}>
                    <DeliveryTipItemColumn tip={delivery.tips} />
                  </Item>
                </Descriptions>
                <DeliveryListLink
                  deliveryId={delivery.id}
                  deliveryType={delivery.delivery_type}
                />
              </Flex>
            </Panel>
          );
        })}
      </Collapse>
    </SkeletonMobile>
  );
}

export default MobileOnGoingList;
