/** @jsxImportSource @emotion/react */
import React from 'react';
import { Card } from 'antd';
import { colors } from '../../../../globalVar/theme';

/**
 * InternalCommentBOV1HistoryNode
 * @param {Object} props.internalComment
 * @return {JSX.Element}
*/
function InternalCommentBOV1HistoryNode({ internalComment }) {
  return (
    <Card
      size="small"
      bodyStyle={{
        margin: '5px 10px !important',
        border: `1px solid ${colors.blueMiddle}`,
        borderTop: `1px solid ${colors.blueMiddle}`,
        borderRadius: '5px 5px 5px 5px',
      }}>
      { internalComment?.map((comment) => {
        return (
          <p css={{
            margin: '2px 2px 2px 2px !important',
          }}
          key={comment.id}>{comment.text}</p>
        );
      })}
    </Card>
  );
}

export default InternalCommentBOV1HistoryNode;
