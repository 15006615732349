import { Select, Tag } from 'antd';
import { findStatusDeliveries } from '../../../utils/findStatusDeliveries';
import { ON_GOING_STATUS_DELIVERIES } from '../../../globalVar/statusDeliveries';
import { useTranslation } from 'react-i18next';

/**
 * Renders a select input component for filtering delivery status.
 *
 * @param {Object} tableParams - The table parameters object.
 * @param {Function} handleTableChanges - The callback function for handling table changes.
 * @return {JSX.Element} - The select input component.
 */
function DeliveryStatusSelectInput({ tableParams, handleTableChanges, style }) {
  const { t } = useTranslation();
  const arrayStatus = ON_GOING_STATUS_DELIVERIES.filter((item)=> item !== '');

  return (
    <Select
      id='deliveries-select-status-filter'
      onSelect={(value) => {
        handleTableChanges({ ...tableParams, filters: { ...tableParams.filters, status: [value] }});
      }}
      placeholder={t('FILTER_BY_STATUS')}
      defaultValue={tableParams.filters?.status}
      options={arrayStatus.map((status) => {
        return ({
          value: status,
          label:
            <Tag style={findStatusDeliveries(status).style}>
              {t(findStatusDeliveries(status).message)}
            </Tag>,
        });
      })}
      style={style}
    />
  );
}

export default DeliveryStatusSelectInput;
