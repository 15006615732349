import { useState, useEffect, useContext, useCallback } from 'react';
import { Form, Alert, Flex } from 'antd';
import { getDeliveryIncidentWarnMessage } from '../../utils/deliveryIncidents/getDeliveryIncidentWarnMessage';
import DrawForm from '../../middlewares/DrawForm';
import initialFormItems from './initialFormItems.json';
import incidentDetailsFormItems from './incidentDetailsFormItems.json';
import { useTranslation } from 'react-i18next';
import useCustomHtml from '../../hooks/useCustomHtml';
import { AppContext } from '../../context/AppContext';
import { writeIncident } from '../../services/apiEndPoints/delivery/incident';
import { useRouter } from '../../hooks/useRouter';
import { NotificationCenterContext } from '../../context/NotificationCenterContext';
import SubmitButton from '../Buttons/SubmitButton';

/**
 * DeliveryIncidentForm component
 * @param {Object} incidentFormRef
 * @param {function} setIncident
 * @param {function} updateIncidentsHistory
 * @param {Object} actions
 * @param {Object} infos
 * @return {JSX.Element}
 */
function DeliveryIncidentForm({ incidentFormRef, setIncident, updateIncidentsHistory, actions, infos }) {
  const { t } = useTranslation();
  const [store] = useContext(AppContext);
  const router = useRouter();
  const notification = useContext(NotificationCenterContext);
  const [incidentFormInstance] = Form.useForm();
  const category = Form.useWatch('category', incidentFormInstance);
  const ctpReason = Form.useWatch('COTRANSPORTER_PROBLEM', incidentFormInstance);
  const isDeliveryClosed = Form.useWatch('delivery-finish', incidentFormInstance);
  const deliveryFinishSwitch = Form.useWatch('delivery-finish', incidentFormInstance);
  const [warnMessage, setWarnMessage] = useState();
  const [formItems, setFormItems] = useState(initialFormItems);
  const formattedWarnMessage = useCustomHtml({
    inputString: t(warnMessage),
  });

  useEffect(() => {
    if (category) {
      setWarnMessage(getDeliveryIncidentWarnMessage({
        category,
        isDeliveryClosed,
      }));
    }
  }, [category, isDeliveryClosed]);

  useEffect(() => {
    if (category) {
      const fields = incidentDetailsFormItems[category].map((item) => item.id);
      incidentFormInstance.resetFields(fields);
      setFormItems([
        ...initialFormItems,
        ...incidentDetailsFormItems[category] || [],
      ]);
    }
  }, [category]);

  useEffect(() => {
    if (ctpReason === 'THEFT') {
      incidentFormInstance.setFieldsValue({
        'delivery-finish': 1,
      });
    }
  }, [ctpReason, deliveryFinishSwitch]);

  useEffect(() => {
    if (ctpReason !== 'THEFT') {
      incidentFormInstance.setFieldsValue({
        'delivery-finish': 0,
      });
    }
  }, [ctpReason]);

  const declareIncident = (formattedValues) => {
    writeIncident({
      ...formattedValues,
      callerId: store.user.id,
      deliveryId: router.query.id,
    }).then((response) => {
      if (response.data) setIncident(response.data);
      updateIncidentsHistory();
      notification.push(response.type, t(response.message));
    });
  };

  const handleCotransporterProblemChange = useCallback((value) => {
    const descriptionField = formItems.find((item) => item.name === 'description');
    if (descriptionField) {
      if (value === 'OTHER') {
        descriptionField.rules = [
          {
            required: true,
            whitespace: true,
            message: t('DESCRIPTION_REQUIRED'),
          },
        ];
      } else {
        descriptionField.rules = descriptionField.rules.filter((rule) => rule.required === false);
      }
    }
  });

  return (
    <>
      <DrawForm
        form={incidentFormInstance}
        ref={incidentFormRef}
        formName='incident-form'
        onFinish={(values) => {
          let formattedValues = {
            incidentTypes: values.category,
            incidentReasons: values[values.category],
            deliveryClosedRequested: false,
          };

          if (values.category === 'ADDITIONAL_JOURNEY') {
            formattedValues = {
              ...formattedValues,
              kilometerRange: values['kilometer-range'],
            };
          }

          if (values['delivery-finish']) {
            formattedValues = {
              ...formattedValues,
              deliveryClosedRequested: true,
            };
          }

          if (values.description?.length > 0) {
            formattedValues = {
              ...formattedValues,
              comment: values.description,
            };
          }

          declareIncident(formattedValues);
        }}
        formItems={formItems}
        handleCotransporterProblemChange={handleCotransporterProblemChange}
      />
      <Flex gap="large" vertical justify="flex-start">
        {warnMessage && (
          <Alert
            type="warning"
            message={formattedWarnMessage}
            showIcon
          />
        )}
        <SubmitButton
          form={incidentFormInstance}
          onClick={() => {
            setTimeout(() => {
              incidentFormRef.current.submit();
            }, 200);
            actions.setOpen(false);
          }}
          popconfirm={{
            title: useCustomHtml({
              keyValues: [
                { key: '[DRIVE_NAME]', value: infos.driveName },
              ],
              inputString: t('CONFIRM_INCIDENT'),
            }),
            okText: t('YES'),
            cancelText: t('NO'),
          }}
        >
          {t('DECLARE_THE_INCIDENT')}
        </SubmitButton>
      </Flex>
    </>
  );
}

export default DeliveryIncidentForm;
