import { createContext } from 'react';

const DrawersContext = createContext({
  visible: false,
  setVisible: () => {},
  childVisible: false,
  setChildVisible: () => {},
  currentDrawer: null,
  setCurrentDrawer: () => {},
});

export default DrawersContext;
