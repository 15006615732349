
/**
 * @param {number} idSate from table errand_status
 * @return {object} is email is good format or no
 */
export function findStatusDeliveries(idSate) {
  switch (idSate) {
  // supprimé => orange
  // case 0:
  //   return {color: 'null', message: 'DELIVERY_status_ALL'};
  case 1:
    return { style: { color: '#EB2F96', background: '#FFF0F6', borderColor: '#F58FC6' }, message: 'DELIVERY_STATUS_1' };
  case 2:
    return { style: { color: '#0889C0', background: '#E6F9FF', borderColor: '#79D6EB' }, message: 'DELIVERY_STATUS_2' };
  case 3:
    return { style: { color: '#FF9900', background: '#FFFBE6', borderColor: '#FFCF78' }, message: 'DELIVERY_STATUS_3' };
  case 4:
    return { style: { color: '#5556FE', background: '#F1EEFF', borderColor: '#8696F8' }, message: 'DELIVERY_STATUS_4' };
  case 5:
    return { style: { color: '#0F4BD4', background: '#D2EAFF', borderColor: '#81A1E0' }, message: 'DELIVERY_STATUS_5' };
  case 6:
    return { style: { color: '#16AC5D', background: '#F1FFE3', borderColor: '#96D9AB' }, message: 'DELIVERY_STATUS_6' };
  case 7:
    return { style: { color: '#DF4C4C', background: '#FFE4DF', borderColor: '#EF9895' }, message: 'DELIVERY_STATUS_7' };
  case 8:
    return { style: { color: '#16AC5D', background: '#F1FFE3', borderColor: '#96D9AB' }, message: 'DELIVERY_STATUS_8' };
  case '':
    return { message: 'ALL_DELIVERIES_STATUTS' };
  default:
    return { color: 'purple', message: 'DELIVERY_STATUS_UNKNOW' };
  }
}
