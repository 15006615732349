/**
 *
 * @param {date} string
 * @param {status} number
 *
 * Si la livraison est dans 2h ou moins (voir même dans le passé)
 * Et que le status est en cours ou moins (status 3)
 *
 * @return {boolean}
 */
export function isUrgentDelivery({ date, status }) {
  const TWO_HOUR = 60 * 60 * 2000;
  return (status <= 3 && ((new Date(date).getTime() - new Date().getTime()) <= TWO_HOUR));
}
