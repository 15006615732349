import { Card, Col, Divider, Row, Skeleton, Typography } from 'antd';
import React, { ReactNode } from 'react';
import { StructuredInfoCard, TitleCard } from '../..';

const { Text } = Typography;

export type LayoutType = 'all-half' | 'first-full';

interface Item {
  label: string;
  value: string | ReactNode;
  fullRow: boolean;
}

interface Section {
  layout: LayoutType;
  label?: string;
  items: Item[];
}

interface DetailProps {
  title: string;
  secondaryTitle: string;
  loading: boolean;
  sections: Section[];
}

function DetailWithDividerCard({ title, secondaryTitle, loading, sections }: Readonly<DetailProps>) {
  const getSpansValue = (layout: LayoutType, fullRow: boolean) => {
    if (layout === 'all-half') {
      return 24;
    }
    return fullRow === false ? 12 : 24;
  };

  const renderItems = (items: Item[], layout: LayoutType) => {
    return items.map(({ label, value, fullRow = false }, itemIndex) => {
      return (
        <Col xs={24} sm={getSpansValue(layout, fullRow)} md={fullRow === false ? 12 : 24} key={itemIndex}>
          <StructuredInfoCard label={`${label} : `} value={value || '-'} direction="vertical" />
        </Col>
      );
    });
  };

  return (
    <Card title={<TitleCard principalWording={title} secondaryWording={secondaryTitle} />}>
      {loading ? (
        <Skeleton active />
      ) : (
        sections.map((section, index) => {
          return (
            <React.Fragment key={index}>
              <Row gutter={[0, 20]}>
                {section.label && (
                  <Col span={24}>
                    <Text strong>{section.label} : </Text>
                  </Col>
                )}
                {renderItems(section.items, section.layout)}
              </Row>
              {index < sections.length - 1 && <Divider />}
            </React.Fragment>
          );
        })
      )}
    </Card>
  );
}

export default DetailWithDividerCard;
