import React from 'react';
import { Button, Result, Typography } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

/**
 * Error504
 * @return {JSX.Element}
 */
export default function Error504() {
  const { t } = useTranslation();

  return (
    <Result
      icon={<img src={process.env.PUBLIC_URL + '/assets/504.svg'} width='120px' alt='BO_504' />}
      subTitle={
        <div>
          <Text>{t('TIME_OUT')}</Text>
          <br />
          <Text>{t('INTERNAL_ERROR_UPDATE_PAGE')}</Text>
        </div>
      }
      extra={
        <Button type='primary' icon={<ReloadOutlined />} onClick={() => window.location.reload()}>
          {t('REFRESH')}
        </Button>
      }
    />
  );
}
