import { getThrowErrorMessage } from '../../messages';
import { apiEndPointsOptions } from '../apiEndPointsOptions';

/**
 * Update a user.
 * @param {object} user
 * @param {number} id
 * @param {string} firstName
 * @param {string} lastName
 * @return {object}
 */
export const updateUserName = async({ id, firstName, lastName }) => {
  const apiUrl = `${process.env.REACT_APP_API_BO}/users/${id}`;
  const method = 'PUT';
  const body = {
    first_name: firstName,
    last_name: lastName,
  };

  try {
    const response = await fetch(
      apiUrl,
      await apiEndPointsOptions(method, body)
    );

    if (response.status === 200) {
      return {
        type: 'success',
        data: body,
        message: 'CHANGE_USER_NAME_AND_FIRSTNAME',
      };
    }

    // Actually can return an error 403 or 404
    const result = await response.json();
    return {
      type: 'error',
      message: result.errors[0].message,
    };
  } catch (error) {
    throw new Error(`An error occurred while fetching the API: ${error}`);
  }
};

export const updateUserMail = async({ id, email }) => {
  const apiUrl = `${process.env.REACT_APP_API_BO}/users/${id}/email`;
  const method = 'PUT';
  const body = {
    email,
  };

  try {
    const response = await fetch(
      apiUrl,
      await apiEndPointsOptions(method, body)
    );

    if (response.status === 200) {
      return {
        type: 'success',
        message: 'CHANGE_USER_MAIL',
        data: body,
      };
    }

    if (response.status === 404) {
      return {
        type: 'success',
        message: 'USER_NOT_FOUND',
      };
    }

    if (response.status === 409) {
      return {
        type: 'error',
        message: 'EMAIL_ALREADY_USED',
      };
    }
  } catch (error) {
    throw new Error(getThrowErrorMessage({ functionName: 'updateUserMail', error }));
  }
};

export const updateUserTelephone = async({ id, telephone }) => {
  const apiUrl = `${process.env.REACT_APP_API_BO}/users/${id}/telephone`;
  const method = 'PUT';
  const body = {
    telephone,
  };

  try {
    const response = await fetch(
      apiUrl,
      await apiEndPointsOptions(method, body)
    );

    if (response.status === 200) {
      return {
        type: 'success',
        message: 'CHANGE_USER_PHONE',
        data: body,
      };
    }

    if (response.status === 400) {
      return {
        type: 'error',
        message: 'BAD_REQUEST',
      };
    }

    if (response.status === 404) {
      return {
        type: 'error',
        message: 'USER_NOT_FOUND',
      };
    }
  } catch (error) {
    throw new Error(getThrowErrorMessage({ functionName: 'updateUserTelephone', error }));
  }
};
