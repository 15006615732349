/**
 * parseInternalCommentBoV1
 * @param {Objet} comment
 * @return {Array}
 */
function parseInternalCommentBoV1(comment) {
  if (comment) {
    const devHour = '2023-08-27T08:37:03+02:00';
    const commentLines = comment.split('\n');
    const finalObject = {
      createdAt: devHour,
      deactivationType: 'INTERNAL_COMMENT',
      internalComment: commentLines.map((line, index) => ({ id: index + 1, text: line })),
    };
    return [finalObject];
  }
  return [];
}
export default parseInternalCommentBoV1;
