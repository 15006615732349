import { apiEndPointsOptions } from './apiEndPointsOptions';

/**
 * Fetch data.
 * @param {string} apiUrl
 * @param {string} method
 * @param {object} signal
 * @param {object | undefined} data
 * @return {object}
 */
async function fetchData({ apiUrl, method, signal, data }) {
  const options = await apiEndPointsOptions(method, data, signal);
  const response = await fetch(apiUrl, options);

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return {
    data: response.status !== 204 ? await response.json() : null,
    status: response.status,
    ok: response.ok,
  };
}

export default fetchData;
