import dayjs from 'dayjs';
/**
 * getHourRoundByQuarter
 * @param {date} date dayjs object
 * @return {string}
 */
export function getHourRoundByQuarter(date) {
  let newHour;
  if (date.minute() > 0 && date.minute() < 15) {
    newHour = dayjs(date).set('minute', 15);
  } else if (date.minute() > 15 && date.minute() < 30) {
    newHour = dayjs(date).set('minute', 30);
  } else if (date.minute() > 30 && date.minute() < 45) {
    newHour = dayjs(date).set('minute', 45);
  } else if (date.minute() > 45 && date.minute() < 60) {
    newHour = dayjs(date).set('minute', 0).add(1, 'hour');
  } else {
    newHour = dayjs(date);
  }
  return newHour;
}
