import { Layout } from 'antd';
import { ReactNode } from 'react';

const { Footer } = Layout;

interface BottomFooterProps {
  textColor: string;
  version: string;
}

/**
 * The BottomFooter component.
 */
function BottomFooter({ textColor, version }: BottomFooterProps): ReactNode {
  return (
    <Footer
      style={{
        textAlign: 'center',
        color: textColor,
        marginTop: 'auto',
      }}
    >
      v{version}
    </Footer>
  );
}

export default BottomFooter;
