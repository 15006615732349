/** @jsxImportSource @emotion/react */
import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Form, Button } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { isEmail } from '../../../utils/isEmail.js';
import { NotificationCenterContext } from '../../../context/NotificationCenterContext.js';
import { iconInput } from '../../../globalVar/theme.js';
import { updateUserMail } from '../../../services/apiEndPoints/user/updateUser.js';

/**
 * @name InputMailUser
 * @param {function} setUser
 * @param {object} user
 * @return {JSX.Element}
 */
function InputMailUser({ user, setUser }) {
  const { id } = useParams();
  const { t } = useTranslation();
  const [formMail] = Form.useForm();
  const watchMailValue = Form.useWatch('email', formMail);
  const notification = useContext(NotificationCenterContext);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [updateMailLoading, setUpdateMailLoading] = useState(false);

  useEffect(() => {
    setSubmitDisabled(watchMailValue === user?.email);
  }, [user, watchMailValue]);

  const changeUserMail = (values) => {
    setUpdateMailLoading(true);
    updateUserMail({
      id,
      email: values.email,
    }).then((response) => {
      setUpdateMailLoading(false);
      if (response.data) setUser({ ...user, ...response.data });
      notification.push(response.type, t(response.message));
    });
  };

  useEffect(() => {
    formMail.setFieldsValue({ email: user?.email });
  }, [user]);

  return (
    <Form
      form={formMail}
      layout="vertical"
      initialValues={{
        email: user?.email,
      }}
      onFinish={changeUserMail}
      css={{ marginTop: 20 }}
    >
      <Form.Item
        name="email"
        label={t('EMAIL')}
        rules={[{
          type: 'email',
          required: true,
          validator(_, value) {
            if (!isEmail(value) && value?.length > 0) {
              return Promise.reject(new Error(t('WRONG_FORMAT_EMAIL')));
            }
            if (value === '') {
              return Promise.reject(new Error(t('EMAIL_REQUIRED')));
            }
            return Promise.resolve();
          },
        }]}
      >
        <Input
          id="user-general-information-email"
          name="email"
          autoComplete="off"
          addonAfter={
            <Button
              htmlType="submit"
              loading={updateMailLoading}
              disabled={submitDisabled}
              icon={<CheckOutlined/>}
              css={iconInput}
            />
          }
        />
      </Form.Item>
    </Form>
  );
}

export default InputMailUser;

