import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import findColorTagsComment from '../../../../utils/findColorTagsComment';

/**
 * Renders a NotationTag component based on the provided value.
 *
 * @param {number} value - The value to evaluate.
 * @param {string} goodTagText - The text to display if the value is 1.
 * @param {string} badTagText - The text to display if the value is not 1.
 * @return {JSX.Element|null} - The NotationTag component.
 */
function NotationTag({ value, goodTagText, badTagText }) {
  const { t } = useTranslation();

  if (value === null || value === undefined || typeof value !== 'number') return null;

  return (
    <Tag style={findColorTagsComment(value)}>
      {value === 1 ? t(goodTagText) : t(badTagText)}
    </Tag>
  );
}

export default NotationTag;
