/** @jsxImportSource @emotion/react */
/* eslint-disable no-console */
import { useContext } from 'react';
import {
  Button,
  Pagination,
  Space,
  Steps,
  Input,
  Switch,
  Slider,
  Result,
  Alert,
  Typography, Tabs,
} from 'antd';
import { AudioOutlined, CloseCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import { NotificationCenterContext } from '../../context/NotificationCenterContext';
import InfoMessage from '../Alert/InfoMessage';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DeliveryIncidentAlert from '../DeliveryIncident/DeliveryIncidentAlert';

const { Title, Paragraph, Text, Link } = Typography;
const { Search } = Input;
const { Step } = Steps;

/**
 * Example component
 * @return {string} Example.
 */
function Example() {
  const { t } = useTranslation();
  const notification = useContext(NotificationCenterContext);
  const navigate = useNavigate();

  const INCIDENT_MOCK = {
    incidentCreationDate: '2023-11-30T16:46:53.000Z',
    incidentType: 'PICKUP_RETURN',
    incidentReasonValue: 'ORDER_PROBLEM',
  };

  return (
    <Space direction="vertical" css={{ maxWidth: 920, margin: '0 auto' }}>
      <Title level={1}>Example</Title>

      <Tabs
        items={[
          {
            key: '0',
            label: 'Tab 1',
            children: <div>Content tab 1</div>,
          },
          {
            key: '1',
            label: 'Tab 2',
            children: <div>Content tab 2</div>,
          },
        ]}
      />

      <Tabs
        type="card"
        items={[
          {
            key: '0',
            label: 'Tab 1',
            children: <div>Content tab 1</div>,
          },
          {
            key: '1',
            label: 'Tab 2',
            children: <div>Content tab 2</div>,
          },
        ]}
      />

      <DeliveryIncidentAlert
        incidentCreationDate={INCIDENT_MOCK.incidentCreationDate}
        incidentType={INCIDENT_MOCK.incidentType}
        incidentReasonValue={INCIDENT_MOCK.incidentReasonValue}
      />

      <Title level={2}>Button with icons</Title>
      <Button
        type='primary'
        shape='circle'
        icon={<ReloadOutlined/>}
      />
      <Button
        type='primary'
        icon={<ReloadOutlined/>}
      >
        Reload
      </Button>

      <Title level={2}>Notifications</Title>
      <Button onClick={() => notification.push('success', <>This is a message to <Link>test notifications</Link>.</>)}>Test success notifications.</Button>
      <Button onClick={() => notification.push('error', <>This is a message to <Link>test notifications</Link>.</>)}>Test error notifications.</Button>
      <Button type='primary' onClick={() => notification.push('success',
        'Livraison créé',
        <Typography.Link onClick={()=>{
          navigate(`delivery/256751`);
          notification.closeNotification();
        }}>
          {t('VIEW_DELIVERY')} 256751
        </Typography.Link>)}
      >
        Test destroy notifications
      </Button>

      <Title level={2}>Basics</Title>
      <Space>
        <InfoMessage alertMessageText={'Hello world!'} />
      </Space>
      <Space>
        DEFAULT :
        <Button>
          Button
        </Button>
        <Button disabled>
          Disabled
        </Button>
        <Button type="dashed">Dashed Button</Button>
        <Button type="text">Text Button</Button>
        <Button type="link">Link Button</Button>
      </Space>
      <Space>
        PRIMARY :
        <Button type="primary">
          Primary
        </Button>
        <Button type="primary" disabled>
          Primary Disabled
        </Button>
        <Button type="dashed" primary="true">Dashed Button Primary</Button>
        <Button type="text" primary="true">Text Button Primary</Button>
        <Button type="link" primary="true">Link Button Primary</Button>
      </Space>
      <Space>
        DANGER :
        <Button danger type="primary">
          Danger
        </Button>
        <Button danger type="primary" disabled>
          Danger Disabled
        </Button>
        <Button type="dashed" danger>Dashed Button Danger</Button>
        <Button type="text" danger>Text Button Danger</Button>
        <Button type="link" danger>Link Button Danger</Button>
      </Space>
      <Pagination defaultCurrent={1} total={50} />
      <Steps current={1}>
        <Step title="Finished" description="This is a description." />
        <Step title="In Progress" description="This is a description." />
        <Step title="Waiting" description="This is a description." />
      </Steps>
      <Search placeholder="input search text" onSearch={(value) => console.log(value)} enterButton />
      <Search
        placeholder="input search text"
        allowClear
        enterButton="Search"
        onSearch={(value) => console.log(value)}
      />
      <Search
        placeholder="input search text"
        enterButton="Search"
        suffix={<AudioOutlined
          style={{
            fontSize: 16,
            color: '#1890ff',
          }}
        />}
        onSearch={(value) => console.log(value)}
      />
      <Switch defaultChecked onChange={(checked) => console.log(`switch to ${checked}`)} />
      <Slider defaultValue={30} onChange={(checked) => console.log(`switch to ${checked}`)} onAfterChange={(onAfterChange) => console.log(`switch to ${onAfterChange}`)} />
      <Slider
        range
        step={10}
        defaultValue={[20, 50]}
        onChange={(checked) => console.log(`switch to ${checked}`)}
        onAfterChange={(onAfterChange) => console.log(`switch to ${onAfterChange}`)}
      />
      <Result
        status="success"
        title="Successfully Purchased Cloud Server ECS!"
        subTitle="Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait."
        extra={[
          <Button type="primary" key="console">
            Go Console
          </Button>,
          <Button key="buy">Buy Again</Button>,
        ]}
      />
      <Alert message="Success Tips" type="success" showIcon />
      <Alert message="Informational Notes" type="info" showIcon />
      <Alert message="Warning" type="warning" showIcon closable />
      <Alert message="Error" type="error" showIcon />
      <Alert
        message="Success Tips"
        description="Detailed description and advice about successful copywriting."
        type="success"
        showIcon
      />
      <Alert
        message="Informational Notes"
        description="Additional description and information about copywriting."
        type="info"
        showIcon
      />
      <Alert
        message="Warning"
        description="This is a warning notice about copywriting."
        type="warning"
        showIcon
        closable
      />
      <Alert
        message="Error"
        description="This is an error message about copywriting."
        type="error"
        showIcon
      />
      <Result
        status="warning"
        title="There are some problems with your operation."
        extra={
          <Button type="primary" key="console">
            Go Console
          </Button>
        }
      />
      <Result
        status="error"
        title="Submission Failed"
        subTitle="Please check and modify the following information before resubmitting."
        extra={[
          <Button type="primary" key="console">
            Go Console
          </Button>,
          <Button key="buy">Buy Again</Button>,
        ]}
      >
        <div>
          <Paragraph>
            <Text
              strong
              style={{
                fontSize: 16,
              }}
            >
              The content you submitted has the following error:
            </Text>
          </Paragraph>
          <Paragraph>
            <CloseCircleOutlined style={{ color: 'red' }} /> Your account has been
            frozen. <a href="/">Thaw immediately</a>
          </Paragraph>
          <Paragraph>
            <CloseCircleOutlined style={{ color: 'red' }} /> Your account is not yet
            eligible to apply. <a href="/">Apply Unlock</a>
          </Paragraph>
        </div>
      </Result>
    </Space>
  );
}

export default Example;

