import { sizes } from '../../../globalVar/theme';
import { useWindowSize } from '../../../hooks/useWindowSize';

/**
 * Wraps the delivery list components with responsive behavior,
 * rendering different components based on the window size.
 *
 * @param {JSX.Element} desktopChildren - The components to render for desktop view.
 * @param {JSX.Element} mobileChildren - The components to render for mobile view.
 *
 * @return {JSX.Element} - The components to render based on the window size.
 */
function DeliveryListResponsiveWrapper({ desktopChildren, mobileChildren }) {
  const windowSize = useWindowSize();

  return windowSize.width > sizes.sm ? desktopChildren : mobileChildren;
}

export default DeliveryListResponsiveWrapper;
