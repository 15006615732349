import { theme, Badge } from 'antd';

/**
 * PrimaryBadge
 * @param {number} number
 * @return {React.JSXElement}
 */
function PrimaryBadge({ number }) {
  const { token } = theme.useToken();

  return number ? (
    <Badge
      showZero={true}
      count={number}
      overflowCount={9999999}
      color={token.colorPrimary}
    />
  ) : null;
}

export default PrimaryBadge;
