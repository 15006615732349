import { initialUser } from './userStore';

/**
 * @param {Object} state
 * @param {Object} action
 * @return {Object}
 */
export default function userReducer(state, action = { type: '', payload: {}}) {
  const clientId = process.env.REACT_APP_KEYCLOAK_CLIENT_ID;
  switch (action.type) {
  case 'LOGOUT':
    if (state?.user?.expires) clearTimeout(state.user.expires);
    return {
      ...state,
      user: initialUser,
    };
  case 'LOGIN':
    return { ...state,
      user: {
        ...state.user,
        id: Number(action.payload.legacy_id),
        email: action.payload.email,
        first_name: action.payload.given_name,
        last_name: action.payload.family_name,
        roles: action.payload.resource_access[clientId]?.roles,
        logged: true,
      },
    };
  default:
    return state;
  }
}
