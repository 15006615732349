/**
 * weblateKeyKyc
 * @param {array} arrayofKyc
 * @param {string} type
 * @param {string} status send by APIS : 'NOT SENT', 'PENDING', 'REFUSED', 'VALIDATED'
 * @return {object}
 */
export function weblateDefinedKeyKyc(arrayofKyc, type, status) {
  if (type === 'iban') {
    if (status === 'VALIDATED') {
      return 'KYC_IBAN_VALIDATED';
    }
    return 'KYC_IBAN_NOT_COMPLETED';
  }
  if (type === 'phone_verified') {
    if (status === 'VALIDATED') {
      return 'KYC_PHONE_VERIFIED';
    }
    return 'KYC_PHONE_UNVERIFIED';
  }
  if (type === 'shopper_policy') {
    if (status === 'VALIDATED') {
      return 'KYC_POLICY_SIGNED';
    }
    return 'KYC_POLICY_NOT_SIGNED';
  }
  if (type === 'national_id') {
    if (status === 'VALIDATED') {
      return 'KYC_NATIONAL_VALIDATED';
    }
    return 'KYC_NATIONAL_NOT_COMPLETED';
  }
  if (type === 'identity_card') {
    const statusOnfido = arrayofKyc.find((doc) => doc.type === 'id_card_shopopop');
    const statusMango = arrayofKyc.find((doc) => doc.type === 'id_card_mango');
    return statusOnfido?.status === 'VALIDATED' ? `KYC_MANGO_${statusMango.status}` : `KYC_CARD_${statusOnfido.status}`;
  }
}
