/* eslint-disable require-jsdoc */
/** @jsxImportSource @emotion/react */
import React from 'react';
import Error504 from './Error504';

/**
 * ErrorBoundary
 * @return {JSX.Element}
 */
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.error({ error, info });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div css= {{
          marginTop: '50vh',
          transform: 'translateY(-50%)',
        }}>
          <Error504 />
        </div>);
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
