import React from 'react';
import { Result, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;


/**
 * Error500
 * @return {JSX.Element}
 */
export default function Error500() {
  const { t } = useTranslation();

  return (
    <Result
      icon={<img src={process.env.PUBLIC_URL + '/assets/500.svg'} width='120px' alt='BO_500' />}
      subTitle={
        <div>
          <Text>{t('SERVE_ERROR')}</Text>
          <Text>{t('INTERNAL_ERROR_UPDATE_PAGE')}</Text>
        </div>
      }
    />
  );
}
