import { useState } from 'react';
import { Button } from 'antd';
import { PlusSquareOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { sizes } from '../../../globalVar/theme';
import AddressDrawer from '../Drawer/AddressDrawer';

/**
 * OpenCreateAddressDrawer
 * @param {Object} props - The props of the component.
 * @param {number} props.userId - The user id.
 * @param {Function} props.setAddresses - The function to update the addresses.
 * @param {Function} props.setIndexValueSelected - The function to update the index value selected.
 * @return {JSX.Element}
 */
function OpenCreateAddressDrawer({ userId, setAddresses, setIndexValueSelected }) {
  const { t } = useTranslation();
  const windowSize = useWindowSize();
  const [opened, setOpened] = useState(false);

  return (
    <>
      {
        windowSize.width > sizes.sm ?
          <Button icon={<PlusSquareOutlined/>} onClick={() => setOpened(true)}>
            {t('ADD_A_NEW_ADDRESS')}
          </Button> :
          <Button icon={<PlusSquareOutlined/>} onClick={() => setOpened(true)}/>
      }
      <AddressDrawer
        action="create"
        opened={opened}
        setAddresses={setAddresses}
        setDrawerOpened={setOpened}
        userId={userId}
        setIndexValueSelected={setIndexValueSelected}
      />
    </>
  );
}

export default OpenCreateAddressDrawer;
