
/**
 * Format the complementary address to the API format.
 * @param {Object} values - The values to format.
 * @return {Object} Return an object.
 */
function formatComplementaryAddressToApi(values) {
  return {
    floor: values.floor === null ? undefined : values.floor,
    apartment: values.apartment === null ? undefined : values.apartment,
    elevator: values.elevator === null ? undefined : values.elevator,
    digicode: values.digicode === null ? undefined : values.digicode,
    additional_info: values.additional_info === null ? '' : values.additional_info,
  };
}

export default formatComplementaryAddressToApi;
