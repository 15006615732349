import { React } from 'react';
import { Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import TouchableView from '../DeviceDetect/TouchableView';
import UntouchableView from '../DeviceDetect/UntouchableView';

/**
 * Profile
 * @return {JSX.Element}
 */
function Profile() {
  const { t } = useTranslation();
  return (
    <>
      <UntouchableView >
        <Button
          icon={<UserOutlined />}
          id='button-archivage-desktop'
        >
          {t('VIEW_PROFILE')}
        </Button>
      </UntouchableView>
      <TouchableView>
        <Button
          icon={<UserOutlined />}
          id='button-archivage-mobile'
        />
      </TouchableView>
    </>
  );
}

export default Profile;
