/** @jsxImportSource @emotion/react */
import { theme, Table } from 'antd';
import SkeletonTableLoader from '../../../SkeletonLoaders/SkeletonTableLoader';

/**
 * DesktopInactiveList - Returns a JSX element representing the desktop inactive list.
 *
 * @return {JSX.Element} The desktop inactive list element.
 */
function DesktopInactiveList({ columns, deliveries, loading, tableParams, handleTableChanges }) {
  const { token } = theme.useToken();
  return (
    <SkeletonTableLoader
      active={true}
      loading={loading}
      columns={columns}
      rowCount={tableParams.pagination.pageSize}
    >
      <Table
        rowKey="id"
        columns={columns}
        rowClassName={(_, index) => (!deliveries[index]?.validated && deliveries[index]?.status !== 7) ? 'validated' : ''}
        dataSource={deliveries}
        pagination={tableParams.pagination}
        onChange={(pagination, filters, sorter) => {
          handleTableChanges({ label: 'inactive', pagination, filters, sorter });
        }}
        css={{
          '.validated': {
            'td:first-of-type': {
              borderLeft: `3px solid ${token.colorWarningBorder}`,
            }}}}
      />
    </SkeletonTableLoader>
  );
}

export default DesktopInactiveList;
