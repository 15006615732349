/** @jsxImportSource @emotion/react */
import { theme, Col, ConfigProvider, Table, Typography, Space } from 'antd';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { customizeRenderEmpty } from '../../../../services/customizeRenderEmpty';
import { StepContext } from '../../../Delivery/Create/CreateDeliveryForm';
import { NotificationCenterContext } from '../../../../context/NotificationCenterContext';
import { AppContext } from '../../../../context/AppContext';
import SkeletonTableLoader from '../../../SkeletonLoaders/SkeletonTableLoader';
import { readUserAddresses } from '../../../../services/apiEndPoints/addresses/userAddress/readUserAddresses';

const { Text } = Typography;

/**
 * UsersListStep1
 * @param {boolean} customizeEmpty
 * @param {array} usersResult
 * @param {function} updateUserSearch function to open modal with search form
 * @param {object} formStep1
 * @param {boolean} loading
 * @return {JSX.Element}
 */
function UsersListStep1({ customizeEmpty, updateUserSearch, formStep1, usersResult, loading }) {
  const { token } = theme.useToken();
  const { setShowCustomerForm, setCurrentStep, setStepsValidated } = useContext(StepContext);
  const [, dispatch] = useContext(AppContext);
  const { t } = useTranslation();

  const notification = useContext(NotificationCenterContext);
  const colorPrimary = token.colorPrimary;

  // a mettre sur l'action de selection d'un user dans la liste
  const onUserSelect = async(user) => {
    await readUserAddresses({ userId: user.id }).then((response) => {
      dispatch({ type: 'SET_CUSTOMER_SELECTED_ADDRESS', payload: response.data[0] });
      setShowCustomerForm(true);
    }).catch(() => {
      notification.push('error', t('INTERNAL_ERROR'));
      setShowCustomerForm(false);
    });
    dispatch({ type: 'EDIT_DELIVERY_CUSTOMER', payload: {
      firstName: user.first_name,
      lastName: user.last_name,
      email: user.email,
      telephone: user.telephone,
      userId: user.id,
      isExisting: true,
    }});
    formStep1.setFieldsValue({
      firstName: user.first_name,
      lastName: user.last_name,
      email: user.email,
      telephone: user.telephone,
      isExisting: true,
    });
    setShowCustomerForm(true);
    setCurrentStep(0);
    setStepsValidated([false, false, false]);
  };

  const columns = [
    {
      title: t('FIRSTNAME_LASTNAME'),
      dataIndex: 'identity',
      key: 'identity',
    },
    {
      title: t('EMAIL'),
      dataIndex: 'mail',
      key: 'mail',
    },
    {
      title: t('PHONE_NUMBER'),
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: t('ACTIONS'),
      dataIndex: 'userSelected',
      key: 'userSelected',
      render: (user) => (
        <Text
          css={{ color: colorPrimary, cursor: 'pointer' }}
          underline
          onClick={() => onUserSelect(user)}>{t('SELECT')}</Text>
      ),
    },
  ];


  return (
    <Col span={24}>
      <ConfigProvider renderEmpty={() => customizeRenderEmpty({
        customizeEmpty: customizeEmpty,
        updateUserSearch: updateUserSearch,
      })}>
        <SkeletonTableLoader loading={loading} active rowCount={10} columns={columns}>
          <Table
            columns={columns}
            dataSource={usersResult.map((user) => {
              return {
                key: user.id,
                identity: user.first_name + ' ' + user.last_name,
                phone: user.telephone,
                mail: user.email,
                userSelected: user,
              };
            })}
            pagination={false}
          />
        </SkeletonTableLoader>
      </ConfigProvider>
      <Space align="center" css={{
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'center',
        marginTop: '20px',
        marginBottom: '20px',
      }}>
        <Text >
          {t('NO_USER_MATCH')}
          <br />
          {t('MODIFY_SEARCH_OR_CREATE_NEW_RECIPIENT')}
        </Text>
      </Space>
    </Col>
  );
}

export default UsersListStep1;
