/**
 * @param {number} toStatus
 * @return {string} Correct event name
 */
export function getEventName(toStatus) {
  const eventNames = {
    1: 'CANCELED',
    3: 'GOING_TO_DRIVE',
    4: 'ARRIVED_AT_DRIVE',
    5: 'GOING_TO_CLIENT',
    6: 'VALIDATED',
    7: 'REMOVED',
  };

  return eventNames[toStatus];
}
