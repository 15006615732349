/** @jsxImportSource @emotion/react */
import { React, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AutoComplete, Input, InputNumber, Select, Skeleton, Card, Col, Row, Space, Form, Typography, Avatar } from 'antd';
import { ShoppingCartOutlined } from '@ant-design/icons';
import useFetch from 'use-http';
import useDebounce from '../../../../hooks/useDebounce';
import HighlightText from '../../../HighlightText/HighlightText';
import { NotificationCenterContext } from '../../../../context/NotificationCenterContext';
import LinkOldBoModal from '../../../Modal/LinkOldBoModal';

const { Text } = Typography;
const { TextArea } = Input;

/**
 * UpdateOrder Form
 * @param {Object} form
 * @param {Object} delivery
 * @param {Number} selectedDriveId
 * @param {Function} setSelectedDriveId
 * @param {Booleab} staticDelivery
 * @param {Object} payload
 * @param {Function} setPayload
 * @return {String}
 */
const UpdateOrder = ({ form, delivery, selectedDriveId, setSelectedDriveId, staticDelivery, payload, setPayload }) => {
  const { t } = useTranslation();
  const [disabledInputStatus, setDisabledInputStatus] = useState(false);
  const [optionsListDrive, setOptionsListDrive] = useState([]);
  const [searchDriveInput, setSearchDriveInput] = useState(null);
  const debouncedSearchTerm = useDebounce(searchDriveInput, 500);
  const driveFetch = useFetch(process.env.REACT_APP_API_BO, {
    cachePolicy: 'no-cache',
  });
  const notification = useContext(NotificationCenterContext);

  useEffect(() => {
    setDisabledInputStatus(delivery.status );
  }, [delivery.status]);

  // Constante pour la modale de lien vers l'ancien BO
  const textPartnerForm = {
    label: t('VIEW_FORM'),
    modalTitle: t('OLD_BO_ACCESS_PARTNER_FORM'),
    modalContent1: t('OLD_BO_ACCESS_PARTNER_FORM_SENTENCE_1'),
    modalContent2: t('OLD_BO_ACCESS_PARTNER_FORM_SENTENCE_2'),
    modalLink: `${process.env.REACT_APP_OLD_BO_URL}/drives/${selectedDriveId}`,
  };

  const launchDriveSearch = async() => {
    if (debouncedSearchTerm && debouncedSearchTerm.length >= 3) {
      const results = await driveFetch.get(`/drives?q=${debouncedSearchTerm}`);
      if (driveFetch.response.ok) {
        setOptionsListDrive(results.length > 0 ? searchResult({ drives: results, search: debouncedSearchTerm }) : []);
      } else {
        if (driveFetch.response.status === 404) {
          setOptionsListDrive([]);
        } else {
          notification.push('error', t('INTERNAL_ERROR'));
        }
      }
    }
  };

  useEffect(() => {
    launchDriveSearch();
  }, [debouncedSearchTerm, driveFetch.get]);

  const handleSearch = (value) => {
    setSearchDriveInput(value);
  };

  const searchResult = ({ drives, search }) => {
    return drives.map((drive) => {
      return {
        drive: drive,
        value: drive.name,
        key: drive.id,
        label: (
          <div>
            <Avatar src={process.env.REACT_APP_STATIC_BUCKET + drive.id + '.webp'} size={22} style={{ marginRight: 5 }} />
            <HighlightText text={`${drive.name}`} highlightTag={search}/>
          </div>
        ),
      };
    });
  };
  const checkDriveInList = async(_, value) => {
    if (!((typeof form.getFieldValue('driveSearch') === 'object') || value === delivery?.details?.drive_name) && (value?.length > 3 || value?.drive)) {
      return Promise.reject(new Error(t('SELECT_DRIVE_FROM_LIST')));
    }
    return Promise.resolve();
  };
  const onDriveSelection = async(value, option) => {
    form.setFieldValue('driveSearch', option);
    setSearchDriveInput(option.drive.name);
    setSelectedDriveId(option.drive.id);
    setPayload({ ...payload, drive_id: option.drive.id });
  };

  const StaticOrder = () => {
    return (
      <Card
        title={t('THE_COMMAND')}
        extra={
          <LinkOldBoModal icon={<ShoppingCartOutlined />} textContent={textPartnerForm}/>
        }
      >
        {!delivery.details ? <Skeleton active={true} size={'default'}/> :
          <div>
            <Row gutter={[20, 20]}>
              <Col xs={24} md={8}>
                <Space.Compact direction="vertical">
                  <Text type="secondary">{t('SEARCH_DRIVE')}</Text>
                  <Text>{delivery?.details?.drive_name}</Text>
                </Space.Compact>
              </Col>
              <Col xs={24} md={8}>
                <Space.Compact direction="vertical">
                  <Text type="secondary">{t('ORDER_REFERENCE')}</Text>
                  <Text>{delivery?.details?.reference}</Text>
                </Space.Compact>
              </Col>
              <Col xs={24} md={8}>
                <Space.Compact direction="vertical">
                  <Text type="secondary">{t('ORDER_AMOUNT')}</Text>
                  <Text>{delivery?.details?.ord_value}</Text>
                </Space.Compact>
              </Col>
              <Col xs={24} md={8}>
                <Space.Compact direction="vertical">
                  <Text type="secondary">{t('ARTICLES_AMOUNT')}</Text>
                  <Text>{delivery?.details?.nbr_article === null ? '-' : delivery.details.nbr_article }</Text>
                </Space.Compact>
              </Col>
              <Col xs={24} md={8}>
                <Space.Compact direction="vertical">
                  <Text type="secondary">{t('VOLUME_DELIVERY')}</Text>
                  <Text>{delivery.details?.transport_size || 'M'}</Text>
                </Space.Compact>
              </Col>
            </Row>
            <Row css={{ marginTop: '20px' }}>
              <Col span={24}>
                <Space.Compact direction="vertical">
                  <Text type="secondary">{t('STORE_COMMENT')}</Text>
                  <Text>{delivery?.details?.additional_info?.length === 0 ? '-' : delivery?.details?.additional_info}</Text>
                </Space.Compact>
              </Col>
            </Row>
          </div>
        }
      </Card>
    );
  };

  return (
    staticDelivery ? (
      <Card
        title={t('THE_COMMAND')}
        extra={
          <LinkOldBoModal icon={<ShoppingCartOutlined />} textContent={textPartnerForm}/>
        }>
        {!delivery.details ? <Skeleton active={true} size={'default'}/> :
          <div>
            <Row gutter={{ xs: 0, md: 20 }}>
              <Col xs={24} md={12}>
                <Form.Item
                  name='driveSearch'
                  label={t('SEARCH_DRIVE')}
                  initialValue={delivery?.details?.drive_name}
                  validateFirst={true}
                  rules={[{
                    required: true,
                    message: t('DRIVE_REQUIRED'),
                  }, {
                    validator: checkDriveInList,
                  }]}
                >
                  <AutoComplete
                    id='search-drive-delivery-detail-delivery'
                    options={optionsListDrive}
                    onSearch={handleSearch}
                    disabled={disabledInputStatus > 1}
                    onSelect={onDriveSelection}
                    notFoundContent={searchDriveInput ? t('NO_RESULT') : null}
                  >
                    <Input.Search
                      loading={driveFetch.loading}
                    />
                  </AutoComplete>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name='reference'
                  label={t('ORDER_REFERENCE')}
                  initialValue={delivery?.details?.reference}
                  rules={[{
                    required: true,
                    message: t('ORDER_REFERENCE_REQUIRED'),
                  }, {
                    max: 255,
                    message: t('TOO_LONG'),
                  }]}
                >
                  <Input
                    name='reference'
                    id='reference-delivery-detail-delivery'
                    disabled={disabledInputStatus === '6' || disabledInputStatus === '7'}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name='orderAmount'
                  label={t('ORDER_AMOUNT')}
                  initialValue={delivery?.details?.ord_value}
                  rules={[{
                    required: true,
                    message: t('ORDER_AMOUNT_REQUIRED'),
                  }]}
                >
                  <InputNumber
                    controls={true}
                    min={0}
                    style={{ width: '100%' }}
                    addonAfter="€"
                    id='order-amount-delivery-detail-delivery'
                    disabled={disabledInputStatus === '6' || disabledInputStatus === '7'}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name='articlesAmount'
                  label={t('ARTICLES_AMOUNT')}
                  initialValue={delivery?.details?.nbr_article}
                  rules={[{
                    required: true,
                    message: t('ARTICLES_AMOUNT_REQUIRED'),
                  }]}
                >
                  <InputNumber
                    controls={true}
                    min={0}
                    style={{ width: '100%' }}
                    id='articles-amount-delivery-detail-delivery'
                    disabled={disabledInputStatus === '6' || disabledInputStatus === '7'}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name='sizeCommand'
                  label={t('VOLUME_DELIVERY')}
                  initialValue={delivery.details?.transport_size || 'M'}
                  rules={[{
                    required: true,
                    message: t('DELIVERY_SIZE_REQUIRED'),
                  }]}
                >
                  <Select
                    id='size-command-delivery-detail-delivery'
                    disabled={disabledInputStatus === '6' || disabledInputStatus === '7'}
                    options={[
                      {
                        value: 'S',
                        label: `S - ${t('DELIVERY_SIZE_S')}`,
                      },
                      {
                        value: 'M',
                        label: `M - ${t('DELIVERY_SIZE_M')}`,
                      },
                      {
                        value: 'L',
                        label: `L - ${t('DELIVERY_SIZE_L')}`,
                      },
                      {
                        value: 'XL',
                        label: `XL - ${t('DELIVERY_SIZE_XL')}`,
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name='storeComment'
                  label={t('STORE_COMMENT')}
                  initialValue={delivery?.details?.additional_info}
                >
                  <TextArea
                    rows={2}
                    showCount
                    maxLength={255}
                    id='store-comment-delivery-detail-delivery'
                    disabled={disabledInputStatus === '6' || disabledInputStatus === '7'}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        }
      </Card>
    ) : <StaticOrder />
  );
};

export default UpdateOrder;
