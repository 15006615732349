/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { Card, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { colors } from '../../globalVar/theme';

const { Text } = Typography;

/**
 * CommentCardModerationHistory
 * @param {Array} comment
 * @return {JSX.Element}
 */
function CommentCardModerationHistory({ comment }) {
  const paragraphs = comment.split('\n');
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <Card
      size='small'
      bodyStyle={{ padding: '15px' }}
      css={{
        border: `1px solid ${colors.primary}`,
        borderRadius: 4,
      }}
    >
      <Text>
        {expanded ?
          paragraphs.map((item, i) => (
            <React.Fragment key={item}>
              {i > 0 && <br />}
              {item}
            </React.Fragment>
          )) :
          comment.substring(0, 125)}
      </Text>
      {comment.length > 125 && (
        <span
          onClick={toggleExpanded}
          style={{ color: colors.primary, textDecoration: 'underline', cursor: 'pointer' }}
        >
        ...{expanded ? t('SHOW_LESS') : t('SHOW_MORE')}
        </span>
      )}
    </Card>
  );
}
export default CommentCardModerationHistory;
