import { useState } from 'react';
import { Button } from 'antd';
import { WarningFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import DeclareIncidentDrawer from './DeclareIncidentDrawer';
import ManageIncidentDrawer from './ManageIncidentDrawer';

/**
 * DeliveryIncidentDrawer component
 * @param {string} driveName
 * @param {Object} incident
 * @param {function} setIncident
 * @param {function} updateIncidentsHistory
 * @param {number} deliveryStatus
 * @return {JSX.Element}
 */
function DeliveryIncidentDrawer({ incident, setIncident, driveName, updateIncidentsHistory, deliveryStatus }) {
  const { t } = useTranslation();
  const [openDeclareIncidentDrawer, setOpenDeclareIncidentDrawer] = useState(false);
  const [openManageIncidentDrawer, setOpenManageIncidentDrawer] = useState(false);

  return incident ? (
    <>
      <Button icon={<WarningFilled />} danger type='primary' onClick={() => setOpenManageIncidentDrawer(true)}>
        {t('MANAGE_INCIDENT')}
      </Button>
      <ManageIncidentDrawer
        open={openManageIncidentDrawer}
        setOpen={setOpenManageIncidentDrawer}
        driveName={driveName}
        incident={incident}
        setIncident={setIncident}
        updateIncidentsHistory={updateIncidentsHistory}
        deliveryStatus={deliveryStatus}
      />
    </>
  ) : (
    <>
      <Button icon={<WarningFilled />} danger type='primary' onClick={() => setOpenDeclareIncidentDrawer(true)}>
        {t('DECLARE_INCIDENT')}
      </Button>
      <DeclareIncidentDrawer
        open={openDeclareIncidentDrawer}
        setOpen={setOpenDeclareIncidentDrawer}
        driveName={driveName}
        setIncident={setIncident}
        updateIncidentsHistory={updateIncidentsHistory}
      />
    </>
  );
}

export default DeliveryIncidentDrawer;
