import { apiEndPointsOptions } from '../../apiEndPointsOptions';

/**
 * Update delivery detail
 * @param {Object} data
 * @return {Promise<void>}
 */
export async function updateDeliveryDetail(data) {
  const apiUrl = `${process.env.REACT_APP_API_BO}/deliveries/${data.deliveryId}`;
  const method = 'PUT';
  const body = {
    tips: data.tips,
    nbr_article: data.nbr_article,
  };

  try {
    const response = await fetch(
      apiUrl,
      await apiEndPointsOptions(method, body),
    );

    const result = await response.json();
    if (response.ok) {
      return {
        type: 'success',
        message: 'DELIVERY_CHANGED_SUCCESSFULLY',
        data: {
          tips: result.tips,
          nbr_article: result.nbr_article,
        },
      };
    } else {
      return {
        type: 'error',
        message: result.errors[0].message,
      };
    }
  } catch (error) {
    throw new Error(`Error in updateDeliveryDetail service: ${error.message}`);
  }
}
