/** @jsxImportSource @emotion/react */
import { Form, Modal, Space, Typography, theme } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import React, { useContext, useState } from 'react';
import ModalContext from '../../context/ModalContext';
import UserSearchForm from '../User/UserSearch/UserSearchForm';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

/**
 * UserSearchModal
 * @param {boolean} inDeliveryCreation
 * @return {JSX.Element}
 */
function UserSearchModal({ inDeliveryCreation }) {
  const { token } = theme.useToken();
  const { t } = useTranslation();
  const [searchform] = Form.useForm();
  const [modalOpenAnimationEnded, setModalOpenAnimationEnded] = useState(false);
  const setOpenModalSearch = useContext(ModalContext)['setOpenModalSearch'];
  const openModalSearch = useContext(ModalContext)['openModalSearch'];
  const colorPrimary = token.colorPrimary;

  const handleCancelSearch = () => {
    setOpenModalSearch(false);
  };

  return (
    <Modal
      afterOpenChange={(e) => setModalOpenAnimationEnded(e)}
      title={
        <Space align='center' size={20}>
          <SearchOutlined css={{ fontSize: 18, color: colorPrimary }} />
          <Text strong css={{ fontSize: 16, color: colorPrimary }}>{inDeliveryCreation ? t('SEARCH_CUSTOMER') : t('SEARCH_USER')}</Text>
        </Space>
      }
      open={openModalSearch}
      onCancel={handleCancelSearch}
      centered
      width={580}
      footer={null}
    >
      <UserSearchForm
        form={searchform}
        modalForm={true}
        modalOpenAnimationEnded={modalOpenAnimationEnded}
      />
    </Modal>
  );
}

export default UserSearchModal;
