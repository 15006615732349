import { Divider, Modal, Space, Typography } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch } from 'use-http';
import ModalContext from '../../context/ModalContext';
import { NotificationCenterContext } from '../../context/NotificationCenterContext';

const { Text } = Typography;

/**
 * AddShopperModal
 * @param {object} props
 * @return {JSX.Element}
 */
function AddShopperModal({ setSearchUser, delivery, setDelivery, selectedShopper }) {
  const { t } = useTranslation();
  const AddshopperFetch = useFetch(process.env.REACT_APP_API_BO, {
    cachePolicy: 'no-cache',
  });
  const setOpenModalShopper = useContext(ModalContext)['setOpenModalShopper'];
  const openModalShopper = useContext(ModalContext)['openModalShopper'];
  const notification = useContext(NotificationCenterContext);


  const handleCancelShopper = () => {
    setOpenModalShopper(false);
    setSearchUser();
  };

  const AddShopper = async() => {
    const payload = {
      shopper_id: selectedShopper.id,
    };
    await AddshopperFetch.put(`/deliveries/${delivery.id}`, payload);
    if (AddshopperFetch.response.ok) {
      setDelivery({
        ...delivery,
        shopper: selectedShopper,
        status: AddshopperFetch.response.data.status,
      });
      setOpenModalShopper(false);
      return notification.push('success', t('DELIVERY_CHANGED_SUCCESSFULLY'));
    }
    if (AddshopperFetch.response.data.errors) {
      console.error(AddshopperFetch.response.data);
      AddshopperFetch.response.data.errors.map((error) => {
        setOpenModalShopper(false);
        setSearchUser();
        return notification.push('error', t(error.message));
      });
    }
  };

  return (
    <Modal
      title={t('UPDATING_DELIVERY')}
      okText={t('VALIDATE')}
      open={openModalShopper}
      onOk={AddShopper}
      onCancel={handleCancelShopper}
      centered
    >
      <Divider />
      <Space direction='vertical' css={{ padding: 0 }}>
        <Text>{t('ADD_SHOPPER_CHANGE_STATUS')}</Text>
        <Text>{t('ADD_SHOPPER_SURE')}</Text>
      </Space>
    </Modal>
  );
}

export default AddShopperModal;
