import React, { useEffect, useState } from 'react';
import { Space, Typography } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { colors } from '../../../globalVar/theme';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

/**
 * LoadMoreAddress
 * @param {Object} props - The props of the component.
 * @param {function} props.setNumberOfRowsView - The setNumberOfRowsView function.
 * @param {function} props.setNumberRowsToDisplay - The setNumberRowstoDisplay function.
 * @return {JSX.Element}
 */
function LoadMoreAddresses({ setNumberOfRowsView, addressesview }) {
  const { t } = useTranslation();
  const [numberRowsToDisplay, setNumberRowsToDisplay] = useState(0);

  useEffect(() => {
    setNumberRowsToDisplay(addressesview);
  }, [addressesview]);

  const onLoadMore = () => {
    setNumberOfRowsView((prev) => prev + 5);
    setNumberRowsToDisplay((prev) => prev - 5);
  };

  return (
    numberRowsToDisplay > 0 ? (
      <Space
        style={{
          width: '100%',
          height: '60px',
          justifyContent: 'center',
          cursor: 'pointer',
          borderTop: '1px solid rgba(13, 55, 155, 0.12)',
        }}
      >
        <ReloadOutlined style={{ color: colors.blueMiddle }} />
        <Text type='secondary' underline onClick={onLoadMore}>
          {t('LOAD_MORE_ADDRESSES')} ({numberRowsToDisplay})
        </Text>
      </Space>
    ) : null
  );
}

export default LoadMoreAddresses;
