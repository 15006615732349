import React from 'react';
// Hooks
import { useTranslation } from 'react-i18next';
import useCustomHtml from '../../../../hooks/useCustomHtml';

/**
 * UserReactivationHistoryNode
 * @param {Object} props
 * @param {Object} props.user
 * @param {string} props.user.firstName
 * @param {string} props.user.lastName
 * @return {JSX.Element}
 */
function UserReactivationHistoryNode({ user }) {
  const { t } = useTranslation();

  const reactivationNode = useCustomHtml({
    keyValues: [
      { key: '[REACTIVATION_AUTHOR_NAME]', value: `${user.firstName} ${user.lastName}`, bold: true },
    ],
    inputString: t('REACTIVATION_AUTHOR'),
  });

  return (
    <div>
      {reactivationNode}
    </div>
  );
}

export default UserReactivationHistoryNode;
