import React from 'react';
import { useTranslation } from 'react-i18next';
import useCustomHtml from '../../../../../hooks/useCustomHtml';

/**
 * PaidHistoryNode
 * @param {Object} user
 * @return {JSX.Element}
 */
function PaidHistoryNode({ user }) {
  const { t } = useTranslation();

  return (
    <div>
      {useCustomHtml({
        keyValues: [
          { key: '[DELIVERY_MAN_NAME]', value: `${user.firstName} ${user.lastName}`, url: `/users/${user.id}` },
        ],
        inputString: t('DELIVERY_RATED_BY_DELIVERY_MAN'),
      })
      }
    </div>
  );
}

export default PaidHistoryNode;
