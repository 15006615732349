/**
 * @param {string} order
 * @param {string} [size] - 'big' | 'short'
 * @return {string}
 */
export function translateOrderAntd(order, size = 'short') {
  let result;
  switch (order) {
  case 'ASC':
    size === 'short' ? result = 'ASC' : result = 'ascend';
    break;

  case 'ascend':
    size === 'short' ? result = 'ASC' : result = 'ascend';
    break;

  case 'DESC':
    size === 'short' ? result = 'DESC' : result = 'descend';
    break;

  case 'descend':
    size === 'short' ? result = 'DESC' : result = 'descend';
    break;

  default:
    result = 'ASC';
    break;
  }
  return result;
}
