/** @jsxImportSource @emotion/react */
import { useState, useEffect, useContext } from 'react';
// Components
import { theme, Timeline, Typography, Space, FloatButton, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { CancelDot, CheckDot, CommentDot, DriveCommentDot } from '../../TimelineUtilsComponents/Dots';
import {
  UserDeactivationHistoryNode,
  UserReactivationHistoryNode,
  InternalCommentBOV1HistoryNode,
  DriveCommentHistoryNode,
  UserInternCommentHistoryNode } from './ModerationHistoryNodes/_index';
// Utils
import dayjs from 'dayjs';
import { toLocaleString } from '../../../utils/toLocaleString';
import { AppContext } from '../../../context/AppContext';
import { useTranslation } from 'react-i18next';
import { isMobile, isTablet } from 'react-device-detect';
import { NotificationCenterContext } from '../../../context/NotificationCenterContext';
import SkeletonNodes from '../../Delivery/Detail/History/SkeletonNode';
import { deleteInternComment } from '../../../services/apiEndPoints/users/moderation';

const { Text } = Typography;

/**
 * UserManagementHistory
 * @param {Object} props
 * @param {Array} props.deactivatedUserHistory
 * @param {Array} props.reactivatedUserHistory
 * @param {Function} props.setVisible
 * @param {boolean} props.isBanUser
 * @param {Function} props.setIsBanUser
 * @param {Function} props.setIsBanishFromAllDrive
 * @param {Array} props.internalCommentBOV1
 * @param {Array} props.commentDriveHistory
 * @param {Array} props.userInternComments
 * @param {boolean} props.loading
 * @return {JSX.Element}
 */
function UserManagementHistory({
  deactivatedUserHistory,
  reactivatedUserHistory,
  setVisible,
  isBanUser,
  setIsBanUser,
  setIsBanishFromAllDrive,
  internalCommentBOV1,
  commentDriveHistory,
  userInternComments,
  loading,
}) {
  const { token } = theme.useToken();
  const { t } = useTranslation();
  const [store] = useContext(AppContext);
  const notification = useContext(NotificationCenterContext);
  const [items, setItems] = useState([]);
  const [sortedHistory, setSortedHistory] = useState([]);
  const [firstBanishmentId, setFirstBanishmentId] = useState(null);
  const userLoggedInId = store.user.id;

  /**
   * useEffect - When the history is updated, merge the banishment and the reactivation history
   * @return {void}
   */
  useEffect(() => {
    mergeInfoHistory();
  }, [deactivatedUserHistory, reactivatedUserHistory]);

  /**
   * useEffect - When the history is updated, get the id of the first banishment
   * @return {void}
   */
  useEffect(() => {
    if (sortedHistory.length > 0 && isBanUser) {
      getFirstBanishmentElementId(sortedHistory);
    } else {
      setFirstBanishmentId(null);
    }
  }, [sortedHistory]);

  /**
   * getFirstBanishmentElementId - Get the id of the first banishment element in the history array (sorted by date)
   * @param {Array} history
   */
  const getFirstBanishmentElementId = (history) => {
    for (const item of history) {
      if (item.deactivationType === 'TOTAL_DEACTIVATION' || item.deactivationType === 'DRIVE_DEACTIVATION') {
        setIsBanishFromAllDrive(item.deactivationType === 'TOTAL_DEACTIVATION');
        setFirstBanishmentId(item.id);
        return;
      }
    }
  };

  /**
   * mergeInfoHistory - Merge the banishment and the reactivation history
   * @return {void}
   */
  const mergeInfoHistory = () => {
    const sorted = [...deactivatedUserHistory, ...reactivatedUserHistory, ...internalCommentBOV1, ...userInternComments, ...commentDriveHistory].sort((firstItem, secondItem) => {
      return dayjs(secondItem.createdAt) - dayjs(firstItem.createdAt);
    });
    setSortedHistory(sorted);
  };

  /**
   * nodeDot
   * @param {string} event
   * @return {JSX.Element}
   */
  const nodeDot = (event) => {
    if (event === 'COTRANSPORTER_REACTIVATION') return <CheckDot color={token.colorSuccess} />;
    if (event === 'TOTAL_DEACTIVATION' || event === 'DRIVE_DEACTIVATION') return <CancelDot color={token.colorError} />;
    if (event === 'DRIVE_COMMENT') return <DriveCommentDot color={token.colorIconDriveHistory} />;
    if (event === 'INTERN_COMMENT_V2') return <CommentDot color={token.colorWarningActive} />;
    if (event === 'INTERNAL_COMMENT') return <CommentDot color={token.colorWarningActive} />;
    return null;
  };

  /**
   * deleteComment - Delete the comment
   * @param {Object} item
   * @return {void}
   * */
  const deleteComment = (item) => {
    deleteInternComment(item).then((response) => {
      if (response.type === 'success') {
        setSortedHistory(sortedHistory.filter((history) => history.uuid !== item.uuid));
      }
      notification.push(response.type, t(response.message));
    });
  };

  /**
   * useEffect - Set the items for the timeline
   * @return {JSX.Element}
   */
  useEffect(() => {
    if (sortedHistory.length > 0) {
      const tempItems = sortedHistory.map((item) => {
        return {
          dot: nodeDot(item.deactivationType),
          children: (
            <div
              css={{
                '.trash-icon': {
                  visibility: (isMobile || isTablet) ? 'visible' : 'hidden',
                },
                '.date': {
                  gap: '0 !important',
                  marginBottom: '5px',
                },
                '.date, .user-name': {
                  fontWeight: 700,
                },
                '&:hover': item.deactivationType === 'INTERN_COMMENT_V2' && userLoggedInId === item.createdBy ? {
                  'fontWeight': 600,
                  '.date, .user-name': {
                    fontWeight: 800,
                  },
                  '.trash-icon': {
                    visibility: 'visible',
                  },
                } : null,
              }}
            >
              <Space css={{ display: 'flex', justifyContent: 'space-between' }}>
                <Space direction='vertical' className='date'>
                  <Text css={{ textTransform: 'capitalize' }}>
                    {toLocaleString({ date: dayjs(item.createdAt) })}
                  </Text>
                  <Text css={{ fontSize: 12 }}>
                    {dayjs(item.createdAt).format('LT')}
                  </Text>
                </Space>
                {item.deactivationType === 'INTERN_COMMENT_V2' && userLoggedInId === item.createdBy &&
                <Popconfirm
                  title={t('WARNING_REMOVAL_COMMENT_MSG')}
                  okText={t('YES')}
                  cancelText={t('NO')}
                  overlayStyle={{ maxWidth: '300px' }}
                  placement='left'
                  onConfirm={() => deleteComment(item)}
                >
                  <FloatButton
                    icon={<DeleteOutlined />}
                    style={{ position: 'unset' }}
                    className='trash-icon'
                  />
                </Popconfirm>}
              </Space>
              {item.deactivationType === 'COTRANSPORTER_REACTIVATION' && (
                <UserReactivationHistoryNode
                  user={{ firstName: item.callerFirstName, lastName: item.callerLastName }}
                />
              )}
              {item.deactivationType === 'TOTAL_DEACTIVATION' && (
                <UserDeactivationHistoryNode
                  user={{ firstName: item.callerFirstName, lastName: item.callerLastName }}
                  reason={item.reason}
                  type={item.deactivationType}
                  description={item.description}
                  setIsBanUser={setIsBanUser}
                  displayReactivationButton={item.id === firstBanishmentId}
                  setVisible={setVisible}
                  setIsBanishFromAllDrive={setIsBanishFromAllDrive}
                />
              )}
              {item.deactivationType === 'DRIVE_DEACTIVATION' && (
                <UserDeactivationHistoryNode
                  user={{ firstName: item.callerFirstName, lastName: item.callerLastName }}
                  reason={item.reason}
                  type={item.deactivationType}
                  driveName={item.driveName}
                  description={item.description}
                  setIsBanUser={setIsBanUser}
                  displayReactivationButton={item.id === firstBanishmentId}
                  setVisible={setVisible}
                  setIsBanishFromAllDrive={setIsBanishFromAllDrive}
                />
              )}
              {item.deactivationType === 'DRIVE_COMMENT' && (
                <DriveCommentHistoryNode
                  drive={{ name: item.driveName, driveId: item.driveId }}
                  delivery={{ id: item.deliveryId, internalReference: item.internalReference }}
                  // need to do a split because the comment is in the format :
                  // [name of the commenyt writter === drive name] - [name and status off the comment writter] - [COMMENT]"
                  // and we only want the comment
                  comment={(item.comment.split('-')).pop().trim()}
                />
              )}
              {item.deactivationType === 'INTERN_COMMENT_V2' && (
                <UserInternCommentHistoryNode
                  user={{ firstName: item.createdByFirstName, lastName: item.createdByLastName }}
                  description={item.description}
                  userFeedback={item.userFeedback}
                />
              )}
              {item.deactivationType === 'INTERNAL_COMMENT' && (
                <InternalCommentBOV1HistoryNode
                  internalComment = {item.internalComment}
                />
              )}
            </div>
          ),
        };
      });
      setItems(tempItems);
    }
  }, [sortedHistory, firstBanishmentId]);

  return loading ? (
    <SkeletonNodes count={3} noElementsAtLeft />
  ) : (
    <Timeline mode={'left'}
      items={items}
      style={{ marginLeft: '10px' }}
    />
  );
}

export default UserManagementHistory;
