import { ReactNode } from 'react';
import { Card, Typography, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { RecipientSummaryProps } from '../../interfaces/RecipientInformationProps';
import { formatPhoneNumber, formatEmail } from '@shopopop/backoffice-frontend-utils';

const { Text } = Typography;

/**
 * @param {RecipientSummaryProps} RecipientSummaryProps
 * @return {ReactNode} RecipientInformationCard component
 */
function RecipientSummaryCard(recipientInformation: Readonly<RecipientSummaryProps>): ReactNode {
  const { t } = useTranslation();

  const formattedPhoneNumber = formatPhoneNumber(recipientInformation?.telephone);
  const formattedEmail = formatEmail(recipientInformation?.email);

  return (
    <Card title={t('RECAP_RCP_INFO_TITLE')}>
      <Row gutter={[0, 16]}>
        <Col xs={24} lg={12} xxl={8}>
          <Text type="secondary">{t('RCP_TABLE_NAME')} : </Text>
          <Text>{recipientInformation.fullName}</Text>
        </Col>
        <Col xs={24} lg={12} xxl={8}>
          <Text type="secondary">{t('RCP_TELEPHONE')} : </Text>
          <Text>{formattedPhoneNumber}</Text>
        </Col>
        <Col xs={24} lg={24} xxl={8}>
          <Text type="secondary">{t('RCP_EMAIL')} : </Text>
          <Text>{formattedEmail || '-'}</Text>
        </Col>
        <Col xs={24}>
          <Text type="secondary">{t('RECAP_ADDRESS')} : </Text>
          <Text>{recipientInformation.address}</Text>
        </Col>
      </Row>
    </Card>
  );
}

export default RecipientSummaryCard;
