/** @jsxImportSource @emotion/react */
import React from 'react';
import useCustomHtml from '../../../../hooks/useCustomHtml';
import { useTranslation } from 'react-i18next';
import CommentCardModerationHistory from '../../../TimelineUtilsComponents/CommentCardModerationHistory';

/**
 * DriveCommentHistoryNode
 * @param {Object} props.delivery
 * @param {Object} props.drive
 * @return {JSX.Element}
 */
function DriveCommentHistoryNode({ drive, delivery, comment }) {
  const { t } = useTranslation();

  return (
    <div>
      <div css={{ marginBottom: 10 }}>
        {useCustomHtml({
          keyValues: [
            { key: '[PICKUP_POINT_NAME]', value: `${drive.name} `, bold: true },
            { key: '[DELIVERY_ID]', value: `${delivery.id}`, url: `/delivery/${delivery.id}` },
          ],
          inputString: t('PICKUP_POINT_REPORT_HISTORY'),
        })}
      </div>
      {comment && <CommentCardModerationHistory comment={comment}/>}
    </div>
  );
}

export default DriveCommentHistoryNode;
