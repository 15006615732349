/**
 * Remove an address from the address list.
 * @param {Array} array
 * @param {number} key
 * @param {number} value
 * @return {Array} Return the new array.
 */
export function removeObjectInArrayByKey({ array, key, value }) {
  return array.filter((item) => item[key] !== value);
}
