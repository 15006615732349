import { INPUT_VALIDATION_REGEX, NO_EMOJI_REGEX } from '../utils/regex';
import formItemValidator from './formItemValidator';

/**
 * processedValidationRules
 * @param {Object} props
 * @param {Array} props.rules
 * @param {function} t - Fonction de traduction fournie par react-i18next
 * @param {Object} apartmentLengthErrorMessage - Message d'erreur pour la longueur de l'appartement
 * @param {Object} digicodeLengthErrorMessage - Message d'erreur pour la longueur du digicode
 * @return {Array} processedRules
 */
function processedValidationRules({ rules, t, apartmentLengthErrorMessage, digicodeLengthErrorMessage }) {
  return (
    rules.map((rule) => {
      if (rule.validator && formItemValidator(rule.validator, t)) {
        return formItemValidator(rule.validator, t);
      }
      if (rule.pattern === 'NO_EMOJI_REGEX') {
        return {
          pattern: NO_EMOJI_REGEX,
          message: t(rule.message),
        };
      }
      if (rule.pattern === 'INPUT_VALIDATION_REGEX') {
        return {
          pattern: INPUT_VALIDATION_REGEX,
          message: t(rule.message),
        };
      }
      if (rule.message === 'APARTMENT_LENGTH_ERROR_MESSAGE' ) {
        return {
          ...rule,
          message: apartmentLengthErrorMessage,
        };
      }
      if (rule.message === 'DIGICODE_LENGTH_ERROR_MESSAGE' ) {
        return {
          ...rule,
          message: digicodeLengthErrorMessage,
        };
      }
      return {
        ...rule,
        message: t(rule.message),
      };
    })
  );
}

export default processedValidationRules;
