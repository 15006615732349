import React, { useReducer, createContext } from 'react';
export const AppContext = createContext();

export const ContextWrapper = ({ reducer, initialState, children }) => {
  const value = useReducer(reducer, initialState);

  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  );
};
