/**
 * findColorTagsComment
 * @param {string} selectedKeys
 * @return {Object}
 */
function findColorTagsComment(selectedKeys) {
  if (selectedKeys === 'BAD_BEHAVIOUR' || selectedKeys === 'NOT_ON_TIME' || selectedKeys === 'NOT_AT_THE_DOOR' || selectedKeys?.toString() === '0') {
    return { color: '#FA9E14', background: 'rgba(255, 251, 230, 1)', borderColor: '#FFCF78' };
  }
  return { color: '#16AC5D', background: 'rgba(241,255,227,1)', borderColor: '#52C41A' };
}
export default findColorTagsComment;
