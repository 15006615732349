/** @jsxImportSource @emotion/react */
import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Form, Avatar, Select, Button } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { countries } from 'country-flag-icons';
import Flags from 'country-flag-icons/react/1x1';
import { getCountryCallingCode, isSupportedCountry, isValidNumberForRegion, parsePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';
import { useParams } from 'react-router-dom';
import { NotificationCenterContext } from '../../../context/NotificationCenterContext.js';
import { toInternationalFormatPhoneNumber } from '../../../utils/toInternationalFormatPhoneNumber.js.js';
import { iconInput } from '../../../globalVar/theme.js';
import { updateUserTelephone } from '../../../services/apiEndPoints/user/updateUser.js';

/**
 * @name InputPhoneUser
 * @param {function} setUser
 * @param {object} user
 * @return {string}
 */
function InputPhoneUser({ user, setUser }) {
  const { id } = useParams();
  const { t } = useTranslation();
  const [formPhone] = Form.useForm();
  const watchPhoneValue = Form.useWatch('telephone', formPhone);
  const { Option } = Select;
  const notification = useContext(NotificationCenterContext);
  const [selectedCountry, setSelectedCountry] = useState(localStorage.getItem('i18nextLng').slice(-2).toUpperCase());
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [updatePhoneLoading, setUpdatePhoneLoading] = useState(false);

  useEffect(() => {
    setSubmitDisabled(watchPhoneValue === user?.telephone);
  }, [user, watchPhoneValue]);

  const changeUserTelephone = (values) => {
    setUpdatePhoneLoading(true);
    updateUserTelephone({
      id,
      telephone: values.telephone,
    }).then((response) => {
      setUpdatePhoneLoading(false);
      if (response.data) setUser({ ...user, ...response.data });
      notification.push(response.type, t(response.message));
    });
  };

  useEffect(() => {
    formPhone.setFieldsValue({ telephone: user?.telephone });
    if (user?.telephone) {
      if (isValidPhoneNumber(user?.telephone)) {
        setSelectedCountry(parsePhoneNumber(user?.telephone)?.country);
      }
    }
  }, [user]);

  // Event to trigger when a search filter is called
  const filterCountrySearch = (value, option) => {
    const countryCallingCode = `+${getCountryCallingCode(option.value)}`;
    return option?.value?.includes(value?.toUpperCase()) || countryCallingCode?.includes(value);
  };

  const getCountryDialOptions = () => {
    const options = [];
    const availableCountries = countries.filter((value) => isSupportedCountry(value)).sort((a, b) => getCountryCallingCode(a) - getCountryCallingCode(b));
    for (const country of availableCountries) {
      const Flag = Flags[country];
      options.push((
        <Option value={country} key={country}>
          <Avatar size={15} icon={<Flag/>}/> <span style={{ verticalAlign: 'middle', marginLeft: 5 }}>+{getCountryCallingCode(country)}</span>
        </Option>
      ));
    }
    return options;
  };

  const countrySelect = (
    <Select showSearch
      style={{ width: 100 }}
      onSelect={(value)=>{
        setSelectedCountry(value);
      }}
      filterOption={filterCountrySearch}
      value={selectedCountry}
    >
      {getCountryDialOptions()}
    </Select>
  );

  return (
    <Form
      form={formPhone}
      layout="vertical"
      initialValues={{
        telephone: user?.telephone,
      }}
      onFinish={changeUserTelephone}
    >
      <Form.Item
        name="telephone"
        label={t('PHONE_NUMBER')}
        validateTrigger="onBlur"
        normalize={(value) => {
          toInternationalFormatPhoneNumber(value, selectedCountry);
        }}
        rules={[() => ({
          required: true,
          validator(_, value) {
            if (value?.length > 0 && !isValidNumberForRegion(value, selectedCountry)) {
              return Promise.reject(new Error(t('WRONG_FORMAT_PHONE_NUMBER')));
            }
            if (value === '') {
              return Promise.reject(new Error(t('PHONE_NUMBER_REQUIRED')));
            }
            return Promise.resolve();
          },
        })]}
      >
        <Input
          id="user-general-information-telephone"
          type="tel"
          autoComplete="off"
          onChange={(e) => {
            formPhone.setFieldsValue({
              telephone: e.target.value,
            });
          }}
          addonBefore={countrySelect}
          addonAfter={
            <Button
              htmlType="submit"
              loading={updatePhoneLoading}
              disabled={submitDisabled}
              icon={<CheckOutlined/>}
              css={iconInput}
            />
          }
        />
      </Form.Item>
    </Form>
  );
}

export default InputPhoneUser;

