import { useRef } from 'react';
import { Drawer } from 'antd';
import DeliveryIncidentForm from './DeliveryIncidentForm';
import { useTranslation } from 'react-i18next';
import { isTouchableView } from '../DeviceDetect/TouchableView';

/**
 * DeliveryIncidentDrawer component
 * @param {boolean} open
 * @param {function} setOpen
 * @param {string} driveName
 * @param {function} setIncident
 * @param {function} updateIncidentsHistory
 * @return {JSX.Element}
 */
function DeclareIncidentDrawer({ open, setOpen, driveName, setIncident, updateIncidentsHistory }) {
  const { t } = useTranslation();
  const incidentFormRef = useRef(null);

  return (
    <Drawer
      title={t('DECLARE_INCIDENT')}
      placement='right'
      width={isTouchableView() ? '100%' : 600}
      open={open}
      onClose={() => setOpen(false)}
    >
      <DeliveryIncidentForm
        incidentFormRef={incidentFormRef}
        setIncident={setIncident}
        updateIncidentsHistory={updateIncidentsHistory}
        actions={{
          setOpen,
        }}
        infos={{
          driveName,
        }}
      />
    </Drawer>
  );
}

export default DeclareIncidentDrawer;
