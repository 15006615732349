/** @jsxImportSource @emotion/react */
import { Button, Col, ConfigProvider, Space, Table, Typography } from 'antd';
import { MinusSquareFilled, PlusSquareFilled } from '@ant-design/icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import { customizeRenderEmpty } from '../../../../services/customizeRenderEmpty';
import UserSearchSubList from './UserSearchSubList';
import { colors } from '../../../../globalVar/theme';
import SkeletonTableLoader from '../../../SkeletonLoaders/SkeletonTableLoader';
import useCustomHtml from '../../../../hooks/useCustomHtml';
import UserListTag from '../../../Tag/UserListTag';

const { Text } = Typography;

/**
 * UsersList
 * @param {boolean} customizeEmpty
 * @param {array} usersResult
 * @param {function} updateUserSearch function to open modal with search form
 * @return {JSX.Element}
 */
function UsersList({ customizeEmpty, updateUserSearch, usersResult, loading }) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [rowExpanded, setRowExpanded] = useState([]);
  const maxResultMessage = useCustomHtml({
    inputString: t('MAX_RESULTS_MSG'),
  });


  const columns = [
    {
      title: t('FIRSTNAME_LASTNAME'),
      dataIndex: 'identity',
      key: 'identity',
    },
    {
      title: t('PHONE_NUMBER'),
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: t('EMAIL'),
      dataIndex: 'mail',
      key: 'mail',
      render: (_, user) => (
        <Space css={{ display: 'flex', justifyContent: 'space-between' }}>
          {user.mail}
          {<UserListTag user={user} />}
        </Space>
      ),
    },
    {
      title: t('ACTIONS'),
      dataIndex: 'profile_link',
      key: 'profile_link',
      render: (id) => (
        <Link to={`/users/${id}`}>
          <Button type='link' css={{ paddingLeft: 0 }}>{t('VIEW_PROFILE')}</Button>
        </Link>
      ),
    },
  ];

  const viewDelivery = (record, e, onExpand) => {
    onExpand(record, e);
    setRowExpanded([...rowExpanded, record.key]);
  };

  const hideDelivery = (record, e, onExpand) => {
    onExpand(record, e);
    setRowExpanded(rowExpanded.filter((key) => key !== record.key));
  };

  return (
    <Col span={24}>
      <ConfigProvider renderEmpty={() => customizeRenderEmpty({
        customizeEmpty: customizeEmpty,
        updateUserSearch: updateUserSearch,
      })}>
        <SkeletonTableLoader loading={loading} active rowCount={10} columns={columns}>
          <Table
            className='expandable-table'
            rowClassName={(record) => rowExpanded.includes(record.key) ? 'rowExpanded' : ''}
            columns={columns}
            expandable={{
              expandedRowRender: (record) => <UserSearchSubList idUser={record.key}/>,
              defaultExpandedRowKeys: ['0'],
              expandIcon: ({ expanded, onExpand, record }) =>
                expanded ?
                  (
                    <MinusSquareFilled style={{ fontSize: '15px', color: colors.primary, lineHeight: 'normal' }} onClick={(e)=> hideDelivery(record, e, onExpand)} />
                  ) : (
                    <PlusSquareFilled style={{ fontSize: '15px', color: colors.primary, lineHeight: 'normal' }} onClick={(e) => viewDelivery(record, e, onExpand)} />
                  ),
            }}
            dataSource={usersResult?.map((user) => {
              return {
                key: user.id,
                identity: user.first_name + ' ' + user.last_name,
                phone: user.telephone,
                mail: user.email,
                profile_link: user.id,
                bannedType: user.bannedType,
                deleted_date: user.deleted_date,
              };
            })}
            pagination={{
              showSizeChanger: true,
              total: usersResult?.length,
              pageSizeOptions: ['10', '20', '30'],
              position: ['bottomCenter'],
              showLessItems: true,
              current: parseInt(searchParams.get('page')),
              defaultCurrent: parseInt(searchParams.get('page')),
              defaultPageSize: parseInt(searchParams.get('limit')),
              size: 'small',
            }}
            onChange={
              (pagination) => {
                const newSearchParams = new URLSearchParams(searchParams);
                if (pagination.current !== searchParams.get('page')) {
                  newSearchParams.set('page', pagination.current);
                }
                if (pagination.pageSize !== searchParams.get('limit')) {
                  newSearchParams.set('limit', pagination.pageSize);
                }
                setSearchParams(newSearchParams.toString());
              }
            }
          />
        </SkeletonTableLoader>
        {usersResult.length === 30 &&
          <Text css={{ textAlign: 'center', padding: '0px 20px 0px 0px' }}>
            {maxResultMessage}
          </Text>
        }
      </ConfigProvider>
    </Col>
  );
}

export default UsersList;
