import { Empty, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import BigEmpty from '../../assets/shopopop-empty.svg';
import { useCustomHtml } from '@shopopop/react-hooks';

const { Text } = Typography;

function EmptyNobody() {
  const { t } = useTranslation();

  return (
    <Empty
      image={String(BigEmpty)}
      imageStyle={{
        marginBottom: 26,
      }}
      description={<Text type="secondary">{useCustomHtml({ inputString: t('RCP_SEARCH_EMPTYSTATE') })}</Text>}
    />
  );
}

export default EmptyNobody;
