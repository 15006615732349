import { Button, Empty } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

export const EmptyError = ({ refreshLink }) => {
  const { t } = useTranslation();

  return (
    <Empty
      image={`${process.env.PUBLIC_URL}/assets/504.svg`}
      description={
        <div>
          <p>{t('TIME_OUT_DATA')}</p>
          <p>{t('INTERNAL_ERROR_UPDATE_PAGE')}</p>
          <Button
            type='primary'
            size='small'
            icon={<ReloadOutlined/>}
            onClick={refreshLink}
          >
            {t('TO_REFRESH_DATA')}
          </Button>
        </div>
      }
    />
  );
};
