/**
 * Formats the parameters into a URL string for the Read Deliveries API call with encodeURIComponent.
 *
 * @param {Object} params - The parameters for the API call.
 * @param {string} params.label - The label parameter. (Required)
 * @param {string} [params.sort] - The sort parameter.
 * @param {string} [params.order] - The order parameter.
 * @param {number} [params.page] - The page parameter.
 * @param {number} [params.limit] - The limit parameter.
 * @param {string} [params.status] - The status parameter.
 * @param {string} [params.timeSlot] - The date parameter in string : today, tommorow...
 * @param {string} [params.q] - The q parameter.
 *
 * @return {string} - The formatted URL string.
 * @throws {Error} - Throws an error if label is not provided or if order is not provided.
 */
function formatReadDeliveriesParamsUrl({ label, sort, order, page, limit, status, timeSlot, q }) {
  // Label is mandatory
  if (!label) {
    throw new Error('Label is required for API call.');
  }

  // Transform order for API
  if (sort) {
    switch (order) {
    case 'ascend':
      order = 'ASC';
      break;
    case 'descend':
      order = 'DESC';
      break;
    default:
      sort = undefined;
      break;
    }
  }

  const params = { label, sort, order, page, limit, status, timeSlot, q };
  const urlParams = Object.keys(params)
    .filter((key) => params[key] !== null && typeof params[key] !== 'undefined')
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&');

  // Replace %3A with : for the hours etc. and return the result
  return `?${urlParams}`.replace(/%3A/g, ':');
}

export default formatReadDeliveriesParamsUrl;
