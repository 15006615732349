/**
 * @param {string} state
 * @param {action} action
 * @return {void}
 */
export default function menuActionReducer(state, action ) {
  switch (action.type) {
  case 'UPDATE_MENU_COLLAPSE':
    return {
      ...state,
      menuOption: {
        ...state.menuOption,
        collapseMenu: action.payload,
      },
    };
  default:
    return state;
  }
}
