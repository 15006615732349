/**
 * ZigwigwiSeparator component
 * @return {ReactNode}
 */
function ZigwigwiSeparator() {
  const theme = localStorage.getItem('theme');
  const style = {
    fill: theme === 'pro' ? '#ffcf78' : '#1b3f92',
    fillRule: 'evenodd',
    strokeWidth: 0,
  };

  return (
    <svg id="Forme-soustitre" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 58 6">
      <path style={style} className="cls-1"
        d={`M0,1c0-.3.2-.5.5-.5,1.6,0,2.4,1.2,3,2.1,0,0,0,0,0,0,.7,1,1.2,1.8,2.2,1.8s1.5-.7,2.2-1.8c0,
        0,0,0,0,0,.6-.9,1.4-2.1,3-2.1s2.4,1.2,3,2.1c0,0,0,0,0,0,.7,1,1.2,1.8,2.2,1.8s1.5-.7,2.2-1.8c0,
        0,0,0,0,0,.6-.9,1.4-2.1,3-2.1s2.4,1.2,3,2.1c0,0,0,0,0,0,.7,1,1.2,1.8,2.2,1.8s1.5-.7,2.2-1.8c0,
        0,0,0,0,0,.6-.9,1.4-2.1,3-2.1s2.4,1.2,3,2.1c0,0,0,0,0,0,.7,1,1.2,1.8,2.2,1.8s.5.2.5.5-.2.5-.5.5c-1.6,
        0-2.4-1.2-3-2.1,0,0,0,0,0,0-.7-1-1.2-1.8-2.2-1.8s-1.5.7-2.2,1.8c0,0,0,0,0,0-.6.9-1.4,2.1-3,
        2.1s-2.4-1.2-3-2.1c0,0,0,0,0,0-.7-1-1.2-1.8-2.2-1.8s-1.5.7-2.2,1.8c0,0,0,0,0,0-.6.9-1.4,2.1-3,
        2.1s-2.4-1.2-3-2.1c0,0,0,0,0,0-.7-1-1.2-1.8-2.2-1.8s-1.5.7-2.2,1.8c0,0,0,0,0,0-.6.9-1.4,2.1-3,
        2.1s-2.4-1.2-3-2.1c0,0,0,0,0,0-.7-1-1.2-1.8-2.2-1.8s-.5-.2-.5-.5Z`}
      />
    </svg>
  );
}

export default ZigwigwiSeparator;
