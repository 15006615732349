/**
 * Process response.
 * @param {object} response
 * @param {object} data
 * @return {object}
 */
function processResponse({ response, data }) {
  if (response.status === 200) {
    return {
      type: 'success',
      status: response.status,
      data,
    };
  }
  if (response.status === 404) {
    return {
      type: 'error',
      status: response.status,
    };
  }
}

export default processResponse;
