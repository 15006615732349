/** @jsxImportSource @emotion/react */
import { Flex, Layout, } from 'antd';
import ReactCountryFlag from 'react-country-flag';
import { breakpoints, sizes } from '../../globalVar/theme';
import { useWindowSize } from '../../hooks/useWindowSize';
import countries from '../../i18n/countries';
import LanguageSelector from '../Settings/LanguageSelector/LanguageSelector';

const { Header: AntHeader } = Layout;

const getCountryByLanguage = () => {
  const language = localStorage.getItem('i18nextLng');
  const languageKey = language.split('-')[0].toUpperCase(); // To obtain "FR" with "fr-FR"

  // if language "en-GB", send "GB" to the flag component
  if (languageKey === 'EN' && language === 'en-GB') {
    return { alpha2: 'GB' };
  }

  return countries.find((country) => country.alpha2 === languageKey);
};

/**
 * Page header with language selector
* @return {JSX.Element}
 */
export default function Header({ height }) {
  const windowSize = useWindowSize();
  const isTablet = windowSize.width > sizes.sm;

  return <AntHeader css={{ backgroundColor: 'white', padding: '20px', height, [breakpoints.md]: { padding: '40px 80px' }}}>
    <Flex justify='space-between' align='center' style={{ height: '100%' }}>
      <img
        src={`${process.env.PUBLIC_URL}/assets/${isTablet ? 'logo-shopopop.svg' : 'short-logo.svg'}`}
        alt="ShopopopLogo"
      />
      <Flex align='center' gap={10} style={{ width: '160px' }} >
        {isTablet ?
          <div style={{
            width: '40px',
            borderRadius: '4px',
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <ReactCountryFlag
              countryCode={getCountryByLanguage()?.alpha2}
              svg
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
          </div> :
          null}
        <LanguageSelector inputStyle={{ width: '100%' }} />
      </Flex>
    </Flex>
  </AntHeader>;
}
