import styled from '@emotion/styled';
import { NO_EMOJI_REGEX } from '@shopopop/backoffice-frontend-utils';
import { Col, Form, Input, Row, Typography } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NO_SPECIAL_CHARACTERS_REGEX } from '../../../../../utils/src/regex/regex';
import { SearchDeliveriesFormProps, SearchDeliveriesFormValues } from '../../../interfaces/SearchDeliveries';
import InputPhoneNumber from '../../Input/InputPhoneNumber/InputPhoneNumber';

const { Paragraph } = Typography;
const { Item, useWatch } = Form;

const StyledForm = styled(Form<SearchDeliveriesFormValues>)`
    .ant-form-item-vertical .ant-form-item-control {
        min-height: unset;
    }
`;

export default function SearchDeliveriesForm({ country, forceVertical = false, form, setFormValid }: SearchDeliveriesFormProps) {
  const { t } = useTranslation();
  const values = useWatch([], form);
  const [formHasValue, setFormHasValue] = useState(false);

  const texFieldRules = useMemo(
    () => [
      {
        pattern: /^(?=.{1,100}$).*/g,
        message: t('FULL_NAME_LIMITE_LENGTH'),
      },
      {
        pattern: NO_SPECIAL_CHARACTERS_REGEX,
        message: t('INVALID_VALUE'),
      },
      {
        pattern: NO_EMOJI_REGEX,
        message: t('INVALID_VALUE'),
      },
    ],
    [t],
  );

  useEffect(() => {
    const hasValue = values && Object.values(values).some((val) => !!val);
    setFormHasValue(hasValue);

    if (hasValue) {
      form
        .validateFields({ validateOnly: true })
        .then(() => setFormValid(true))
        .catch(() => setFormValid(false));
    } else {
      setFormValid(false);
    }
  }, [values]);

  return (
    <div>
      <Paragraph italic type={formHasValue ? 'secondary' : 'danger'}>
        {t('SEARCH_DELIVERY_DESC')}
      </Paragraph>
      <StyledForm form={form}>
        <Row gutter={20}>
          <Col xs={24} md={forceVertical ? 24 : 12}>
            <Item name="name" label={t('DELIVERY_SEARCH_RCP_NAME')} layout="vertical" rules={texFieldRules}>
              <Input />
            </Item>
          </Col>
          <Col xs={24} md={forceVertical ? 24 : 12}>
            <InputPhoneNumber country={country} form={form} inputName="phoneNumber" label="DELIVERY_SEARCH_RCP_PHONE_NB" layout="vertical" />
          </Col>
        </Row>
        <Item name="reference" label={t('DELIVERY_SEARCH_ORDER_REF')} layout="vertical" rules={texFieldRules}>
          <Input />
        </Item>
      </StyledForm>
    </div>
  );
}
