import React from 'react';
import { useTranslation } from 'react-i18next';
import useCustomHtml from '../../../../../hooks/useCustomHtml';
import initialFormItems from '../../../../DeliveryIncident/initialFormItems.json';
import incidentDetailsFormItems from '../../../../DeliveryIncident/incidentDetailsFormItems.json';

/**
 * DeliveryIncidentNode
 * @param {Object} props.user
 * @param {string} props.reason
 * @param {string} props.type
 * @param {string} props.comment
 * @param {string} props.kilometerRange
 * @param {boolean} props.deliveryClosedRequested
 * @return {JSX.Element}
 */
function DeliveryIncidentNode({ user, reason, type, comment, kilometer, deliveryClosedRequested }) {
  const { t } = useTranslation();

  return (
    <div>
      {useCustomHtml({
        keyValues: [
          { key: '[SHOPOPOP_NAME]', value: `${user.firstName} ${user.lastName}`, bold: true },
        ],
        inputString: t('DELIVERY_INCIDENT_REPORTED_BY'),
      })}
      {type ? <NewIncidentDescription
        reason={reason}
        type={type}
        comment={comment}
        kilometer={kilometer}
        deliveryClosedRequested={deliveryClosedRequested}
      /> : t(`${reason}_INCIDENT`)}
    </div>
  );
}

/**
 * @anme NewIncidentDescription
 * @param {string} type
 * @param {string} reason
 * @param {string} comment
 * @param {string} kilometer
 * @param {boolean} deliveryClosedRequested
 * @return {JSX.Element}
 */
function NewIncidentDescription({ type, reason, comment, kilometer, deliveryClosedRequested }) {
  const { t } = useTranslation();

  const category = initialFormItems[0].options.find((option) => option.value === type);

  const reasonView = incidentDetailsFormItems[category.value]
    .find((category) => category.name === type)
    .options.find((option) => option.value === reason);

  const kilometersRange = incidentDetailsFormItems[category.value]
    ?.find((category) => category.name === 'kilometer-range')
    ?.options.find((option) => option.value === kilometer);

  const incidentDescriptionWithKilometerRange = useCustomHtml({
    keyValues: [
      { key: '[INCIDENT_TYPE]', value: t(`${category.label}`), bold: true },
      { key: '[INCIDENT_REASON]', value: t(`${reasonView.label}`), bold: true },
      { key: '[KILOMETER_RANGE]', value: t(`${kilometersRange?.label}`) },
    ],
    inputString: t('DELIVERY_INCIDENT_DESCRIPTION_WITH_KILOMETER'),
  });

  const incidentDescriptionSimple = useCustomHtml({
    keyValues: [
      { key: '[INCIDENT_TYPE]', value: t(`${category.label}`), bold: true },
      { key: '[INCIDENT_REASON]', value: t(`${reasonView.label}`), bold: true },
    ],
    inputString: t('DELIVERY_INCIDENT_DESCRIPTION'),
  });

  const incidentDescription = kilometer ? incidentDescriptionWithKilometerRange : incidentDescriptionSimple;

  const deliveryClosedRequestedView = useCustomHtml({
    keyValues: [
      { key: '[DELIVERY_PAID]', value: t('DELIVERY_PAID'), bold: true },
    ],
    inputString: t('DELIVERY_CLOSED_REQUESTED'),
  });

  return (
    <>
      <div>
        {incidentDescription}
        {deliveryClosedRequested === 1 && deliveryClosedRequestedView}
      </div>
      <div>
        {comment}
      </div>
    </>
  );
}

export default DeliveryIncidentNode;
