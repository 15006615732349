import { apiEndPointsOptions } from '../../apiEndPointsOptions';

export const readHistory = async(deliveryId) => {
  const apiUrl = `${process.env.REACT_APP_API_BO}/deliveries/${deliveryId}/history`;
  const method = 'GET';

  try {
    const response = await fetch(
      apiUrl,
      await apiEndPointsOptions(method),
    );
    const result = await response.json();

    if (response.status === 200) {
      return {
        type: 'success',
        data: result,
      };
    } else {
      return {
        type: 'error',
        data: result,
      };
    }
  } catch (error) {
    throw new Error(`Error in readHistory service: ${error.message}`);
  }
};
