import { React } from 'react';
import { useTranslation } from 'react-i18next';
import { toLocaleString } from '../../../../utils/toLocaleString';
import { toLocaleStringHourandMinutes } from '../../../../utils/toLocaleStringHourandMinutes';
import { Skeleton, Card, Row, Col, Typography, Space } from 'antd';
import { ClockCircleOutlined, CalendarOutlined } from '@ant-design/icons';
import { distanceParser } from '../../../../utils/distanceParser';
import { formatCurrency } from '../../../../utils/formatCurrency';
import BetaFeature from '../../../BetaFeature';
import Dayjs from 'dayjs';

const { Text } = Typography;

/**
 * CreateDeliveryForm
 * @param {object} delivery
 * @param {boolean} loading
 * @param {number} deliveryShifted - Remove when the old feature is removed (BetaFeature)
 * @param {number} deliveryShiftedDateCount
 * @param {number} deliveryShiftedSlotCount
 * @param {number} deliveryShopperCancelled
 * @return {JSX.Element}
 */
const Delivery = ({ delivery, loading, deliveryShifted, deliveryShiftedDateCount, deliveryShiftedSlotCount, deliveryShopperCancelled }) => {
  const { t } = useTranslation();

  // Remove when the old feature is removed (BetaFeature)
  const ShiftedDeliveryDetails = () => {
    if (deliveryShifted === 1) {
      return (
        <Text type='danger'>
          {deliveryShifted} {t('DELIVERY_SHIFT')}
        </Text>
      );
    } else if (deliveryShifted > 1) {
      return (
        <Text type='danger'>
          {deliveryShifted} {t('DELIVERY_SHIFTS')}
        </Text>
      );
    } else {
      return null;
    }
  };

  const ShiftedDeliverySlotDetail = () => {
    // Check if this is a change of hour and/or minutes
    const isHourStartChanged = new Dayjs(delivery.initial_delivery_start_utc).format('HH') !== new Dayjs(delivery.delivery_start).format('HH');
    const isMinuteStartChanged = new Dayjs(delivery.initial_delivery_start_utc).format('mm') !== new Dayjs(delivery.delivery_start).format('mm');
    const isHourEndChanged = new Dayjs(delivery.initial_delivery_end_utc).format('HH') !== new Dayjs(delivery.delivery_end).format('HH');
    const isMinuteEndChanged = new Dayjs(delivery.initial_delivery_end_utc).format('mm') !== new Dayjs(delivery.delivery_end).format('mm');

    if (deliveryShiftedSlotCount > 0 && (isHourStartChanged || isMinuteStartChanged || isHourEndChanged || isMinuteEndChanged)) {
      return (
        <Row gutter={10}>
          <Col>
            <Text delete type='secondary'>{toLocaleStringHourandMinutes(delivery.initial_delivery_start_utc)}-{toLocaleStringHourandMinutes(delivery.initial_delivery_end_utc)}</Text>
          </Col>
          <Col>
            <Text type='danger'>
              {deliveryShiftedSlotCount} <ClockCircleOutlined />
            </Text>
          </Col>
        </Row>
      );
    } else {
      return null;
    }
  };

  const ShiftedDeliveryDateDetail = () => {
    // Check if this is day or month or year as changed
    const isDayChanged = new Dayjs(delivery.initial_delivery_start_utc).format('DD') !== new Dayjs(delivery.delivery_start).format('DD');
    const isMonthChanged = new Dayjs(delivery.initial_delivery_start_utc).format('MM') !== new Dayjs(delivery.delivery_start).format('MM');
    const isYearChanged = new Dayjs(delivery.initial_delivery_start_utc).format('YYYY') !== new Dayjs(delivery.delivery_start).format('YYYY');

    if (deliveryShiftedDateCount > 0 && (isDayChanged || isMonthChanged || isYearChanged)) {
      return (
        <Row gutter={10}>
          <Col>
            <Text delete type='secondary'>{toLocaleString({ date: delivery.initial_delivery_start_utc, fullDate: true })}</Text>
          </Col>
          <Col>
            <Text type='danger'>
              {deliveryShiftedDateCount} <CalendarOutlined />
            </Text>
          </Col>
        </Row>
      );
    } else {
      return null;
    }
  };

  return (
    <Card>
      {loading ? <Skeleton.Input active={true} size={'default'} block={true} /> :
        <Row gutter={[20, 20]}>
          <BetaFeature
            deprecatedComponent={
              <Col xs={12} sm={8} xxl={4}>
                <Space.Compact direction="vertical">
                  <Text>
                    {delivery?.delivery_start && toLocaleString({ date: delivery?.delivery_start, fullDatetime: false })}
                  </Text>
                  <Text strong>
                    {delivery?.delivery_start && toLocaleStringHourandMinutes(delivery?.delivery_start)} - {delivery?.delivery_end && toLocaleStringHourandMinutes(delivery?.delivery_end)}
                  </Text>
                  <ShiftedDeliveryDetails/>
                </Space.Compact>
              </Col>
            }
          >
            <Col xs={12} sm={8} xxl={4}>
              <Space.Compact direction="vertical">
                <Text type="secondary">
                  {t('DELIVERY_DATE')}
                </Text>
                <Text strong>
                  {delivery?.delivery_start && toLocaleString({ date: delivery?.delivery_start })}
                </Text>
                <ShiftedDeliveryDateDetail/>
              </Space.Compact>
            </Col>

            <Col xs={12} sm={8} xxl={4}>
              <Space.Compact direction="vertical">
                <Text type="secondary">
                  {t('DELIVERY_SLOTS_HOUR')}
                </Text>
                <Text strong>
                  {delivery?.delivery_start && toLocaleStringHourandMinutes(delivery?.delivery_start)} - {delivery?.delivery_end && toLocaleStringHourandMinutes(delivery?.delivery_end)}
                </Text>
                <ShiftedDeliverySlotDetail/>
              </Space.Compact>
            </Col>
          </BetaFeature>

          <Col xs={12} sm={8} xxl={4}>
            <Space.Compact direction="vertical">
              <Text type="secondary">
                {t('TIP')}
              </Text>
              <Text strong>
                {formatCurrency(delivery?.tips)}
              </Text>
              <Text type="danger">
                {delivery.tips > delivery.initial_tips && `+${formatCurrency(delivery.tips - delivery.initial_tips)}`}
              </Text>
            </Space.Compact>
          </Col>

          <Col xs={12} sm={8} xxl={4}>
            <Space.Compact direction="vertical">
              <Text type="secondary">
                {t('DISTANCE')}
              </Text>
              <Text strong>
                {delivery?.distance && <span>{distanceParser(delivery?.distance).value} {distanceParser(delivery?.distance).unit}</span>}
              </Text>
            </Space.Compact>
          </Col>

          <Col xs={12} sm={8} xxl={4}>
            <Space.Compact direction="vertical">
              <Text type="secondary">
                {t('CANCELLED')}
              </Text>
              <Text strong>
                {deliveryShopperCancelled}
              </Text>
            </Space.Compact>
          </Col>

          <BetaFeature>
            <Col xs={12} sm={8} xxl={4}>
              <Space.Compact direction="vertical">
                <Text type="secondary">
                  {t('TENSION_ZONE')}
                </Text>
                <Text strong type={delivery?.drive_tension_zone === 'high' ? 'danger' : null}>
                  {delivery?.drive_tension_zone && t(`TENSION_ZONE_${delivery?.drive_tension_zone.toUpperCase()}`)}
                </Text>
              </Space.Compact>
            </Col>
          </BetaFeature>
        </Row>}
    </Card>
  );
};

export default Delivery;
