import Keycloak from 'keycloak-js';
import keycloakConfig from '../keycloakConfig';

const kc = new Keycloak(keycloakConfig);

const initKeycloak = (onInitialized) => {
  kc.init({
    onLoad: 'check-sso',
    pkceMethod: 'S256',
    silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
  })
    .then((auth) => {
      if (!auth) {
        console.error('user is not authenticated');
      }
      onInitialized();
    })
    .catch((err) => {
      console.error('Error to Keycloak initialization', err);
    });
};

const isLoggedIn = () => !!kc.token;

const doLogin = () => {
  kc.login();
  localStorage.setItem('isLoggedIn', true);
};
const doLogout = () => {
  kc.logout({ redirectUri: `${window.location.origin}/login` });
  localStorage.removeItem('isLoggedIn');
};

const getToken = () => kc.token;
const getTokenParsed = () => kc.tokenParsed;

const updateToken = () =>
  new Promise((resolve, reject) => {
    kc.updateToken(5)
      .then(() => {
        resolve(kc.token);
      })
      .catch(() => {
        doLogin();
        reject(new Error('Failed to update token'));
      });
  });

const hasRole = (roles) => roles.some((role) => kc.hasRealmRole(role));

const AuthService = {
  initKeycloak,
  isLoggedIn,
  doLogin,
  doLogout,
  getToken,
  getTokenParsed,
  updateToken,
  hasRole,
};


export default AuthService;
