import { useCallback, useContext, useEffect, useState } from 'react';
import { ConfigProvider, Space, Tabs } from 'antd';
import RefreshDeliveryList from './DeliveryListItems/RefreshDeliveryList';
import DeliverySearchInput from './DeliveryListItems/DeliverySearchInput';
import OnGoingListWrapper from './OnGoing/OnGoingListWrapper';
import ProcessedListWrapper from './Processed/ProcessedListWrapper';
import InactiveListWrapper from './Inactive/InactiveListWrapper';
import { readDeliveries } from '../../services/apiEndPoints/deliveries/readDeliveries';
import LabelBadgeCounter from '../LabelBadgeCounter/LabelBadgeCounter';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import formatReadDeliveriesParamsUrl from '../../utils/deliveries/formatReadDeliveriesParamsUrl';
import getSearchParams from '../../utils/getSearchParams';
import { translateOrderAntd } from '../../utils/translateOrderAntd';
import formatDeliveriesColumns from '../../utils/deliveries/formatDeliveriesColumns';
import { sizes } from '../../globalVar/theme';
import DeliveryListMobileFilters from './DeliveryListItems/DeliveryListMobileFilters';
import DeliveryListMobilePagination from './DeliveryListItems/DeliveryListMobilePagination';
import { customizeRenderEmpty } from '../../services/customizeRenderEmpty';
import { useWindowSize } from '../../hooks/useWindowSize';
import { AppContext } from '../../context/AppContext';

const DEFAULT_ACTIVE_TAB = 'ongoing';

const DELIVERY_LIST_MEMORY_FILTER = ['ongoing'];
const DELIVERY_LIST_REDUCER_ACTIONS = {
  update: {
    'ongoing': 'UPDATE_DELIVERY_ONGOING_LIST',
    'inactive': 'UPDATE_DELIVERY_INACTIVE_LIST',
    'action-needed': 'UPDATE_DELIVERY_ACTION_NEEDED_LIST',
  },
};

/**
 * Renders the DeliveryListContainer component.
 *
 * @return {JSX.Element} Return the JSX element.
 */
function DeliveryListContainer() {
  const { t } = useTranslation();
  const { search } = useLocation();
  const defaultActiveKey = getSearchParams(search).label || DEFAULT_ACTIVE_TAB;
  const navigate = useNavigate();
  const windowSize = useWindowSize();
  const searchValueParam = getSearchParams(search).q;

  const [loading, setLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [listInError, setListInError] = useState(false);
  const [deliveries, setDeliveries] = useState([]);
  const [store, dispatch] = useContext(AppContext);
  const [tableParams, setTableParams] = useState({
    pagination: {
      total: 0,
      current: getSearchParams(search).page,
      pageSize: getSearchParams(search).limit,
      showSizeChanger: true,
      pageSizeOptions: [10, 15, 20],
      position: ['bottomCenter'],
    },
    filters: {
      status: getSearchParams(search).status,
      timeSlot: getSearchParams(search).timeSlot,
    },
    sorter: {
      field: getSearchParams(search).sort,
      order: translateOrderAntd(getSearchParams(search).order, 'long'),
    },
  });

  /**
    * Returns the URL filters object based on the search parameters. Return null if only label is present.
    *
    * @return {Object|null} - The URL filters object containing status, timeSlot, sorter, label, and q.
    */
  const getURLFilters = () => {
    const searchParams = getSearchParams(search);
    const filters = (searchParams.status || searchParams.timeSlot) ? {
      status: searchParams.status,
      timeSlot: searchParams.timeSlot,
    } : null;
    const sorter = searchParams.sort ? {
      field: searchParams.sort,
      order: translateOrderAntd(searchParams.order, 'long'),
    } : null;

    if (searchParams) {
      return (filters || sorter || searchParams.q) ? {
        filters,
        sorter: sorter,
        label: searchParams.label,
        q: searchParams.q,
      } : null;
    } else {
      return null;
    }
  };

  const setMemoryFilters = (key, url) => {
    if (DELIVERY_LIST_MEMORY_FILTER.includes(key) && Object.prototype.hasOwnProperty.call(DELIVERY_LIST_REDUCER_ACTIONS.update, key)) {
      dispatch({ type: DELIVERY_LIST_REDUCER_ACTIONS.update[key], payload: url });
    }
  };

  useEffect(() => {
    const filters = getURLFilters();
    if (filters && !store.deliveryList[filters.label]) {
      setMemoryFilters(filters.label, search);
    }
  }, []);

  const dateFilters = [{
    text: t('TODAY'),
    value: 'today',
  }, {
    text: t('TOMORROW'),
    value: 'tomorrow',
  }, {
    text: t('AFTER_TOMORROW'),
    value: 'afterTomorrow',
  }];

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    getDeliveriesList(signal);

    return () => {
      controller.abort();
    };
  }, [search]);

  const getDeliveriesList = (signal) => {
    setLoading(true);
    setListInError(false);
    readDeliveries(search, signal)
      .then((result) => {
        if (result.aborted) return;
        setLoading(false);
        setRefreshing(false);
        if (result?.success) {
          setDeliveries(() => formatDeliveriesColumns(result.list));
          setTableParams({
            ...tableParams,
            pagination: {
              ...tableParams.pagination,
              total: result.count,
            },
          });
        }
      })
      .catch(() => {
        setLoading(false);
        setRefreshing(false);
        setListInError(true);
      });
  };

  const handleTabChange = (key) => {
    setDeliveries([]);
    setTableParams({
      pagination: {
        ...tableParams.pagination,
        total: 0,
        current: 1,
        pageSize: 10,
      },
      filters: {
        status: getSearchParams(store.deliveryList).status,
        timeSlot: getSearchParams(store.deliveryList).timeSlot,
      },
      sorter: {
        field: getSearchParams(store.deliveryList).sort,
        order: translateOrderAntd(getSearchParams(store.deliveryList).order, 'long'),
      },
    });

    // Memorize the current search params to keep them when changing tab
    const currentUrl = new URL(window.location.href);
    const q = currentUrl.searchParams.get('q');

    if (DELIVERY_LIST_MEMORY_FILTER.includes(key) && store.deliveryList[key]) {
      return navigate(store.deliveryList[key]);
    } else {
      const newUrl = formatReadDeliveriesParamsUrl({ label: key, q });
      setMemoryFilters(key, newUrl);

      return navigate(newUrl);
    }
  };

  const refreshList = useCallback(() => {
    setLoading(true);
    setRefreshing(true);
    getDeliveriesList();
  }, [search]);

  const launchSearch = useCallback((q) => {
    const url = new URL(window.location.href);
    url.searchParams.set('q', q);
    navigate(url.pathname + url.search);
    setMemoryFilters(defaultActiveKey, url.search);
  }, []);

  const handleTableChanges = useCallback(({ label, pagination, filters, sorter }) => {
    const url = new URL(window.location.href);

    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setDeliveries([]);
    }

    const newUrl = formatReadDeliveriesParamsUrl({
      label: label || defaultActiveKey,
      page: pagination?.current,
      limit: pagination?.pageSize,
      sort: sorter?.field,
      order: sorter?.order,
      status: filters?.status ? filters?.status[0] : null,
      timeSlot: filters?.timeSlot ? filters?.timeSlot[0] : null,
      q: url.searchParams.get('q') || null,
    });

    setMemoryFilters(label, newUrl);

    navigate(newUrl);
  }, []);

  const lists = [
    {
      key: 'ongoing',
      label: (
        <LabelBadgeCounter
          text={t('IN_PROGRESS')}
          count={tableParams.pagination.total}
          active={defaultActiveKey === 'ongoing'}
        />
      ),
      children: (
        <OnGoingListWrapper
          deliveries={deliveries}
          loading={loading}
          tableParams={tableParams}
          handleTableChanges={handleTableChanges}
          dateFilters={dateFilters}
        />
      ),
    },
    {
      key: 'action-needed',
      label: (
        <LabelBadgeCounter
          text={t('PROCESSED')}
          count={tableParams.pagination.total}
          active={defaultActiveKey === 'action-needed'}
        />
      ),
      children: (
        <ProcessedListWrapper
          deliveries={deliveries}
          loading={loading}
          tableParams={tableParams}
          handleTableChanges={handleTableChanges}
        />
      ),
    },
    {
      key: 'inactive',
      label: (
        <LabelBadgeCounter
          text={t('INACTIVE')}
          count={tableParams.pagination.total}
          active={defaultActiveKey === 'inactive'}
        />
      ),
      children: (
        <InactiveListWrapper
          deliveries={deliveries}
          loading={loading}
          tableParams={tableParams}
          handleTableChanges={handleTableChanges}
        />
      ),
    },
  ];

  return (
    <Space direction="vertical">
      <DeliveryListMobileFilters
        activeTab={defaultActiveKey}
        searchValueParam={searchValueParam}
        launchSearch={launchSearch}
        tableParams={{ ...tableParams, ...store.deliveryList[defaultActiveKey] }}
        handleTableChanges={handleTableChanges}
      />
      <ConfigProvider
        renderEmpty={() => {
          return customizeRenderEmpty({
            customizeEmpty: listInError,
            refreshLink: refreshList,
          });
        }}
      >
        <Tabs
          type="card"
          defaultActiveKey={defaultActiveKey}
          activeKey={defaultActiveKey}
          onChange={handleTabChange}
          tabBarExtraContent={windowSize.width > sizes.sm ? {
            left: <RefreshDeliveryList loading={refreshing} action={refreshList}/>,
            right: <DeliverySearchInput launchSearch={launchSearch} searchValueParam={searchValueParam} />,
          } : null}
          items={lists}
        />
      </ConfigProvider>
      <DeliveryListMobilePagination activeTab={defaultActiveKey} tableParams={tableParams} handleTableChanges={handleTableChanges} />
    </Space>
  );
}

export default DeliveryListContainer;
