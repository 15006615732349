import { Drawer, Form, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import ArchiveAddressButtonDrawer from '../CTA/ArchiveAddressButtonDrawer';
import UpdateAddressForm from '../Form/UpdateAddressForm';
import SubmitButton from '../../Buttons/SubmitButton';
import CreateAddressForm from '../Form/CreateAddressForm';
import { useState } from 'react';

/**
 * Renders the AddressDrawer component.
 * @param {Object} props - The props of the component.
 * @param {boolean} opened
 * @param {string} action - create, update, select
 * @param {Object} address
 * @param {Function} setAddresses
 * @param {number} userId
 * @param {Function} setDrawerOpened
  * @param {number} indexValueSelected
 * @param {Function} setIndexValueSelected
 * @param {number} index
 * @return {JSX.Element}
 */
function AddressDrawer({ opened, action, address, setAddresses, userId, setDrawerOpened, indexValueSelected, setIndexValueSelected, index }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [showAdditionalInfoForm, setShowAdditionalInfoForm] = useState(false);

  const drawerWording = {
    'create': {
      title: 'ADD_AN_ADDRESS',
    },
    'update': {
      title: 'MODIFY_AN_ADDRESS',
    },
  };

  const onClose = () => {
    setShowAdditionalInfoForm(false);
    setDrawerOpened(false);
    form.resetFields();
  };

  return (
    <Drawer
      title={t(drawerWording[action].title)}
      open={opened}
      onClose={onClose}
      footer={
        <Space>
          {action === 'update' ?
            <ArchiveAddressButtonDrawer
              address={address}
              setAddresses={setAddresses}
              setDrawerOpened={setDrawerOpened}
              indexValueSelected={indexValueSelected}
              setIndexValueSelected={setIndexValueSelected}
              index={index}
            /> : null}
          <SubmitButton form={form} onClick={() => form.submit()}>
            {t('SAVE')}
          </SubmitButton>
        </Space>}
      width={window.innerWidth <= 630 ? '100%' : 630}
    >
      {action === 'create' &&
        <CreateAddressForm
          form={form}
          setAddresses={setAddresses}
          userId={userId}
          setDrawerOpened={setDrawerOpened}
          showAdditionalInfoForm={showAdditionalInfoForm}
          setShowAdditionalInfoForm={setShowAdditionalInfoForm}
          setIndexValueSelected={setIndexValueSelected}
        />
      }
      {action === 'update' &&
        <UpdateAddressForm
          form={form}
          address={address}
          setAddresses={setAddresses}
          setDrawerOpened={setDrawerOpened}
          setIndexValueSelected={setIndexValueSelected}
        />
      }
    </Drawer>
  );
}

export default AddressDrawer;
