/** @jsxImportSource @emotion/react */
import { React, useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useDebounce from '../../../hooks/useDebounce';
import { NotificationCenterContext } from '../../../context/NotificationCenterContext';
import useFetch from 'use-http';
import { useRouter } from '../../../hooks/useRouter';
// Components
import { Drawer, Space, Alert, Radio, Form, Input, Switch, Avatar, AutoComplete, Button, Col, Row, Popconfirm } from 'antd';
import HighlightText from '../../HighlightText/HighlightText';
import RadioCard from '../../RadioCard';
import { writeUserBan } from '../../../services/apiEndPoints/users/moderation';

const { TextArea } = Input;

/**
  * UserBanishmentFormDrawer
  * @constructor
  * @param {Object} props
  * @param {boolean} isBanUser
  * @param {boolean} childVisible
  * @param {Function} setChildVisible
  * @param {Function} setIsBanUser
  * @param {Function} setIsBanishFromAllDrive
  * @param {Function} getUserDeactivationReasons
  * @param {Function} setVisible
  * @return {JSX.Element}
  * @constructor
 */
function UserBanishmentFormDrawer({ isBanUser, childVisible, setChildVisible, setIsBanUser, setIsBanishFromAllDrive, getUserDeactivationReasons, setVisible }) {
  const { t } = useTranslation();
  const notification = useContext(NotificationCenterContext);
  const [optionsListDrive, setOptionsListDrive] = useState([]);
  const [searchDriveInput, setSearchDriveInput] = useState(null);
  const [searchDriveInputId, setSearchDriveInputId] = useState(null);
  const [isBanFromAllPickupPointsChecked, setIsBanFromAllPickupPointsChecked] = useState(true);
  const [disableSubmitButton, setDisableSubmitButton] = useState(true);
  const router = useRouter();
  const debouncedSearchTerm = useDebounce(searchDriveInput, 500);
  const driveFetch = useFetch(process.env.REACT_APP_API_BO, {
    cacheLife: parseInt(process.env.REACT_APP_LOCAL_CACHING_DATA, 10),
  });
  const [form] = Form.useForm();
  const [formOnePickUpPoint] = Form.useForm();
  const layout = {
    labelCol: { span: 16 },
    wrapperCol: { span: 8 },
  };

  /**
   * onClose - Fermeture du drawer
   * @return {void}
   */
  function onClose() {
    setChildVisible(false);
  }

  /**
   * clearForm - Reset du formulaire
   * @return {void}
   */
  const clearForm = () => {
    form.resetFields();
    formOnePickUpPoint.resetFields();
    setIsBanFromAllPickupPointsChecked(true);
    setDisableSubmitButton(true);
    setChildVisible(false);
    setVisible(false);
  };

  /**
   * launchDriveSearch - Lancement de la recherche de drive
   * @return {Promise<void>}
   */
  const launchDriveSearch = async() => {
    if (debouncedSearchTerm && debouncedSearchTerm.length >= 3) {
      const results = await driveFetch.get(`/drives?q=${debouncedSearchTerm}`);
      if (driveFetch.response.ok) {
        setOptionsListDrive(results.length > 0 ? searchResult({ drives: results, search: debouncedSearchTerm }) : []);
      } else if (driveFetch.response.status === 404) {
        setOptionsListDrive([]);
      } else {
        notification.push('error', t('INTERNAL_ERROR'));
      }
    }
  };

  useEffect(() => {
    launchDriveSearch();
  }, [debouncedSearchTerm, driveFetch.get, form]);

  /**
   * handleSearch - Gestion de la recherche de drive
   * @param {string} value - Valeur du champ
   * @return {void}
   */
  const handleSearch = (value) => {
    if (value !== searchDriveInput) {
      setSearchDriveInputId(null);
    }
    setSearchDriveInput(value);
  };

  /**
   * searchResult - Résultat de la recherche de drive
   * @param {Array} drives - Liste des drives
   * @param {string} search - Valeur du champ
   * @return {Array}
   */
  const searchResult = ({ drives, search }) => {
    return drives.map((drive) => {
      return {
        drive: drive,
        value: drive.name,
        key: drive.id,
        label: (
          <div>
            <Avatar src={process.env.REACT_APP_STATIC_BUCKET + drive.id + '.webp'} size={22} style={{ marginRight: 5 }} />
            <HighlightText text={`${drive.name}`} highlightTag={search} />
          </div>
        ),
      };
    });
  };

  /**
   * onDriveSelection - Selection d'un drive dans la liste
   * @param {string} value - Valeur du champ
   * @param {Object} option - Option sélectionnée
   * @return {void}
   */
  const onDriveSelection = async(value, option) => {
    form.setFieldsValue({ onePickupPoint: option });
    setSearchDriveInputId(option.drive.id);
    setSearchDriveInput(option.drive.name);
    if (
      form.getFieldValue('desactivationReason')?.length > 0 &&
      form.getFieldValue('descriptionDesactivation')?.length > 0) {
      setDisableSubmitButton(false);
    }
  };

  /**
   * checkDriveInList - Vérification de la présence du drive dans la liste
   * @param {Object} _ - Champ
   * @param {Object} value - Valeur du champ
   * @return {Promise<void>}
   */
  const checkDriveInList = (_, value) => {
    if (!isBanFromAllPickupPointsChecked) {
      if ((typeof form.getFieldValue('driveSearch') !== 'object') && (value?.length > 3 || value.drive)) {
        return Promise.reject(new Error(t('SELECT_DRIVE_FROM_LIST')));
      } else {
        return Promise.resolve();
      }
    } else {
      return Promise.resolve();
    }
  };

  /**
   * onFormFinish - Submit du formulaire
   * @param {Object} values - Valeurs du formulaire
   * @return {void}
   */
  async function onFormFinish() {
    const IdNumber = Number(router.query.id);
    writeUserBan(IdNumber, isBanFromAllPickupPointsChecked ?
      {
        deactivated_reason: form.getFieldValue('desactivationReason'),
        details: form.getFieldValue('descriptionDesactivation')?.trim().replace(/(\r\n|\n|\r)/gm, '<br>'),
      } : {
        deactivated_reason: form.getFieldValue('desactivationReason'),
        drive_id: form.getFieldValue('onePickupPoint')?.drive?.id,
        details: form.getFieldValue('descriptionDesactivation')?.trim().replace(/(\r\n|\n|\r)/gm, '<br>'),
      }).then((response) => {
      if (response.type === 'success') {
        setIsBanUser(true);
        setIsBanishFromAllDrive(isBanFromAllPickupPointsChecked);
        getUserDeactivationReasons();
      }
      notification.push(response.type, t(response.message));
      clearForm();
    });
  }

  /**
   * onSwitchChange - Changement de valeur du switch
   * @param {Boolean} checked - Valeur du switch
   * @return {void}
   */
  function onSwitchChange(checked) {
    setIsBanFromAllPickupPointsChecked(checked);
    formOnePickUpPoint.setFieldsValue({ isBanFromallPickupPoints: checked });
    form.setFieldsValue({ onePickupPoint: null });
  }

  /**
   * onFormCompleted - check if all fileds are completed
   * @return {void}
   */
  function onFormCompleted() {
    if (
      form.getFieldValue('desactivationReason')?.length > 0 &&
      form.getFieldValue('descriptionDesactivation')?.length > 0 &&
      (formOnePickUpPoint.getFieldValue('isBanFromAllPickupPoints') || searchDriveInputId)) {
      setDisableSubmitButton(false);
    } else {
      setDisableSubmitButton(true);
    }
  }

  return (
    <Drawer
      title={t('DEACTIVATION_FORM_TITLE')}
      placement="right"
      width={window.innerWidth <= 630 ? '100%' : 630}
      open={childVisible}
      onClose={onClose}
    >
      <Space direction='vertical' style={{ width: '100%', marginBottom: 20 }}>
        <Alert message={t('WARNING_DEACTIVATION_MSG')} type="warning" showIcon />
      </Space>
      <Form
        layout={layout}
        form={form}
        onFieldsChange={onFormCompleted}
      >
        <RadioCard legacy>
          <Row >
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                labelCol={{ span: 24 } }
                name='desactivationReason'
                label={t('DEACTIVATION_REASON')}
                rules={[{
                  required: true,
                  message: t('DEACTIVATION_REASON_REQUIRED'),
                }]}
              >
                <Radio.Group >
                  <Radio value='THEFT' className='blue-primary-text'>{t('THEFT')}</Radio>
                  <Radio value='BEHAVIOUR' className='blue-primary-text'>{t('BEHAVIOUR')}</Radio>
                  <Radio value='MULTI_ACCOUNTS' className='blue-primary-text'>{t('MULTI_ACCOUNTS')}</Radio>
                  <Radio value='BAD_RATING' className='blue-primary-text'>{t('BAD_RATING')}</Radio>
                  <Radio value='EXCESSIVE_CANCELLATIONS' className='blue-primary-text'>{t('EXCESSIVE_CANCELLATIONS')}</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row >
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Form
                layout='inline'
                form={formOnePickUpPoint}
                onFieldsChange={onFormCompleted}
                disabled={isBanUser}
                css={{ overflowX: 'hidden' }}
              >
                <Form.Item
                  name='isBanFromAllPickupPoints'
                  initialValue={isBanFromAllPickupPointsChecked}
                  label={t('ALL_PICKUP_POINT_DEACTIVATION')}
                  rules={[{
                    required: true,
                  }]}
                >
                  <Switch defaultChecked onChange={onSwitchChange}/>
                </Form.Item>
              </Form>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} >
              <Form.Item
                name='onePickupPoint'
                label={t('ONE_PICKUP_POINT_DEACTIVATION')}
                rules={[{
                  required: !isBanFromAllPickupPointsChecked,
                  message: t('PICKUP_POINT_REQUIRED'),
                }, {
                  validator: checkDriveInList,
                }]}
                initialValue={null}
              >
                <AutoComplete
                  id='search-drive-user-desactivation'
                  options={optionsListDrive}
                  onSearch={handleSearch}
                  onSelect={onDriveSelection}
                  disabled={isBanFromAllPickupPointsChecked}
                >
                  <Input.Search loading={driveFetch.loading} />
                </AutoComplete>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                labelCol={{ span: 24 } }
                name='descriptionDesactivation'
                label={t('DEACTIVATION_DESCRIPTION')}
                rules={[{
                  required: true,
                  message: t('DEACTIVATION_DESCRIPTION_REQUIRED'),
                }]}
              >
                <TextArea showCount placeholder={t('DEACTIVATION_DESCRIPTION_PLACEHOLDER')} maxLength={255} />
              </Form.Item>
            </Col>
          </Row>
        </RadioCard>
        <Popconfirm
          title={t('DEACTIVATION_POPIN')}
          onConfirm={onFormFinish}
          okText={t('YES')}
          cancelText={t('NO')}
          okButtonProps={{ shape: 'round' }}
          cancelButtonProps={{ shape: 'round' }}
          disabled={disableSubmitButton}
        >
          <Button
            disabled={disableSubmitButton}
            type='primary'
          >
            {t('DEACTIVATE_BUTTON')}
          </Button>
        </Popconfirm>
      </Form>
    </Drawer>
  );
}

export default UserBanishmentFormDrawer;
