import AuthService from '../AuthService';

export const apiEndPointsOptions = async(method, data, signal) => {
  const headers = {
    'Content-Type': 'application/json;charset=utf-8',
    'x-shopopop-backoffice-user-countries': localStorage.getItem('CountriesFilter') || '',
  };
  if (AuthService.isLoggedIn()) {
    try {
      const updateToken = await AuthService.updateToken();
      headers.Authorization = `Bearer ${updateToken}`;
    } catch (e) {
      console.error('Failed to update token', e);
    }
  }
  return {
    method: method,
    headers: new Headers(headers),
    credentials: 'include',
    cache: 'no-cache',
    body: JSON.stringify(data),
    signal,
  };
};
