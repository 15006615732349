/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';
import { Radio, Form, Row, Col, Card, Typography } from 'antd';
import { AppContext } from '../../../../context/AppContext';
import { useTranslation } from 'react-i18next';
import RadioCard from '../../../RadioCard';

const { Text } = Typography;

/**
 * DeliverySizeFieldset
 * @return {fieldset}
 */
function DeliverySizeFieldset() {
  const { t } = useTranslation();
  const [store, dispatch] = useContext(AppContext);

  const handleDeliverySize = (e) =>{
    dispatch({ type: 'CREATE_ORDERED_INFOS_SIZE', payload: {
      size: e?.target?.value,
    }});
  };

  return (
    <Card title={t('VOLUME_DELIVERY')}>
      <Row gutter={{ md: 46 }}>
        <Col span={24}>
          <Text type='secondary' italic>
            {t('DELIVERY_SIZE_EXPLICATION')}
          </Text>
          <div css={{ height: 20 }} /> {/* oupsy ! Chhhhhhhhuuuuuu... */}
          <RadioCard legacy>
            <Form.Item
              name='deliverySize'
              label={t('VOLUME_DELIVERY')}
              rules={[{
                required: true,
                message: t('DELIVERY_SIZE_REQUIRED'),
              }]}
              initialValue={store.delivery.size}
            >
              <Radio.Group
                onChange={handleDeliverySize}
                initialValues={store.delivery.size}
                id='handle-delivery-size-create-step2'
              >
                <Radio value='S' className='blue-primary-text'>
                  <span>S</span>{t('DELIVERY_SIZE_S')}
                </Radio>
                <Radio value='M' className='blue-primary-text'>
                  <span>M</span>{t('DELIVERY_SIZE_M')}
                </Radio>
                <Radio value='L' className='blue-primary-text'>
                  <span>L</span>{t('DELIVERY_SIZE_L')}
                </Radio>
                <Radio value='XL' className='blue-primary-text'>
                  <span>XL</span>{t('DELIVERY_SIZE_XL')}
                </Radio>
              </Radio.Group>
            </Form.Item>
          </RadioCard>
        </Col>
      </Row>
    </Card>
  );
}
export default DeliverySizeFieldset;
