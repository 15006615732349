import { Flex, Pagination } from 'antd';
import { sizes } from '../../../globalVar/theme';
import { useWindowSize } from '../../../hooks/useWindowSize';

/**
 * Renders the mobile pagination component for the delivery list.
 *
 * @param {string} activeTab - The actual tab opened (ongoing etc...)
 * @param {Object} tableParams - The parameters used to configure the table.
 * @param {Function} handleTableChanges - The function to handle table changes.
 * @return {JSX.Element|null} - The JSX element representing the mobile pagination component or null if the window size is larger than sm.
 */
function DeliveryListMobilePagination({ activeTab, tableParams, handleTableChanges }) {
  const windowSize = useWindowSize();

  return windowSize.width <= sizes.sm ? (
    <Flex justify="center">
      <Pagination
        current={tableParams.pagination?.current || 1}
        total={tableParams.pagination?.total}
        onChange={(value) => {
          handleTableChanges({
            ...tableParams,
            label: activeTab,
            pagination: {
              ...tableParams.pagination,
              current: value,
            },
          });
        }}
      />
    </Flex>
  ) : null;
}

export default DeliveryListMobilePagination;
