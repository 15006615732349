/** @jsxImportSource @emotion/react */
import { React, useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from '../../../hooks/useRouter';
import { NotificationCenterContext } from '../../../context/NotificationCenterContext';
import findColorTagsComment from '../../../utils/findColorTagsComment';
// Components
import { Drawer, Form, Input, Tag, Button, Select } from 'antd';
import { writeInternComment } from '../../../services/apiEndPoints/users/moderation';
const { TextArea } = Input;

/**
  * UserCommentFormDrawer
  * @constructor
  * @param {Object} props
  * @param {boolean} visibleCommentDrawer
  * @param {Function} setVisibleCommentDrawer
  * @param {Function} setUserInternComments
  * @param {Object} userInternComments
  * @param {Object} commentForm
  * @param {Function} setActiveTab
  * @return {JSX.Element}
  * @constructor
 */
function UserCommentFormDrawer({ visibleCommentDrawer, setVisibleCommentDrawer, setUserInternComments, userInternComments, commentForm, setActiveTab }) {
  const { t } = useTranslation();
  const notification = useContext(NotificationCenterContext);
  const form = commentForm;
  const [disableSubmitButton, setDisableSubmitButton] = useState(true);
  const router = useRouter();

  /**
   * onClose - Fermeture du drawer
   * @return {void}
   */
  function onClose() {
    setVisibleCommentDrawer(false);
  }
  const optionsTags = [
    {
      label: t('BEHAVIOUR'),
      options: [
        { label: t('GOOD_BEHAVIOUR'), value: 'GOOD_BEHAVIOUR' },
        { label: t('BAD_BEHAVIOUR'), value: 'BAD_BEHAVIOUR' },
      ],
    },
    {
      label: t('PUNCTUALITY'),
      options: [
        { label: t('ON_TIME'), value: 'ON_TIME' },
        { label: t('NOT_ON_TIME'), value: 'NOT_ON_TIME' }],
    },
    {
      label: t('DELIVERY_AT_THE_DOOR'),
      options: [
        { label: t('AT_THE_DOOR'), value: 'AT_THE_DOOR' },
        { label: t('NOT_AT_THE_DOOR'), value: 'NOT_AT_THE_DOOR' },
      ],
    },
  ];
  useEffect(() => {
    onFormCompleted();
  }, [disableSubmitButton]);

  /**
   * clearForm - Reset du formulaire
   * @return {void}
   */
  const clearForm = () => {
    form.resetFields();
    setVisibleCommentDrawer(false);
  };
  // Logic in form

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    return (
      <Tag
        css={{
          display: 'flex',
          alignItems: 'center',
          marginTop: '5px',
          marginBottom: '5px',
        }}
        style={findColorTagsComment(value)}
        closable={closable}
        onClose={onClose}
      >
        {label}
      </Tag>
    );
  };

  const checkTagNumber = (_, value) => {
    if (value.length > 3) {
      return Promise.reject(new Error(t('MAX_TAG_COMMENT_AUTHORIZED')));
    } else {
      return Promise.resolve();
    }
  };
  /**
   * onFormCompleted - check if all fileds are completed and in the correct length
   * @return {void}
   */
  function onFormCompleted() {
    if ( (form.getFieldValue('userFeedback')?.length > 0 && form.getFieldValue('description')?.length > 0) && form.getFieldValue('userFeedback')?.length <= 3) {
      setDisableSubmitButton(false);
    } else {
      setDisableSubmitButton(true);
    }
  }

  /**
   * onFormFinish - Submit du formulaire
   * @param {Object} values - Valeurs du formulaire
   * @return {void}
   */
  async function onFormFinish() {
    const description = form.getFieldValue('description');
    const IdNumber = Number(router.query.id);
    const payload = {
      userFeedback: form.getFieldValue('userFeedback'),
      description: description?.trim().replace(/(\r\n|\n|\r)/gm, '<br>'),
    };
    writeInternComment(IdNumber, payload).then((response) => {
      if (response.type === 'success') {
        setUserInternComments([...userInternComments, {
          userFeedback: response.data.userFeedback,
          description: response.data.description,
          createdBy: response.data.createdBy,
          createdByFirstName: response.data.createdByFirstName,
          createdByLastName: response.data.createdByLastName,
          createdAt: response.data.createdAt,
          deactivationType: 'INTERN_COMMENT_V2',
        }]);
      }
      setVisibleCommentDrawer(false);
      setActiveTab('userManagementHistory');
      notification.push(response.type, t(response.message));
      clearForm();
    });
  }

  return (
    <Drawer
      title={t('COMMENT_FORM_TITLE')}
      placement="right"
      width={window.innerWidth <= 630 ? '100%' : 630}
      open={visibleCommentDrawer}
      onClose={onClose}
      footer={
        <Button
          type='primary'
          size='big'
          onClick={onFormFinish}
          disabled={disableSubmitButton}
        >
          {t('ADD_COMMENT_BUTTON')}
        </Button>
      }
    >
      <Form
        layout={'vertical'}
        form={form}
        onFieldsChange={onFormCompleted}
      >
        <Form.Item
          name='userFeedback'
          label={t('INTERN_COMMENT_TAG')}
          rules={[
            {
              required: true,
              message: t('TAG_REQUIRED'),
            },
            { validator: checkTagNumber,
            },
          ]}
        >
          <Select
            multiple
            mode="multiple"
            listHeight={300}
            placeholder={t('COMMENT_TAG_PLACEHOLDER')}
            tagRender={tagRender}
            options={optionsTags}
          />
        </Form.Item>
        <Form.Item
          name='description'
          label={t('INTERN_COMMENT_DESCRIPTION')}
          rules={[
            {
              required: true,
              message: t('DESCRIPTION_REQUIRED'),
            },
          ]}
        >
          <TextArea showCount maxLength={255} placeholder={t('COMMENT_DESCRIPTION_PLACEHOLDER')} rows={4} />
        </Form.Item>
      </Form>
    </Drawer>
  );
}

export default UserCommentFormDrawer;
