import { React, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import DrawersContext from '../../context/DrawersContext';
// Components
import { Button } from 'antd';
import { FolderOpenOutlined } from '@ant-design/icons';
import ArchiveDrawer from '../Drawers/ArchiveDrawer';

/**
 * Archivage
 * @param {Object} delivery - Contain information of the delivery
 * @param {Function} setDelivery - Set the delivery
 * @param {Function} setStaticDelivery - Set the static delivery
 * @return {JSX.Element}
 */
function Archivage({ delivery, setDelivery, setStaticDelivery }) {
  const { t } = useTranslation();
  const showDrawer = useContext(DrawersContext)['setVisible'];
  const setCurrentDrawer = useContext(DrawersContext)['setCurrentDrawer'];
  /**
   * openArchiveDrawer
   */
  function openArchiveDrawer() {
    showDrawer(true);
    setCurrentDrawer(
      <ArchiveDrawer
        deliveryInfo={delivery}
        setDelivery={setDelivery}
        setStaticDelivery={setStaticDelivery}
      />,
    );
  }
  return delivery.status >= 1 && delivery.status <= 2 ? (
    <Button
      danger
      onClick={openArchiveDrawer}
      icon={<FolderOpenOutlined />}
      id='button-archivage'
    >
      {t('ARCHIVE')}
    </Button>
  ) : null;
}

export default Archivage;
