/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import { Popover, Modal, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { findKycStatus } from '../../../../utils/findKycStatus';
import { weblateDefinedKeyKyc } from '../../../../utils/weblateDefinedKeyKyc';
import UntouchableView from '../../../DeviceDetect/UntouchableView';
import TouchableView from '../../../DeviceDetect/TouchableView';
import { breakpoints, colors } from '../../../../globalVar/theme';

/**
 * KYCStatus
 * @param {object} kycView
 * @param {object} kycListDatabase
 * @return {JSX}
 */
function KYCStatus({ kycView, kycListDatabase }) {
  const { t } = useTranslation();
  // Modal (used for kyc view detail on mobile)
  const [modalTitle, setModalTitle] = useState();
  const [modalContent, setModalContent] = useState();

  const numberDocValid = kycView.filter((doc) => (doc.status === 'VALIDATED')).length;

  /**
   * getKycRefusedReason
   * @return {string}
   */
  function getKycRefusedReason() {
    const refusedOnfido = kycListDatabase.find((doc) => doc.type === 'id_card_shopopop_refused_reason');
    const refusedMango = kycListDatabase.find((doc) => doc.type === 'id_card_mango_refused_reason');

    return refusedOnfido?.status ?? refusedMango?.status;
  }

  /**
   * viewDetail
   * @param {Object} doc
   * @return {void}
   */
  function viewDetail(doc) {
    setModalTitle(t(`KYC_${doc.type}`));
    setModalContent(
      <div css={shopperKYCStateStyle}>
        <div css={{ color: findKycStatus((doc.status)).color }}>
          {t(weblateDefinedKeyKyc(kycListDatabase, doc.type, doc.status))}
          {doc.type === 'identity_card' && getKycRefusedReason() ? t(`KYC_${getKycRefusedReason()}`) : null}
        </div>
        {doc.type === 'identity_card' && <p>{t(getKycRefusedReason())}</p>}
      </div>,
    );
    showModal();
  }

  const shopperValidatedKYCStyle = {
    marginBottom: 5,
    fontSize: 12,
    textTransform: 'lowercase',
    fontWeight: 'normal',
  };

  const shopperKYCStyle = {
    width: '15vw',
    height: 16,
    borderRadius: 16,
    [breakpoints.md]: {
      width: '8vw',
    },
    [breakpoints.lg]: {
      width: '3vw',
      height: '8px',
    },
  };

  const shopperKYCListStyle = {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    gap: 3,
  };

  const shopperKYCStateStyle = {
    'p': {
      margin: 0,
    },
    'p:nth-child(2)': {
      color: colors.blueMiddle,
    },
    [breakpoints.lg]: {
      fontSize: 12,
    },
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal
        title={modalTitle}
        centered
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleOk}
        footer={[
          <Button key="submit" type="primary" onClick={handleOk}>
            Ok
          </Button>,
        ]}
      >
        {modalContent}
      </Modal>
      <div>
        <p css={shopperValidatedKYCStyle}> {numberDocValid} {t('KYC_OF')} {kycView.length} {t('KYC_ALLDOC_COMPLETED')}</p>
        <div css={shopperKYCListStyle}>
          {kycView?.map((doc, index) => (
            <div key={index}>
              <UntouchableView>
                <Popover
                  placement='bottomRight'
                  title={t(`KYC_${doc.type}`)}
                  content={
                    <div css={shopperKYCStateStyle}>
                      <div css={{ color: findKycStatus((doc.status)).color }}>
                        {t(weblateDefinedKeyKyc(kycListDatabase, doc.type, doc.status))}
                        {doc.type === 'identity_card' && getKycRefusedReason() ? t(`KYC_${getKycRefusedReason()}`) : null}
                      </div>
                      {doc.type === 'identity_card' && <p>{t(getKycRefusedReason())}</p>}
                    </div>
                  }
                >
                  <div
                    css={{
                      ...shopperKYCStyle,
                      backgroundColor: findKycStatus((doc.status)).color,
                    }}>
                  </div>
                </Popover>
              </UntouchableView>

              <TouchableView>
                <div
                  css={{
                    ...shopperKYCStyle,
                    backgroundColor: findKycStatus((doc.status)).color,
                  }}
                  onClick={() => viewDetail(doc)}
                >
                </div>
              </TouchableView>

            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default KYCStatus;
