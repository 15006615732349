import React from 'react';
import Dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import useCustomHtml from '../../../../../hooks/useCustomHtml';
import { toLocaleStringHourandMinutes } from '../../../../../utils/toLocaleStringHourandMinutes';

/**
 * ShiftedHistoryNode
 * @param {Object} props
 * @param {Object} props.oldDate
 * @param {Date} props.start
 * @param {Date} props.end
 * @param {Object} props.newDate
 * @param {Date} props.start
 * @param {Date} props.end
 * @param {Object} props.user
 * @param {string} props.user.firstName
 * @param {string} props.user.lastName
 * @return {JSX.Element}
 */
function ShiftedHistoryNode({ oldDate, newDate, user }) {
  const { t } = useTranslation();

  const deliveryDateUpdate = useCustomHtml({
    keyValues: [
      { key: '[SHOPOPOP_NAME]', value: `${user.firstName} ${user.lastName}`, bold: true },
      { key: '[PREVIOUS_DATE]', value: new Dayjs(oldDate.start).format('D MMMM') },
      { key: '[NEW_DATE]', value: new Dayjs(newDate.start).format('D MMMM'), bold: true },
    ],
    inputString: t('DELIVERY_DATE_UPDATE'),
  });

  const deliveryTimeSlot = useCustomHtml({
    keyValues: [
      { key: '[SHOPOPOP_NAME]', value: `${user.firstName} ${user.lastName}`, bold: true },
      { key: '[PREVIOUS_TIMESLOT]', value: oldDate.start ? `${toLocaleStringHourandMinutes(oldDate.start)}-${toLocaleStringHourandMinutes(oldDate.end)}` : '' },
      { key: '[NEW_TIMESLOT]', value: newDate.start ? `${toLocaleStringHourandMinutes(newDate.start)}-${toLocaleStringHourandMinutes(newDate.end)}` : '', bold: true },
    ],
    inputString: t('DELIVERY_TIMESLOT'),
  });

  const deliveryDateAndTimeSlot = useCustomHtml({
    keyValues: [
      { key: '[SHOPOPOP_NAME]', value: `${user.firstName} ${user.lastName}`, bold: true },
      { key: '[PREVIOUS_DATE]', value: new Dayjs(oldDate.start).format('D MMMM') },
      { key: '[NEW_DATE]', value: new Dayjs(newDate.start).format('D MMMM'), bold: true },
      { key: '[PREVIOUS_TIMESLOT]', value: oldDate.start ? `${toLocaleStringHourandMinutes(oldDate.start)}-${toLocaleStringHourandMinutes(oldDate.end)}` : '' },
      { key: '[NEW_TIMESLOT]', value: newDate.start ? `${toLocaleStringHourandMinutes(newDate.start)}-${toLocaleStringHourandMinutes(newDate.end)}` : '', bold: true },
    ],
    inputString: t('DELIVERY_DATE_AND_TIMESLOT'),
  });

  const deliveryTimeSlotOnError = useCustomHtml({
    keyValues: [
      { key: '[SHOPOPOP_NAME]', value: `${user.firstName} ${user.lastName}`, bold: true },
    ],
    inputString: t('DELIVERY_TIMESLOT_ON_ERROR'),
  });

  // Check if this is day or month or year as changed
  const isDayChanged = new Dayjs(oldDate.start).format('DD') !== new Dayjs(newDate.start).format('DD');
  const isMonthChanged = new Dayjs(oldDate.start).format('MM') !== new Dayjs(newDate.start).format('MM');
  const isYearChanged = new Dayjs(oldDate.start).format('YYYY') !== new Dayjs(newDate.start).format('YYYY');
  // Check if this is a change of hour and/or minutes
  const isHourStartChanged = new Dayjs(oldDate.start).format('HH') !== new Dayjs(newDate.start).format('HH');
  const isMinuteStartChanged = new Dayjs(oldDate.start).format('mm') !== new Dayjs(newDate.start).format('mm');
  const isHourEndChanged = new Dayjs(oldDate.end).format('HH') !== new Dayjs(newDate.end).format('HH');
  const isMinuteEndChanged = new Dayjs(oldDate.end).format('mm') !== new Dayjs(newDate.end).format('mm');

  // No delivery date and slot
  if (!oldDate.start && !newDate.start && !oldDate.end && !newDate.end) {
    return <div>{deliveryTimeSlotOnError}</div>;
  }

  // Delivery date is modified
  if (isDayChanged || isMonthChanged || isYearChanged) {
    // Delivery date and slot are modified
    if (isHourStartChanged || isMinuteStartChanged || isHourEndChanged || isMinuteEndChanged) {
      return <div>{deliveryDateAndTimeSlot}</div>;
    }
    return <div>{deliveryDateUpdate}</div>;
  }

  // Delivery slot is modified
  if (isHourStartChanged || isMinuteStartChanged || isHourEndChanged || isMinuteEndChanged) {
    return <div>{deliveryTimeSlot}</div>;
  }

  return <></>; // En théorie, on ne devrait jamais arriver ici car on appelle le composant uniquement en cas de changement de date
}

export default ShiftedHistoryNode;
