/** @jsxImportSource @emotion/react */
import { useContext, useEffect, useState } from 'react';
import { SearchOutlined, EditFilled } from '@ant-design/icons';
import { theme, Button, Card, Col, Row, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import TouchableView from '../../../DeviceDetect/TouchableView';
import UntouchableView from '../../../DeviceDetect/UntouchableView';
import UsersList from './UsersList';
import UsersListMobile from './UsersListMobile';
import ModalContext from '../../../../context/ModalContext';
import UserSearchModal from '../../../Modal/UserSearchModal';
import UsersListStep1 from './UserListStep1';
import UserListStep1Mobile from './UserListStep1Mobile';
import useDocumentTitle from '../../../../hooks/useDocumentTitle';
import { useSearchParams } from 'react-router-dom';
import { parsePhoneNumber } from 'libphonenumber-js';
import { readUsers } from '../../../../services/apiEndPoints/users';
import { NotificationCenterContext } from '../../../../context/NotificationCenterContext';

const { Text } = Typography;

/**
  * UserResult
  * @param {boolean} inDeliveryCreation
  * @param {object} formStep1
  * @return {JSX.Element}
  */
function UserResult({ inDeliveryCreation, formStep1 }) {
  const { token } = theme.useToken();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  useDocumentTitle(inDeliveryCreation ? t('URL_DELIVERY_CREATION_RECIPIENT') : t('URL_USER_RESEARCH_RESULTS'));
  const [customizeEmpty, setCustomizeEmpty] = useState(false);
  const [usersResult, setUsersResult] = useState([]);
  const setOpenModalSearch = useContext(ModalContext)['setOpenModalSearch'];
  const colorPrimary = token.colorPrimary;
  const notification = useContext(NotificationCenterContext);
  const [isLoading, setIsLoading] = useState(false);

  const getUserFilter = () => {
    let formParams = [];
    setIsLoading(true);
    if (searchParams.get('name')) formParams = [...formParams, `name=${searchParams.get('name')}`];
    if (searchParams.get('telephone')) formParams = [...formParams, `telephone=${encodeURIComponent(parsePhoneNumber(searchParams.get('telephone')).number)}`];
    if (searchParams.get('email')) formParams = [...formParams, `email=${encodeURIComponent(searchParams.get('email'))}`];

    readUsers(formParams.join('&'))
      .then((response) => {
        setIsLoading(false);
        if (response?.length >= 0) {
          setUsersResult(response);
        } else notification.push(response.type, response.message);
      });
  };

  useEffect(() => {
    getUserFilter();
  }, [searchParams.get('name'), searchParams.get('telephone'), searchParams.get('email')]);


  const updateUserSearch = () => {
    setOpenModalSearch(true);
  };

  useEffect(()=> {
    if (usersResult?.length === 0) {
      setCustomizeEmpty(true);
    }
  }, [usersResult]);

  return (
    <Row gutter={[0, 20]}>
      <Col span={24}>
        <Card>
          <Row justify='space-between' align='middle'>
            <Space align='start' size={15}>
              <SearchOutlined css={{ fontSize: 24, marginTop: 4, color: colorPrimary }} />
              <Space direction='vertical' size={0}>
                <Text strong css={{ fontSize: 16, color: colorPrimary }}> {inDeliveryCreation ? t('SEARCH_CUSTOMER') : t('SEARCH_USER')}</Text>
                <Text css={{ fontSize: 14, color: '#8092BB' }}>
                  {searchParams.get('name')}
                  {searchParams.get('name') && searchParams.get('telephone') && ', '}
                  {searchParams.get('telephone')}
                  {(searchParams.get('name') || searchParams.get('telephone')) && searchParams.get('email') && ', '}
                  {searchParams.get('email')}
                </Text>
              </Space>
            </Space>
            <TouchableView>
              <Button type='primary' shape='circle' icon={<EditFilled />} onClick={updateUserSearch} />
            </TouchableView>
            <UntouchableView>
              <Button type='primary' onClick={updateUserSearch}>{t('UPDATE_SEARCH')}</Button>
            </UntouchableView>
          </Row>
        </Card>
      </Col>
      <UntouchableView>
        {inDeliveryCreation ?
          <UsersListStep1
            customizeEmpty={customizeEmpty}
            updateUserSearch={updateUserSearch}
            usersResult={usersResult}
            formStep1={formStep1}
            loading={isLoading}
          /> :
          <UsersList
            customizeEmpty={customizeEmpty}
            updateUserSearch={updateUserSearch}
            usersResult={usersResult}
            loading={isLoading}
          />
        }
      </UntouchableView>
      <TouchableView>
        {inDeliveryCreation ?
          <UserListStep1Mobile
            customizeEmpty={customizeEmpty}
            updateUserSearch={updateUserSearch}
            usersResult={usersResult}
            formStep1={formStep1}
            loading={isLoading}
          /> :
          <UsersListMobile
            customizeEmpty={customizeEmpty}
            updateUserSearch={updateUserSearch}
            usersResult={usersResult}
            loading={isLoading}
          />
        }
      </TouchableView>
      <UserSearchModal inDeliveryCreation={inDeliveryCreation} />
    </Row>
  );
}

export default UserResult;
