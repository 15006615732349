/** @jsxImportSource @emotion/react */
import { Avatar } from 'antd';
import { colors } from '../../../globalVar/theme';

/**
 * UserAvatar
 * @param {string} avatarFirstName
 * @param {string} avatarLastName
 * @param {string} userId
 * @return {Avatar}
 */
function UserAvatar({ avatarFirstName, avatarLastName, userId }) {
  const generateColor = () => {
    const avatarColors = colors.avatar;

    if (Number(userId) === 205579) return 'yellow';

    return [6234, 6524, 30133, 444526, 844177].includes(Number(userId)) ?
      avatarColors[avatarColors.length - 1] :
      avatarColors[avatarFirstName?.toUpperCase()[0]?.charCodeAt().toString()[1]];
  };

  return (
    <Avatar
      size={95}
      css={{ backgroundColor: generateColor(), marginBottom: 20 }}
    >
      <span css={{ fontSize: 40, color: 'rgba(0, 0, 0, 0.5)' }}>{avatarFirstName?.toUpperCase()[0]}{avatarLastName?.toUpperCase()[0]}</span>
    </Avatar>
  );
}
export default UserAvatar;
