
import { Button } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';

/**
 * Refreshes the delivery list by setting the loading state to true.
 *
 * @return {JSX.Element} - The JSX element representing the refresh button.
 */
function RefreshDeliveryList({ loading, action }) {
  const refreshDeliveryList = () => {
    action();
  };

  return (
    <Button
      type="primary"
      shape="circle"
      loading={loading}
      icon={<ReloadOutlined />}
      onClick={refreshDeliveryList}
      style={{ marginRight: 15 }}
    />
  );
}

export default RefreshDeliveryList;
