/**
 *  This function takes an array of objects and returns an array of objects sorted by the name property
 * @param {Array} CountryArray
 * @return {Array}
 */
export function countryAlphaOrder(CountryArray) {
  const mappedCountries = CountryArray.map((country, index) => {
    return {
      index: index,
      name: country.name,
    };
  });
  mappedCountries.sort((a, b) => {
    return a.name > b.name ? 1 : -1;
  });
  return [
    ...mappedCountries.map((country) => {
      return CountryArray[country.index];
    }),
  ];
}

