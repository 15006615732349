import { apiEndPointsOptions } from '../../../apiEndPointsOptions';

export const deleteInternComment = async(comment) => {
  const apiUrl = `${process.env.REACT_APP_API_BO}/users/${comment.uuid}/reviews`;
  const method = 'DELETE';

  try {
    const response = await fetch(apiUrl, await apiEndPointsOptions(method));

    if (response.status === 200) {
      return {
        type: 'success',
        message: 'REMOVAL_INTERN_COMMENT_SUCCESS',
      };
    }

    return {
      type: 'error',
      message: 'REMOVAL_INTERN_COMMENT_ERROR',
    };
  } catch (error) {
    throw new Error(`Error in deleteInternComment service: ${error.message}`);
  }
};
