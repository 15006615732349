import { initialUser } from './user/userStore';
import { initialDelivery } from './delivery/deliveryStore';
import { initialDeliveries } from './deliveries/deliveriesStore';
import { inititialMenuOption } from './ActionMenu/menuActionStore';
import { initialCaptcha } from './captcha/captchaStore';
import { initialDeliveryList } from './DeliveryList/deliveryListStore';

const initialState = {
  user: initialUser,
  delivery: initialDelivery,
  deliveries: {
    ongoing: initialDeliveries,
    inactive: initialDeliveries,
    urgent: initialDeliveries,
  },
  deliveryList: initialDeliveryList,
  menuOption: inititialMenuOption,
  captcha: initialCaptcha,
};

const combineReducers = (reducers) => {
  return (state, action) => {
    return Object.keys(reducers).reduce(
      (acc, prop) => {
        return ({
          ...acc,
          ...reducers[prop]({ [prop]: acc[prop] }, action),
        });
      },
      state,
    );
  };
};

export { initialState, combineReducers };
