/** @jsxImportSource @emotion/react */
import { Global, SerializedStyles, css } from '@emotion/react';
import { theme } from 'antd';
import { ReactNode } from 'react';

const { useToken } = theme;

/**
 * The GlobalStyle component.
 * @return {ReactNode}
 */
function GlobalStyle({ specificStyle }: { specificStyle?: SerializedStyles }): ReactNode {
  const { token } = useToken();
  const electricBlue = '#0066FF';
  const electricBlueLight = '#EAF2FF';

  return (
    <Global
      styles={css`
        :root {
            --color-archived: #8E8CC2;
            --color-available: #EB2F96;
            --color-reserved: #0889C0;
            --color-in-progress: #FA9E14;
            --color-to-pickup-point: #5556FE;
            --color-at-pickup-point: #5556FE;
            --color-to-recipient: #0F4BD4;
            --color-delivered-and-finished: #16AC5D;
            --color-default: #2F3960;

            --bg-color-archived: #FAFAFF;
            --bg-color-available: #FFF0F6;
            --bg-color-reserved: #E6F9FF;
            --bg-color-in-progress: #FFFBE6;
            --bg-color-to-pickup-point: #F1EEFF;
            --bg-color-at-pickup-point: #F1EEFF;
            --bg-color-to-recipient: #D2EAFF;
            --bg-color-delivered-and-finished: #F1FFE3;
            --bg-color-default: #EFEFF2;
        }
          
        // Global
        html, body {
          overscroll-behavior: none;
        }
        body {
          overflow-y: scroll;
          margin: 0;
        }
        .ant-typography strong {
          /**
            Info: Le token "fontWeightStrong" du theme ne modifie que les h1, h2, h3...
            donc obligé de surcharger ici pour les strong de Typography.
           */
          font-weight: 700 !important;
        }
        .ant-layout > main {
          padding-top: 0px !important;
        }
        // Menu AntD Link (because we can't actually do this with Ant Design theming system - version 5.13.3)
        .ant-menu-title-content > a > span {
          color: ${token?.Menu?.itemColor} !important;
        }
        .ant-menu-inline-collapsed-tooltip {
          display: none;
        }
        .ant-menu-item-selected .ant-menu-title-content > a > span {
          color: ${token?.Menu?.itemSelectedColor} !important;
        }
        // AntD Trigger on mobile
        .ant-layout-sider-zero-width-trigger {
          top: 10px !important;
          background-color: ${token.colorPrimaryBg} !important;
          z-index: 999999 !important;
        }
        // AntD Tags
        .ant-tag {
          // Depend of general "borderRadius" token, but we can't specify it in "tags" object of the antd theme.
          border-radius: 4px !important;
        }  
        .ant-tag-cyan {
          color: ${electricBlue} !important;
          background-color: ${electricBlueLight} !important;
          border-color: ${electricBlue} !important;
        }
        // AntD Slider
        .ant-slider.ant-slider-disabled .ant-slider-track {
          background-color: #6364F3 !important;
        }
        .ant-slider.ant-slider-disabled .ant-slider-handle::after {
          box-shadow: 0 0 0 2px #6364F3 !important;
        }
        // AntD Notifications
        .force-antd-notification-style {
          font-size: 16px !important;
          a {
            font-family: ${token.fontFamily} !important;
            font-size: 16px !important;
          }
        }
        // Enlever la bordure de l'input search disabled au hover
        .ant-input-disabled:hover {
          border-color: ${token.colorBorderSecondary} !important;
        }
        // ant drawer footer
        .ant-drawer-footer {
          padding: 20px !important;
        }
        .ant-picker-footer {
          display: none !important;
        }
        .ant-btn-link {
          > span {
          text-decoration: underline;
          }
        }

        // Enlever le block blanc dans les timepicker
        .ant-picker-time-panel-column::after {
          display: none !important;
        }

        // Le premier enfant est à gauche avec une taille min de 365px, la partie de droite prend le reste.
        // En mobile elles sont l'une en-dessous de l'autre.
        // La partie de gauche est fixe au scroll (sticky position).
        .dual-panel-wrapper {
          position: relative;
          display: block;
          @media (min-width: ${token.screenLG}px) {
            display: grid;
            grid-template-columns: 365px 1fr;
            grid-gap: 20px;
          }
          > div:first-of-type > div {
            overflow-y: auto;
            @media (min-width: ${token.screenLG}px) {
              position: sticky;
              top: 64px;
              height: calc(100vh - 84px);
            }
          }
        }

        // Fix le bug de la scrollbar qui apparait sur le body
        body {
          background-color: ${token.colorBgLayout} !important;
        }
        
        .ant-notification-notice-message a, .ant-notification-notice-description a {
          text-decoration: underline !important;
        }
        .blue-primary-text {
          // NE PAS METTRE D'IMPORTANT ICI : Cela casserais le style dans d'autres partie du BO, il faudrait alors trouver une autre solution.
          color: ${token.colorPrimary};
        }
        .ant-input-group-wrapper-status-error {
          .ant-input-group-addon button {
            border-color: ${token.colorError} !important;
            color: ${token.colorError} !important;
          }
        }
        .ant-input-number-group-wrapper-status-error {
          .ant-input-number-group-addon {
            border-color: ${token.colorError} !important;
            color: ${token.colorError} !important;
          }
        }
        // Style des notifications
        .ant-notification-notice-message {
          margin-bottom: 0 !important;
          color: ${token.colorPrimaryText} !important;
        }
        .ant-notification-notice-close {
          border-radius: 50px !important;
        }
        // Custom de la list pour les adresses
        .card-addresses {
          .ant-list-item {
            padding: 8px 24px
          }
          .ant-list-item .ant-list-item-meta .ant-list-item-meta-title {
            margin : 0 ;
          }
          p {
            margin: 0;
          }
        }
        .ant-timeline > li:last-child {
          padding-bottom: 0 !important;
        }
        .ant-timeline-item-head-custom {
          padding: 0 !important;
        }
        .ant-timeline-item-head-custom svg {
          fill: white !important;
        }
        .ant-timeline-item-content {
          a {
            text-decoration: underline;
          }
        }
        // Timeline custom in delivery details
        .delivery-timeline-history-wrapper .ant-timeline-label {
          transform: translateX(calc(-50% + 127px)) !important;
        }
        .delivery-timeline-history-wrapper .ant-timeline-item-label {
          padding-right: 7px !important;
        }
        .delivery-timeline-history-wrapper .ant-timeline-item-content {
          width: calc(90% - 12px) !important;
        }
        @media (min-width: 576px) {
          .delivery-timeline-history-wrapper .ant-timeline-item-content {
            width: calc(70% - 12px) !important;
          }
        }
        @media (min-width: 768px) {
          .delivery-timeline-history-wrapper .ant-timeline-item-content {
            width: calc(80% - 12px) !important;
          }
        }
        // Surcharge l'icone de fermeture d'Ant-Design
        .anticon-close {
          width: 20px !important;
          height: 20px !important;
          background-size: 20px !important;
          background-image: url(../assets/close-outline.svg) !important;
          justify-content: center;
        }
        .white-anticon-close .anticon-close {
          background-image: url(../assets/close-outline-white.svg) !important;
        }
        .ant-select-selection-item {
          .anticon-close {
            width: 16px !important;
            background-size: 16px !important;
            background-position-y: 3px !important;
            background-image: url(../assets/close-outline-blue-middle.svg) !important;
          }
        }
        // Fix l'icône de fermeture des Drawers
        .ant-drawer-close {
          height: 20px !important;
          padding: 0 !important;
        }
        // Fix l'icône de fermeture des Modals
        .ant-layout-sider-trigger  {
         background-color: ${token.colorPrimaryActive}!important;
        }
        // Ajout d'un liseré sur les lignes de la table lorsqu'il y a un sous-tableau
        .rowExpanded > td:first-of-type {
            border-left: solid ${token.colorPrimary} 2px !important;
            border-radius: 0px !important ;
          }
        .ant-table-expanded-row > td {
          border-left: solid ${token.colorPrimary} 2px !important;
        },
          
        // Filters in tables
        .ant-table-filter-dropdown-btns > button:first-of-type {
          padding-left: 0;
          margin-right: 20px;
        }
        // Expandable table
        .ant-table-expanded-row {
          width: calc(100% - 200px) !important;
          > td {
            padding: 0 !important;
            > div {
              display: grid;
              grid-template-columns: 48px 1fr;
              grid-gap: 16px;
              > div {
                margin: 16px 16px 16px 0 !important;
                border: 1px solid ${token.colorPrimaryBg} !important;
                border-radius: 8px;
                overflow: hidden;
                th {
                  color: ${token.colorTextSecondary} !important;
                  background-color: white !important;
                }
                .validated {
                  td:first-of-type {
                    border-left: 3px solid #FAAD14;
                  }
                }
              }
              &::before {
                content: '';
                border-right: 1px solid ${token.colorBgLayout} !important;
              }
            }
          }
        }
        .expandable-table tbody tr > td:first-of-type {
          border-right: 1px solid ${token.colorBgLayout} !important;
          tbody {
            tr:last-child > td {
              border: 0 !important;
            }
            tr td:first-of-type {
              border-right: 0 !important;
            }
          }
        }
        // END OF : Expandable table
        .ant-card-head {
          position: relative;
          padding: 8px 24px !important;
          min-height: 0 !important;
          z-index: 1; // Pour fixer le fait que son border-bottom ne se voit pas dans le cas où un table est à l'intérieur
        }
          
        // Ant Card
        .ant-card-small {
          .ant-card-head {
            padding: 8px 12px !important;
          }
          .ant-card-body {
            padding: 12px !important;
          }     
        }

        // Supprime le liseré sous les tabs 
        .ant-tabs-top >.ant-tabs-nav::before {
          border-bottom: none !important;
        }
        .ant-tabs-top >div>.ant-tabs-nav::before {
          border-bottom: none !important;
        }
          
        /**
        IMPORTANT: Cette partie sert à customiser
        les tabs de type "card" dans tout le BO car
        le style d'Ant Design ne convenait pas et
        impossible d'avoir cette effet avec leurs 
        simples variables de thème  
         */
        .ant-tabs-card {
          .ant-tabs-nav-list {
            margin-bottom: 0;
            gap: 32px;  
          }
          .ant-tabs-tab {
            padding: 12px 0 !important;
            border: 0 !important;
            border-radius: 0 !important;
            transition: all 0s !important;
            background-color: transparent !important;
              
            &::after {
              content: '';
              position: absolute;
              bottom: 0;
              left: 50%;
              transform: translateX(-50%);  
              width: 0;
              opacity: 0;
              background-color: ${token.colorPrimary};
              transition: all 0.2s ease-in-out;
            }  
          }
          .ant-tabs-tab-active {
            position: relative;  
            border: 0 solid;
            &::after {
              width: 100%;
              height: 2px;
              opacity: 1;
            }  
          }
        }  
          
        /*
         * Center content in ant-d buttons (écrase le line-height de base d'ant design qui est de 1.57142857, il pose clairement soucie)
         * PS: Fun Fact de l'étrange : appliquer ce line-height de 1 depuis le fichier "theme.json" ne donne clairement 
         * pas le même résultat... (sans doute un effet "boîte noir" de la part d'Ant Design)
        */
        .ant-btn {
          line-height: 1 !important;
        }

        // Fix font-family in ant-d notification (il semble la config de base d'ant-d ne fonctionne pas)
        .ant-notification > div {
          font-family: ${token.fontFamily} !important;
        }

        // Encore une fois la config du thème d'Ant Design ne fait absolument rien, il va falloir investiguer
        .ant-alert-with-description {
          padding: 10px 15px !important; 
        }
        .ant-alert-with-description .ant-alert-icon {
          font-size: 16px;
          padding-top: 3px;
        }
          
        // Dropdowns
        .ant-dropdown-trigger {
          cursor: default;
          &:hover, &:focus, &:active, &:visited {
            color: ${token.colorPrimary} !important;
          }
          &:disabled {
            color: ${token.colorTextTertiary} !important;
          }
        }
          
        // radio button in error need color error border
        .ant-form-item-has-error .ant-radio-button-wrapper {
            border-color: ${token.colorError} !important;
        }
        .ant-form-item-has-error .ant-radio-button-wrapper:not(:first-child)::before {
            background-color: ${token.colorError} !important;
        }

        // Add padding on ant-popover only for mobile devices
        @media (max-width: ${token.screenMD}px) {
          .ant-popover {
            padding: 0 10px !important;
          }
        }

        ${specificStyle}
      `}
    />
  );
}

export default GlobalStyle;
