/** @jsxImportSource @emotion/react */
import { Drawer, Button, Popconfirm, Alert, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { isTouchableView } from '../DeviceDetect/TouchableView';
import useCustomHtml from '../../hooks/useCustomHtml';
import initialFormItems from './initialFormItems.json';
import incidentDetailsFormItems from './incidentDetailsFormItems.json';
import { updateIncident } from '../../services/apiEndPoints/delivery/incident/updateIncident';
import { useContext } from 'react';
import { NotificationCenterContext } from '../../context/NotificationCenterContext';

const { Text } = Typography;

/**
 * ManageIncidentDrawer component
 * @param {boolean} open
 * @param {function} setOpen
 * @param {string} driveName
 * @param {Object} incident
 * @param {function} setIncident
 * @param {function} updateIncidentsHistory
 * @param {number} deliveryStatus
 * @return {JSX.Element}
 */
function ManageIncidentDrawer({ open, setOpen, driveName, incident, setIncident, updateIncidentsHistory, deliveryStatus }) {
  const { t } = useTranslation();

  const category = initialFormItems[0].options.find((option) => option.value === incident.incidentTypes);

  const reason = incidentDetailsFormItems[category.value]
    .find((category) => category.name === incident.incidentTypes)
    .options.find((option) => option.value === incident.incidentReasons);

  const kilometersRange = incidentDetailsFormItems[category.value]
    ?.find((category) => category.name === 'kilometer-range')
    ?.options.find((option) => option.value === incident.kilometerRange);

  return (
    <Drawer
      title={t('MANAGE_INCIDENT')}
      placement='right'
      width={isTouchableView() ? '100%' : 600}
      open={open}
      onClose={() => setOpen(false)}
      footer={(
        deliveryStatus !== 6 &&
        <CancelledIncidentButton
          driveName={driveName}
          incident={incident}
          setIncident={setIncident}
          updateIncidentsHistory={updateIncidentsHistory}
          setOpen={setOpen}
        />
      )}
    >
      <Alert
        type="warning"
        description={useCustomHtml({
          inputString: t('MANAGE_WARN_MESSAGE'),
        })}
        showIcon
        style={{ marginBottom: 20 }}
      />
      <Space direction="vertical" css={{ gap: '20px !important' }}>
        <DrawerTextItem label={t('INCIDENT_CATEGORY')} value={t(category.label)} />
        <DrawerTextItem label={t('INCIDENT_REASON')} value={t(reason.label)} />
        {kilometersRange && (
          <DrawerTextItem label={t('ADDITIONAL_KILOMETERS')} value={t(kilometersRange.label)} />
        )}
        {incident.comment && (
          <DrawerTextItem label={t('DESCRIPTION')} value={incident.comment} />
        )}
      </Space>
    </Drawer>
  );
}

/**
 * DrawerTextItem component
 * @param {string} label
 * @param {string} value
 * @return {JSX.Element}
 */
function DrawerTextItem({ label, value }) {
  return (
    <Space direction="vertical" css={{ gap: '5px !important' }}>
      <Text type="secondary" css={{ fontSize: 12 }}>{label}</Text>
      <Text>{value}</Text>
    </Space>
  );
}

/**
 * @name CancelledIncidentButton
 * @param {Object} incident
 * @param {string} driveName
 * @param {function} setIncident
 * @param {function} setOpen
 * @param {function} updateIncidentsHistory
 * @return {JSX.Element}
 */
function CancelledIncidentButton({ incident, driveName, setIncident, setOpen, updateIncidentsHistory }) {
  const notification = useContext(NotificationCenterContext);
  const { t } = useTranslation();

  const deleteIncident = () => {
    updateIncident(incident).then((response) => {
      if (response.data) {
        setIncident(null);
        setOpen(false);
        updateIncidentsHistory();
      }
      notification.push(response.type, t(response.message));
    });
  };

  return (
    <Popconfirm
      title={useCustomHtml({
        keyValues: [
          { key: '[DRIVE_NAME]', value: driveName },
        ],
        inputString: t('CONFIRM_CANCEL_INCIDENT'),
      })}
      okText={t('YES')}
      cancelText={t('NO')}
      onConfirm={deleteIncident}
    >
      <Button type="primary" htmlType="button">
        {t('CANCEL_INCIDENT')}
      </Button>
    </Popconfirm>
  );
}

export default ManageIncidentDrawer;
