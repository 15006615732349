import { useContext, useEffect } from 'react';
import { AppContext } from '../../../context/AppContext';
import { useNavigate, useLocation } from 'react-router-dom';
import Login from '../Login';

/**
 * ProtectedRoute
 * @param {JSX.Element} children
 * @return {JSX.Element}
*/
function ProtectedRoute({ roles, children }) {
  const [store] = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation().pathname;

  useEffect(() => {
    if (isAuthorized && location === '/login') {
      navigate('/deliveries?label=ongoing');
    }
  }, [location]);

  const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
  const isAuthorized = isLoggedIn && store.user.roles ? store.user.roles.some((role) => roles.includes(role)) : false;

  return isAuthorized ? children : <Login />;
}

export default ProtectedRoute;
