import dayjs from 'dayjs';

export type relativeReferenceDateName = 'TODAY' | 'TOMORROW' | 'AFTER_TOMORROW' | 'YESTERDAY';
export type BoDate = dayjs.Dayjs;

const dateReferences: Readonly<Record<relativeReferenceDateName, string>> = Object.freeze({
  YESTERDAY: dayjs().subtract(1, 'day').startOf('day').toISOString(),
  TODAY: dayjs().startOf('day').toISOString(),
  TOMORROW: dayjs().add(1, 'day').startOf('day').toISOString(),
  AFTER_TOMORROW: dayjs().add(2, 'day').startOf('day').toISOString(),
});

export function getDateReferences(filter: relativeReferenceDateName[] = []): Record<relativeReferenceDateName, string> {
  return Object.fromEntries(Object.entries(dateReferences).filter(([key]) => filter.includes(key as relativeReferenceDateName))) as Record<relativeReferenceDateName, string>;
}

export function getStringFromBoDate(inputDate: dayjs.Dayjs): string | undefined {
  const inputDateString = inputDate.startOf('day').toISOString();
  const entry = Object.entries(dateReferences).find(([, value]) => value === inputDateString);
  return entry ? entry[0] : undefined;
}

export const boDateFromString = (date: string): BoDate => dayjs(date);
