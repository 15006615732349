import { useState, useEffect } from 'react';
import { theme } from 'antd';

/**
 * useScreenSize
 * @example const { width, height, orientation, mobileMode } = useScreenSize();
 * @return {{orientation: (string), width: number, height: number, mobileMode: boolean}}
 */
function useScreenSize() {
  const { token } = theme.useToken();

  const getScreenSize = () => ({
    width: window.innerWidth,
    height: window.innerHeight,
    orientation: window.matchMedia('(orientation: portrait)').matches ? 'portrait' : 'landscape',
    mobileMode: window.innerWidth <= token.screenMDMin,
  });

  const [screenSize, setScreenSize] = useState(getScreenSize);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(getScreenSize);
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);

    // Cleanup function
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, []);

  return screenSize;
}

export default useScreenSize;
