import React from 'react';
import { useTranslation } from 'react-i18next';
import useCustomHtml from '../../../../../hooks/useCustomHtml';

/**
 * ValidatedHistoryNode
 * @param {Object} props
 * @param {Object} props.user
 * @param {number} props.user.id
 * @param {string} props.user.firstName
 * @param {string} props.user.lastName
 * @param {boolean} props.validatedWithCode
 * @return {JSX.Element}
 */
function ValidatedHistoryNode({ user, validatedWithCode }) {
  const { t } = useTranslation();

  const validatedWithCodeByName = useCustomHtml({
    keyValues: [
      { key: '[DELIVERY_MAN_NAME]', value: `${user.firstName} ${user.lastName}`, url: `/users/${user.id}` },
    ],
    inputString: t('VALIDATED_WITH_CODE_BY_NAME'),
  });

  const validatedWithoutCodeByName = useCustomHtml({
    keyValues: [
      { key: '[DELIVERY_MAN_NAME]', value: `${user.firstName} ${user.lastName}`, url: `/users/${user.id}` },
    ],
    inputString: t('VALIDATED_WITHOUT_CODE_BY_NAME'),
  });

  return (
    <div>
      {validatedWithCode ? validatedWithCodeByName : validatedWithoutCodeByName}
    </div>
  );
}

export default ValidatedHistoryNode;
