/**
 * @param {Date} date
 * @return {string}
 */
function getNumberOfDays(date) {
  const oneDay = 1000 * 60 * 60 * 24;
  const diffInTime = date.getTime() - new Date().getTime();
  const diffInDays = Math.round(diffInTime / oneDay);
  return diffInDays;
}

/**
 * @param {date} date
 * @param {boolean} fullDatetime
 * @param {string} component
 * @param {boolean} fullDate
 * @return {string} date in string format
 */
export function toLocaleString({ date, fullDatetime, component, fullDate }) {
  const dateString = date instanceof Date ? date.toISOString() : date;
  date = new Date(dateString);
  const locale = localStorage.getItem('i18nextLng') || navigator.language;
  const rtf = new Intl.RelativeTimeFormat(locale, { numeric: 'auto' });

  if (!fullDate) {
    const diffInDays = getNumberOfDays(date);
    if (diffInDays >= -1 && diffInDays <= 2) {
      return rtf
        .formatToParts(diffInDays, 'day')
        .map((result) => result.value)
        .join(' ');
    }
  }

  const options = () => {
    if (component === 'history') {
      return { year: 'numeric', month: 'numeric', day: 'numeric' };
    }
    if (fullDatetime) {
      return { month: 'long', day: 'numeric' };
    }
    return { year: 'numeric', month: 'long', day: 'numeric' };
  };
  const dateTostringLocale = (new Intl.DateTimeFormat(locale, options()).format(date));
  return dateTostringLocale;
}
