import { Slider } from 'antd';
import styled from '@emotion/styled';

const StyledSlider = styled(Slider)`
  height: 34px !important;
  text-align: left;
  margin-left: 0;
  margin-top: 12px;

  .ant-slider-mark {
    margin-left: 10px;
    width: 250px;
  }

  .ant-slider-mark-text {
    width: 100%;
    text-align: left !important;
  }
`;

export default StyledSlider;
