/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCustomHtml from '../../../../../hooks/useCustomHtml';
import { Button, Typography } from 'antd';

/**
 * Content
 * @param {Object} props
 * @param {string} props.additionalReason
 * @param {Function} props.setIsExpanded
 * @return {JSX.Element}
 */
function Content({ additionalReason, setIsExpanded }) {
  const { t } = useTranslation();

  return (
    <>
      {additionalReason &&
          <Typography.Paragraph
            ellipsis={{
              rows: 2,
              expandable: true,
              symbol: t('SHOW_MORE'),
              onExpand: () => setIsExpanded(true),
            }}
          >
            {additionalReason}
          </ Typography.Paragraph>}</>
  );
}
/**
 * RemovedHistoryNode
 * @param {Object} props
 * @param {Object} props.user
 * @param {string} props.user.firstName
 * @param {string} props.user.lastName
 * @param {string} props.reason
 * @return {JSX.Element}
 */
function RemovedHistoryNode({ user, reason, additionalReason }) {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const additionalReasonFormated = useCustomHtml({ inputString: additionalReason });

  return (
    <>
      <div>
        {useCustomHtml({
          keyValues: [
            { key: '[SHOPOPOP_NAME]', value: `${user.firstName} ${user.lastName}`, bold: true },
            { key: '[CANCELLATION_REASON]', value: t(reason), bold: true },
          ],
          inputString: t('DELIVERY_ARCHIVING_BY_SHOPOPOP'),
        })}
        {isExpanded ?
          <>
            {additionalReasonFormated}
            <Button
              type='link'
              onClick={() => setIsExpanded(false)}
              style={{ padding: 0 }}
            >
              ...{t('SHOW_LESS')}
            </Button>
          </> : <Content user={user} reason={reason} additionalReason={additionalReasonFormated} setIsExpanded={setIsExpanded} />
        }
      </div>
    </>
  );
}

export default RemovedHistoryNode;
