/** @jsxImportSource @emotion/react */
import { useState, useEffect, useCallback, useContext } from 'react';
import dayjs from 'dayjs';
import { useFetch } from 'use-http';
import { theme, Card, Space, Timeline, Typography } from 'antd';
import { toLocaleString } from '../../../../utils/toLocaleString';
import {
  UpdatedTipsHistoryNode,
  ShiftedHistoryNode,
  BookedHistoryNode,
  AddedHistoryNode,
  StatusHistoryNode,
  CanceledHistoryNode,
  RemovedHistoryNode,
  ShowReferenceHistoryNode,
  ValidatedHistoryNode,
  DeliveryIncidentNode,
  CommentHistoryNode,
  PushedHistoryNode,
  CanceledIncidentNode,
  PaidHistoryNode,
  NotationHistoryNode,
} from './HistoryNodes/_index';
import { EuroDot, ClockDot, CheckDot, CancelDot, RemovedDot, IncidentDot, CheckWaitCircleDot } from '../../../TimelineUtilsComponents/Dots';
import { sizes } from '../../../../globalVar/theme';
import AddComment from './AddComment';
import { AppContext } from '../../../../context/AppContext';
import SkeletonNodes from './SkeletonNode';

const { Text } = Typography;

/**
 * History
 * @return {JSX.Element}
 */
function History({ delivery, history, pushes, loading, incident }) {
  const { token } = theme.useToken();
  const [items, setItems] = useState([]);
  const [comments, setComments] = useState([]);
  const [sortedHistoryAndComments, setSortedHistoryAndComments] = useState([]);
  const [store] = useContext(AppContext);
  const { get, response } = useFetch(process.env.REACT_APP_API_BO, {
    cachePolicy: 'no-cache',
  });

  /**
   * getComment - Get the comment of the delivery
   * @param {number} deliveryId - The delivery id
   * @return {Promise<void>}
   */
  const getComments = async() => {
    await get(`/deliveries/${delivery.id}/comments`);
    if (response.status === 200) {
      setComments(response.data.map((comment) => ({ ...comment, type: 'INTERN_COMMENT' })));
    } else {
      console.error(response.status);
    }
  };

  /**
   * mergeHistoryAndComments - Merge the history and the comments
   * @return {void}
   */
  const mergeHistoryAndComments = () => {
    const merged = [...history, ...comments, ...pushes, ...incident];
    const filteredByIncidentTypes = merged.filter((item) => !(item.type === 'DELIVERY_INCIDENT' && item.incidentTypes === null));
    const sorted = filteredByIncidentTypes.sort((firstItem, secondItem) => {
      return dayjs(secondItem.date || secondItem.created_at || secondItem.createdAt) - dayjs(firstItem.date || firstItem.created_at || firstItem.createdAt);
    });
    setSortedHistoryAndComments(sorted);
  };

  useEffect(() => {
    if (delivery.id) {
      getComments();
    }
  }, [delivery]);

  /**
   * useEffect - Merge the history and the comments
   */
  useEffect(() => {
    mergeHistoryAndComments();
  }, [history, comments, incident]);

  /**
   * addCommentToList - Add a comment to the list
   */
  const addCommentToList = useCallback((comment) => {
    setComments([
      { ...comment, type: 'INTERN_COMMENT' },
      ...comments,
    ]);
  });

  /**
   * deleteCommentFromList - delete a comment from the list
   */
  const deleteCommentFromList = useCallback((commentId) => {
    setComments(comments.filter((comment) => comment.id !== commentId));
  });

  // On formate la date pour l'afficher dans le timeline dans la partie gauche (les labels, dans notre cas)
  const formatDateLabel = (item, index) => {
    const formattedDate = dayjs(item.date || item.created_at || item.createdAt).format('LL');
    // On affiche toujours la date du 1er élément
    if (index === 0) {
      return toLocaleString({ date: item.date || item.created_at || item.createdAt, component: 'history' });
    }
    // Si la date est la même que celle de l'élément précédent, on ne l'affiche pas
    if (formattedDate === dayjs(sortedHistoryAndComments[index - 1].date || sortedHistoryAndComments[index - 1].created_at || sortedHistoryAndComments[index - 1].createdAt).format('LL')) {
      return null;
    }
    // Sinon on l'affiche
    return toLocaleString({ date: item.date || item.created_at || item.createdAt, component: 'history' });
  };

  /**
   * nodeColors
   * @param {string} event
   * @return {string}
   */
  const nodeColors = (event) => {
    const greenEvents = ['BOOKED', 'VALIDATED', 'SHOW_REFERENCE', 'SHOPPER_ADD_RATE', 'GOING_TO_DRIVE', 'ARRIVED_AT_DRIVE', 'GOING_TO_CLIENT', 'ARRIVED_AT_CLIENT'];
    const yellowEvents = ['INCREASE_TIPS', 'SHIFTED', 'PUSH'];
    const redEvents = ['CANCELED', 'REMOVED', 'DELIVERY_INCIDENT', 'INCIDENT_CANCELED'];

    if (greenEvents.includes(event)) return token.colorSuccess;
    if (yellowEvents.includes(event)) return token.colorWarningActive;
    if (redEvents.includes(event)) return token.colorError;
    return token.colorPrimaryBg;
  };

  /**
   * nodeDot
   * @param {string} event
   * @return {JSX.Element}
   */
  const nodeDot = (event) => {
    if (event === 'INCREASE_TIPS') return <EuroDot color={token.colorWarningActive} />;
    if (event === 'SHIFTED') return <ClockDot color={token.colorWarningActive} />;
    if (event === 'VALIDATED') return <CheckWaitCircleDot color={token.colorSuccess} />;
    if (event === 'PAID') return <CheckDot color={token.colorSuccess} />;
    if (event === 'CANCELED') return <CancelDot color={token.colorError} />;
    if (event === 'REMOVED') return <RemovedDot color={token.colorError} />;
    if (event === ('DELIVERY_INCIDENT' || 'INCIDENT_CANCELED')) return <IncidentDot color={token.colorError} />;
    return null;
  };

  /**
   * useEffect - Set the items for the timeline
   * @return {JSX.Element}
   */
  useEffect(() => {
    if (sortedHistoryAndComments.length > 0) {
      const tempItems = sortedHistoryAndComments.map((item, index) => {
        return {
          label: window.innerWidth >= sizes.md ? (
            <Text
              strong
              css={{ textTransform: 'capitalize' }}
            >
              {formatDateLabel(item, index)}
            </Text>
          ) : null,
          color: nodeColors(item.type),
          dot: nodeDot(item.type),
          children: (
            <div>
              <Space direction='vertical'>
                {
                  (window.innerWidth < sizes.md && formatDateLabel(item, index)) ? (
                    <Text
                      strong
                      css={{ textTransform: 'capitalize' }}
                    >
                      {formatDateLabel(item, index)}
                    </Text>
                  ) : null
                }
                <Text strong>
                  {dayjs(item.date || item.created_at || item.createdAt).format('LT')}
                </Text>
              </Space>
              {item.type === 'PAID' && <PaidHistoryNode />}
              {item.type === 'SHOPPER_ADD_RATE' &&
                <NotationHistoryNode
                  user={{ firstName: item.first_name, lastName: item.last_name, id: item.id }}
                />
              }
              {item.type === 'INCIDENT_CANCELED' && (
                <CanceledIncidentNode
                  user={{ firstName: item.callerFirstName, lastName: item.callerLastName }}
                  tips={item === incident[incident.length - 1] && item.tips }
                />
              )}
              {item.type === 'DELIVERY_INCIDENT' && (
                <DeliveryIncidentNode
                  user={{ firstName: item.first_name || item.callerFirstName, lastName: item.last_name || item.callerLastName, user_id: item.id || item.callerId }}
                  reason={item.additional_info?.reason || item.incidentReasons}
                  type={item.incidentTypes}
                  comment={item.comment}
                  kilometer={item.kilometerRange}
                  deliveryClosedRequested={item.deliveryClosedRequested}
                />
              )}
              {item.type === 'INCREASE_TIPS' && (
                <UpdatedTipsHistoryNode
                  oldTips={item.additional_info.old_value}
                  newTips={item.additional_info.new_value}
                  user={{ firstName: item.first_name, lastName: item.last_name }}
                />
              )}
              {item.type === 'SHIFTED' && (
                <ShiftedHistoryNode
                  oldDate={{
                    start: item.additional_info?.old_start_date,
                    end: item.additional_info?.old_end_date,
                  }}
                  newDate={{
                    start: item.additional_info?.new_start_date,
                    end: item.additional_info?.new_end_date,
                  }}
                  user={{ firstName: item.first_name, lastName: item.last_name }}
                />
              )}
              {item.type === 'BOOKED' && item.additional_info?.delivery_man_id && (
                <BookedHistoryNode
                  user={{
                    firstName: item.first_name,
                    lastName: item.last_name,
                    id: item.id,
                    roles: item.roles }}
                  deliveryMan={{
                    id: item.additional_info.delivery_man_id,
                    firstName: item.delivery_man_first_name,
                    lastName: item.delivery_man_last_name,
                  }}
                />
              )}
              {item.type === 'ADDED' && (
                <AddedHistoryNode
                  user={{ firstName: item.first_name, lastName: item.last_name, roles: item.roles }}
                  addedSoftware={item.added_software}
                  driveName={delivery.details?.drive_name}
                />
              )}
              {item.type === 'CANCELED' && item.additional_info?.delivery_man_id && (
                <CanceledHistoryNode
                  user={{ firstName: item.first_name, lastName: item.last_name }}
                  deliveryMan={{ firstName: item.delivery_man_first_name, lastName: item.delivery_man_last_name, id: item.additional_info.delivery_man_id }}
                />
              )}
              {item.type === 'REMOVED' && (
                <RemovedHistoryNode
                  user={{ firstName: item.first_name, lastName: item.last_name }}
                  reason={item.additional_info.reason}
                  additionalReason={item.additional_info.additional_reason}
                />
              )}
              {item.type === 'VALIDATED' && (
                <ValidatedHistoryNode
                  user={{ id: item.id, firstName: item.first_name, lastName: item.last_name }}
                  validatedWithCode={item.additional_info?.validation ? true : false}
                />
              )}
              {item.type === 'BOOKED' && !item.additional_info?.delivery_man_id && (
                <BookedHistoryNode
                  user={{ firstName: item.first_name, lastName: item.last_name, roles: item.roles }}
                  deliveryMan={{ id: item.id, firstName: item.first_name, lastName: item.last_name }}
                />
              )}
              {item.type === 'CANCELED' && !item.additional_info?.delivery_man_id && (
                <CanceledHistoryNode
                  deliveryMan={{ id: item.id, firstName: item.first_name, lastName: item.last_name }}
                />
              )}
              {item.type === 'SHOW_REFERENCE' && (
                <ShowReferenceHistoryNode
                  deliveryMan={{ id: item.id, firstName: item.first_name, lastName: item.last_name }}
                />
              )}
              {item.type === 'PUSH' && (
                <PushedHistoryNode
                  user={{ firstName: item.first_name, lastName: item.last_name }}
                />
              )}
              {(
                item.type === 'GOING_TO_DRIVE' ||
                item.type === 'ARRIVED_AT_DRIVE' ||
                item.type === 'GOING_TO_CLIENT' ||
                item.type === 'ARRIVED_AT_CLIENT'
              ) && (
                <StatusHistoryNode
                  status={item.type}
                  user={{ firstName: item.first_name, lastName: item.last_name, id: item.id, roles: item.roles }}
                />
              )}
              {item.type === 'INTERN_COMMENT' && (
                <CommentHistoryNode
                  user={{ firstName: item.first_name, lastName: item.last_name }}
                  comment={item.comment}
                  commentId={item.id}
                  createCommmentUser={item.user_id}
                  deleteCommentFromList={(e) => deleteCommentFromList(e)}
                  userLoggedInId={store.user.id}
                />
              )}
            </div>
          ),
        };
      });
      setItems(tempItems);
    }
  }, [sortedHistoryAndComments]);

  return (
    <div className="delivery-timeline-history">
      <Card css={{ overflow: 'hidden', marginBottom: 20 }}>
        <AddComment deliveryId={delivery.id} addCommentToList={(e) => addCommentToList(e)}/>
      </Card>
      <Card css={{ overflow: 'hidden' }}>
        <div className="delivery-timeline-history-wrapper">
          {loading ? (
            <SkeletonNodes count={3} />
          ) : (
            <Timeline
              mode={'left'}
              items={items}
            />
          )}
        </div>
      </Card>
    </div>
  );
}

export default History;
