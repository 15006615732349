/** @jsxImportSource @emotion/react */
import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { rgba } from 'emotion-rgba';
import { InputNumber, Select, Skeleton, Card, Collapse, Form, Row, Divider, Space, Typography, Col, Radio } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { DELIVERY_STATUSES } from '../../../globalVar/statusDeliveries';
import { colors } from '../../../globalVar/theme';
import InfoMessage from '../../Alert/InfoMessage';
import { findStatusDeliveries } from '../../../utils/findStatusDeliveries';
import { toLocaleString } from '../../../utils/toLocaleString';
import { distanceParser } from '../../../utils/distanceParser';
import BetaFeature from '../../BetaFeature';
import UpdateStatusModal from '../../Modal/UpdateStatusModal';
import ModalContext from '../../../context/ModalContext';
import useCustomHtml from '../../../hooks/useCustomHtml';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import DeliveryTimeSlot from '../../DeliveryTime/DeliveryTimeSlot';
import RadioCard from '../../RadioCard';
import DeliveryIncidentAlert from '../../DeliveryIncident/DeliveryIncidentAlert';
dayjs.extend(localizedFormat);

const { Option } = Select;
const { Text } = Typography;

/**
 * UpdateDelivery
 * @param {object} props
 * @return {string}
 */
function UpdateDelivery({
  form,
  delivery,
  loading,
  touch,
  deliveryShopperCancelled,
  setDelivery,
  setSearchUser,
  setStaticDelivery,
  setPushAtUpdate,
  setDeliveryShopperCancelled,
  pushAtUpdate,
  push,
  incident,
}) {
  const { t } = useTranslation();

  const deliveryStart = dayjs(delivery?.delivery_start);
  const deliveryEnd = dayjs(delivery?.delivery_end);
  const withdrawalStart = dayjs(delivery?.withdrawal_start);
  const withdrawalEnd = dayjs(delivery?.withdrawal_end);

  const setOpenModalStatus = useContext(ModalContext)['setOpenModalStatus'];

  const [status, setStatus] = useState(null);
  const [toStatus, setToStatus] = useState(null);

  // Variables pour indiquer si la livraison est archivée
  const dateArchiving = delivery.delivery_removed?.date ? dayjs(delivery.delivery_removed?.date).format('LL') : null;
  const reasonArchiving = delivery.delivery_removed?.reason;
  const archivedAlertMessageText = useCustomHtml({
    keyValues: [
      { key: '[ARCHIVING_DATE]', value: dateArchiving ? ` ${t('THE')} ${dateArchiving}` : '', bold: true },
      { key: '[ARCHIVING_REASON]', value: t(reasonArchiving), bold: true },
    ],
    inputString: t('ARCHIVED_DELIVERY_MESSAGE'),
  });

  const onSelectStatus = (value) => {
    setToStatus(value);
    setOpenModalStatus(true);
  };

  useEffect(() => {
    setStatus(delivery.status);
    form.setFieldsValue({ 'tips': delivery.tips });
  }, [delivery]);

  useEffect(() => {
    if (status > 1) {
      form.resetFields([
        'driveSearch',
        'reference',
        'orderAmount',
        'articlesAmount',
        'sizeCommand',
        'storeComment',
      ]);
    }
  }, [status]);

  const Header = ({ addPadding }) => {
    /**
     * setTitle
     * @return {string}
    */
    function setTitle() {
      if (loading) return t('DELIVERY');
      if (!delivery.validated && delivery.status === 6) return t('DELIVERY_ARCHIVED');
      if (!delivery.validated && delivery.status === 7) return t('DELIVERY_INCIDENT_TITLE');
      if (delivery.validated && delivery.status === 6) return t('DELIVERY_DELIVERED');
      return t('UPDATE_DELIVERY');
    }

    return (
      <Space direction='vertical' css={addPadding ? { padding: '20px 0' } : {}}>
        <Text>
          {setTitle()}
        </Text>
        <Space>
          <Text type="secondary">ID: {delivery?.internal_reference}</Text>
          <Text css={{ color: colors.orange }}>Code: {delivery?.code}</Text>
        </Space>
      </Space>
    );
  };

  // Affichage du contenu de la page
  const StaticContent = () => {
    return (
      loading ? <Skeleton active={true} size={'default'}/> :
        <>
          {incident && (
            <DeliveryIncidentAlert
              incidentCreationDate={incident.createdAt}
              incidentType={incident.incidentTypes}
              incidentReasonValue={incident.incidentReasons}
              oldIncidentReason={delivery.incident?.reason}
            />
          )}
          {!incident && delivery.delivery_removed && <InfoMessage alertMessageText={archivedAlertMessageText} margin={20} type='warning'/>}
          {status &&
            <>
              <Row gutter={10}>
                <Text>{t('DELIVERY_TABLE_STATE')}</Text>
                <Select
                  defaultValue={t(findStatusDeliveries(status).message)}
                  onSelect={onSelectStatus}
                  css={{
                    'width': '100%',
                    'marginTop': '5px',
                    '.ant-select-selector': {
                      border: '0 !important',
                      borderRadius: 16,
                      backgroundColor: `${rgba(colors.deliveryStatus[status - 1], 0.1)} !important`,
                      color: colors.deliveryStatus[status - 1],
                    },
                    '.ant-select-arrow': {
                      display: 'none',
                    },
                  }}
                  disabled={status}
                />
              </Row>
            </>
          }
          <Divider />
          <Row gutter={[10, 10]}>
            <Col span={24}>
              <Space.Compact direction="vertical">
                <Text type="secondary">{t('DELIVERY_DAY')}</Text>
                <Text strong={true}>
                  {delivery?.delivery_start && toLocaleString({ date: delivery?.delivery_start, fullDatetime: false })}
                </Text>
              </Space.Compact>
            </Col>
            <Col span={24}>
              <Space.Compact direction="vertical">
                <Text type="secondary">{t('DELIVERY_SLOTS_HOUR')}</Text>
                <Text>{deliveryStart.format('HH:mm')} - {deliveryEnd.format('HH:mm')}</Text>
              </Space.Compact>
            </Col>
            <Col span={24}>
              <Space.Compact direction="vertical">
                <Text type="secondary">{t('DELIVERY_WITHDRAWAL_HOUR')}</Text>
                <Text>{withdrawalStart.format('HH:mm')} - {withdrawalEnd.format('HH:mm')}</Text>
              </Space.Compact>
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={24}>
              <Space.Compact direction="vertical">
                <Text type="secondary">{t('TIP')}</Text>
                <Text strong={true} >{delivery?.tips} €</Text>
              </Space.Compact>
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={8}>
              <Space.Compact direction="vertical">
                <Text type="secondary">{t('DISTANCE')}</Text>
                <Text strong={true}>
                  {delivery?.distance && <span>{distanceParser(delivery?.distance).value} {distanceParser(delivery?.distance).unit}</span>}
                </Text>
              </Space.Compact>
            </Col>
            <Col span={8}>
              <Space.Compact direction="vertical">
                <Text type="secondary">
                  {t('CANCELLED')}
                </Text>
                <Text strong>
                  {deliveryShopperCancelled}
                </Text>
              </Space.Compact>
            </Col>
            <BetaFeature>
              <Col span={8}>
                <Space.Compact direction="vertical">
                  <Text type="secondary">
                    {t('TENSION_ZONE')}
                  </Text>
                  <Text strong>
                    {delivery?.drive_tension_zone && t(`TENSION_ZONE_${delivery?.drive_tension_zone.toUpperCase()}`)}
                  </Text>
                </Space.Compact>
              </Col>
            </BetaFeature>
          </Row>
        </>
    );
  };

  return touch ? (
    <Collapse
      expandIcon={({ isActive }) => isActive ? <UpOutlined /> : <DownOutlined />}
      css={{ marginTop: 20 }}
      expandIconPosition={'end'}
    >
      <Collapse.Panel header={<Header />}>
        {delivery.validated && delivery.status < 6 ? (
          <Content
            status={status}
            toStatus={toStatus}
            loading={loading}
            delivery={delivery}
            deliveryShopperCancelled={deliveryShopperCancelled}
            onSelectStatus={onSelectStatus}
            withdrawalStart={withdrawalStart}
            withdrawalEnd={withdrawalEnd}
            setDelivery={setDelivery}
            setStatus={setStatus}
            setSearchUser={setSearchUser}
            setDeliveryShopperCancelled={setDeliveryShopperCancelled}
            form={form}
            setStaticDelivery={setStaticDelivery}
            setPushAtUpdate={setPushAtUpdate}
            pushAtUpdate={pushAtUpdate}
            push={push}
            incident={incident}
          />
        ) : (
          <StaticContent />
        )}
      </Collapse.Panel>
    </Collapse>
  ) : (
    <Card title={<Header addPadding />} css={{ position: 'sticky', top: 84 }}>
      {delivery.validated && delivery.status < 6 ? (
        <Content
          status={status}
          toStatus={toStatus}
          loading={loading}
          delivery={delivery}
          onSelectStatus={onSelectStatus}
          withdrawalStart={withdrawalStart}
          withdrawalEnd={withdrawalEnd}
          setDelivery={setDelivery}
          deliveryShopperCancelled={deliveryShopperCancelled}
          setDeliveryShopperCancelled={setDeliveryShopperCancelled}
          setStatus={setStatus}
          setSearchUser={setSearchUser}
          form={form}
          setStaticDelivery={setStaticDelivery}
          setPushAtUpdate={setPushAtUpdate}
          pushAtUpdate={pushAtUpdate}
          push={push}
          incident={incident}
        />
      ) : (
        <StaticContent />
      )}
    </Card>
  );
}

/**
 * @name Content
 * @param {object} props
 * @param {object} props.form
 * @param {number} props.status
 * @param {function} props.toStatus
 * @param {boolean} props.loading
 * @param {object} props.delivery
 * @param {function} props.onSelectStatus
 * @param {moment} props.withdrawalStart
 * @param {moment} props.withdrawalEnd
 * @param {number} props.deliveryShopperCancelled
 * @param {function} props.setDelivery
 * @param {function} props.setStatus
 * @param {function} props.setSearchUser
 * @param {function} props.setStaticDelivery
 * @param {function} props.setDeliveryShopperCancelled
 * @param {function} props.setPushAtUpdate
 * @param {moment} props.deliveryDay
 * @param {function} props.setDeliveryDay
 * @param {boolean} props.pushAtUpdate
 * @param {number} props.push
 * @param {object} props.incident
 * @return {JSX.Element}
 */
function Content({
  form, loading,
  delivery, setDelivery,
  status, toStatus, setStatus, onSelectStatus,
  withdrawalStart, withdrawalEnd,
  deliveryShopperCancelled,
  setSearchUser,
  setStaticDelivery,
  setDeliveryShopperCancelled,
  deliveryDay,
  setDeliveryDay,
  setPushAtUpdate, pushAtUpdate,
  push,
  incident,
}) {
  const { t } = useTranslation();
  const arrayStatus = DELIVERY_STATUSES.filter((item) => item !== '');

  return (
    loading ? <Skeleton active={true} size={'default'}/> :
      <>
        {incident && (
          <DeliveryIncidentAlert
            incidentCreationDate={incident.createdAt}
            incidentType={incident.incidentTypes}
            incidentReasonValue={incident.incidentReasons}
          />
        )}
        {status &&
          <>
            <Text>{t('DELIVERY_TABLE_STATE')}</Text>
            <Select
              value={t(findStatusDeliveries(delivery.status).message)}
              onSelect={onSelectStatus}
              css={{
                'width': '100%',
                'marginTop': '5px',
                '.ant-select-selector': {
                  border: '0 !important',
                  borderRadius: 16,
                  backgroundColor: `${rgba(colors.deliveryStatus[status - 1], 0.1)} !important`,
                  color: colors.deliveryStatus[status - 1],
                },
                '.ant-select-arrow': {
                  color: colors.deliveryStatus[status - 1],
                },
              }}
            >
              {arrayStatus.map((statusElement, index) => {
                return (
                  <Option key={index} value={statusElement} disabled={
                    statusElement === status || statusElement === 8 || statusElement === 7 || statusElement === 2 || status === 1 || status === 6 || (incident && statusElement === 1)
                  }>
                    {t(findStatusDeliveries(statusElement).message)}
                  </Option>
                );
              })}
            </Select>
          </>
        }
        <Divider />
        <DeliveryTimeSlot
          method='PUT'
          form={form}
          deliveryDay={deliveryDay}
          setDeliveryDay={setDeliveryDay}
          initialDates={{
            start: dayjs(delivery.delivery_start),
            end: dayjs(delivery.delivery_end),
          }}
        />
        <Col span={24}>
          <Space.Compact direction="vertical">
            <Text type="secondary">{t('DELIVERY_WITHDRAWAL_HOUR')}</Text>
            <Text>{withdrawalStart.format('HH:mm')} - {withdrawalEnd.format('HH:mm')}</Text>
          </Space.Compact>
        </Col>
        <Divider />
        <Form.Item
          name='tips'
          label={t('TIP')}
          rules={[() => ({
            validator(_, value) {
              if (value > 40 || value < 0) {
                return Promise.reject(new Error(t('UPDATE_TIPS')));
              } else {
                return Promise.resolve();
              }
            },
            required: true,
          })]}
          initialValue={delivery?.tips}
        >
          <InputNumber
            controls={true}
            min={0}
            step={0.5}
            style={{ width: '100%' }}
            addonAfter="€"
            keyboard={true}
            id='tips-update-delivery-detail-delivery'
          />
        </Form.Item>
        {status === 1 &&
        <Row>
          <Col span={8}>
            <Space.Compact direction="vertical">
              <Text type="secondary">
                {t('DELIVERY_TABLE_PUSH')}
              </Text>
              <Text strong>
                {push}
              </Text>
            </Space.Compact>
          </Col>
          <Col span={8} offset={8} style={{ display: 'flex', justifyContent: 'end' }}>
            <RadioCard>
              <Form.Item name='push' onChange={()=> setPushAtUpdate(!pushAtUpdate)}>
                <Radio checked={pushAtUpdate} style={{ marginInlineEnd: 0, fontSize: 12, padding: 8, color: colors.primary }}>Push</Radio>
              </Form.Item>
            </RadioCard>
          </Col>
        </Row>
        }
        <UpdateStatusModal
          fromStatus={status}
          toStatus={toStatus}
          delivery={delivery}
          deliveryShopperCancelled={deliveryShopperCancelled}
          setDelivery={setDelivery}
          setStatus={setStatus}
          setSearchUser={setSearchUser}
          setStaticDelivery={setStaticDelivery}
          setDeliveryShopperCancelled={setDeliveryShopperCancelled}
        />
      </>
  );
}

export default UpdateDelivery;
