/** @jsxImportSource @emotion/react */
import { Link } from 'react-router-dom';
import { Skeleton, Col, Row, Card, Typography, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import Profile from '../../../Profile/Profile';

const { Text } = Typography;

/**
 * Recipient Form
 * @param {object} delivery
 * @return {JSX.Element}
 */
function Recipient({ delivery }) {
  const { t } = useTranslation();
  const userID = delivery?.recipient?.id;

  return (
    <Card
      title={t('USER_CUSTOMER')}
      extra={
        <Link
          to={`/users/${userID}?tab=recipient`}
          target='_blank'
        >
          <Profile />
        </Link>
      }
    >
      {!delivery?.recipient ? <Skeleton /> :
        <>
          <Row gutter={[0, 16]}>
            <Col xs={24} sm={8}>
              <Space.Compact direction='vertical'>
                <Text type='secondary'>{t('FIRST_NAME')}</Text>
                <Text>{delivery?.recipient.first_name || '-'}</Text>
              </Space.Compact>
            </Col>
            <Col xs={24} sm={12}>
              <Space.Compact direction='vertical'>
                <Text type='secondary'>{t('NAME')}</Text>
                <Text>{delivery?.recipient.last_name || '-'}</Text>
              </Space.Compact>
            </Col>
            <Col xs={24} sm={8}>
              <Space.Compact direction='vertical'>
                <Text type='secondary'>{t('PHONE_NUMBER')}</Text>
                <Text>{delivery?.recipient.phone}</Text>
              </Space.Compact>
            </Col>
            <Col xs={24} sm={12}>
              <Space.Compact direction='vertical'>
                <Text type='secondary'>{t('EMAIL')}</Text>
                <Text>{delivery?.recipient.email}</Text>
              </Space.Compact>
            </Col>
          </Row>
        </>
      }
    </Card>
  );
}

export default Recipient;
