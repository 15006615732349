import { useTranslation } from 'react-i18next';
import SkeletonMobile from '../../../SkeletonLoaders/SkeletonMobile';
import { Collapse, Descriptions, Flex } from 'antd';
import DeliveryDeadline from '../../DeliveryListItems/DeliveryDeadline';
import Item from 'antd/es/list/Item';
import DeliveryDate from '../../DeliveryListItems/DeliveryDate';
import DeliveryWithdrawalHour from '../../DeliveryListItems/DeliveryWithdrawalHour';
import DeliveryDistanceItemColumn from '../../DeliveryListItems/DeliveryDistanceItemColumn';
import DeliveryListLink from '../../DeliveryListItems/DeliveryListLink';
import DeliveryTipItemColumnProcessed from '../../DeliveryListItems/DeliveryTipItemColumnProcessed';
import DeliveryTensionZoneItemColumn from '../../DeliveryListItems/DeliveryTensionZoneItemColumn';
import { useState } from 'react';

const { Panel } = Collapse;


/**
 * Returns a JSX element representing the mobile processed list.
 *
 * @return {JSX.Element} - The mobile on going list JSX element.
 */
function MobileProcessedList({ deliveries, loading, tableParams }) {
  const { t } = useTranslation();
  const [activeKey, setActiveKey] = useState(0);

  return (
    <SkeletonMobile
      loading={loading}
      emptyState={tableParams.pagination.total === 0}
    >
      <Collapse accordion activeKey={activeKey} onChange={(key) => setActiveKey(Number(key))}>
        {deliveries?.map((delivery, index) => {
          return (
            <Panel
              key={index}
              header={`${delivery?.client?.first_name} ${delivery?.client?.last_name}`}
              extra={<DeliveryDeadline deliveryEnd={delivery.delivery_end}/>}
            >
              <Flex vertical gap="middle">
                <Descriptions
                  size='small'
                  bordered
                  labelStyle={{ fontWeight: 600, color: '#1B3F92', padding: 8, fontSize: 12 }}
                >
                  <Item label={t('DATE')}>
                    <DeliveryDate shiftedDate={delivery.shifted_date} />
                  </Item>
                  <Item label={t('DELIVERY_SLOTS')}>
                    <DeliveryWithdrawalHour shiftedWithdrawal={delivery.shifted_withdrawal} />
                  </Item>
                  <Item label={t('DELIVERY_TABLE_TIPS')}>
                    <DeliveryTipItemColumnProcessed increaseTips={delivery.increase_tips} />
                  </Item>
                  <Item label={t('DELIVERY_TABLE_DISTANCE')}>
                    <DeliveryDistanceItemColumn distance={delivery.distance} />
                  </Item>
                  <Item label={t('TENSION_ZONE')}>
                    <DeliveryTensionZoneItemColumn tensionZone={delivery.driveTensionZone} />
                  </Item>
                </Descriptions>
                <DeliveryListLink
                  deliveryId={delivery.id}
                  deliveryType={delivery.delivery_type}
                />
              </Flex>
            </Panel>
          );
        })}
      </Collapse>
    </SkeletonMobile>
  );
}

export default MobileProcessedList;
