/** @jsxImportSource @emotion/react */
import { Col, Layout, Row, Space } from 'antd';
import { breakpoints, sizes } from '../../globalVar/theme';
import { useWindowSize } from '../../hooks/useWindowSize';
import Header from './Header';

const { Content } = Layout;
/**
 * Layout used for login or some parameters pages
 * @param {JSX.Element} children
 * @param {string | undefined} imageUrl
 * @return {JSX.Element}
 */
export default function TwoSidesWithHeaderLayout({ children, imageUrl = '/assets/pc-bo-login.svg' }) {
  const { width } = useWindowSize();
  const headerHeight = width > sizes.md ? 116 : 94;

  return (
    <Layout style={{ height: '100vh' }}>
      <Header height={headerHeight} />
      <Content>
        <Row style={{ height: `calc(100vh - ${headerHeight}px)` }}>
          <Col xs={{ span: 24 }} lg={{ span: 11 }} css={{
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            padding: '64px 20px 0',
            [breakpoints.md]: {
              justifyContent: 'center',
              padding: '0 80px',
            }
          }}>
            {children}
          </Col>
          <Col
            xs={{ span: 0 }} lg={{ span: 13 }}
            style={{ backgroundColor: '#FDFAF3' }}
          >
            <Space style={{ width: '100%', height: '100%', justifyContent: 'center' }}>
              <img src={`${process.env.PUBLIC_URL}${imageUrl}`} alt="ShopopopBoPicto" />
            </Space>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}
