// utils/useChangeLanguage.js
import { useTranslation } from 'react-i18next';
import mapI18N from '../i18n/i18nAntD';

/**
 * useChangeLanguage
 * @return {Function}
 */
export default function useChangeLanguage() {
  const { i18n } = useTranslation();

  return async(value) => {
    const lang = mapI18N.filter((l)=> l.name === value)[0]?.keys;
    await i18n.changeLanguage(lang, (err) => {
      if (err) {
        throw err;
      }
    });
  };
}
