/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import { Button, Table, Row, Tag, ConfigProvider, Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import ShopTableRow from '../../../Deliveries/ShopTableRow';
import { toLocaleString } from '../../../../utils/toLocaleString';
import { toLocaleStringHourandMinutes } from '../../../../utils/toLocaleStringHourandMinutes';
import { findStatusDeliveries } from '../../../../utils/findStatusDeliveries';
import { useFetch } from 'use-http';
import SkeletonTableLoader from '../../../SkeletonLoaders/SkeletonTableLoader';
import { customizeRenderEmpty } from '../../../../services/customizeRenderEmpty';
import DeliveryIncidentIconListItem from '../../../DeliveryList/DeliveryListItems/DeliveryIncidentIconListItem';

/**
 * UserSearchSubList
 * @param {number} idUser
 * @return {JSX.Element}
 */
function UserSearchSubList({ idUser }) {
  const { t } = useTranslation();
  const [deliveriesSubList, setDeliveriesSublist] = useState([]);
  const [customizeEmpty, setCustomizeEmpty] = useState(false);
  const usersDeliveriesListFetch = useFetch(process.env.REACT_APP_API_BO, {
    cachePolicy: 'no-cache',
  });

  useEffect(()=>{
    getDeliveriesListUser();
  }, []);

  const getDeliveriesListUser = async() => {
    const result = await usersDeliveriesListFetch.get(`users/${idUser}/deliveries?is=either&limit=2&sort=delivery_start&order=DESC`);
    if (usersDeliveriesListFetch.response.status === 200) {
      setDeliveriesSublist(result.list);
    } else {
      setCustomizeEmpty(true);
    }
  };

  const columns = [
    {
      title: t('DELIVERY_TABLE_TRADE'),
      dataIndex: 'trade',
      key: 'trade',
      render: (_, { trade }) => (
        <ShopTableRow
          logoSrc={process.env.REACT_APP_STATIC_BUCKET + trade.trade_id + '.webp'}
          shopName={trade.name}
          shopCity={trade.address?.zip_code + ' ' + trade.address?.city}
        />
      ),
      width: '35%',
    },
    {
      title: t('DELIVERY_DATE'),
      dataIndex: 'delivery_start',
      key: 'delivery_start',
      render: (data) => (
        <Row gutter={10} css={{ fontSize: 12 }}>
          <div>
            <div>{toLocaleString({ date: data?.deliveryStart, fullDatetime: true })}</div>
          </div>
        </Row>
      ),
      width: '20%',
    },
    {
      title: t('DELIVERY_SLOTS_HOUR'),
      dataIndex: 'delivery_start',
      key: 'delivery_start',
      render: (data) => (
        <Row gutter={10} css={{ fontSize: 12 }}>
          <div>
            <div>{toLocaleStringHourandMinutes(data?.deliveryStart)} - {toLocaleStringHourandMinutes(data?.deliveryEnd)}</div>
          </div>
        </Row>
      ),
      width: '20%',
    },
    {
      title: t('DELIVERY_TABLE_STATE'),
      dataIndex: 'status',
      key: 'status',
      render: (data) => (
        <Flex justify="space-between">
          <Tag style={findStatusDeliveries(data.status).style}>
            {t(findStatusDeliveries(data.status).message)}
          </Tag>
          {data.incident && <DeliveryIncidentIconListItem incident={data.incident} />}
        </Flex>
      ),
      width: '20%',
    },
    {
      title: t('ACTIONS'),
      dataIndex: 'button_link',
      key: 'button_link',
      render: (id) => (
        <Link to={`../delivery/${id.deliveryId}`}>
          <Button type='text' shape="circle" icon={<ArrowRightOutlined />}/>
        </Link>
      ),
      width: '5%',
    },
  ];
  return (
    <div
    >
      <ConfigProvider renderEmpty={() => customizeRenderEmpty({
        customizeEmpty: customizeEmpty,
      })}>
        <SkeletonTableLoader loading={usersDeliveriesListFetch.loading} rowCount={2} columns={columns}>
          <Table
            rowClassName={(_, index) => deliveriesSubList[index]?.validated === 0 ? 'validated' : ''}
            columns={columns}
            dataSource= {deliveriesSubList?.map((data) => {
              return {
                key: data?.id,
                trade: data?.trade,
                delivery_start: { deliveryEnd: data?.delivery_end, deliveryStart: data?.delivery_start, status: data?.status },
                status: { status: data?.status, incident: data?.incident },
                button_link: { deliveryId: data?.id },
              };
            })
            }
            pagination={false}
          />
        </SkeletonTableLoader>
      </ConfigProvider>
    </div>
  );
}

export default UserSearchSubList;
