import { apiEndPointsOptions } from '../../../apiEndPointsOptions';

export const readRatesUserHistory = async(userId) => {
  const apiUrl = `${process.env.REACT_APP_API_BO}/users/${userId}/rates`;
  const method = 'GET';

  try {
    const response = await fetch(
      apiUrl,
      await apiEndPointsOptions(method),
    );

    const result = await response.json();

    if (response.status === 200) return result;

    if (response.status === 404) return [];

    return {
      type: 'error',
      message: `Error during the request: ${result.message}`,
    };
  } catch (error) {
    throw new Error(`Error in readRatesUserHistory service: ${error.message}`);
  }
};
