/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useFetch from 'use-http';
import { Table, Tag, Popover, Badge, ConfigProvider, Button, Card, Space, theme } from 'antd';
import { ArrowRightOutlined, WarningFilled } from '@ant-design/icons';
import { toLocaleString } from '../../../utils/toLocaleString';
import { toLocaleStringHourandMinutes } from '../../../utils/toLocaleStringHourandMinutes';
import { findStatusDeliveries } from '../../../utils/findStatusDeliveries';
import { findDetailsDeliveryIncidents } from '../../../utils/findDetailsDeliveryIncidents';
import { useRouter } from '../../../hooks/useRouter';
import SkeletonTableLoader from '../../SkeletonLoaders/SkeletonTableLoader';
import ShopTableRow from '../../Deliveries/ShopTableRow';
import { customizeRenderEmpty } from '../../../services/customizeRenderEmpty';
import { Link } from 'react-router-dom';
import { colors } from '../../../globalVar/theme';

/**
 * UserDeliveriesList
 * @return {string}
 */
function UserDeliveriesList({ statusError }) {
  const { token } = theme.useToken();
  const { t } = useTranslation();
  const router = useRouter();
  const deliveriesListUsersFetch = useFetch(process.env.REACT_APP_API_BO, {
    cachePolicy: 'no-cache',
  });
  const [deliveries, setDeliveries] = useState([]);
  const [deliveriesCount, setDeliveriesCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [customizeEmpty, setCustomizeEmpty] = useState(false);

  useEffect(() => {
    if (statusError === 200) {
      getDeliveriesRecipient();
    }
  }, [currentPage, statusError]);

  const getDeliveriesRecipient = async() => {
    const recipientId = router.query.id;
    await deliveriesListUsersFetch.get(`users/${recipientId}/deliveries?is=recipient&page=${currentPage}&sort=delivery_start&order=DESC`);
    if (deliveriesListUsersFetch.response.status === 200) {
      setDeliveriesCount(deliveriesListUsersFetch.response?.data.count);
      setDeliveries(deliveriesListUsersFetch.response?.data.list);
      setCustomizeEmpty(false);
    } else {
      if (deliveriesListUsersFetch.response.status > 409) {
        setCustomizeEmpty(true);
      }
    }
  };

  const columns = [
    {
      title: t('DELIVERY_TABLE_TRADE'),
      dataIndex: 'trade',
      render: (trade) => {
        return (
          <ShopTableRow
            logoSrc={process.env.REACT_APP_STATIC_BUCKET + trade.logo + '.webp'}
            shopName={trade.name}
            shopCity={trade.address?.zip_code + ' ' + trade.address?.city}
          />
        );
      },
    },
    {
      title: t('DELIVERY_TABLE_DATE_AND_HOUR'),
      dataIndex: 'date',
      render: (date) => {
        return date ? (
          <div>
            <div>{toLocaleString({ date: date.delivery_start, fullDatetime: true })}</div>
            <span>
              {toLocaleStringHourandMinutes(date.delivery_start)} - {toLocaleStringHourandMinutes(date.delivery_end)}
            </span>
          </div>
        ) : null;
      },
    },
    {
      title: t('DELIVERY_TABLE_SHOPPER'),
      dataIndex: 'shopper',
      render: (shopper) => {
        return (
          <>
            <div>{shopper.first_name}</div>
            <div>{shopper.last_name}</div>
          </>
        );
      },
    },
    {
      title: t('DELIVERY_TABLE_STATE'),
      dataIndex: 'status',
      render: (status) => {
        const statusDetails = findStatusDeliveries(status.status);
        const popoverContent = findDetailsDeliveryIncidents(status.popoverContent);
        if (popoverContent === null) {
          return <Tag style={statusDetails.style} >{t(statusDetails.message)}</Tag>;
        } else {
          return (
            <Popover classname='popover-incident' placement='bottom' content={popoverContent} trigger='hover'>
              {<Tag style={statusDetails.style} >{t(statusDetails.message)}</Tag>}
            </Popover>
          );
        }
      },
    },
    {
      title: t('TIP'),
      dataIndex: 'tip',
    },
    {
      key: 'incident_icon',
      dataIndex: 'incident_icon',
      width: 21,
      render: (incident) => (
        incident && <WarningFilled style={{ color: token.colorError, fontSize: '15px' }} />
      ),
    },
    {
      key: 'button_link',
      dataIndex: 'button_link',
      width: 60,
      render: ({ deliveryId }) => (
        <Link to={`/delivery/${deliveryId}`}>
          <Button type='text' shape="circle" icon={<ArrowRightOutlined />}/>
        </Link>
      ),
    },
  ];

  return (
    <SkeletonTableLoader loading={deliveriesListUsersFetch.loading} columns={columns} title={() => t('DELIVERIES')}>
      <ConfigProvider renderEmpty={() => customizeRenderEmpty({
        customizeEmpty: customizeEmpty,
        refreshLink: () => getDeliveriesRecipient(),
      })}>
        <Card
          bodyStyle={{ padding: 0 }}
          title={(
            <Space css={{ width: '100%', justifyContent: 'space-between' }}>
              {t('DELIVERIES')}
              <Badge
                count={deliveriesCount}
                style={{ backgroundColor: colors.primary }}
              />
            </Space>
          )}
        >
          <Table
            columns={columns}
            rowClassName={(_, index) => (deliveries[index]?.validated === 0 && deliveries[index]?.status !== 7) ? 'validated' : ''}
            dataSource={deliveries?.map((delivery) => {
              return {
                key: delivery.id,
                trade: {
                  logo: delivery.trade.trade_id,
                  name: delivery.trade.name,
                  address: {
                    zip_code: delivery.trade.address.zip_code,
                    city: delivery.trade.address.city,
                  },
                },
                date: { delivery_end: delivery.delivery_end, delivery_start: delivery.delivery_start },
                shopper: { first_name: delivery.shopper?.first_name, last_name: delivery.shopper?.last_name },
                status: { status: delivery.status, popoverContent: null },
                tip: `${delivery.tips}€`,
                incident_icon: delivery?.incident,
                button_link: { deliveryId: delivery?.id },
              };
            })}
            pagination={{
              total: deliveriesCount,
              showLessItems: true,
              defaultCurrent: currentPage,
              showSizeChanger: false,
              defaultPageSize: 5,
              size: 'small',
            }}
            onChange={(pagination) => {
              setCurrentPage(pagination.current);
            }
            }
            css={{
              '.validated': {
                'td:first-of-type': {
                  borderLeft: `3px solid ${token.colorWarningBorder}`,
                },
              },
            }}
          />
        </Card>
      </ConfigProvider>
    </SkeletonTableLoader>
  );
}

export default UserDeliveriesList;
