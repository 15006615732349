/**
 * @name addressFormatter
 * @param {object} address
 * @return {string}
 */
function addressFormatter(address) {
  const parts = [];

  if (address.address) parts.push(address.address);

  // Combiner zip_code et city sans virgule entre eux
  if (address.zip_code || address.city) {
    const zipCity = [address.zip_code, address.city].filter(Boolean).join(' ');
    parts.push(zipCity);
  }

  return parts.join(', ');
}

export default addressFormatter;
