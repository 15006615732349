import { Space, Typography } from 'antd';
import { ItemCardDirection } from '../../interfaces/ItemDetailCard';

const { Text } = Typography;

interface ContentVerticalCardProps {
  label: string;
  value: string | React.ReactNode;
  direction: ItemCardDirection;
}

const StructuredInfoCard = ({ label, value, direction }: Readonly<ContentVerticalCardProps>) => {
  return (
    <Space direction={direction} style={{ alignItems: 'flex-start' }}>
      <Text type="secondary" style={{ whiteSpace: 'nowrap', alignItems: 'start' }}>
        {label}
      </Text>
      <Text>{value}</Text>
    </Space>
  );
};

export default StructuredInfoCard;
