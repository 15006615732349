import React from 'react';
import { useTranslation } from 'react-i18next';
import useCustomHtml from '../../../../../hooks/useCustomHtml';


/**
 * BookedHistoryNode
 * @param {Object} props
 * @param {Object} props.deliveryMan
 * @param {number} props.deliveryMan.id
 * @param {string} props.deliveryMan.firstName
 * @param {string} props.deliveryMan.lastName
 * @param {Object} props.user
 * @param {string} props.user.firstName
 * @param {string} props.user.lastName
 * @return {JSX.Element}
 */
function BookedHistoryNode({ deliveryMan, user }) {
  const { t } = useTranslation();
  const isAdmin = user?.roles.includes('ADMIN');

  const deliveryManAddedByShopopop = useCustomHtml({
    keyValues: [
      { key: '[SHOPOPOP_NAME]', value: ` ${user?.firstName} ${user?.lastName}`, bold: true },
      { key: '[DELIVERY_MAN_NAME]', value: `${deliveryMan?.firstName} ${deliveryMan?.lastName}`, url: `/users/${deliveryMan.id}`, openNewTab: true },
    ],
    inputString: t('DELIVERY_MAN_ADDED_BY_SHOPOPOP'),
  });
  const deliveryBookedByDeliveryMan = useCustomHtml({
    keyValues: [
      { key: '[DELIVERY_MAN_NAME]', value: `${deliveryMan.firstName} ${deliveryMan.lastName}`, url: `/users/${deliveryMan.id}`, openNewTab: true },
    ],
    inputString: t('DELIVERY_BOOKED_BY_DELIVERY_MAN'),
  });

  // Si on a le deliveryManId, c'est un cotransporteur qui a été ajouté par le SC
  return isAdmin ? (
    <div>{deliveryManAddedByShopopop}</div>
  ) : (
    <div>{deliveryBookedByDeliveryMan}</div>
  );
}

export default BookedHistoryNode;
