import { getThrowErrorMessage } from '../../messages';
import fetchData from '../fetchData';
import processResponse from '../processResponse';

/**
 * Read a user.
 * @param {number} userId
 * @param {boolean} getDeactivatedUser
 * @return {object}
 */
export const readUser = async({ userId }) => {
  const apiUrl = `${process.env.REACT_APP_API_BO}/users/${userId}`;
  const method = 'GET';

  try {
    const response = await fetchData({ apiUrl, method });
    const data = response.data;

    return processResponse({ response, data });
  } catch (error) {
    throw new Error(getThrowErrorMessage({ functionName: 'readUser', error }));
  }
};

/**
 * Read a deactivated user.
 * @param {number} userId
 * @return {object}
 */
export const readUserDeactivationReasons = async({ userId }) => {
  const apiUrl = `${process.env.REACT_APP_API_BO}/users/${userId}/deactivated`;
  const method = 'GET';

  try {
    const response = await fetchData({ apiUrl, method });
    const data = response.data.length > 0 ? response.data[0] : null;

    return processResponse({ response, data });
  } catch (error) {
    throw new Error(getThrowErrorMessage({ functionName: 'readUserDeactivationReasons', error }));
  }
};

/**
 * Read user documents.
 * @param {number} userId
 * @return {object}
 */
export const readUserDocuments = async({ userId }) => {
  const apiUrl = `${process.env.REACT_APP_API_BO}/users/${userId}/documents`;
  const method = 'GET';

  try {
    const response = await fetchData({ apiUrl, method });
    const data = response.data;

    return processResponse({ response, data });
  } catch (error) {
    throw new Error(getThrowErrorMessage({ functionName: 'readUserDocuments', error }));
  }
};

/**
 * Read user deliveries.
 * @param {number} userId
 * @param {number} page
 * @param {string} sort
 * @param {string} order
 * @return {object}
 */
export const readCotransportorDeliveries = async({ userId, page, sort, order }) => {
  const apiUrl = `${process.env.REACT_APP_API_BO}/users/${userId}/deliveries?is=shopper&page=${page}&sort=${sort}&order=${order}`;
  const method = 'GET';

  try {
    const response = await fetchData({ apiUrl, method });
    const data = response.data;

    return processResponse({ response, data });
  } catch (error) {
    throw new Error(getThrowErrorMessage({ functionName: 'readCotransportorDeliveries', error }));
  }
};

/**
 * Read user deliveries.
 * @param {number} userId
 * @param {number} page
 * @return {object}
 */
export const readCotransportorCanceledDeliveries = async({ userId, page }) => {
  const apiUrl = `${process.env.REACT_APP_API_BO}/users/${userId}/deliveries/cancelled?page=${page}`;
  const method = 'GET';

  try {
    const response = await fetchData({ apiUrl, method });
    const data = response.data;

    return processResponse({ response, data });
  } catch (error) {
    throw new Error(getThrowErrorMessage({ functionName: 'readCotransportorCanceledDeliveries', error }));
  }
};
