/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';
import { Popconfirm, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import useCustomHtml from '../../../../hooks/useCustomHtml';
import { useFetch } from 'use-http';
import { useRouter } from '../../../../hooks/useRouter';
import { NotificationCenterContext } from '../../../../context/NotificationCenterContext';

/**
 * UserDeactivationHistoryNode
 * @param {Object} props
 * @param {Object} props.user
 * @param {string} props.user.firstName
 * @param {string} props.user.lastName
 * @param {string} props.reaason
 * @param {string} props.type
 * @param {string} props.driveName
 * @param {string} props.description
 * @param {Function} props.setIsBanUser
 * @param {boolean} props.displayReactivationButton
 * @param {Function} props.setVisible
 * @param {Function} props.setIsBanishFromAllDrive
 * @return {JSX.Element}
 */
function UserDeactivationHistoryNode({
  user,
  reason,
  type,
  driveName,
  description,
  setIsBanUser,
  displayReactivationButton,
  setVisible,
  setIsBanishFromAllDrive,
}) {
  const { t } = useTranslation();
  const router = useRouter();
  const notification = useContext(NotificationCenterContext);
  const reactivationFetch = useFetch(process.env.REACT_APP_API_BO, {
    cachePolicy: 'no-cache',
  });

  /**
   * confirmReactivation - Reactivate the user
  */
  const confirmReactivation = async() => {
    await reactivationFetch.delete(`bans/deactivated_user/${router.query.id}`);
    if (reactivationFetch.response.ok) {
      setIsBanishFromAllDrive(false);
      setIsBanUser(false);
      notification.push('success', t('REACTIVATION_SUCCESS'));
      setVisible(false);
    } else {
      notification.push('error', t('REACTIVATION_ERROR'));
    }
  };

  // Custom HTML
  const TotalDeactivation = () =>{
    let keyValues = [];
    let inputString = '';

    if (!user.firstName && !user.lastName && reason === 'SUSPICIOUS_ACCOUNT') {
      keyValues = [...keyValues, { key: '[DEACTIVATION_REASON_HISTORY]', value: (reason ? t(reason) : '-') }];
      inputString = 'AUTOMATIC_DEACTIVATION_HISTORY';
    } else {
      keyValues = [...keyValues, { key: '[DEACTIVATION_AUTHOR]', value: (user.firstName ? `${user.firstName} ${user.lastName}` : '-'), bold: true }];
      keyValues = [...keyValues, { key: '[DEACTIVATION_REASON_HISTORY]', value: (reason ? t(reason) : '-') }];
      keyValues = [...keyValues, { key: '[PICKUP_POINT_DEACTIVATION_HISTORY]', value: t(type) }];
      keyValues = [...keyValues, { key: '[DEACTIVATION_DESCRIPTION]', value: description || '-' }];
      inputString = 'DEACTIVATION_HISTORY';
    }

    return (
      useCustomHtml({
        keyValues,
        inputString: t(inputString),
      })
    );
  };

  const DeactivationFromOneDrive = ()=>{
    let keyValues = [];
    keyValues = [...keyValues, { key: '[DEACTIVATION_AUTHOR]', value: (user.firstName ? `${user.firstName} ${user.lastName}` : '-'), bold: true }];
    keyValues = [...keyValues, { key: '[DEACTIVATION_REASON_HISTORY]', value: (reason ? t(reason) : '-') }];
    keyValues = [...keyValues, { key: '[PICKUP_POINT_DEACTIVATION_HISTORY]', value: driveName }];
    keyValues = [...keyValues, { key: '[DEACTIVATION_DESCRIPTION]', value: description || '-' }];
    return (
      useCustomHtml({
        keyValues,
        inputString: t('DEACTIVATION_HISTORY'),
      })
    );
  };

  return (
    <div>
      {type === 'TOTAL_DEACTIVATION' ?
        <TotalDeactivation /> :
        <DeactivationFromOneDrive/>
      }
      {displayReactivationButton && <div>
        <Popconfirm
          placement="bottom"
          title={t('REACTIVATION_POPIN')}
          onConfirm={confirmReactivation}
          okText={t('YES')}
          cancelText={t('NO')}
          overlayStyle={{ width: 340 }}
          okButtonProps={{ shape: 'round' }}
          cancelButtonProps={{ shape: 'round' }}
        >
          <Button
            type='primary'
            size='small'
            css={{ marginTop: '10px' }}
          >
            {t('REACTIVATION_BUTTON')}
          </Button>
        </Popconfirm>
      </div>}
    </div>
  );
}

export default UserDeactivationHistoryNode;
