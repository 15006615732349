import { ReactNode, Children } from 'react';
import { Layout } from 'antd';

const { Header } = Layout;

export interface FixedHeaderProps {
  mobileMode: boolean;
  children: ReactNode;
}

/**
 * FixedHeader component
 */
function FixedHeader({ mobileMode, children }: FixedHeaderProps): ReactNode {
  return (
    <Header
      style={{
        display: 'flex',
        justifyContent: Children.count(children) > 1 ? 'space-between' : 'flex-start',
        alignItems: 'center',
        position: 'sticky',
        top: 0,
        width: mobileMode ? '100%' : 'calc(100% + 48px)',
        padding: mobileMode ? '0 0 0 30px' : '0 24px',
        marginLeft: mobileMode ? 0 : '-24px',
        zIndex: 10,
      }}
    >
      {children}
    </Header>
  );
}

export default FixedHeader;
