import { apiEndPointsOptions } from '../../apiEndPointsOptions';

export const updateIncident = async(data) => {
  const apiUrl = `${process.env.REACT_APP_API_BO}/deliveries/${data.deliveryId}/incidents`;
  const method = 'PUT';

  try {
    const response = await fetch(
      apiUrl,
      await apiEndPointsOptions(method)
    );

    if (response.status === 200) {
      return {
        type: 'success',
        message: 'INCIDENT_SUCCESS_CANCELLED',
        data,
      };
    }

    return {
      type: 'error',
      message: 'INCIDENT_UPDATE_IMPOSSIBLE',
    };
  } catch (error) {
    throw new Error(`Error in updateIncident service: ${error.message}`);
  }
};
