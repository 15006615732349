import { Tag } from 'antd';
import React from 'react';
import { MinusCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { colors } from '../../globalVar/theme';
import { useTranslation } from 'react-i18next';

/**
 * @name UserTagList
 * @return {JSX.Element}
 */
function UserListTag({ user }) {
  const { t } = useTranslation();
  let tagProps;

  if (user.deleted_date) {
    tagProps = {
      name: t('USER_DELETED'),
      icon: <MinusCircleOutlined />,
      color: colors.blueMiddle,
    };
  }
  if (user.bannedType) {
    tagProps = {
      name: t('USER_DEACTIVATED'),
      icon: <ExclamationCircleOutlined />,
      color: colors.orangeAlert,
    };
  }

  return (
    tagProps ?
      <Tag
        icon={tagProps.icon}
        style= {{ color: tagProps.color, background: '#ffffff', borderColor: tagProps.color, marginRight: '0' }}
      >
        {tagProps.name}
      </Tag> :
      null
  );
}

export default UserListTag;
