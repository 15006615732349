export const EnumDeliveryStatuses = {
  AVAILABLE: 1,
  RESERVED: 2,
  ONGOING: 3,
  NEAR_DRIVE: 4,
  ARRIVING: 5,
  FINISHED: 6,
  INCIDENT: 7,
  PAYED: 8,
  ALL: '',
};
export const DELIVERY_STATUSES = Object.values(EnumDeliveryStatuses);
export const INACTIVE_STATUSES_DELIVERIES = Object.values(EnumDeliveryStatuses).filter((status) => status !== EnumDeliveryStatuses.FINISHED);
export const ON_GOING_STATUS_DELIVERIES = Object.values(EnumDeliveryStatuses).filter((status) => status < EnumDeliveryStatuses.INCIDENT);
