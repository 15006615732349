/**
  * disableTimePickerArray
 * @param {number} start
 * @param {number} end
 * @return {array}
 * @example
 * disableTimePickerArray(0, 23)
 * // [0, 1, 2, 3, 4, 5, 6, 7, 8, 9 ... 23]
  */
export function disableTimePickerArray(start, end) {
  const array = [];
  for (let i = start; i <= end; i++) {
    array.push(i);
  }
  return array;
}
