import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { theme } from 'antd';

interface UseMenuItemsProps {
  menuItems: {
    key: string;
    path: string;
    icon: ComponentType;
    isLogout?: boolean;
  }[];
  onLogout: () => void;
}

/**
 * useMenuItems
 */
function useMenuItems({ menuItems, onLogout }: UseMenuItemsProps) {
  const { t } = useTranslation();
  const location = useLocation();
  const { token } = theme.useToken();

  const activeKey =
    menuItems.find((item) => {
      const pathWithoutQuery = item.path?.split('?')[0];
      const locationWithoutQuery = location.pathname.split('?')[0];
      return pathWithoutQuery === locationWithoutQuery;
    })?.key || '';

  const antdMenuItems = menuItems.map(({ key, path, icon: Icon, isLogout }) => ({
    key,
    title: t(key),
    icon: isLogout ? (
      <div onClick={onLogout}>
        <Icon />
      </div>
    ) : (
      <Icon />
    ),
    label: isLogout ? <div onClick={onLogout}>{t(key)}</div> : <Link to={path}>{activeKey === key ? <span>{t(key)}</span> : <span style={{ color: token.colorTextBase }}>{t(key)}</span>}</Link>,
  }));

  return { antdMenuItems, activeKey };
}

export default useMenuItems;
