/** @jsxImportSource @emotion/react */
import { React, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DrawersContext from '../../context/DrawersContext';
import { NotificationCenterContext } from '../../context/NotificationCenterContext';
import { CachePolicies, useFetch } from 'use-http';
import { getEventName } from '../../utils/getEventName';
// Components
import { Drawer, Space, Button, Alert, Radio, Form, Input, Popconfirm } from 'antd';
import RadioCard from '../RadioCard';

const { TextArea } = Input;

/**
 * ArchiveDrawer
 * @param {Object} deliveryInfo - Contain information of the delivery
 * @param {Function} setDelivery - Set the delivery
 * @param {Function} setStaticDelivery - Set the static delivery
 * @return {JSX.Element}
 */
function ArchiveDrawer({ deliveryInfo, setDelivery, setStaticDelivery }) {
  const { t } = useTranslation();
  const visible = useContext(DrawersContext)['visible'];
  const setVisible = useContext(DrawersContext)['setVisible'];
  const notification = useContext(NotificationCenterContext);
  const eventFetch = useFetch(process.env.REACT_APP_API_BO, {
    cachePolicy: CachePolicies.NO_CACHE,
  });
  const [form] = Form.useForm();
  // Valeur du radio bouton selectionné
  const [value, setValue] = useState();
  // Mise à jour de la valeur du radio bouton selectionné
  const onChange = (e) => {
    setValue(e.target.value);
  };
  // Valeur de la description
  const [description, setDescription] = useState();
  // Met à jour la valeur de la description
  const updateDescription = (e) => {
    setDescription(e.target.value?.trim());
  };
  /**
   * saveArchiving - Fait l'action d'archiver la livraison
   */
  const saveArchiving = async() => {
    const body = {
      errand_id: deliveryInfo.id,
      event: getEventName(7),
      remove_reason: value,
      additional_reason: description?.trim().replace(/(\r\n|\n|\r)/gm, '<br>'),
    };
    await eventFetch.post('/deliveries/event', body);
    if (eventFetch.response.ok) {
      setVisible(false);
      setDelivery({
        ...deliveryInfo,
        validated: !deliveryInfo.validated,
        delivery_removed: {
          date: new Date(),
          reason: value,
        },
      });
      setStaticDelivery(false);
      return notification.push('success', t('SUCCESSFULLY_ARCHIVING'));
    } else {
      return notification.push('error', t('ARCHIVING_ERROR'));
    }
  };
  /**
   * onClose - Fermeture du drawer
   */
  function onClose() {
    setVisible(false);
  }

  return (
    <Drawer
      title={t('ARCHIVING_DRAWER_TITLE')}
      placement="right"
      width={window.innerWidth <= 630 ? '100%' : 630}
      open={visible}
      footer={
        <Space>
          <Popconfirm
            placement="bottomRight"
            title={t('ARCHIVING_POPCONFIRM_MESSAGE') + ' ' + deliveryInfo?.details?.drive_name + ' ?'}
            onConfirm={saveArchiving}
            okText={t('YES')}
            cancelText={t('NO')}
            overlayStyle={{ width: 340 }}
            okButtonProps={{ shape: 'round' }}
            cancelButtonProps={{ shape: 'round' }}
            disabled={!value || (value === 'OTHER' && !description)}
          >
            <Button
              type="primary"
              shape="round"
              disabled={!value || (value === 'OTHER' && !description)}
            >
              {t('ARCHIVE')}
            </Button>
          </Popconfirm>
        </Space>
      }
      onClose={onClose}
    >
      <Space direction='vertical' style={{ width: '100%' }}>
        <Alert message={t('WARNING_ARCHIVE_MESSAGE')} type="warning" showIcon />
      </Space>
      <div css={{ height: 20 }} />
      <Form layout='vertical' form={form}
        onValuesChange={(changedValue) => {
          if (changedValue.archivingReason !== 'OTHER') {
            form.validateFields(['addDescription']);
          }
        }}
      >
        <RadioCard legacy>
          <Form.Item
            name='archivingReason'
            label={t('ARCHIVING_REASON')}
            rules={[{
              required: true,
            }]}
          >
            <Radio.Group onChange={onChange}>
              <Radio value='CANCEL_PICKUP_POINT'>{t('CANCEL_PICKUP_POINT')}</Radio>
              <Radio value='CANCEL_RECIPIENT'>{t('CANCEL_RECIPIENT')}</Radio>
              <Radio value='NO_SHOPPER'>{t('NO_SHOPPER')}</Radio>
              <Radio value='DUPLICATED_DELIVERY'>{t('DUPLICATED_DELIVERY')}</Radio>
              <Radio value='OTHER'>{t('OTHER')}</Radio>
            </Radio.Group>
          </Form.Item>
          {value &&
            <Form.Item
              name='addDescription'
              label={t('DESCRIPTION_FIELD')}
              rules={[
                { required: value === 'OTHER' },
              ]}
            >
              <TextArea onChange={updateDescription} />
            </Form.Item>
          }
        </RadioCard>
      </Form>
    </Drawer>
  );
}

export default ArchiveDrawer;
