import { initialDelivery } from './deliveryStore';
/**
 * @param {string} state
 * @param {action} action
 * @return {void}
 */
export default function deliveryReducer(state, action = {}) {
  switch (action.type) {
  case 'CREATE_DELIVERY_CUSTOMER': // TODO it going to be edited after technical conception
    return { ...state,
      delivery: { ...state.delivery,
        customer: {
          ...state.delivery.customer,
          ...action.payload,
        },
      },
    };
  case 'CREATE_DELIVERY_DATE': // TODO it going to be edited after technical conception
    return { ...state,
      delivery: { ...state.delivery,
        date: {
          ...action.payload,
        },
      },
    };
  case 'EDIT_DELIVERY_CUSTOMER':
    return { ...state,
      delivery: { ...state.delivery,
        customer: {
          ...state.delivery.customer,
          ...action.payload,
        },
      },
    };
  case 'CREATE_ORDERED_INFOS_DRIVE':
    return { ...state,
      delivery: {
        ...state.delivery,
        ...action.payload,
      },
    };
  case 'CREATE_DRIVE_INFO':
    return { ...state,
      delivery: { ...state.delivery,
        drive: {
          ...state.delivery.drive,
          ...action.payload,
        },
      },
    };
  case 'CREATE_ORDERED_INFOS_TIPS':
    return { ...state,
      delivery: {
        ...state.delivery,
        ...action.payload,
      },
    };
  case 'SET_ORDER_DISTANCE_INFOS':
    return { ...state,
      delivery: {
        ...state.delivery,
        distance: action.payload.distance,
        isValidatedDistance: action.payload.isValidatedDistance,
      },
    };
  case 'CREATE_ORDERED_INFOS_SIZE':
    return { ...state,
      delivery: {
        ...state.delivery,
        ...action.payload,
      },
    };
  case 'SET_ABTESTING_NUMBER':
    return { ...state,
      delivery: {
        ...state.delivery,
        ...action.payload,
      },
    };
  case 'SET_CUSTOMER_ADDRESSES':
    return { ...state,
      delivery: { ...state.delivery,
        customer: {
          ...state.delivery.customer,
          addresses: [
            ...action.payload,
          ],
        },
      },
    };

  case 'UPDATE_CUSTOMER_ADDRESSES':
    return { ...state,
      delivery: { ...state.delivery,
        customer: {
          ...state.delivery.customer,
          addresses: [
            ...action.payload,
          ],
        },
      },
    };

  case 'SET_CUSTOMER_SELECTED_ADDRESS':
    return { ...state,
      delivery: { ...state.delivery,
        customer: {
          ...state.delivery.customer,
          address: {
            ...action.payload,
          },
        },
      },
    };

  case 'SET_NEW_ADDRESS':
    return { ...state,
      delivery: { ...state.delivery,
        customer: {
          ...state.delivery.customer,
          createNewAddress: action.payload,
        },
      },
    };

  case 'RESET_DELIVERY_FIELDS':
    return { ...state,
      delivery: {
        ...initialDelivery,
      },
    };
  case 'RESET_CUSTOMER_SELECTED_ADDRESS':
    return { ...state,
      delivery: { ...state.delivery,
        customer: {
          ...state.delivery.customer,
          address: {
            ...action.payload,
          },
        },
      },
    };
  default:
    return state;
  }
}
