// hooks/useAddressSearch.js
import { useCallback } from 'react';
import { readAddresses } from '../services/apiEndPoints/addresses/readAddresses';

const useAddressSearch = () => {
  const onAddressSearch = useCallback(async(addressSearch, userLocale) => {
    const userCountries = localStorage?.getItem('CountriesFilter')?.split(',');
    const [userLanguage] = userLocale?.split('-') ?? [];

    try {
      const response = await readAddresses({ addressSearch, locale: userLanguage, country: userCountries?.[0] });
      const result = Array.isArray(response.data) ? response.data : [];
      return result;
    } catch (error) {
      console.error('Error searching addresses:', error);
      return [];
    }
  }, []);

  return onAddressSearch;
};

export default useAddressSearch;
