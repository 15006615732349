import DesktopInactiveList from './Desktop/DesktopInactiveList';
import MobileInactiveList from './Mobile/MobileInactiveList';
import DeliveryListResponsiveWrapper from '../DeliveryListUtils/DeliveryListResponsiveWrapper';
import { useTranslation } from 'react-i18next';
import { INACTIVE_STATUSES_DELIVERIES } from '../../../globalVar/statusDeliveries';
import DeliveryTradeItemColumn from '../DeliveryListItems/DeliveryTradeItemColumn';
import DeliveryTimeSlotRow from '../DeliveryListItems/DeliveryTimeSlotRow';
import PersonDataRow from '../../Deliveries/PersonDataRow';
import defineSortOrder from '../../../utils/table/defineSortOrder';
import DeliveryStatusListItem from '../DeliveryListItems/DeliveryStatusListItem';
import DeliveryTipItemColumn from '../DeliveryListItems/DeliveryTipItemColumn';
import DeliveryIncidentIconListItem from '../DeliveryListItems/DeliveryIncidentIconListItem';
import DeliveryListLink from '../DeliveryListItems/DeliveryListLink';

/**
 * Creates a wrapper component for an inactive delivery list, which renders a different
 * component based on the width of the window.
 *
 * @return {JSX.Element} The rendered JSX element.
 */
function InactiveListWrapper({ deliveries, loading, tableParams, handleTableChanges }) {
  const { t } = useTranslation();
  const arrayStatus = INACTIVE_STATUSES_DELIVERIES.filter((item)=> item !== '');
  const columns = [
    {
      key: 'trade',
      title: t('DELIVERY_TABLE_TRADE'),
      dataIndex: 'trade',
      render: (trade) => trade ? <DeliveryTradeItemColumn trade={trade} /> : null,
    },
    {
      title: t('DELIVERY_TABLE_TITLE_DELIVERY'),
      key: 'timeSlot',
      dataIndex: 'time_slot',
      render: (timeSlot) => timeSlot.start && timeSlot.end ? <DeliveryTimeSlotRow timeSlot={timeSlot} /> : null,
    },
    {
      title: t('DELIVERY_TABLE_CUSTOMER'),
      key: 'customer',
      dataIndex: 'client',
      render: (customer) => <PersonDataRow person={customer} />,
    },
    {
      title: t('DELIVERY_TABLE_SHOPPER'),
      key: 'shopper',
      dataIndex: 'shopper',
      render: (shopper) => <PersonDataRow person={shopper} />,
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      sorter: true,
      defaultSortOrder: defineSortOrder({ key: 'status', ...tableParams.sorter }),
      defaultFilteredValue: tableParams.filters?.status ? [tableParams.filters?.status?.toString()] : null,
      filters: arrayStatus.map((item) => ({
        text: <DeliveryStatusListItem status={item} />,
        value: item,
      })),
      filterMultiple: false,
      render: (status) => <DeliveryStatusListItem status={status} />,
    },
    {
      title: t('DELIVERY_TABLE_TIPS'),
      key: 'tips',
      dataIndex: 'tips',
      sorter: true,
      defaultSortOrder: defineSortOrder({ key: 'tips', ...tableParams.sorter }),
      render: (tip) => <DeliveryTipItemColumn tip={tip} />,
    },
    {
      key: 'incident_icon',
      dataIndex: 'incident',
      width: 21,
      render: (incident) => <DeliveryIncidentIconListItem incident={incident} />,
    },
    {
      key: 'button_link',
      dataIndex: 'button_link',
      width: 60,
      render: ({ deliveryId, deliveryType }) => (
        <DeliveryListLink
          deliveryId={deliveryId}
          deliveryType={deliveryType}
        />
      ),
    },
  ];

  return (
    <DeliveryListResponsiveWrapper
      desktopChildren={<DesktopInactiveList columns={columns} deliveries={deliveries} loading={loading} tableParams={tableParams} handleTableChanges={handleTableChanges} />}
      mobileChildren={<MobileInactiveList columns={columns} deliveries={deliveries} loading={loading} tableParams={tableParams} handleTableChanges={handleTableChanges} />}
    />
  );
}

export default InactiveListWrapper;
