import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useCustomHtml from '../../../../../hooks/useCustomHtml';

/**
 * AddedHistoryNode
 * @param {Object} props
 * @param {Object} props.user
 * @param {string} props.user.firstName
 * @param {string} props.user.lastName
 * @param {string} props.addedSoftware
 * @param {string} props.driveName
 * @return {JSX.Element}
*/
function AddedHistoryNode({ user, addedSoftware, driveName }) {
  const { t } = useTranslation();
  const [nameContent, setNameContent] = useState('');
  useEffect(() => {
    if (user?.roles.includes('ROLE_PRO')) {
      setNameContent(driveName);
    } else {
      setNameContent(`${user.firstName} ${user.lastName}`);
    }
  }, [user, driveName]);

  return (
    <div>
      <div>
        {useCustomHtml({
          keyValues: [
            { key: '[NAME]', value: nameContent, bold: true },
          ],
          inputString: t('POSTED_BY'),
        })}
      </div>
      <div>
        {useCustomHtml({
          keyValues: [
            { key: '[SOURCE_NAME]', value: addedSoftware },
          ],
          inputString: t('DELIVERY_SOURCE'),
        })}
      </div>
    </div>
  );
}

export default AddedHistoryNode;
