import React from 'react';
import { useTranslation } from 'react-i18next';
import useCustomHtml from '../../../../../hooks/useCustomHtml';

/**
 * UpdatedTipsHistoryNode
 * @param {Object} props
 * @param {number} props.oldTips
 * @param {number} props.newTips
 * @param {Object} props.user
 * @param {string} props.user.firstName
 * @param {string} props.user.lastName
 * @return {JSX.Element}
 */
function UpdatedTipsHistoryNode({ oldTips, newTips, user }) {
  const { t } = useTranslation();
  return (
    <div>
      {useCustomHtml({
        keyValues: [
          { key: '[SHOPOPOP_NAME]', value: `${user.firstName} ${user.lastName}`, bold: true },
          { key: '[PREVIOUS_TIP]', value: oldTips, nextContent: '€' },
          { key: '[NEW_TIP]', value: newTips, nextContent: '€', bold: true },
        ],
        inputString: oldTips < newTips ? t('TIP_INCREASE') : t('TIP_DECREASE'),
      })}
    </div>
  );
}

export default UpdatedTipsHistoryNode;
