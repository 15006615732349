import dayjs from 'dayjs';

/**
 * Generate days options
 * @param {number} numDay
 * @return {dayjs[]}
 * */
export const generateDaysOptions = (numDay) => {
  const options = [];
  for (let i = 0; i < numDay; i++) {
    options.push(dayjs().add(i, 'day'));
  }
  return options;
};

/**
 * Get date from index
 * @param { number } index
 * @return { dayjs.Dayjs | null }
 */
export const getDateFromIndex = (index) => {
  const daysOptions = generateDaysOptions(3);
  if (index >= 0 && index < daysOptions.length) {
    return daysOptions[index];
  }
  return null;
};
/**
 * Get index from value
 * @param {dayjs} value
 * @return { number | null }
 * */
export const getIndexFromValue = (value) => {
  const daysOptions = generateDaysOptions(3);
  const index = daysOptions.findIndex((date) => date.isSame(value, 'day'));
  return index !== -1 ? index : null;
};
