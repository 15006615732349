import { useCallback } from 'react';

/**
 * ATTENTION : Hook qui finalement est pas mal utilisé avec le BO interne.
 * Actuellement pas mal de difficulté à traiter le typage car suivant les différentes
 * évolutions de nos APIs nous retrouvons pour le même cas (elevator non définie avec des valeurs
 * set à null, undefined ou même 0).
 *
 * Le hook est donc pour le moment utilisé QUE dans le BO interne (js pure, pas de TS)
 * et pas dans l'externe pour le moment en attendant que l'on clarifie la situation.
 */
const useTransformElevatorData = () => {
  const transformedElevatorData = useCallback((elevator: boolean | null | undefined | 0 | 'yes' | 'no' | 'dontknow') => {
    if (elevator === 'yes' || elevator === 'no' || elevator === 'dontknow') return elevator;
    if (elevator) return 'yes';
    if (elevator === false || elevator === 0) return 'no';
    if (elevator === null) return 'dontknow';
    return undefined;
  }, []);

  return { transformedElevatorData };
};

export default useTransformElevatorData;
