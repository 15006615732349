/**
 * Extracts the search parameters from the given search string and returns them as an object.
 *
 * @param {string} search - The search string containing the parameters.
 * @return {Object} - An object containing the extracted search parameters. The keys are parameter names and the values are parameter values.
 */
function getSearchParams(search) {
  const params = new URLSearchParams(search);
  const paramsObj = {};
  for (const [key, value] of params) {
    paramsObj[key] = value;
  }
  return paramsObj;
}

export default getSearchParams;
