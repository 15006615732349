/** @jsxImportSource @emotion/react */
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Form, Input, Typography } from 'antd';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { NotificationCenterContext } from '../../../context/NotificationCenterContext';
import { configurePassword } from '../../../services/apiEndPoints/user/configurePassword';
import TwoSidesWithHeaderLayout from '../../layouts/TwoSidesWithHeaderLayout';

const { Paragraph, Title } = Typography;
const { Item, useForm } = Form;

/**
 * Configuration password page
 * @return {JSX.Element}
 */
export default function ConfigurePassword() {
  const { t } = useTranslation();
  const [configurationForm] = useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const logger = useContext(NotificationCenterContext);

  const onSubmit = ({ email }) => {
    setLoading(true);
    configurePassword(email)
      .then(() => {
        logger.push('success', t('RESEND_CONFIG_FORM_CONFIRM'), '');
      })
      .catch((error) => {
        console.error(error);
        logger.push('error', t('RESEND_CONFIG_FORM_ERROR'), '');
      })
      .finally(() => {
        setLoading(false);
        navigate('/settings');
      });
  };

  return <TwoSidesWithHeaderLayout>
    <Link to="/settings" css={{ alignSelf: 'start' }}>
      <Button
        type='link'
        icon={<ArrowLeftOutlined />}
        css={{
          'marginBottom': 10,
          'span:last-child': {
            textDecoration: 'underline',
          },
        }}
      >
        {t('BACK_TO_SETTINGS')}
      </Button>
    </Link>
    <div>
      <Title level={1} style={{ fontWeight: 700, marginBottom: 10 }}>{t('RESEND_CONFIG_FORM_TITLE')}</Title>
      <Paragraph size='large' css={{ margin: 0 }}>{t('RESEND_CONFIG_FORM_MSG')}</Paragraph>
    </div>
    <Form form={configurationForm} layout='vertical' onFinish={onSubmit} autoComplete="on">
      <Item
        label={t('LOGIN_EMAIL')}
        name='email'
        rules={[
          {
            required: true,
            message: t('LOGIN_NO_EMAIL'),
          },
          {
            type: 'email',
            message: t('LOGIN_BAD_EMAIL_FORMAT'),
          }
        ]}>
        <Input allowClear size="large" placeholder='abc@def.com' />
      </Item>
      <Item css={{ margin: 0 }}>
        <Button type='primary' htmlType="submit" loading={loading}>{t('VALIDATE')}</Button>
      </Item>
    </Form>
  </TwoSidesWithHeaderLayout>;
}
