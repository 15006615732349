import { apiEndPointsOptions } from '../../apiEndPointsOptions';

export const writeIncident = async(data) => {
  const apiUrl = `${process.env.REACT_APP_API_BO}/deliveries/${data.deliveryId}/incidents`;
  const method = 'POST';

  try {
    const response = await fetch(
      apiUrl,
      await apiEndPointsOptions(method, data)
    );

    if (response.status === 200) {
      return {
        type: 'success',
        message: 'INCIDENT_SUCCESS_DECLARATION',
        data,
      };
    }

    if (response.status === 409) {
      return {
        type: 'error',
        message: 'INCIDENT_ALREADY_DECLARED',
      };
    }

    return {
      type: 'error',
      message: 'AN_ERROR_OCCURRED_WHILE_DECLARING_INCIDENT',
    };
  } catch (error) {
    throw new Error(`Error in writeIncident service: ${error.message}`);
  }
};
