/**
   * @function formatDistance
   * @description Affiche la distance en mètre ou en kilomètre
   * @param {number} distance
   * @return {string}
   */
export function formatDistance(distance) {
  if (distance < 1000) {
    return distance + ' m';
  } else {
    return (distance / 1000).toFixed(1).replace('.', ',') + ' km';
  }
}
