import { translateOrderAntd } from '../translateOrderAntd';

/**
 * Defines the sort order for a specific key.
 *
 * @param {object} params - The parameters for defining the sort order.
 * @param {string} params.key - The key to check for sorting.
 * @param {string} [params.field] - The field to compare with the key.
 * @param {string} [params.order] - The order of sorting.
 * @return {string|null} - The sort order if the field matches the key, otherwise null.
 */
function defineSortOrder({ key, field, order }) {
  if (!field && !order) return null;
  return field === key && order ? translateOrderAntd(order, 'long') : null;
}

export default defineSortOrder;
