import React, { useContext, useEffect, useState } from 'react';
import { AutoComplete, Checkbox, Col, Form, Input, InputNumber, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import useCustomHtml from '../../../hooks/useCustomHtml';
import countries from '../../../i18n/countries.json';
import { writeAddress } from '../../../services/apiEndPoints/addresses/writeAddress';
import { readAddresses } from '../../../services/apiEndPoints/addresses/readAddresses';
import { NotificationCenterContext } from '../../../context/NotificationCenterContext';
import HighlightText from '../../HighlightText/HighlightText';
import useDebounce from '../../../hooks/useDebounce';
import { INPUT_VALIDATION_REGEX, NO_EMOJI_REGEX } from '../../../utils/regex';

const { TextArea } = Input;

/**
 * Renders the CreateAddressForm component.
 * @param {Object} props - The props of the component.
 * @param {Object} props.form - The form object.
 * @param {Function} props.setAddresses - The function to update the addresses.
 * @param {number} props.userId - The user id.
 * @param {Function} props.setDrawerOpened - The function to update the drawer state.
 * @param {boolean} props.showAdditionalInfoForm - The state of the additional info form.
 * @param {Function} props.setShowAdditionalInfoForm - The function to update the additional info form state.
 * @param {Function} props.setIndexValueSelected - The function to update the index value selected.
 * @return {JSX.Element} Return the JSX element.
 */
function CreateAddressForm({ form, setAddresses, userId, setDrawerOpened, showAdditionalInfoForm, setShowAdditionalInfoForm, setIndexValueSelected }) {
  const { t } = useTranslation();
  const notification = useContext(NotificationCenterContext);
  const [suggestions, setSuggestions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [geolocId, setGeolocId] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const countryInput = Form.useWatch('country', form);

  useEffect(() => {
    if (debouncedSearchTerm.length >= 3 && debouncedSearchTerm.length < 100) {
      readAddresses({ addressSearch: debouncedSearchTerm, country: form.getFieldValue('country') })
        .then((response) => {
          setSuggestions(response.data);
          if (response.type === 'error') {
            notification.push(response.type, t(response.message));
          }
        });
    }
  }, [debouncedSearchTerm]);

  const handleSelect = (value) => {
    const { address, placeId } = JSON.parse(value);
    setGeolocId(placeId);
    form.setFieldsValue({ address: address });
    setShowAdditionalInfoForm(true);
  };

  const handleAddressCreationSubmit = (values) => {
    const address = {
      additional_info: values?.additional_info,
      address: values?.address,
      apartment: values?.apartment,
      country: values?.country,
      floor: values?.floor,
      digicode: values?.digicode,
      user_id: userId,
      zip_code: '',
      city: '',
      placeId: geolocId,
      elevator: values.elevator,
    };
    writeAddress({ address })
      .then((response) => {
        if (response.type === 'success') {
          setAddresses((addresses) => [{ ...address, id: response.data }, ...addresses]);
          setIndexValueSelected(0);
        }
        notification.push(response.type, t(response.message));
        setSearchTerm('');
        setDrawerOpened(false);
        setShowAdditionalInfoForm(false);
        form.resetFields();
      });
  };

  const customHtmlMessageApartment = useCustomHtml({
    keyValues: [
      { key: '[LABEL]', value: t('APARTMENT') },
    ],
    inputString: t('INPUT_NUMBER_LIMITE_LENGTH'),
  });

  const customHtmlMessageDigicode = useCustomHtml({
    keyValues: [
      { key: '[LABEL]', value: t('DIGICODE') },
    ],
    inputString: t('INPUT_NUMBER_LIMITE_LENGTH'),
  });

  return (
    <Form
      name='CreateAddress'
      form={form}
      layout='vertical'
      onFinish={(values) => handleAddressCreationSubmit(values)}
    >
      <Row>
        <Col xs={24}>
          <Form.Item
            label={t('COUNTRY')}
            name="country"
            rules={[{ required: true, message: t('COUNTRY_REQUIERED') }]}
            htmlFor='address-form-country'
          >
            <Select
              style={{ width: '100%' }}
              placeholder={t('SELECT_A_COUNTRY')}
              options={countries.map((country) => {
                return ({
                  value: country.alpha2,
                  label: country.name,
                });
              }
              )}
              id='address-form-country'
            />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item
            label={t('SEARCH_AN_ADDRESS')}
            name="address"
            required
            htmlFor='address-form-address'
          >
            <AutoComplete
              style={{ width: '100%' }}
              options={suggestions.map((suggestion) => ({
                value: JSON.stringify({ address: suggestion.address, placeId: suggestion.placeId }),
                label: (
                  <div>
                    <HighlightText
                      text={suggestion.address}
                      highlightTag={searchTerm}
                    />
                  </div>
                ),
              }))}
              onSelect={handleSelect}
              onSearch={(value) => setSearchTerm(value)}
              disabled={!countryInput}
              id='address-form-address'
            >
              <Input.Search
                placeholder={t('TYPE_YOUR_SEARCH')}
              />
            </AutoComplete>
          </Form.Item>
        </Col>
        {
          showAdditionalInfoForm ?
            (<>
              <p style={{ fontStyle: 'italic', paddingTop: '20px' }}>
                {t('PLEASE_FILL_ALL_APARTMENTS_FIELDS')}
              </p>
              <Row gutter={10}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                  <Form.Item
                    name='apartment'
                    label={t('APARTMENT')}
                    rules={[
                      {
                        max: 20,
                        message: customHtmlMessageApartment,
                      },
                      {
                        pattern: INPUT_VALIDATION_REGEX,
                        message: t('INVALID_VALUE'),
                      },
                    ]}
                    htmlFor="address-form-apartment"
                  >
                    <Input id='address-form-apartment' name='apartment' />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                  <Form.Item
                    name='digicode'
                    label={t('DIGICODE')}
                    rules={[
                      {
                        type: 'string',
                        max: 20,
                        message: customHtmlMessageDigicode,
                      },
                      {
                        pattern: INPUT_VALIDATION_REGEX,
                        message: t('INVALID_VALUE'),
                      },
                    ]}
                    htmlFor='adress-form-digicode'
                  >
                    <Input id='adress-form-digicode' name='digicode' />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                  <Form.Item
                    name='floor'
                    label={t('FLOOR')}
                    rules={[
                      {
                        pattern: /^(?=.{0,40}$).*/g,
                        message: t('FLOOR_LIMIT_LENGHT'),
                      },
                      {
                        type: 'number',
                        min: 0,
                        max: 40,
                        message: t('FLOOR_LIMIT_LENGHT'),
                      },
                      {
                        pattern: INPUT_VALIDATION_REGEX,
                        message: t('INVALID_VALUE'),
                      },
                    ]}
                    htmlFor='adress-form-floor'
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      id='adress-form-floor'

                      name='floor'
                      controls={true}
                      type='number'
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                  <Form.Item
                    name="elevator"
                    valuePropName="checked"
                    htmlFor='checkbox-adress-form-elevator'
                  >
                    <Checkbox id='checkbox-adress-form-elevator'>{t('ELEVATOR')}</Checkbox>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                  <Form.Item
                    name='additional_info'
                    label={t('FURTHER_INFORMATIONS')}
                    rules={[
                      {
                        pattern: NO_EMOJI_REGEX,
                        message: t('INVALID_VALUE'),
                      },
                    ]}
                    htmlFor='adress-form-additional-info'
                  >
                    <TextArea id='adress-form-additional-info' rows={5} showCount maxLength={255} allowClear />
                  </Form.Item>
                </Col>
              </Row>
            </>) : null}
      </Row>
    </Form>
  );
}

export default CreateAddressForm;
