import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { updateAddress } from '../../../services/apiEndPoints/addresses/updateAddress';
import { NotificationCenterContext } from '../../../context/NotificationCenterContext';
import formatComplementaryAddressToApi from '../../../utils/addresses/formatComplementaryAddressToApi';
import { removeObjectInArrayByKey } from '../../../utils/manipulateArray/removeObjectInArrayByKey';
import DrawForm from '../../../middlewares/DrawForm';
import countryStreetAddressFormItems from '../../../components/Addresses/Form/formItems/countryStreetAddressFormItems';
import additionnalInfoFormItems from '../../../components/Addresses/Form/formItems/additionnalInfoFormItems';

/**
 * Renders the UpdateAddressForm component.
 * @param {Object} props - The props of the component.
 * @param {Object} props.address - The form object.
 * @param {Function} props.setAddresses - The function to update the addresses.
 * @param {Object} props.form - The form object.
 * @param {Function} props.setDrawerOpened - The function to update the drawer state.
 * @param {Function} props.setIndexValueSelected - The function to update the index value selected.
 * @return {JSX.Element} Return the JSX element.
 */
function UpdateAddressForm({ address, setAddresses, form, setDrawerOpened, setIndexValueSelected }) {
  const { t } = useTranslation();
  const notification = useContext(NotificationCenterContext);

  return (
    <DrawForm
      form={form}
      formName='UpdateAddress'
      formItems={[...countryStreetAddressFormItems, ...additionnalInfoFormItems]}
      initialValues={{
        country: address.country,
        address: address.original_address,
        apartment: address.apartment,
        digicode: address.digicode,
        floor: address.floor,
        elevator: address.elevator,
        additional_info: address.additional_info,
      }}
      onFinish={(values) => {
        const addressInfos = formatComplementaryAddressToApi(values);
        updateAddress({ addressId: address.id, data: addressInfos })
          .then((response) => {
            setDrawerOpened(false);
            notification.push(response.type, t(response.message));
            if (response.type === 'success') {
              setAddresses((addresses) => {
                const newArray = removeObjectInArrayByKey({ array: addresses, key: 'id', value: response.data.id });
                newArray.unshift(response.data);
                return newArray;
              });
              setIndexValueSelected(0);
            }
          });
      }}
    />
  );
}

export default UpdateAddressForm;
