/**
 * @name formatCurrency
 * @param {number} value
 * @return {string}
 */
export function formatCurrency(value) {
  const locale = localStorage.getItem('i18nextLng') || navigator.language;

  return new Intl.NumberFormat(locale, { style: 'currency', currency: 'EUR' }).format(value);
}
