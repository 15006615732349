/** @jsxImportSource @emotion/react */
import { Typography } from 'antd';
import { distanceParser } from '../../../utils/distanceParser';

const { Text } = Typography;

/**
 * Returns a JSX element displaying the parsed delivery distance.
 *
 * @param {number} distance - The distance to be parsed.
 * @return {JSX.Element} - A JSX element displaying the parsed distance value and unit.
 */
function DeliveryDistanceItemColumn({ distance }) {
  const parsedDistance = distanceParser(distance);

  return <Text type={parsedDistance.value >= 15 ? 'danger' : null} css={{ fontSize: 12 }}>{parsedDistance.value} {parsedDistance.unit}</Text>;
}

export default DeliveryDistanceItemColumn;
