/** @jsxImportSource @emotion/react */
import LegacyRadioCard from './LegacyRadioCard';
import { colors } from '../../globalVar/theme';
import { isTouchableView } from '../DeviceDetect/TouchableView';

/**
* @function RadioCard
* @param {Object} props - The properties passed to the component
* @param {ReactNode} props.children - The content to be rendered within the RadioCard component
* @param {Array} props.variations - ['horizontal'] - The variations to be applied to the RadioCard component
* @param {string} props.cssProps - The css properties to be applied to the RadioCard component
* @param {boolean} props.legacy - Whether to use the legacy version of the component
* @return {JSX.Element} - A React component that renders a radio card with the provided children
*/
function RadioCard({ children, variations, cssProps, legacy }) {
  const deviceSize = isTouchableView() ? 'small' : 'medium';
  const style = {
    'display': 'grid',
    'gridTemplateColumns': cssProps?.[deviceSize]?.gridTemplateColumns || '1fr',
    'gap': 10,
    'label': {
      'margin': 0,
      'padding': '10px 20px',
      'border': `1px solid ${colors.primary}`,
      'borderRadius': 4,
      'fontSize': '0.75rem',
      'lineHeight': '0.938rem',
      'color': colors.primary,
    },
    '.ant-radio-inner': {
      'borderColor': colors.primary,
    },
    '.ant-radio-wrapper-checked': {
      'transition': 'background-color 0.3s ease-in-out',
      'backgroundColor': colors.lightBlue,
      '.ant-radio-inner': {
        'backgroundColor': 'white',
        ':after': {
          'width': 24,
          'height': 24,
          'marginBlockStart': -12,
          'marginInlineStart': -12,
          'backgroundColor': colors.primary,
        },
      },
    },
    '.ant-radio-wrapper': variations?.includes('vertical') && deviceSize === 'medium' ? {
      'display': 'flex',
      'flexDirection': 'column',
      'alignItems': 'center',
      'textAlign': 'center',
      'justifyItems': 'center',
      'gap': 10,
      'span': {
        'padding': 0,
      },
      ':after': {
        'display': 'none',
      },
    } : null,
  };

  return legacy ? (
    <LegacyRadioCard>
      {children}
    </LegacyRadioCard>
  ) : (
    <div css={style}>
      {children}
    </div>
  );
}

export default RadioCard;
