import React, { useContext } from 'react';
import { Modal, Divider, Space, Typography } from 'antd';
import { getStatusKey } from '../../utils/getStatusKey';
import { getEventName } from '../../utils/getEventName';
import ModalContext from '../../context/ModalContext';
import { NotificationCenterContext } from '../../context/NotificationCenterContext';
import { useFetch } from 'use-http';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

/**
 * UpdateStatusModal
 * @param {number} fromStatus initial status of the delivery
 * @param {number} toStatus status to update
 * @param {number} delivery delivery informations
 * @param {number} deliveryShopperCancelled number of cancelled shopper on the delivery
 * @param {function} setStatus function to update the status of the delivery
 * @param {function} setDelivery function to update the delivery informations
 * @param {function} setSearchUser function to update shopper search
 * @param {function} setDeliveryShopperCancelled function to update the deliveryShopperCancelled count
 * @return {JSX.Element}
 */
function UpdateStatusModal({ fromStatus, toStatus, delivery, deliveryShopperCancelled, setDelivery, setStatus, setSearchUser, setStaticDelivery, setDeliveryShopperCancelled }) {
  const { t } = useTranslation();
  const openModalStatus = useContext(ModalContext)['openModalStatus'];
  const setOpenModalStatus = useContext(ModalContext)['setOpenModalStatus'];
  const eventFetch = useFetch(process.env.REACT_APP_API_BO, {
    cachePolicy: 'no-cache',
  });
  const notification = useContext(NotificationCenterContext);

  const handleOk = async() => {
    const body = {
      errand_id: delivery.id,
      event: getEventName(toStatus),
    };
    await eventFetch.post('/deliveries/event', body);
    if (eventFetch.response.ok) {
      if (toStatus === 1) {
        setSearchUser();
        setDeliveryShopperCancelled(deliveryShopperCancelled + 1);
        setDelivery({ ...delivery, status: toStatus, shopper: null });
      }
      if (toStatus === 6) {
        setDelivery({ ...delivery, status: toStatus });
        setStaticDelivery(false);
      }
      setStatus(toStatus);
      setDelivery({ ...delivery, status: toStatus });
      setOpenModalStatus(false);
      return notification.push('success', t('STATUS_SUCCESSFULLY_UPDATED'));
    } else {
      setOpenModalStatus(false);
      return notification.push('error', t('UPDATE_DELIVERY_STATUS_ERROR'));
    }
  };

  const handleCancel = () => {
    setOpenModalStatus(false);
  };

  return (
    <Modal
      title={t('UPDATING_DELIVERY')}
      open={openModalStatus}
      onOk={handleOk}
      onCancel={handleCancel}
      centered
    >
      <Divider />
      <Space direction='vertical' css={{ padding: 0 }}>
        <Text>{t(getStatusKey(fromStatus, toStatus))}</Text>
      </Space>
    </Modal>
  );
}

export default UpdateStatusModal;
