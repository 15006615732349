import DesktopOnGoingList from './Desktop/DesktopOnGoingList';
import MobileOnGoingList from './Mobile/MobileOnGoingList';
import DeliveryListResponsiveWrapper from '../DeliveryListUtils/DeliveryListResponsiveWrapper';
import { ON_GOING_STATUS_DELIVERIES } from '../../../globalVar/statusDeliveries';
import { useTranslation } from 'react-i18next';
import PersonDataRow from '../../Deliveries/PersonDataRow';
import DeliveryListLink from '../DeliveryListItems/DeliveryListLink';
import DeliveryTimeSlotRow from '../DeliveryListItems/DeliveryTimeSlotRow';
import DeliveryTradeItemColumn from '../DeliveryListItems/DeliveryTradeItemColumn';
import DeliveryTipItemColumn from '../DeliveryListItems/DeliveryTipItemColumn';
import DeliveryDistanceItemColumn from '../DeliveryListItems/DeliveryDistanceItemColumn';
import DeliveryStatusListItem from '../DeliveryListItems/DeliveryStatusListItem';
import DeliveryIncidentIconListItem from '../DeliveryListItems/DeliveryIncidentIconListItem';
import defineSortOrder from '../../../utils/table/defineSortOrder';

/**
 * Creates a wrapper component for an ongoing list, which renders a different
 * component based on the width of the window.
 *
 * @return {JSX.Element} The rendered JSX element.
 */
function OnGoingListWrapper({ deliveries, loading, tableParams, handleTableChanges, dateFilters }) {
  const { t } = useTranslation();
  const arrayStatus = ON_GOING_STATUS_DELIVERIES.filter((item)=> item !== '');
  const columns = [
    {
      key: 'trade',
      title: t('DELIVERY_TABLE_TRADE'),
      dataIndex: 'trade',
      render: (trade) => trade ? <DeliveryTradeItemColumn trade={trade} /> : null,
    },
    {
      title: t('DELIVERY_TABLE_TITLE_DELIVERY'),
      key: 'timeSlot',
      dataIndex: 'time_slot',
      defaultFilteredValue: tableParams.filters?.timeSlot ? [tableParams.filters?.timeSlot] : null,
      filters: dateFilters.map((date) =>({
        text: date.text,
        value: date.value,
      })),
      filterMultiple: false,
      render: (timeSlot) => timeSlot.start && timeSlot.end ? <DeliveryTimeSlotRow timeSlot={timeSlot} /> : null,
    },
    {
      title: t('DELIVERY_TABLE_CUSTOMER'),
      key: 'customer',
      dataIndex: 'client',
      render: (customer) => <PersonDataRow person={customer} />,
    },
    {
      title: t('DELIVERY_TABLE_SHOPPER'),
      key: 'shopper',
      dataIndex: 'shopper',
      render: (shopper) => <PersonDataRow person={shopper} />,
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      sorter: true,
      defaultSortOrder: defineSortOrder({ key: 'status', ...tableParams.sorter }),
      defaultFilteredValue: tableParams.filters?.status ? [tableParams.filters?.status?.toString()] : null,
      filters: arrayStatus.map((item) => ({
        text: <DeliveryStatusListItem status={item} />,
        value: item,
      })),
      filterMultiple: false,
      render: (status) => <DeliveryStatusListItem status={status} />,
    },
    {
      title: t('DELIVERY_TABLE_DISTANCE'),
      key: 'distance_drive_dest',
      dataIndex: 'distance_drive_dest',
      sorter: true,
      defaultSortOrder: defineSortOrder({ key: 'distance_drive_dest', ...tableParams.sorter }),
      render: (distance) => <DeliveryDistanceItemColumn distance={distance} />,
    },
    {
      title: t('DELIVERY_TABLE_TIPS'),
      key: 'tips',
      dataIndex: 'tips',
      sorter: true,
      defaultSortOrder: defineSortOrder({ key: 'tips', ...tableParams.sorter }),
      render: (tip) => <DeliveryTipItemColumn tip={tip} />,
    },
    {
      key: 'incident_icon',
      dataIndex: 'incident',
      width: 21,
      render: (incident) => <DeliveryIncidentIconListItem incident={incident} />,
    },
    {
      key: 'button_link',
      dataIndex: 'button_link',
      width: 60,
      render: ({ deliveryId, deliveryType }) => (
        <DeliveryListLink
          deliveryId={deliveryId}
          deliveryType={deliveryType}
        />
      ),
    },
  ];

  return (
    <DeliveryListResponsiveWrapper
      desktopChildren={<DesktopOnGoingList columns={columns} deliveries={deliveries} loading={loading} tableParams={tableParams} handleTableChanges={handleTableChanges} />}
      mobileChildren={<MobileOnGoingList columns={columns} deliveries={deliveries} loading={loading} tableParams={tableParams} handleTableChanges={handleTableChanges} />}
    />
  );
}

export default OnGoingListWrapper;
