import DesktopProcessedList from './Desktop/DesktopProcessedList';
import MobileProcessedList from './Mobile/MobileProcessedList';
import DeliveryListResponsiveWrapper from '../DeliveryListUtils/DeliveryListResponsiveWrapper';
import { useTranslation } from 'react-i18next';
import DeliveryDistanceItemColumn from '../DeliveryListItems/DeliveryDistanceItemColumn';
import PersonDataRow from '../../Deliveries/PersonDataRow';
import DeliveryWithdrawalHour from '../DeliveryListItems/DeliveryWithdrawalHour';
import DeliveryDate from '../DeliveryListItems/DeliveryDate';
import DeliveryListLink from '../DeliveryListItems/DeliveryListLink';
import DeliveryDeadline from '../DeliveryListItems/DeliveryDeadline';
import DeliveryTipItemColumnProcessed from '../DeliveryListItems/DeliveryTipItemColumnProcessed';
import DeliveryTensionZoneItemColumn from '../DeliveryListItems/DeliveryTensionZoneItemColumn';

/**
 * Creates a wrapper component for an ongoing list, which renders a different
 * component based on the width of the window.
 *
 * @return {JSX.Element} The rendered JSX element.
 */
function ProcessedListWrapper({ deliveries, loading, tableParams, handleTableChanges }) {
  const { t } = useTranslation();
  const columns = [
    {
      title: t('DELIVERY_END'),
      key: 'delivery_end',
      dataIndex: 'delivery_end',
      render: (deliveryEnd) => <DeliveryDeadline deliveryEnd={deliveryEnd} />,
    },
    {
      title: t('DATE'),
      key: 'date',
      dataIndex: 'shifted_date',
      render: (shiftedDate) => <DeliveryDate shiftedDate={shiftedDate} />,
    },
    {
      title: t('DELIVERY_SLOTS'),
      key: 'shifted_withdrawal',
      dataIndex: 'shifted_withdrawal',
      render: (shiftedWithdrawal) => <DeliveryWithdrawalHour shiftedWithdrawal={shiftedWithdrawal} />,
    },
    {
      title: t('DELIVERY_TABLE_CUSTOMER'),
      key: 'customer',
      dataIndex: 'client',
      render: (customer) => <PersonDataRow person={customer} />,
    },
    {
      title: t('DELIVERY_TABLE_TIPS'),
      key: 'tips',
      dataIndex: 'increase_tips',
      render: (increaseTips) => <DeliveryTipItemColumnProcessed increaseTips={increaseTips} />,
    },
    {
      title: t('DELIVERY_TABLE_DISTANCE'),
      key: 'distance_drive_dest',
      dataIndex: 'distance_drive_dest',
      render: (distance) => <DeliveryDistanceItemColumn distance={distance} />,
    },
    {
      title: t('TENSION_ZONE'),
      key: 'tension',
      dataIndex: 'driveTensionZone',
      render: (driveTensionZone) => <DeliveryTensionZoneItemColumn tensionZone={driveTensionZone} />,
    },
    {
      key: 'button_link',
      dataIndex: 'button_link',
      width: 60,
      render: ({ deliveryId, deliveryType }) => (
        <DeliveryListLink
          deliveryId={deliveryId}
          deliveryType={deliveryType}
        />
      ),
    },
  ];
  return (
    <DeliveryListResponsiveWrapper
      desktopChildren={<DesktopProcessedList columns={columns} deliveries={deliveries} loading={loading} tableParams={tableParams} handleTableChanges={handleTableChanges}/>}
      mobileChildren={<MobileProcessedList columns={columns} deliveries={deliveries} loading={loading} tableParams={tableParams} handleTableChanges={handleTableChanges}/>}
    />
  );
}

export default ProcessedListWrapper;
