import { apiEndPointsOptions } from '../apiEndPointsOptions';

/**
 * Update delivery detail
 * @param {String} addressSearch
 * @param {String} country
 * @return {Promise<any>}
 */
export async function readAddresses({ addressSearch, locale, country }) {
  const apiUrl = `${process.env.REACT_APP_API_BO}/addresses/autocomplete?q=${addressSearch}&locale=${locale || 'FR'}${country ? '&country=' + country : ''}`;
  const method = 'GET';

  try {
    const response = await fetch(
      apiUrl,
      await apiEndPointsOptions(method),
    );

    const result = await response.json();

    if (response.ok) {
      return {
        type: 'success',
        data: result,
      };
    }

    if (response.status === 404) {
      return {
        type: 'not_found',
        data: [],
      };
    }
    return {
      type: 'error',
      message: 'INTERNAL_ERROR',
    };
  } catch (error) {
    throw new Error(`Error in updateAddress service: ${error.message}`);
  }
}
