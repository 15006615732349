import { Space } from 'antd';
import PrimaryBadge from '../Badges/PrimaryBadge';
import { useTranslation } from 'react-i18next';

/**
 * Renders a label badge counter.
 *
 * @param {string} text - The text to be displayed as the label.
 * @param {number} count - The count to be displayed in the badge.
 * @param {boolean} active - Specifies whether the badge should be visible or hidden.
 *
 * @return {JSX.Element} The JSX element representing the label badge counter.
 */
function LabelBadgeCounter({ text, count, active }) {
  const { t } = useTranslation();

  return (
    <Space>
      {text}
      {text !== t('INACTIVE') ? <PrimaryBadge number={active ? count : 0} /> : null}
    </Space>
  );
}

export default LabelBadgeCounter;
