/**
 * BetaFeature
 * @param {React.ReactElement} children
 * @param {React.ReactElement} deprecatedComponent
 * @return {JSX.Element}
 */
function BetaFeature({ children, deprecatedComponent }) {
  return process.env.REACT_APP_BO_BETA_FEATURES_ACTIVATED === 'true' ? children : (deprecatedComponent || null);
}

export default BetaFeature;
