/**
 * @param {date} date
 * @return {string} date in string format
 */
export function toLocaleStringHourandMinutes(date) {
  /*
   Rajout du slice sur la date en attendant de savoir lequel des service traite la timeZone,
  car cette dernière est supprimée via le slice car la date est renvoyée au format iso
*/
  const dateString = date instanceof Date ? date.toISOString() : date;
  date = new Date(dateString);
  const locale = localStorage.getItem('i18nextLng') || navigator.language;
  return new Intl.DateTimeFormat(locale, {
    hour: '2-digit',
    minute: '2-digit',
  }).format(date).replace(':', (locale.split('-').includes('en')) ? ':' : 'h');
}
