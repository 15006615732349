/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { UserAddOutlined } from '@ant-design/icons';
import { StepContext } from '../CreateDeliveryForm';
import { initialDelivery } from '../../../../reducers/delivery/deliveryStore';
import { AppContext } from '../../../../context/AppContext';


/**
 * ButtonCreateNewUser
  * @return {Button}
  * @param {object} form
 */
function ButtonCreateNewUser( { formStep1 }) {
  const { t } = useTranslation();
  const [, dispatch] = useContext(AppContext);
  const { setShowCustomerForm, setStepsValidated } = useContext(StepContext);

  const clearSelectedUser = () => {
    dispatch({ type: 'EDIT_DELIVERY_CUSTOMER', payload: initialDelivery.customer });
    formStep1.resetFields();
    setShowCustomerForm(true);
    setStepsValidated([false, false, false]);
  };


  return (
    <>
      <Button
        id='button-create-new-customer-create-step1'
        type="primary"
        htmlType="button"
        icon={<UserAddOutlined />}
        onClick={clearSelectedUser}
        name='createNewCustomer'
      >
        {t('NEW_CLIENT')}
      </Button>
    </>
  );
}
export default ButtonCreateNewUser;
