/**
 * FormItemValidator
 * @param {string} validator
 * @param {function} t
 * @return {Object} validationFunctions
 */
function formItemValidator(validator, t) {
  switch (validator) {
  case 'validationTips':
    return () => ({
      validator(_, value) {
        if (value > 40 || value < 0) {
          return Promise.reject(new Error(t('UPDATE_TIPS')));
        } else {
          return Promise.resolve();
        }
      },
    });
  default:
    return null;
  }
}


export default formItemValidator;
