import { formatCurrency } from '../../../utils/formatCurrency';

/**
 * Displays the delivery tip amount in a formatted column.
 *
 * @param {object} increaseTips
 * @return {JSX.Element} - The formatted column displaying the delivery tip amount.
 */
function DeliveryTipItemColumn({ tip }) {
  return <span>{formatCurrency(tip)}</span>;
}

export default DeliveryTipItemColumn;
