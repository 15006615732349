/** @jsxImportSource @emotion/react */
import DeliverySearchInput from './DeliverySearchInput';
import DeliveryDateSelectInput from './DeliveryDateSelectInput';
import DeliveryStatusSelectInput from './DeliveryStatusSelectInput';
import { sizes } from '../../../globalVar/theme';
import { Space } from 'antd';
import { useWindowSize } from '../../../hooks/useWindowSize';

/**
 * Renders mobile filters for a delivery list.
 *
 * @param {string} activeTab - The active tab.
 * @param {string} searchValueParam - The search value parameter.
 * @param {function} launchSearch - The function to execute when launching a search.
 * @param {object} tableParams - The table parameters.
 * @param {function} handleTableChanges - The function to handle table changes.
 *
 * @return {JSX.Element|null} The mobile filters component.
 */
function DeliveryListMobileFilters({ activeTab, searchValueParam, launchSearch, tableParams, handleTableChanges }) {
  const windowSize = useWindowSize();

  if (windowSize.width > sizes.sm) return null;

  const style = {
    width: '100%',
  };

  const filters = {
    'ongoing': (
      <>
        <DeliverySearchInput launchSearch={launchSearch} style={style} searchValueParam={searchValueParam} />
        <DeliveryDateSelectInput tableParams={tableParams} handleTableChanges={handleTableChanges} style={style} />
        <DeliveryStatusSelectInput tableParams={tableParams} handleTableChanges={handleTableChanges} style={style} />
      </>
    ),
    'inactive': (
      <>
        <DeliverySearchInput launchSearch={launchSearch} style={style} searchValueParam={searchValueParam} />
        <DeliveryStatusSelectInput tableParams={tableParams} handleTableChanges={handleTableChanges} style={style} />
      </>
    ),
    'action-needed': (
      <>
        <DeliverySearchInput launchSearch={launchSearch} style={style} searchValueParam={searchValueParam} />
      </>
    ),
  };

  if (!filters[activeTab]) return null;

  return (
    <Space direction="vertical" css={{ width: '100%' }}>
      {filters[activeTab]}
    </Space>
  );
}

export default DeliveryListMobileFilters;
