/** @jsxImportSource @emotion/react */
import { Select } from 'antd';
import { countryAlphaOrder } from '../../../utils/countryAlphaOrder';
import { mapI18N, useChangeLanguage } from '@shopopop/react-hooks';
import { useTranslation } from 'react-i18next';

/**
 * LanguageSelector
 * @param {Object} inputStyle
 * @return {JSX.Element}
 */
function LanguageSelector({ inputStyle }) {
  const { i18n } = useTranslation();

  /**
   * handleChangeLanguage
   * @param {string} value
   */
  const handleChangeLanguage = useChangeLanguage();
  const defaultValue = mapI18N.filter((lang) => lang.keys.includes(i18n.languages[0]))[0]?.name;

  return (
    <Select
      css={inputStyle}
      showSearch
      defaultValue={defaultValue}
      onSelect={handleChangeLanguage}
      options={countryAlphaOrder(mapI18N).map((lang)=> {
        return ({
          value: lang.name,
          label: lang.name,
        });
      })
      }
    />
  );
}

export default LanguageSelector;
