/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';
import { Button, Form, Typography, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { StepContext } from '../CreateDeliveryForm';
import useFetch from 'use-http';
import { NotificationCenterContext } from '../../../../context/NotificationCenterContext';
import { Navigate, useNavigate } from 'react-router-dom';
import { AppContext } from '../../../../context/AppContext';
import DeliveryTimeSlot from '../../../DeliveryTime/DeliveryTimeSlot';
import dayjs from 'dayjs';
import useDocumentTitle from '../../../../hooks/useDocumentTitle';

/**
 * Step3
 * @return {Form}
 */
function Step3() {
  const { t } = useTranslation();
  useDocumentTitle(t('URL_DELIVERY_CREATION_DELIVERY'));
  const [store, dispatch] = useContext(AppContext);
  const { setCurrentStep, setStepsValidated } = useContext(StepContext);
  const deliveriesFetch = useFetch((process.env.REACT_APP_API_BO, {
    cachePolicy: 'no-cache',
  }));

  const notification = useContext(NotificationCenterContext);
  const navigate = useNavigate();
  const [formStep3] = Form.useForm();

  const updateStoreDate = () => {
    const formValue = formStep3.getFieldsValue();
    dispatch({ type: 'CREATE_DELIVERY_DATE', payload: {
      start: formValue.hourStart,
      end: formValue.hourEnd,
    }});
  };


  const onFinish = async(value) => {
    await deliveriesFetch.post('/deliveries', {
      // TODO need get Real value for: transport
      address_id: store.delivery.customer.address.id,
      drive_id: store.delivery.drive.driveId,
      reference: store.delivery.reference,
      additional_info: store.delivery.storeComment,
      ord_value: store.delivery.orderAmount,
      delivery_start: value.hourStart.toISOString(),
      delivery_end: value.hourEnd.toISOString(),
      transport: '',
      transport_size: store.delivery.size,
      nbr_article: store.delivery.articlesAmount,
      ab_test: store.delivery.abTest,
      client_id: store.delivery.customer.userId,
    });
    if (deliveriesFetch.response.status === 200) {
      notification.push('success',
        t('DELIVERY_CREATED'),
        <Typography.Link onClick={()=>{
          const currentUrl = window.location.href;
          if (!currentUrl.includes('/delivery/')) {
            navigate(`${deliveriesFetch.response.data}`);
          }
          notification.closeNotification();
        }}>
          {t('VIEW_DELIVERY')}
        </Typography.Link>);
      dispatch({ type: 'RESET_DELIVERY_FIELDS' });
    }
    if (deliveriesFetch.response.status > 409) {
      notification.push('error', t('INTERNAL_ERROR'));
    }
    if (deliveriesFetch.response.status === 400) {
      notification.push('error', t(deliveriesFetch.response.data.errors[0].message));
    }
  };

  const prevButton = () => {
    updateStoreDate();
    setStepsValidated([true, true, true]);
    setCurrentStep(1);
  };


  return (
    <Form
      form={formStep3}
      name="CreateInformationDateForm"
      onFinish={onFinish}
      layout="vertical"
    >
      <Card title={t('DELIVERY')}>
        <DeliveryTimeSlot
          method='POST'
          form={formStep3}
          initialDates={{
            start: dayjs(store.delivery.date.start),
            end: dayjs(store.delivery.date.end),
          }}
        />
      </Card>
      <div css={{ display: 'flex', justifyContent: 'center', padding: '25px 0', gap: 100 }}>
        <Button icon={<ArrowLeftOutlined />} id='button-prev-create-step3' onClick={() => prevButton()} type="primary" htmlType="button" name="prev">
          {t('PREVIEWS')}
        </Button>
        <Button type="primary"id='button-finish-create-step3' htmlType="submit" name="finish" loading={deliveriesFetch.loading}>
          {t('FINISH')}
          <ArrowRightOutlined/>
        </Button>
        {deliveriesFetch.response.status === 200 && <Navigate to={`/deliveries${store.deliveryList.ongoing ?? '?label=ongoing'}`}/>}
      </div>
    </Form>
  );
}

export default Step3;
