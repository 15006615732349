import Dayjs from 'dayjs';

/**
 * Get the number of date and slot shifted
 * @param {Array} shiftedDeliveryList - List of shifted delivery
 * @return {Object} - Number of date and slot shifted
 */
export function getShiftedCountsDateAndSlot(shiftedDeliveryList) {
  let shiftedDateCount = 0;
  let shiftedSlotCount = 0;
  // Parse the history to get the number of date and slot shifted
  shiftedDeliveryList?.map((shiftedDelivery) => {
    const oldDate = { end: shiftedDelivery.additional_info.old_end_date, start: shiftedDelivery.additional_info.old_start_date };
    const newDate = { end: shiftedDelivery.additional_info.new_end_date, start: shiftedDelivery.additional_info.new_start_date };

    const isDayChanged = new Dayjs(oldDate.start).format('DD') !== new Dayjs(newDate.start).format('DD');
    const isMonthChanged = new Dayjs(oldDate.start).format('MM') !== new Dayjs(newDate.start).format('MM');
    const isYearChanged = new Dayjs(oldDate.start).format('YYYY') !== new Dayjs(newDate.start).format('YYYY');
    const isHourStartChanged = new Dayjs(oldDate.start).format('HH') !== new Dayjs(newDate.start).format('HH');
    const isMinuteStartChanged = new Dayjs(oldDate.start).format('mm') !== new Dayjs(newDate.start).format('mm');
    const isHourEndChanged = new Dayjs(oldDate.end).format('HH') !== new Dayjs(newDate.end).format('HH');
    const isMinuteEndChanged = new Dayjs(oldDate.end).format('mm') !== new Dayjs(newDate.end).format('mm');

    // Delivery date is modified
    if (isDayChanged || isMonthChanged || isYearChanged) {
      if (isHourStartChanged || isMinuteStartChanged || isHourEndChanged || isMinuteEndChanged) {
        shiftedSlotCount = shiftedSlotCount + 1;
      }
      shiftedDateCount = shiftedDateCount + 1;
    } else if (isHourStartChanged || isMinuteStartChanged || isHourEndChanged || isMinuteEndChanged) {
      shiftedSlotCount = shiftedSlotCount + 1;
    }
    return null;
  });
  return { shiftedDateCount: shiftedDateCount, shiftedSlotCount: shiftedSlotCount };
}
