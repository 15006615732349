/** @jsxImportSource @emotion/react */
import { Typography } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

/**
 * Represents a deadline in the delivery list.
 *
 * @param {Object} deliveryEnd - The delivery end time object.
 *
 * @return {JSX.Element} - The rendered React element representing the deadline.
 */
function DeliveryDeadline({ deliveryEnd }) {
  const { t } = useTranslation();
  const difference = dayjs().diff(dayjs(deliveryEnd), 'second');
  const duration = dayjs.duration(Math.abs(difference), 'seconds');

  const splitWeblateKeyValue = (value) => {
    const entries = value.split('-');
    return {
      days: entries[0] || 'd',
      hours: entries[1] || 'h',
      minutes: entries[2] || 'min',
    };
  };

  const renderTimeLabel = (time, labelKey) => {
    return time > 0 ? ` ${time}${splitWeblateKeyValue(t('COUNTDOWN_WORDS'))[labelKey] || ''}` : '';
  };

  const timeIsPassed = difference > 0;

  return (
    <Text type={timeIsPassed ? 'danger' : null} strong={timeIsPassed} css={{ fontSize: 12 }}>
      {timeIsPassed && '-'}
      {renderTimeLabel(Math.floor(duration.asDays()), 'days')}
      {renderTimeLabel(duration.hours(), 'hours')}
      {renderTimeLabel(duration.minutes(), 'minutes')}
    </Text>
  );
}

export default DeliveryDeadline;
