/**
 * Change the delivery list state based on the given action.
 *
 * @param {object} state - The current state of the delivery list.
 * @param {String} payload - The payload of the action.
 * @param {object} action - The action object.
 * @return {object} - The updated delivery list state.
 */
export default function deliveryListReducer(state = {}, { payload, type }) {
  let newState = state;
  switch (type) {
  case 'UPDATE_DELIVERY_INACTIVE_LIST':
    newState = {
      ...state,
      deliveryList: {
        ...state.deliveryList,
        inactive: payload,
      },
    };
    break;
  case 'UPDATE_DELIVERY_ONGOING_LIST':
    newState = {
      ...state,
      deliveryList: {
        ...state.deliveryList,
        ongoing: payload
      },
    };
    break;
  case 'UPDATE_DELIVERY_ACTION_NEEDED_LIST':
    newState = {
      ...state,
      deliveryList: {
        ...state.deliveryList,
        'action-needed': payload
      }
    };
    break;

  default:
    newState = state;
  }

  return newState;
}
