import React, { useState } from 'react';
import { Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { sizes } from '../../../globalVar/theme';
import AddressDrawer from '../Drawer/AddressDrawer';

/**
 * OpenEditAddressDrawer
 * @param {Object} props
 * @param {Object} props.address
 * @param {Function} props.setAddresses
 * @param {number} props.indexValueSelected
 * @param {Function} props.setIndexValueSelected
 * @param {number} props.index
 * @return {JSX.Element}
 */
function OpenEditAddressDrawer({ address, setAddresses, indexValueSelected, setIndexValueSelected, index }) {
  const { t } = useTranslation();
  const windowSize = useWindowSize();
  const [opened, setOpened] = useState(false);

  return (
    <>
      {
        windowSize.width > sizes.sm ?
          <Button type='link' onClick={() => setOpened(true)}>
            {t('ACCESS_ADRESS_DETAILS')}
          </Button> :
          <Button icon={<EditOutlined />} onClick={() => setOpened(true)}/>
      }
      <AddressDrawer
        action="update"
        address={address}
        setAddresses={setAddresses}
        opened={opened}
        setDrawerOpened={setOpened}
        indexValueSelected={indexValueSelected}
        setIndexValueSelected={setIndexValueSelected}
        index={index}
      />
    </>
  );
}

export default OpenEditAddressDrawer;
