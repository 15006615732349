/** @jsxImportSource @emotion/react */
import { Col, Collapse, Row, Typography, Space, Button } from 'antd';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StepContext } from '../../../Delivery/Create/CreateDeliveryForm';
import { NotificationCenterContext } from '../../../../context/NotificationCenterContext';
import useFetch from 'use-http';
import { AppContext } from '../../../../context/AppContext';
import SkeletonMobile from '../../../SkeletonLoaders/SkeletonMobile';
import { colors } from '../../../../globalVar/theme';


const { Text } = Typography;
const { Panel } = Collapse;

/**
 * UserListStep1Mobile
 * @param {boolean} customizeEmpty
 * @param {array} usersResult
 * @param {function} updateUserSearch function to open modal with search form
 * @param {object} formStep1
 * @param {boolean} loading
 * @return {JSX.Element}
 */
function UserListStep1Mobile({ customizeEmpty, updateUserSearch, usersResult, formStep1, loading }) {
  const { setShowCustomerForm, setCurrentStep, setStepsValidated } = useContext(StepContext);
  const [, dispatch] = useContext(AppContext);
  const { t } = useTranslation();
  const notification = useContext(NotificationCenterContext);
  const [collapseActiveKey, setCollapseActiveKey] = useState(0);
  const usersFetch = useFetch(process.env.REACT_APP_API_BO, {
    cachePolicy: 'no-cache',
  });


  const onUserSelect = async(user) => {
    await usersFetch.get(`/users/${user.id}/addresses`);
    if (usersFetch.response.status === 200) {
      dispatch({ type: 'SET_CUSTOMER_ADDRESSES', payload: usersFetch.response.data });
      setShowCustomerForm(true);
    }
    if (usersFetch.error) {
      notification.push('error', t('INTERNAL_ERROR'));
      setShowCustomerForm(false);
    }
    dispatch({ type: 'EDIT_DELIVERY_CUSTOMER', payload: {
      firstName: user.first_name,
      lastName: user.last_name,
      email: user.email,
      telephone: user.telephone,
      userId: user.id,
      isExisting: true,
    }});
    formStep1.setFieldsValue({
      firstName: user.first_name,
      lastName: user.last_name,
      email: user.email,
      telephone: user.telephone,
      isExisting: true,
    });
    setShowCustomerForm(true);
    setCurrentStep(0);
    setStepsValidated([false, false, false]);
  };

  return (
    <Col span={24}>
      <SkeletonMobile
        loading={loading}
        emptyState={usersResult.length === 0}
        emptyError={customizeEmpty}
        updateUserSearch={updateUserSearch}
      >
        <Collapse
          accordion
          activeKey={collapseActiveKey}
          onChange={(key)=>setCollapseActiveKey(key)}
        >
          {usersResult.map((user, index) =>
            <Panel
              key={index}
              header={`${user.first_name} ${user.last_name}`}
            >
              <Row gutter={[0, 10]}>
                <Col span={8}>
                  <Text strong css={{ color: `${colors.primary}`, fontSize: 12 }}>
                    {t('PHONE')}
                  </Text>
                </Col>
                <Col span={16}>
                  <Text css={{ fontSize: 12 }}>{user.telephone}</Text>
                </Col>
                <Col span={8}>
                  <Text strong css={{ color: `${colors.primary}`, fontSize: 12 }}>
                    {t('EMAIL')}
                  </Text>
                </Col>
                <Col span={16}>
                  <Text css={{ fontSize: 12 }}>{user.email}</Text>
                </Col>
                <Col span={24}>
                  <Row justify='center'>
                    <Space direction='vertical'>
                      <Button
                        type='primary'
                        onClick={
                          ()=> {
                            onUserSelect(user);
                          }
                        }> {t('SELECT')}</Button>
                    </Space>
                  </Row>
                </Col>
              </Row>
            </Panel>,
          )}
        </Collapse>
        <Row
          css={{ marginTop: '10px !important', textAlign: 'center' }}
        >
          <Text>
            {t('NO_USER_MATCH')}
            <br />
            {t('MODIFY_SEARCH_OR_CREATE_NEW_RECIPIENT')}
          </Text>
        </Row>
      </SkeletonMobile>
    </Col>
  );
}

export default UserListStep1Mobile;
