/** @jsxImportSource @emotion/react */
import { ConfigProvider, Card, Space, Typography, Image, Tag } from 'antd';
import KYCStatus from './KYCStatus/KYCStatus';
import { useTranslation } from 'react-i18next';
import { customizeRenderEmpty } from '../../../services/customizeRenderEmpty';
import { useRouter } from '../../../hooks/useRouter';
import Paragraph from 'antd/es/typography/Paragraph';
import { breakpoints } from '../../../globalVar/theme';
import InterfaceIllustrationCircle from '../../../assets/interface-illustration-circle.webp';

const { Text } = Typography;

/**
 * ShopperAcount
 * @param {object} kycView
 * @param {object} kycListDatabase
 * @return {JSX}
 */
function ShopperAcount({ kycView, kycListDatabase }) {
  const { t } = useTranslation();
  const router = useRouter();

  // TODO : remplacer par un state lorsque on intègrera une requête API pour afficher des données
  const customizeEmpty = false;

  return (
    <ConfigProvider renderEmpty={() => customizeRenderEmpty({
      customizeEmpty: customizeEmpty,
      dataString: 'NO_DATA',
    })}>
      <Card
        title={
          <Space css={{
            display: 'block',
            padding: '20px 0',
            [breakpoints.md]: {
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
            },
          }}>
            {t('SHOPPER_ACCOUNT')}
            <KYCStatus kycView={kycView} kycListDatabase={kycListDatabase} />
          </Space>
        }
      >
        <Space align='center' size='large' css={{
          display: 'block',
          [breakpoints.md]: {
            display: 'flex',
            width: '100%',
            textAlign: 'left',
          },
        }}>
          <Space
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Image
              src={InterfaceIllustrationCircle}
              alt="interface illustration"
              width={120}
              preview={false}
            />
          </Space>
          <Space
            direction='vertical'
            css={{
              marginTop: 10,
              [breakpoints.md]: {
                marginTop: 0,
              },
            }}
          >
            <Tag color="cyan">Nouveau !</Tag>
            <Text>{t('ACCESS_SHOPPER_OLDBO_TITLE')}</Text>
            <Text css={{ fontSize: 12 }}>
              {t('ACCESS_SHOPPER_DESCRIPTION_OLDBO')}
            </Text>
            <Text css={{ fontSize: 12 }} type='secondary'>
              {t('ACCESS_SHOPPER_LINK_DESCRIPTION_OLDBO')}
            </Text>
            <Paragraph
              copyable={`${process.env.REACT_APP_OLD_BO_URL}/users/${router.query.id}`}
              css={{
                color: '#1B3F92',
                fontSize: 12,
              }}
            >
              {`${process.env.REACT_APP_OLD_BO_URL}/users/${router.query.id}`}
            </Paragraph>
          </Space>
        </Space>
      </Card>
    </ConfigProvider>
  );
}

export default ShopperAcount;
