import React from 'react';
import { useTranslation } from 'react-i18next';
import useCustomHtml from '../../../../../hooks/useCustomHtml';

/**
 * ShowReferenceHistoryNode
 * @param {Object} props
 * @param {Object} props.deliveryMan
 * @param {number} props.deliveryMan.id
 * @param {string} props.deliveryMan.firstName
 * @param {string} props.deliveryMan.lastName
 * @return {JSX.Element}
 */
function ShowReferenceHistoryNode({ deliveryMan }) {
  const { t } = useTranslation();

  return (
    <div>
      {useCustomHtml({
        keyValues: [
          { key: '[DELIVERY_MAN_NAME]', value: `${deliveryMan.firstName} ${deliveryMan.lastName}`, url: `/users/${deliveryMan.id}` },
        ],
        inputString: t('REFERENCE_DISPLAY'),
      })}
    </div>
  );
}

export default ShowReferenceHistoryNode;
