import { NO_EMOJI_REGEX } from '@shopopop/backoffice-frontend-utils';
import { AutoComplete, Button, Card, Col, Form, Input, InputNumber, Radio, Row } from 'antd';
import { FormInstance } from 'antd/es/form';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useDebounce from '../../../hooks/src/hooks/useDebounce';
import { NO_SPECIAL_CHARACTERS_REGEX } from '../../../utils/src/regex/regex';
import { AddressOption } from '../interfaces/RecipientAddressInformationProps';
import StructuredInfoCard from './ContentCard/StructuredInfoCard';
import HighlightText from './HighlightText';

interface RecipientDeliveryAddressProps {
  form: FormInstance;
  onAddressSearch: (query: string, language?: string) => Promise<AddressOption[]>;
  onAddressSelect?: ({ address, placeId }: AddressOption) => void;
  onAddressClear?: () => void;
  submitButton: {
    disabled: boolean;
    visible: boolean;
  };
  showOriginalAddress: string | null;
  id?: number | null;
  originalAddress: string | null;
}

const { TextArea } = Input;

function RecipientDeliveryAddress(props: Readonly<RecipientDeliveryAddressProps>): ReactNode {
  const { form, originalAddress, onAddressSearch, onAddressSelect, onAddressClear, submitButton = { disabled: false, visible: false }, showOriginalAddress = null } = props;

  const { t, i18n } = useTranslation();

  const [options, setOptions] = useState<AddressOption[]>([]);
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(!!originalAddress);
  const [searchTerm, setSearchTerm] = useState('');
  const [previousSelectedAddress, setPreviousSelectedAddress] = useState(originalAddress);
  const [validateStatus, setValidateStatus] = useState<'error' | 'success' | ''>('');

  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  useEffect(() => {
    // When an original address is set, the field is validated
    setValidateStatus(originalAddress ? 'success' : '');
  }, [form, originalAddress]);

  useEffect(() => {
    if (debouncedSearchTerm.length >= 3 && debouncedSearchTerm.length < 100) {
      onAddressSearch(debouncedSearchTerm, i18n?.language).then((suggestions: AddressOption[]) => {
        setOptions(suggestions.slice(0, 5));
      });
    } else if (debouncedSearchTerm.length === 0) {
      setOptions([]);
    }
    if (originalAddress) setShowAdditionalInfo(true);
  }, [debouncedSearchTerm, onAddressSearch, originalAddress]);

  const handleSelect = (value: string) => {
    const { address, placeId } = JSON.parse(value);
    form.setFieldsValue({ address });
    setSearchTerm('');
    setOptions([]);
    setShowAdditionalInfo(true);
    if (previousSelectedAddress !== value) {
      setPreviousSelectedAddress(value);
      onAddressSelect?.({ address, placeId });
      setValidateStatus('success');
      form.setFieldsValue({
        elevator: 'dontknow',
        floor: null,
        comment: '',
      });
    }
  };

  return (
    <Card title={t('RCP_ADDRESS_TITLE')}>
      <Row gutter={16}>
        <Col xs={24}>
          <Form.Item
            name="address"
            label={t('RCP_ADDRESS')}
            hasFeedback
            validateStatus={validateStatus}
            rules={[
              { required: true, message: t('RCP_ADDRESS_REQUIRED') },
              {
                pattern: NO_EMOJI_REGEX,
                message: t('INVALID_VALUE'),
              },
              () => ({
                validator() {
                  if (searchTerm.length >= 3) {
                    setValidateStatus('error');
                    return Promise.reject(new Error(t('ERROR_OCCURED_ON_ADDRESS_CREATION')));
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
          >
            <AutoComplete
              data-testid="address-search"
              options={options.map((option) => ({
                value: JSON.stringify({
                  address: option.address,
                  placeId: option.placeId,
                }),
                label: (
                  <div>
                    <HighlightText text={option.address} highlightTag={searchTerm} />
                  </div>
                ),
              }))}
              onSearch={(value) => {
                setSearchTerm(value);
              }}
              onSelect={handleSelect}
              onClear={() => onAddressClear?.()}
            />
          </Form.Item>
        </Col>
        {showOriginalAddress && (
          <Col xs={24}>
            <Form.Item>
              <StructuredInfoCard label={t('RCP_ORIGINAL_ADDRESS')} value={showOriginalAddress} direction="vertical" />
            </Form.Item>
          </Col>
        )}
        {showAdditionalInfo && (
          <>
            <Col xs={24} xl={12}>
              <Form.Item name="elevator" label={t('LIFT')} rules={[{ required: true, message: t('LIFT_REQUIRED') }]}>
                <Radio.Group
                  data-testid="elevator-radio"
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <Radio.Button value={'yes'} style={{ flex: 1, textAlign: 'center' }}>
                    {t('LIFT_YES')}
                  </Radio.Button>
                  <Radio.Button value={'no'} style={{ flex: 1, textAlign: 'center' }}>
                    {t('LIFT_NO')}
                  </Radio.Button>
                  <Radio.Button
                    value={'dontknow'}
                    style={{
                      flex: 1,
                      textAlign: 'center',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {t('LIFT_UNKNOWN')}
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24} xl={12}>
              <Form.Item
                name="floor"
                label={t('FLOOR')}
                rules={[
                  {
                    pattern: /^(?=.{0,40}$).*/g,
                    message: t('FLOOR_LIMIT_LENGHT'),
                  },
                  {
                    type: 'number',
                    min: 0,
                    max: 40,
                    message: t('FLOOR_LIMIT_LENGHT'),
                  },
                  {
                    pattern: NO_SPECIAL_CHARACTERS_REGEX,
                    message: t('INVALID_VALUE'),
                  },
                  {
                    pattern: NO_EMOJI_REGEX,
                    message: t('INVALID_VALUE'),
                  },
                ]}
              >
                <InputNumber data-testid="floor-input" min={0} max={40} />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name="comment"
                label={<span>{t('ADDRESS_ADDITIONAL_INFO')}</span>}
                rules={[
                  {
                    pattern: NO_EMOJI_REGEX,
                    message: t('INVALID_VALUE'),
                  },
                ]}
              >
                <TextArea showCount maxLength={255} data-testid="comment-input" style={{ height: 52 }} />
              </Form.Item>
            </Col>
          </>
        )}
      </Row>
      {submitButton.visible && (
        <Button htmlType="submit" type="primary" disabled={submitButton.disabled}>
          {t('SAVE')}
        </Button>
      )}
    </Card>
  );
}

export default RecipientDeliveryAddress;
