import { useState, useEffect, useContext } from 'react';
import { Layout, theme } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import BreadcrumbWrapper from '../BreadcrumbWrapper/BreadcrumbWrapper';
import BreadcrumbContext from '../../context/BreadcrumbContext';
import DrawersContext from '../../context/DrawersContext';
import ModalContext from '../../context/ModalContext';
import { SideMenu, GlobalStyle, StyledLayout, FixedHeader, BottomFooter, SideMenuLogo } from '@shopopop/backoffice-ui';
import useScreenSize from '../../hooks/useScreenSize';
import { adminMenuItems } from '../../globalVar/menuItems';
import { AppContext } from '../../context/AppContext';
import { css } from '@emotion/react';
import AuthService from '../../services/AuthService';

/**
 * LayoutWrapper
 * @param {boolean} showMenu
 * @param {JSX.Element} children
 * @return {JSX.Element}
 */
function LayoutWrapper({ showMenu, children }) {
  const { i18n } = useTranslation();
  const { token } = theme.useToken();
  const { orientation, mobileMode, width } = useScreenSize();
  const { doLogout } = AuthService;
  // State for Breadcrumb
  const [infoBreadcrumb, setInfoBreadcrumb] = useState({});
  // State for Modal
  const [openModalShopper, setOpenModalShopper] = useState(false);
  const [openModalStatus, setOpenModalStatus] = useState(false);
  const [openModalSearch, setOpenModalSearch] = useState(false);
  // State for Drawer
  const [showDrawer, setShowDrawer] = useState(false);
  const [childVisible, setChildVisible] = useState(false);
  const [currentDrawer, setCurrentDrawer] = useState(null);
  // State for Sider, default value is mobileMode
  // to hide Sider on mobile devices by default
  // and show it on desktop devices by default
  const [siderCollapsed, setSiderCollapsed] = useState(mobileMode);
  const [store] = useContext(AppContext);

  // Set the language of dayjs with connected user language choice
  useEffect(() => {
    dayjs.locale(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    setSiderCollapsed(width <= token.screenLGMax);
  }, [orientation, width]);

  return showMenu ? (
    <>
      <GlobalStyle
        specificStyle={css`
          .ant-segmented {
            border: 1px solid ${token.colorBorderSecondary} !important;
          }
        `}
      />
      <Layout hasSider style={{ minHeight: '100vh' }}>
        <SideMenu
          menuItems={adminMenuItems.map((route) => route.key === 'MENU_DELIVERIES' ? { ...route, path: `/deliveries${store.deliveryList.ongoing ?? '?label=ongoing'}` } : route)}
          logoComponent={
            <SideMenuLogo
              logo={process.env.PUBLIC_URL + '/assets/logo-shopopop-white.svg'}
              logoSM={process.env.PUBLIC_URL + '/assets/mini-logo-white.svg'}
              small={siderCollapsed}
            />
          }
          siderCollapsed={siderCollapsed}
          setSiderCollapsed={setSiderCollapsed}
          onLogout={doLogout}
        />
        <StyledLayout
          onClick={() => {
            if (mobileMode) {
              setSiderCollapsed(true);
            }
          }}
          mobilemode={mobileMode?.toString()}
          sidercollapsed={siderCollapsed?.toString()}
          backgroundcolor={token.colorBgSpotlight}
        >
          <BreadcrumbContext.Provider value={{ infoBreadcrumb, setInfoBreadcrumb }}>
            <FixedHeader mobileMode={mobileMode}>
              <BreadcrumbWrapper />
            </FixedHeader>
            <DrawersContext.Provider value={{
              visible: showDrawer,
              setVisible: setShowDrawer,
              currentDrawer,
              setCurrentDrawer,
              childVisible,
              setChildVisible,
            }}>
              <ModalContext.Provider value={{
                openModalShopper,
                setOpenModalShopper,
                openModalStatus,
                setOpenModalStatus,
                openModalSearch,
                setOpenModalSearch,
              }}>
                <>
                  {children}
                  {showDrawer && currentDrawer}
                </>
              </ModalContext.Provider>
            </DrawersContext.Provider>
          </BreadcrumbContext.Provider>
          <BottomFooter
            textColor={token.colorTextSecondary}
            version={process.env.REACT_APP_VERSION}
          />
        </StyledLayout>
      </Layout>
    </>
  ) : (
    children
  );
}

export default LayoutWrapper;
