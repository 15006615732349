import { apiEndPointsOptions } from '../../../apiEndPointsOptions';

export const writeInternComment = async(userId, comment) => {
  const apiUrl = `${process.env.REACT_APP_API_BO}/users/${userId}/reviews`;
  const method = 'POST';

  try {
    const response = await fetch(apiUrl, await apiEndPointsOptions(method, comment));
    const result = await response.json();

    if (response.status === 200) {
      return {
        type: 'success',
        data: result,
        message: 'INTERN_COMMENT_SUCCESS',
      };
    } else {
      return {
        type: 'error',
        message: 'INTERN_COMMENT_ERROR',
      };
    }
  } catch (error) {
    throw new Error(`Error in writeInternComment service: ${error.message}`);
  }
};
