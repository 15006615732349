/** @jsxImportSource @emotion/react */
import { Skeleton, Space } from 'antd';

/**
 * @name SkeletonNodes
 * @param {Object} props
 * @param {number} props.count
 * @param {boolean} props.noElementsAtLeft
 * @return {JSX.Element}
 */
function SkeletonNodes({ count, noElementsAtLeft }) {
  return (
    <Space
      direction="vertical"
      css={{ width: '100%' }}
    >
      {Array.from({ length: count }, (_, i) => (
        <SkeletonNode key={i} noElementsAtLeft={noElementsAtLeft} />
      ))}
    </Space>
  );
}

/**
 * @name SkeletonNode
 * @return {JSX.Element}
 */
function SkeletonNode({ noElementsAtLeft }) {
  return (
    <Space
      align="start"
      css={{
        'width': '100%',
        'marginBottom': '20px',
        '> .ant-space-item:last-child': {
          'width': '80%',
        },
        '> .ant-space-item:first-child': {
          'display': noElementsAtLeft ? 'none' : 'block',
        },
        '@media (max-width: 768px)': {
          '> .ant-space-item:first-child': {
            'display': 'none',
          },
        },
        '@media (min-width: 768px)': {
          '> .ant-space-item:last-child': {
            'width': '100%',
          },
        },
      }}
    >
      <Skeleton.Input active={true} size={16} />
      <Skeleton.Avatar active={true} size={'small'} css={{ transform: 'translateY(-5px)' }} />
      <Skeleton
        active={true}
        size={'small'}
        css={{
          width: '80%',
          minWidth: '80%',
        }}
        paragraph={{
          rows: 2,
        }}
      />
    </Space>
  );
}

export default SkeletonNodes;
