/** @jsxImportSource @emotion/react */
import { Flex, Space, Typography } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import { toLocaleStringHourandMinutes } from '../../../utils/toLocaleStringHourandMinutes';
import { sizes } from '../../../globalVar/theme';
import { useWindowSize } from '../../../hooks/useWindowSize';

const { Text } = Typography;

/**
 * Represents a time slot in the delivery list.
 *
 * @param {Object} shiftedWithdrawal - The time slot object.
 * @param {Date} shiftedWithdrawal.start - The start time of the time slot.
 * @param {Date} shiftedWithdrawal.end - The end time of the time slot.
 *
 * @return {JSX.Element} - The rendered React element representing the time slot.
 */
function DeliveryWithdrawalHour({ shiftedWithdrawal }) {
  const windowSize = useWindowSize();
  const commonContent = (
    <>
      <div>{toLocaleStringHourandMinutes(shiftedWithdrawal.start)} - {toLocaleStringHourandMinutes(shiftedWithdrawal.end)}</div>
      {shiftedWithdrawal.nb > 0 && <Text type='danger' css={{ fontSize: 12 }}>{shiftedWithdrawal.nb} <ClockCircleOutlined /> </Text>}
    </>
  );

  return (
    windowSize.width > sizes.sm ?
      (
        <Space align='center'>
          {commonContent}
        </Space>
      ) : (
        <Flex justify='space-between'>
          {commonContent}
        </Flex>
      )
  );
}

export default DeliveryWithdrawalHour;
