/**
 * Fichier sans doute provisoire en attendant l'arrivé du theme-admin.json de Ant-Design.
 * Il permet de définir les couleurs du thème de l'application, et d'autres choses comme les tailles de police etc.
 *
 */
export const colors = {
  // Couleurs
  primary: '#1b3f92',
  orange: '#ef7c6a',
  orangeAlert: '#FAAD14',
  lightBlue: '#e2e7f3',
  greyLight: '#EFEFF2',
  blueMiddle: '#8092BB',
  terracotta: '#c2544c',
  lightTerracotta: '#ffedea',
  error: '#f5222d',
  warning: '#faad14',
  avatar: ['#E6E6FA', '#FFF0F5', '#FFFACD', '#ADD8E6', '#F08080', '#E0FFFF', '#90EE90', '#D3D3D3', '#F0E68C', '#87CEFA', '#FF1493'],
  deliveryStatus: ['#EB2F96', '#0889C0', '#FF9900', '#5556FE', '#0F4BD4', '#16AC5D', '#DF4C4C', '#16AC5D', '#722ED1'],
  electricBlue: '#0066FF',
  electricBlueLight: '#EAF2FF',
};

export const gutters = {
  base: 20,
};

export const iconInput = {
  border: 'none',
  borderRadius: 4,
  backgroundColor: 'transparent !important',
  boxShadow: 'none',
  height: '30px',
};
export const sizes = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
};
export const breakpoints = {
  // Tailles d'écran
  xs: `@media (min-width: ${sizes.xs}px)`,
  sm: `@media (min-width: ${sizes.sm}px)`,
  md: `@media (min-width: ${sizes.md}px)`,
  lg: `@media (min-width: ${sizes.lg}px)`,
  xl: `@media (min-width: ${sizes.xl}px)`,
  xxl: `@media (min-width: ${sizes.xxl}px)`,
};
