/** @jsxImportSource @emotion/react */
import React from 'react';
import { breakpoints, colors } from '../../globalVar/theme';

/**
* @deprecated
* @function LegacyRadioCard
* @param {Object} props - The properties passed to the component
* @param {ReactNode} props.children - The content to be rendered within the RadioCard component
* @return {JSX.Element} - A React component that renders a radio card with the provided children
*/
function LegacyRadioCard({ children }) {
  const radioCard = {
    'display': 'flex',
    'alignItems': 'center',
    'padding': '15px 20px',
    'border': `1px solid ${colors.primary}`,
    'borderRadius': 4,
    '.ant-radio-inner': {
      borderColor: colors.primary,
    },
    '> span:last-child': {
      '> span:first-of-type': {
        marginRight: 10,
        fontWeight: 500,
      },
    },
  };

  const radioCardChecked = {
    ...radioCard,
    backgroundColor: colors.lightBlue,
  };

  const radioCardError = {
    ...radioCard,
    'border': `1px solid ${colors.error}`,
    'color': colors.error,
    '.ant-radio-inner': {
      borderColor: colors.error,
    },
  };

  const wrapper = {
    '.ant-radio-group': {
      width: '100%',
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridTemplateRows: '1fr 1fr 1fr 1fr',
      gap: 20,
    },
    [breakpoints.md]: {
      '.ant-radio-group': {
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: '1fr 1fr',
      },
    },
    '.ant-radio-wrapper': radioCard,
    '.ant-radio-wrapper-checked': radioCardChecked,
    '.ant-form-item-has-error': {
      '.ant-radio-wrapper': radioCardError,
    },
  };

  return (
    <div css={wrapper}>
      {children}
    </div>
  );
}

export default LegacyRadioCard;
