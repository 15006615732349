export enum DeliveryStatusEnum {
  Available = 'available', // 1
  Reserved = 'reserved', // 2
  ToPickUpPoint = 'toPickUpPoint', // 3
  AtPickUpPoint = 'atPickUpPoint', // 4
  ToRecipient = 'toRecipient', // 5
  DeliveredAndFinished = 'deliveredAndFinished', // 6 & 8
}

export enum DeliveryListTabNames {
  Schedule = 'schedule',
  InProgress = 'inProgress',
  Interrupted = 'interrupted',
  DeliveredAndFinished = 'deliveredAndFinished',
}
