import frFR from 'antd/lib/locale/fr_FR';
import enUS from 'antd/lib/locale/en_US';
import esES from 'antd/lib/locale/es_ES';
import nlBE from 'antd/lib/locale/nl_BE';
import nlNL from 'antd/lib/locale/nl_NL';
import itIT from 'antd/lib/locale/it_IT';
import frBE from 'antd/lib/locale/fr_BE';
import deDE from 'antd/lib/locale/de_DE';

const mapI18N = [{
  file: frFR,
  keys: ['fr-FR', 'fr'],
  name: 'Français',
}, {
  file: frBE,
  keys: ['fr-BE'],
  name: 'Français (BE et LUX)',
}, {
  file: enUS,
  keys: ['en-GB', 'en', 'en-US'],
  name: 'English',
}, {
  file: esES,
  keys: ['es', 'es-ES'],
  name: 'Español',
}, {
  file: nlNL, nlBE,
  keys: ['nl', 'nl-NL', 'nl-BE'],
  name: 'Dutch (BE and NL)',
}, {
  file: itIT,
  keys: ['it', 'it-IT'],
  name: 'Italiano',
}, {
  file: deDE,
  keys: ['de', 'de-DE'],
  name: 'Deutsch',
}];

export default mapI18N;
