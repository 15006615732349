import React from 'react';
import { useTranslation } from 'react-i18next';
import useCustomHtml from '../../../../../hooks/useCustomHtml';

/**
 * CanceledHistoryNode
 * @param {Object} props
 * @param {Object} props.deliveryMan
 * @param {number} props.deliveryMan.id
 * @param {string} props.deliveryMan.firstName
 * @param {string} props.deliveryMan.lastName
 * @param {Object} props.user
 * @param {string} props.user.firstName
 * @param {string} props.user.lastName
 * @return {JSX.Element}
 */
function CanceledHistoryNode({ deliveryMan, user }) {
  const { t } = useTranslation();

  const deliveryManDeletedByShopopop = useCustomHtml({
    keyValues: [
      { key: '[DELIVERY_MAN_NAME]', value: `${deliveryMan.firstName} ${deliveryMan.lastName}`, url: `/users/${deliveryMan.id}` },
      { key: '[SHOPOPOP_NAME]', value: `${user?.firstName} ${user?.lastName}`, bold: true },
    ],
    inputString: t('DELIVERY_MAN_DELETED_BY_SHOPOPOP'),
  });

  const deliveryCancellationByDeliveryMan = useCustomHtml({
    keyValues: [
      { key: '[DELIVERY_MAN_NAME]', value: `${deliveryMan.firstName} ${deliveryMan.lastName}`, url: `/users/${deliveryMan.id}` },
    ],
    inputString: t('DELIVERY_CANCELLATION_BY_DELIVERY_MAN'),
  });

  return user ? (
    <div>{deliveryManDeletedByShopopop}</div>
  ) : (
    <div>{deliveryCancellationByDeliveryMan}</div>
  );
}

export default CanceledHistoryNode;
