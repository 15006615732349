import React, { useContext } from 'react';
import { Button, Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import BigEmpty from '../../assets/shopopopEmpty.svg';
import { AppContext } from '../../context/AppContext';

export const EmptyNobody = ({ updateUserSearch }) => {
  const [store] = useContext(AppContext);
  const { t } = useTranslation();
  const isUserSearchStep1 = store.delivery.abTest !== null;

  return (
    <Empty
      image={BigEmpty}
      description={
        <>
          <p>{t('NO_RESULT_SEARCH')}</p>
          {!isUserSearchStep1 && <Button type='primary' onClick={updateUserSearch}>{t('UPDATE_SEARCH')}</Button>}
        </>
      }
    />
  );
};
