import { createContext } from 'react';

const ModalContext = createContext({
  openModalShopper: false,
  openModalStatus: false,
  setOpenModalShopper: () => {},
  setOpenModalStatus: () => {},
  openModalSearch: false,
  setOpenModalSearch: () => {},
});

export default ModalContext;
