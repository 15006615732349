import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * DeliveryDateSelectInput method is responsible for rendering a select input for selecting delivery dates.
 *
 * @param {Object} tableParams - The table parameters object.
 * @param {Function} handleTableChanges - The callback function for handling table changes.
 * @return {JSX.Element} - The rendered select input.
 */
function DeliveryDateSelectInput({ tableParams, handleTableChanges, style }) {
  const { t } = useTranslation();

  return (
    <Select
      id='deliveries-select-date-filter'
      onChange={(value) => handleTableChanges({
        ...tableParams,
        filters: {
          ...tableParams.filters,
          timeSlot: value === 'all' ? null : [value],
        },
      })}
      placeholder={t('DELIVERY_FILTER_DATE')}
      defaultValue={tableParams.filters?.timeSlot || 'all'}
      options={[
        {
          label: t('TODAY'),
          value: 'today',
        },
        {
          value: 'tomorrow',
          label: t('TOMORROW'),
        },
        {
          value: 'afterTomorrow',
          label: t('AFTER_TOMORROW'),
        },
        {
          value: 'all',
          label: t('ALL_DATE'),
        },
      ]}
      style={style}
    />
  );
}

export default DeliveryDateSelectInput;
