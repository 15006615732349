/** @jsxImportSource @emotion/react */
import { Select, Space, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import countries from '../../../i18n/countries.json';
import { countryAlphaOrder } from '../../../utils/countryAlphaOrder';

const { Text } = Typography;

/**
 * CountriesSelector
 * @param {object} inputStyle - css
 * @return {string}
 */
function CountriesSelector({ inputStyle }) {
  const { t } = useTranslation();
  const allCountries = countries.map((country)=> country.alpha2.toUpperCase());
  const countriesListAlpha = [{ name: t('ALL_COUNTRIES'), value: 'all' }, ...countryAlphaOrder(countries)];
  const [selectedCountry, setSelectedCountry] = useState([]);

  useEffect(() => {
    if (localStorage.getItem('CountriesFilter')) {
      const storedCountries = localStorage.getItem('CountriesFilter').split(',');
      if (storedCountries.toString() === allCountries.toString()) {
        setSelectedCountry([countriesListAlpha[0].value]);
      } else {
        setSelectedCountry(storedCountries);
      }
    } else {
      setSelectedCountry([countriesListAlpha[0].value]);
    }
  }, []);

  const handleChangeCountry = (countries) => {
    if (countries.length === 0) {
      localStorage.setItem('CountriesFilter', allCountries);
      setSelectedCountry(countriesListAlpha[0].value);
    } else {
      countries.map((country, index) => {
        if (country === 'all' && index !== 0) {
          localStorage.setItem('CountriesFilter', allCountries);
          return setSelectedCountry(countriesListAlpha[0].value);
        } else {
          const countriesfilter = countries.filter((country) => country !== 'all');
          localStorage.setItem('CountriesFilter', countriesfilter);
          return setSelectedCountry(countriesfilter);
        }
      });
    }
  };

  return (
    <Space direction='vertical'>
      <Space.Compact direction='vertical' >
        <Text strong>{t('COUNTRY_FILTER')}</Text>
        <Text type='secondary'>{t('COUNTRY_FILTER_CHOICE')}</Text>
      </Space.Compact>
      <Select
        mode='multiple'
        maxTagCount='responsive'
        value={selectedCountry}
        onChange={handleChangeCountry}
        options={countriesListAlpha.map((country)=> {
          return ({
            label: country.name,
            value: country.value || country.alpha2,
          });
        })}
        css={inputStyle}
      />
    </Space>
  );
}

export default CountriesSelector;
