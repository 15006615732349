import { apiEndPointsOptions } from '../../../apiEndPointsOptions';

export const readDeactivatedUserHistory = async(userId) => {
  const apiUrl = `${process.env.REACT_APP_API_BO}/bans/deactivated_history/${userId}`;
  const method = 'GET';

  try {
    const response = await fetch(apiUrl, await apiEndPointsOptions(method));
    const result = await response.json();

    if (response.status === 200) {
      return {
        type: 'success',
        data: result,
      };
    }
    return {
      type: 'error',
      message: `Error during the request: ${result.message}`,
    };
  } catch (error) {
    throw new Error(
      `Error in readDeactivatedUserHistory service: ${error.message}`
    );
  }
};
