import { Tag } from 'antd';
import { findStatusDeliveries } from '../../../utils/findStatusDeliveries';
import { useTranslation } from 'react-i18next';

/**
 * This method returns a JSX.Element representing the delivery status of an item.
 *
 * @param {number} status - The current delivery status of the item.
 * @return {JSX.Element} - A JSX.Element representing the delivery status.
 */
function DeliveryStatusListItem({ status }) {
  const { t } = useTranslation();
  const statusDetails = findStatusDeliveries(status);

  return (
    <Tag style={statusDetails.style}>
      {t(statusDetails.message)}
    </Tag>
  );
}

export default DeliveryStatusListItem;
