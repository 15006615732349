/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Skeleton, Card, Space, Typography } from 'antd';
import UserAvatar from './UserAvatar';
import { useParams } from 'react-router-dom';
import { toLocaleString } from '../../../utils/toLocaleString';
import { toLocaleStringHourandMinutes } from '../../../utils/toLocaleStringHourandMinutes';
import { colors } from '../../../globalVar/theme';
import dayjs from 'dayjs';
import useCustomHtml from '../../../hooks/useCustomHtml';
import InfoMessage from '../../Alert/InfoMessage';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import InputNameUser from './InputNameUser';
import InputMailUser from './InputMailUser';
import InputPhoneUser from './InputPhoneUser';

const { Text } = Typography;

/**
 * UserGeneralInformations
 * @param {object} user
 * @param {function} setUser
 * @param {boolean} isBanUser
 * @param {object} userDeactivationReasons
 * @param {object} idCardShopopop
 * @param {[object]} kycListDatabase
 * @param {boolean} loading
 * @return {JSX.Element}
 */
function UserGeneralInformations({ user, setUser, isBanUser, userDeactivationReasons, kycListDatabase, loading }) {
  const { id } = useParams();
  const { t } = useTranslation();
  const locale = localStorage.getItem('i18nextLng') || navigator.language;
  const [dateIso, setDateIso] = useState('');
  const [inscriptionDate, setInscriptionDate] = useState(null);
  const [lastLogin, setLastLogin] = useState(new Date());

  useDocumentTitle(user ? `${user?.first_name} ${user?.last_name}` : '...');

  // format date
  useEffect(() => {
    if (dateIso) {
      const dateIsoFormat = new Date(dateIso);
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      setInscriptionDate(new Intl.DateTimeFormat(locale, options).format(dateIsoFormat));
    }
    setDateIso(user?.registration_date);
    setLastLogin(user?.last_login);
  }, [dateIso, userDeactivationReasons, user]);

  // variable pour la clé weblate concernant le bannissement de l'utilisateur
  const dateDeactivated = userDeactivationReasons?.banned_date ? dayjs(userDeactivationReasons.banned_date).format('LL') : null;
  const reasonDeactivated = userDeactivationReasons?.banned_reason ? userDeactivationReasons.banned_reason.split(' |')[0].toUpperCase() : '';
  const driveName = userDeactivationReasons?.drive_name ? userDeactivationReasons.drive_name : null;

  const alertMessageText = useCustomHtml({
    keyValues: [
      { key: '[BANNED_DATE]', value: dateDeactivated ? ` ${t('THE')} ${dateDeactivated}` : '', bold: true },
      { key: '[BANNED_REASON]', value: t(reasonDeactivated), bold: true },
      { key: '[DRIVE_ID]', value: driveName ? `${t('SEARCH_DRIVE')} : ${driveName}.` : `${t('SEARCH_DRIVE')} : ${t('ALL_PICKUP_POINTS')}`, bold: false },
    ],
    inputString: t('USER_BANNED'),
  });

  return (
    <Card bodyStyle={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      overflowY: 'auto',
    }}>
      {loading && <Skeleton.Avatar active='true' size={95} shape={'circle'} loading={false} />}
      <Skeleton
        paragraph={{ rows: 4 }}
        active='true'
        loading={loading}
      >
        <div css={{ flexGrow: 2 }}>
          {user?.deleted_date && <InfoMessage alertMessageText={`${t('DELIVERER_ACCOUNT_DELETED')} ${dayjs(user.deleted_date).format('LL')}.`} margin={34} type='info'/>}
          {!user?.deleted_date && isBanUser && <InfoMessage alertMessageText={alertMessageText} margin={34} type='warning'/>}
          <Space direction='vertical' align='center' css={{ width: '100%' }} size={0}>
            {user && <UserAvatar avatarFirstName={user?.first_name} avatarLastName={user?.las_name} userId={id}/>}
            <InputNameUser
              user={user}
              setUser={setUser}
              kycListDatabase={kycListDatabase}
            />
            <Text css={{ fontSize: '12px' }}type='secondary'>{t('REGISTERED_SINCE')} {inscriptionDate}</Text>
          </Space>
          <InputMailUser user={user} setUser={setUser}/>
          <InputPhoneUser user={user} setUser={setUser}/>
        </div>

        <Space
          direction='vertical'
          align='center'
          css={{
            paddingTop: 10,
            borderTop: `0.5px solid ${colors.lightBlue}`,
          }}>
          <Text type='secondary'
            css={{ textAlign: 'center',
            }}>
            <div>ID: {id}</div>
            {
              lastLogin ?
                <div>
                  {t('LAST_CONNECTION')} {toLocaleString({ date: lastLogin, fullDatetime: true })} - {toLocaleStringHourandMinutes(lastLogin)}
                </div> :
                <></>
            }
          </Text>
        </Space>
      </Skeleton>
    </Card>
  );
}

export default UserGeneralInformations;

