/**
 * @param {number} fromStatus of deliverie in meter
 * @param {number} toStatus of deliverie in meter
 * @return {string} Correct weblate key to use
 */
export function getStatusKey(fromStatus, toStatus) {
  if ((fromStatus >= 2 && fromStatus <= 5) && ((toStatus === 1 || toStatus >= 2) && toStatus <= 5)) {
    // If the status is changed to Disponible, the shopper is removed from the delivery
    if (toStatus === 1) {
      return `RESET_DELIVERY_FROM_STATUS_${fromStatus}`;
    } else {
      // If the status is changed to another status, the shopper is not removed from the delivery
      return `UPDATE_DELIVERY_FROM_STATUS_${fromStatus}_TO_${toStatus}`;
    }
    // If the status is changed to Livrée, the delivery is finished and the shopper receives his tip
  } else if ((fromStatus >= 2 && fromStatus <= 5) && toStatus === 6) {
    return `VALIDATE_DELIVERY_FROM_STATUS_${fromStatus}`;
  } else {
    return 'STATUS_NOT_VALID';
  }
}
