import dayjs from 'dayjs';

/**
 * @param {dayjs.Dayjs} startDate
 * @return {boolean}
 */
export function isWrongDeliveryStartHour(startDate) {
  return !startDate.isBetween(
    dayjs(startDate).hour(8).minute(0),
    dayjs(startDate).hour(20).minute(0),
    'minutes',
    '[]', // includes start and end date
  );
}

/**
 * @param {dayjs.Dayjs} endDate
 * @return {boolean}
 */
export function isWrongDeliveryEndHour(endDate) {
  return !endDate.isBetween(
    dayjs(endDate).hour(9).minute(0),
    dayjs(endDate).hour(21).minute(0),
    'minutes',
    '[]', // includes start and end date
  );
}

/**
 * @param {Object} params
 * @param {dayjs.Dayjs} params.startDate
 * @param {string} params.method
 * @param {Object} params.initialDates
 * @param {dayjs.Dayjs} params.initialDates.start
 * @return {boolean}
 */
export function isHourInThePast({ startDate, method, initialDates }) {
  const isStartHourBeforeLocaleHour = startDate.isBefore(dayjs());

  if (method === 'PUT') {
    const isStartDateChanged = !startDate.isSame(dayjs(initialDates.start));
    return isStartHourBeforeLocaleHour && isStartDateChanged;
  }

  return isStartHourBeforeLocaleHour;
}
