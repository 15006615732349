import { useEffect, useState } from 'react';
import { Alert, Space } from 'antd';
import incidentDetailsFormItems from './incidentDetailsFormItems.json';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import useCustomHtml from '../../hooks/useCustomHtml';

/**
 * @name DeliveryIncidentAlert
 * @description Le but est de former un message d'alerte à partir des données de l'incident issue de l'API et de le rendre dans une Alert And-Design
 * @param {string} incidentType
 * @param {string} incidentReasonValue
 * @param {string} incidentCreationDate
 * @param {string} oldIncidentReason
 * @return {JSX.Element}
 */
function DeliveryIncidentAlert({ incidentCreationDate, incidentType, incidentReasonValue, oldIncidentReason }) {
  const { t } = useTranslation();
  const [reasonLabel, setReasonLabel] = useState('');

  incidentCreationDate = dayjs(incidentCreationDate).format('DD/MM/YYYY');

  const archivedAlertMessageText = useCustomHtml({
    keyValues: [
      { key: '[INCIDENT_DATE]', value: incidentCreationDate ? ` ${t('THE')} ${incidentCreationDate}` : '', bold: true },
      { key: '[INCIDENT_REASON]', value: t(reasonLabel), bold: true },
    ],
    inputString: t('INCIDENT_DELIVERY_MESSAGE'),
  });

  useEffect(() => {
    if (oldIncidentReason) setReasonLabel(`${oldIncidentReason}_INCIDENT`);
    else {
    // Trouver le tableau correspondant à l'incidentType
      const incidentTypeDetails = incidentDetailsFormItems[incidentType];

      if (incidentTypeDetails) {
      // Trouver l'objet correspondant à l'incidentReasonValue
        const reasonDetails = incidentTypeDetails[0].options.find(
          (option) => option.value === incidentReasonValue
        );

        if (reasonDetails) {
        // Mettre à jour le message avec le libellé correspondant
          setReasonLabel(reasonDetails.label);
        }
      }
    }
  }, [incidentType, incidentReasonValue]);

  return (
    <Space style={{ marginBottom: 20 }}>
      <Alert
        description={archivedAlertMessageText}
        type='warning'
        showIcon
      />
    </Space>
  );
}

export default DeliveryIncidentAlert;
