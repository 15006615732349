import { useTranslation } from 'react-i18next';
import useCustomHtml from '../../../../../hooks/useCustomHtml';

/**
 * CanceledIncidentNode
 * @param {Object} props
 * @param {Object} props.user
 * @param {string} props.user.firstName
 * @param {string} props.user.lastName
 * @param {string} props.tips
 * @return {JSX.Element}
 */
function CanceledIncidentNode({ user, tips }) {
  const { t } = useTranslation();

  const incidentTipChange = useCustomHtml({
    keyValues: [
      { key: '[TIPS]', value: tips, nextContent: '€', bold: true },
    ],
    inputString: t('INCIDENT_CANCELED_TIP_CHANGE'),
  });

  return (<>
    {useCustomHtml({
      keyValues: [
        { key: '[SHOPOPOP_NAME]', value: `${user?.firstName} ${user?.lastName}`, bold: true },
      ],
      inputString: t('DELIVERY_INCIDENT_CANCELED_BY'),
    })}
    {tips && incidentTipChange}
  </>);
}

export default CanceledIncidentNode;
