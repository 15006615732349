/** @jsxImportSource @emotion/react */
import { Card } from 'antd';

/**
 * This method generates a tabbed card component.
 *
 * @param {JSX.Element} children - An array of tab items.
 * @return {JSX.Element} - The generated tabbed card component.
 */
function TabbedCard({ children }) {
  return (
    <Card
      css={{
        '.ant-card-body': {
          padding: 0,
        },
        '.ant-tabs-nav': {
          padding: '0 20px',
          marginBottom: '5px',
        },
        '@media (max-width: 768px)': {
          '.ant-tabs-content-holder': {
            padding: 20,
          },
        },
        '.ant-card': {
          boxShadow: 'unset',
        },
      }}
    >
      {children}
    </Card>
  );
}

export default TabbedCard;
