/** @jsxImportSource @emotion/react */
import { Flex, Space, Typography } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import { toLocaleString } from '../../../utils/toLocaleString';
import { sizes } from '../../../globalVar/theme';
import { useWindowSize } from '../../../hooks/useWindowSize';

const { Text } = Typography;

/**
 * Represents a day in the delivery list.
 *
 * @param {Object} shiftedDate - The date object.
 *
 * @return {JSX.Element} - The rendered React element representing the day of date.
 */
function DeliveryDate({ shiftedDate }) {
  const windowSize = useWindowSize();
  const commonContent = (
    <>
      <div>{toLocaleString({ date: shiftedDate.date, fullDatetime: true })}</div>
      {shiftedDate.nb > 0 && <Text type='danger' css={{ fontSize: 12 }}>{shiftedDate.nb} <CalendarOutlined /></Text>}
    </>
  );

  return (
    windowSize.width > sizes.sm ?
      (
        <Space align='center'>
          {commonContent}
        </Space>
      ) : (
        <Flex justify='space-between'>
          {commonContent}
        </Flex>
      )
  );
}

export default DeliveryDate;
