/** @jsxImportSource @emotion/react */
import { React, useState } from 'react';
import { Button, Modal, Typography, Space } from 'antd';
import { CopyOutlined, CheckOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { colors } from '../../globalVar/theme';
import TouchableView from '../DeviceDetect/TouchableView';
import UntouchableView from '../DeviceDetect/UntouchableView';


const { Paragraph, Text } = Typography;

/**
 * LinkOldBoModal
 * @param {JSX.Element} icon
 * @param {Object} textContent
 * @param {boolean} isDeliveriesList
 * @param {boolean} isIncident
 * @return {JSX.Element}
 */
function LinkOldBoModal({ icon, textContent, isDeliveriesList, isIncident }) {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [copyLinkClick, setCopyLinkClick] = useState(false);


  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setCopyLinkClick(false);
  };

  const copyLink = () => {
    setCopyLinkClick(true);
    navigator.clipboard.writeText(textContent.modalLink);
  };

  const buttonLink = () => {
    if (isIncident) {
      return (
        <Button
          danger
          type='primary'
          icon={icon}
          id='button-incident'
          onClick={showModal}
        >
          {t('DECLARE_INCIDENT')}
        </Button>
      );
    }
    if (isDeliveriesList) {
      return (
        <>
          <UntouchableView>
            <Button type='text' shape="circle" icon={<ArrowRightOutlined />} onClick={showModal}/>
          </UntouchableView>
          <TouchableView>
            <Button type='primary' onClick={showModal}>
              {textContent.label}
            </Button>
          </TouchableView>
        </>
      );
    } else {
      return (
        <>
          <UntouchableView>
            <Button
              icon={icon}
              onClick={showModal}
            >
              {textContent.label}
            </Button>
          </UntouchableView>
          <TouchableView>
            <Button
              icon={icon}
              onClick={showModal}
            />
          </TouchableView>
        </>
      );
    }
  };


  return (
    <>
      {buttonLink()}
      <Modal
        title={
          <Space align='center' size={20}>
            <Text strong css={{ fontSize: 16, color: colors.primary }}>{textContent.modalTitle}</Text>
          </Space>}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={<Button type='primary' icon={copyLinkClick ? <CheckOutlined /> : <CopyOutlined />} onClick={copyLink}> {t('COPY_LINK')} </Button>}
        centered
      >
        <Paragraph>{textContent.modalContent1}</Paragraph>
        <Paragraph>{textContent.modalContent2}</Paragraph>
        <Paragraph>{textContent.modalLink}</Paragraph>
      </Modal>
    </>
  );
}


export default LinkOldBoModal;
