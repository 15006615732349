import { useCallback, useEffect, useState } from 'react';
import AddressesListCard from '../Addresses/AddressesListCard';
import { Flex } from 'antd';
import { readUserAddresses } from '../../services/apiEndPoints/addresses/userAddress/readUserAddresses';

/**
 * Renders the AdressesListCard component.
 * @return {JSX.Element}
 */
function AddressesExample() {
  const [addresses, setAddresses] = useState([]);
  const [addressesLoading, setAddressesLoading] = useState(true);

  useEffect(() => {
    readUserAddresses({ userId: 204944 })
      .then((response) =>{
        setAddresses(response.data);
        setAddressesLoading(false);
      });
  }, []);

  const onChangeSelectedValue = useCallback((address) => {
    return address;
  }
  , []);

  return (
    <Flex vertical gap="large">
      <AddressesListCard
        addresses={addresses}
        setAddresses={setAddresses}
        title='ADDRESSES'
        maxRows={2}
        actions={{
          update: true,
          create: true,
          select: true,
        }}
        userId={204944}
        addressesLoading={addressesLoading}
        selectOtherAddress={onChangeSelectedValue}
      />
      <AddressesListCard
        addresses={addresses}
        setAddresses={setAddresses}
        title='ADDRESSES'
        maxRows={4}
        actions={{
          update: true,
          create: false,
          select: false,
        }}
        userId={204944}
        addressesLoading={addressesLoading}
        selectOtherAddress={onChangeSelectedValue}
      />
      <AddressesListCard
        addresses={addresses}
        setAddresses={setAddresses}
        title='ADDRESSES'
        maxRows={2}
        actions={{
          update: false,
          create: true,
          select: false,
        }}
        userId={204944}
        addressesLoading={addressesLoading}
      />
      <AddressesListCard
        addresses={addresses}
        setAddresses={setAddresses}
        title='ADDRESSES'
        maxRows={2}
        actions={{
          update: false,
          create: false,
          select: true,
        }}
        userId={204944}
        addressesLoading={addressesLoading}
      />
      <AddressesListCard
        addresses={addresses}
        setAddresses={setAddresses}
        title='ADDRESSES'
        maxRows={2}
        actions={{
          update: false,
          create: false,
          select: false,
        }}
        userId={204944}
        addressesLoading={addressesLoading}
      />
    </Flex>
  );
}

export default AddressesExample;
