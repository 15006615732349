/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TimePicker, Form, Segmented, DatePicker, Space } from 'antd';
import dayjs from 'dayjs';
import { getHourRoundByQuarter } from '../../utils/getHourRoundByQuarter';
import { getDateFromIndex, getIndexFromValue } from '../../utils/convertDaysSegementedToPicker';
import { disableTimePickerArray } from '../../utils/disableTimePickerArray';
import { defineInitialDates } from './defineInitialDates';
import {
  isHourInThePast,
  isWrongDeliveryStartHour,
  isWrongDeliveryEndHour,
} from './checkDeliverySlotRules';

/**
 * DeliveryTimeSlot
 * @param {Object} form
 * @param {Object} initialDates // { start: dayjs(), end: dayjs() }
 * @param {string} method // 'PUT' or 'POST'
 * @return {JSX.Element}
 */
function DeliveryTimeSlot({ form, initialDates, method }) {
  const { t } = useTranslation();
  initialDates = (initialDates.start.isValid() && initialDates.end.isValid()) ? initialDates : defineInitialDates(dayjs());
  const [selectedDay, setSelectedDay] = useState(initialDates.start);

  const daysOptions = [
    { label: t('TODAY'), value: 0, disabled: dayjs().isAfter(dayjs().hour(20).minute(0), 'minute') },
    { label: t('TOMORROW'), value: 1 },
    { label: t('AFTER_TOMORROW'), value: 2 },
  ];

  /**
   *  handleHourChangeStart
   * @param {Object} value
   * on arrondie l'heure de début et de fin de livraison par 1/4 heure
   * puis on ajoute 60 minutes à l'heure de fin pour respecter la durée de 1h de delai entre le début et la fin de la livraison et faire gagner du temps au SC
   * et on relance la validation des champs du formulaire de livraison
   */
  const handleHourChangeStart = (value) => {
    form.setFieldsValue({
      hourStart: selectedDay.hour(getHourRoundByQuarter(value).hour()).minute(getHourRoundByQuarter(value).minute()),
      hourEnd: selectedDay.hour(getHourRoundByQuarter(value).hour()).minute(getHourRoundByQuarter(value).minute()).add(1, 'hour'),
    });
    form.validateFields(['hourStart']);
    form.validateFields(['hourEnd']);
  };

  /**
   *  handleHourChangeEnd
   * @param {Object} value
   * on arrondie l'heure de fin de livraison par 1/4 heure
   * et on relance la validation des champs du formulaire de livraison
   */
  const handleHourChangeEnd = (value) => {
    form.setFieldsValue({ hourEnd: selectedDay.hour(getHourRoundByQuarter(value).hour()).minute(getHourRoundByQuarter(value).minute()) });
    form.validateFields(['hourEnd']);
  };

  // validator Rules for start hour
  const checkStartTimeRules = () => {
    const startDate = form.getFieldValue('hourStart').dayOfYear(selectedDay.dayOfYear());

    if (isHourInThePast({ startDate, method, initialDates })) {
      return Promise.reject(new Error(t('HOUR_CANT_BE_IN_THE_PAST')));
    }

    if (isWrongDeliveryStartHour(startDate)) {
      return Promise.reject(new Error(t('WRONG_DELIVERY_HOUR')));
    }

    return Promise.resolve();
  };

  // heures supportées pour les livraisons
  const disabledRangeTime = (_, type) => {
    if (type === 'start') {
      return {
        disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 21, 22, 23],
        disabledMinutes: (selectedHour) => {
          if (selectedHour === 20) {
            return disableTimePickerArray(1, 59);
          }
          return [];
        },
      };
    }
    if (type === 'end') {
      return {
        disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 8, 22, 23],
        disabledMinutes: (selectedHour) => {
          if (selectedHour === 21) {
            return disableTimePickerArray(1, 59);
          }
          return [];
        },
      };
    }
  };

  // validator Rules for end hour
  const checkEndTimeRules = () => {
    // #region Get and set ref times
    if (form.isFieldTouched('hourStart') || form.isFieldTouched('hourEnd')) {
      const startDate = form.getFieldValue('hourStart').dayOfYear(selectedDay.dayOfYear());
      const endDate = form.getFieldValue('hourEnd').dayOfYear(selectedDay.dayOfYear());

      if (endDate.isBefore(dayjs())) {
        return Promise.reject(new Error(t('HOUR_CANT_BE_IN_THE_PAST')));
      }

      if (endDate.isSameOrBefore(startDate)) {
        return Promise.reject(new Error(t('END_HOUR_IS_EGUAL_OR_BEFORE_START_HOUR')));
      }

      if (isWrongDeliveryEndHour(endDate)) {
        return Promise.reject(new Error(t('WRONG_DELIVERY_HOUR')));
      }

      if (endDate.diff(startDate, 'hours') < 1) {
        return Promise.reject(new Error(t('WRONG_INTERVAL_BETWEEN_DELIVERY_DATES')));
      }
    }

    return Promise.resolve();
  };

  /**
   * handleDayChangeDayPicker
   * @param {Object} value
   * on change la valeur du segmented
   * puis on change la valeur du date Picker
   * et on relance la validation des champs du formulaire de livraison
   * */
  const handleDayChangeDayPicker = (value) => {
    setSelectedDay(value);
    form.setFieldsValue({
      deliveryDayButton: getIndexFromValue(value),
      hourStart: value.hour(form.getFieldValue('hourStart').hour()).minute(form.getFieldValue('hourStart').minute()),
      hourEnd: value.hour(form.getFieldValue('hourEnd').hour()).minute(form.getFieldValue('hourEnd').minute()),
    });
    form.validateFields(['hourStart']);
    form.validateFields(['hourEnd']);
  };

  /**
   * handleDayChangeSegmented
   * @param {Object} value
   * on change la valeur du date Picker
   * puis on change la valeur du segmented
   * et on relance la validation des champs du formulaire de livraison
   * */
  const handleDayChangeSegmented = (value) => {
    const dayTemp = getDateFromIndex(value);
    setSelectedDay(dayTemp);
    form.setFieldsValue({
      deliveryDayPicker: dayTemp,
      hourStart: dayTemp.hour(form.getFieldValue('hourStart').hour()).minute(form.getFieldValue('hourStart').minute()),
      hourEnd: dayTemp.hour(form.getFieldValue('hourEnd').hour()).minute(form.getFieldValue('hourEnd').minute()),
    });
    form.validateFields(['hourStart']);
    form.validateFields(['hourEnd']);
  };

  return (
    <>
      <Space direction="vertical" css={{
        width: '100%',
        maxWidth: 520,
      }}>
        <Form.Item
          label={t('DELIVERY_DAY')}
          required
          css={{ marginBottom: '0px' }}
        >
          <Form.Item
            name="deliveryDayButton"
            initialValue={getIndexFromValue(initialDates.start)}
            css={{ marginBottom: '10px' }}
          >
            <Segmented
              options={daysOptions}
              onChange={(e) => handleDayChangeSegmented(e)}
              block
            />
          </Form.Item>
          <Form.Item
            name="deliveryDayPicker"
            initialValue={initialDates.start}
            css={{ marginBottom: '10px' }}
          >
            <DatePicker
              allowClear={false}
              onChange={(value) => handleDayChangeDayPicker(value)}
              disabledDate={(date) => {
                return date.isBefore(dayjs(), 'day');
              }}
              css={{
                width: '100%',
              }}
              format='DD/MM/YYYY'
            />
          </Form.Item>
        </Form.Item>
      </Space>
      <Space
        align='start'
        css={{
          maxWidth: 520,
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: 10,
        }}>
        <Form.Item
          name="hourStart"
          label={t('DELIVERY_START')}
          initialValue={initialDates.start}
          rules={[() => ({
            required: true,
            validator: checkStartTimeRules,
          })]}
        >
          <TimePicker
            css={{
              width: '100%',
            }}
            allowClear={false}
            disabledTime={(e) => disabledRangeTime(e, 'start')}
            onChange={(e) => handleHourChangeStart(e)}
            needConfirm={false}
            format={'HH:mm'}
            showTime={{
              hideDisabledOptions: true,
              minuteStep: 15,
            }}
          />
        </Form.Item>
        <Form.Item
          name="hourEnd"
          label={t('DELIVERY_END')}
          initialValue={initialDates.end}
          rules={[() => ({
            validator: checkEndTimeRules,
            required: true,
          })]}
        >
          <TimePicker
            format={'HH:mm'}
            css={{
              width: '100%',
            }}
            allowClear={false}
            disabledTime={(e) => disabledRangeTime(e, 'end')}
            needConfirm={false}
            onChange={(e) => handleHourChangeEnd(e)}
            showTime={{
              hideDisabledOptions: true,
              minuteStep: 15,
            }}
          />
        </Form.Item>
      </Space>
    </>
  );
}
export default DeliveryTimeSlot;
