/** @jsxImportSource @emotion/react */
import React, { useState, useContext, createContext, useEffect } from 'react';
import { Steps } from 'antd';
import Step1 from './Step01/Step1';
import Step2 from './Step02/Step2';
import Step3 from './Step03/Step3';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../../context/AppContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { breakpoints } from '../../../globalVar/theme';

export const StepContext = createContext();

/**
 * CreateDeliveryForm
 * @return {Form}
 */
function CreateDeliveryForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [store, dispatch] = useContext(AppContext);
  const MIN_STEP = 0;
  const MAX_STEP = 2;
  const [currentStep, setCurrentStep] = useState(0);
  const [stepsValidated, setStepsValidated] = useState([false, false, false]);
  const [showCustomerForm, setShowCustomerForm] = useState(false);

  useEffect(() => {
    if (location.search === '') {
      setCurrentStep(0);
      setShowCustomerForm(false);
    }
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (currentStep < MIN_STEP) {
      setCurrentStep(MIN_STEP);
    }
    if (currentStep > MAX_STEP) {
      setCurrentStep(MAX_STEP);
    }
    navigate(`?step=${currentStep}`);
  }, [currentStep]);

  /**
  * Creation of step items
  */
  const stepItems = [
    {
      title: t('CUSTOMER_STEP'),
      description: `${store.delivery?.customer?.firstName} ${store.delivery?.customer?.lastName}`,
      disabled: !stepsValidated[0],
    },
    {
      title: t('THE_COMMAND_TITLE'),
      description: store.delivery?.drive?.driveName,
      disabled: !stepsValidated[1],
    },
    {
      title: t('DELIVERY_STEP'),
      disabled: !stepsValidated[2],
    },
  ];

  return (
    <StepContext.Provider value={{ currentStep, setCurrentStep, setStepsValidated, showCustomerForm, setShowCustomerForm }}>
      <Steps
        items= {stepItems}
        type={'default'}
        current={currentStep}
        size='small'
        css={{
          marginBottom: 10,
          [breakpoints.md]: {
            maxWidth: 'calc(100% - (174px  * 2) - 25px)',
            margin: '40px auto',
          },
        }}
      />
      {(currentStep <= 0) && <Step1 store={store} dispatch={dispatch} />}
      {currentStep === 1 && <Step2 store={store} dispatch={dispatch} />}
      {currentStep === 2 && <Step3 store={store} dispatch={dispatch} />}
    </StepContext.Provider>
  );
}

export default CreateDeliveryForm;
