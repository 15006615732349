import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Fr from './locales/fr.json';
import En from './locales/en.json';
import FrLu from './locales/fr_LU.json';
import FrBe from './locales/fr_BE.json';
import Nl from './locales/nl.json';
import NlBe from './locales/nl_BE.json';
import It from './locales/it.json';
import Es from './locales/es.json';
import De from './locales/de.json';

// dayjs JS LOCALE IMPORT
import 'dayjs/locale/fr';
import 'dayjs/locale/es';
import 'dayjs/locale/nl';
import 'dayjs/locale/it';
import 'dayjs/locale/pt';
import 'dayjs/locale/nl-be';
import 'dayjs/locale/de';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: 'fr',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: En,
      },
      fr: {
        translation: Fr,
      },
      de: {
        translation: De,
      },
      it: {
        translation: It,
      },
      nl: {
        translation: Nl,
      },
      nl_BE: {
        translation: NlBe,
      },
      fr_BE: {
        translation: FrBe,
      },
      fr_LU: {
        translation: FrLu,
      },
      es: {
        translation: Es,
      },
    },
  });

export default i18n;
