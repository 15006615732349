/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import { Typography, theme } from 'antd';
import type { TextProps } from 'antd/lib/typography/Text';

const { Text } = Typography;

interface DividerWithTextProps {
  text: string;
  colorBg: string;
  colorText: TextProps['type'];
}

function DividerWithText({ text, colorBg, colorText = 'secondary' }: Readonly<DividerWithTextProps>) {
  // TODO : remplacer paddingLG par padding lorsque les marges des cards mobiles seront corrigées
  const { token } = theme.useToken();
  return (
    <StyledDivider colorBg={colorBg} colorText={colorText} paddingLg={token.paddingLG}>
      <Text strong type={colorText}>
        {text}
      </Text>
    </StyledDivider>
  );
}

const StyledDivider = styled.div<{ colorBg: string; colorText: string; paddingLg: number }>`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.colorBg};
  height: 32px;
  border-bottom: 1px solid ${(props) => props.colorText};
  width: calc(100% + ${(props) => props.paddingLg} * 2)px;
  margin: 0 -${(props) => props.paddingLg}px;
  padding: 0 ${(props) => props.paddingLg}px;

  &:first-of-type {
    margin-top: -${(props) => props.paddingLg}px;
  }
`;

export default DividerWithText;
