/** @jsxImportSource @emotion/react */
import { Alert } from 'antd';

/**
 *  InfoMessage
 * @param {string} alertMessageText
 * @param {number} margin
 * @param {string} type
 * @return {JSX.Element} InfoMessage component
 */
const InfoMessage = ({ alertMessageText, margin, type }) => {
  return (
    <Alert
      message={alertMessageText}
      type={type}
      showIcon
      css={{ marginBottom: margin, padding: '10px 15px' }}
    />
  );
};

export default InfoMessage;

