import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import BigEmpty from '../../assets/shopopopEmpty.svg';

export const EmptyData = ({ dataString, style }) => {
  const { t } = useTranslation();

  return (
    <div style={style}>
      <Empty
        image={BigEmpty}
        description={t(dataString)}
      />
    </div>
  );
};
