// useDocumentTitle.js
import { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Sets the document title.
 * @param {string} title The title to set.
 * @param {boolean} prevailOnUnmount Whether to keep the title after the component unmounts.
 * @return {void}
 */
function useDocumentTitle(title, prevailOnUnmount = false) {
  const { t } = useTranslation();
  const defaultTitle = useRef(document.title);

  useEffect(() => {
    document.title = `${title} - ${t('APP_NAME')}`;
  }, [title]);

  useEffect(() => () => {
    if (!prevailOnUnmount) {
      document.title = `${defaultTitle.current} - ${t('APP_NAME')}`;
    }
  }, []);
}

export default useDocumentTitle;
